import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchProviders } from '../../../store/providersSlice';
import { WhitelabelContext } from '../../../whitelabel-config/WhitelabelProvider';
import Message from '../../../components/translation/Message';
import { createProvider } from '../../../api/course-api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'block',
      marginBottom: theme.spacing(6),
    },
  })
);

export default function AddProviderButton() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [providerName, setProviderName] = React.useState('');
  const { getMessage } = React.useContext(WhitelabelContext);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProviderNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProviderName((event.target as HTMLInputElement).value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createProvider(providerName).then(() => {
      dispatch(fetchProviders());
    });
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <Message id="course.courseAdmin.editProvidersDashboardPage.addProvider" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>
          <Message id="course.courseAdmin.editProvidersDashboardPage.addProvider" />
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={`${getMessage('course.courseAdmin.editProvidersDashboardPage.providerName')}`}
              fullWidth
              required
              className={classes.input}
              value={providerName}
              onChange={handleProviderNameChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              <Message id="handleUser.cancel" />
            </Button>
            <Button type="submit" color="primary">
              <Message id="course.courseAdmin.editCoursePage.add" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
