import React, { useState, useEffect } from 'react';
import {
  Box,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { forumCreateTopicUrl } from '../../routes/paths';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchForumTopicPreviews,
  forumTopicsSelector,
  forumTopicsIsLoadingSelector,
  deleteForumTopic,
} from '../../store/forumSlice';
import Message from '../../components/translation/Message';
import {
  forumCategoriesSelector,
  fetchForumCategories,
} from '../../store/forumCategoriesSlice';
import Forum from '../../components/forum/ForumListOverview';
import { fetchUser, userSelector } from '../../store/userSlice';
import PageOrSectionHeader from '../../components/typography/PageOrSectionHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topicsColumns: {
      padding: theme.spacing(1, 0),
      '& > *': {
        alignSelf: 'center',
        paddingRight: theme.spacing(2),
      },
    },
    topicsHeader: {
      color: '#4D555B',
    },
    topicReplies: {
      textAlign: 'center',
    },
    authorAvatar: {
      width: '32px',
      height: '32px',
      fontSize: '18px',
      margin: 'auto',
    },
    selectCategories: {
      padding: '10px 14px',
    },
  })
);

const ForumOverviewPage = () => {
  const [chosenCategory, setchosenCategory] = useState<string>('');

  const forumTopicsPreviews = useSelector(forumTopicsSelector);
  const categories = useSelector(forumCategoriesSelector);
  const isLoading = useSelector(forumTopicsIsLoadingSelector);
  const user = useSelector(userSelector);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchForumCategories({}));
  }, [dispatch]);

  useEffect(() => {
    if (categories && chosenCategory) {
      dispatch(fetchForumTopicPreviews(chosenCategory));
    } else if (categories) {
      dispatch(fetchForumTopicPreviews());
    }

    if (!user) {
      dispatch(fetchUser());
    }
  }, [categories, chosenCategory, dispatch, user]);

  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setchosenCategory(event.target.value as string);
  };

  return (
    <Box p={{ xs: 1, md: 4 }} width='100%' maxWidth='1300px'>
      <PageOrSectionHeader>
        <Message id="forum.overviewPage.titleTopic" />
      </PageOrSectionHeader>
      <Box pt={4} pb={2} display='flex' justifyContent='space-between' flexWrap='wrap'>
        {categories && categories.length > 0 &&
          <Select
            labelId="topic-category"
            value={chosenCategory}
            onChange={handleCategoryChange}
            defaultValue="Show all categories"
            variant="outlined"
            classes={{ outlined: classes.selectCategories }}
            displayEmpty
          >
            <MenuItem value="">
              <Message id="forum.overviewPage.showCategories" />
            </MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        }
        {categories && categories.length === 0 &&
          <Box pr={2}>
            <Typography color='error'>
              <Message id='forum.overviewPage.noCategories' />
            </Typography>
          </Box>
        }
        {!categories && <Box pt={2} />}
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={forumCreateTopicUrl}
          disabled={!categories || categories.length === 0}
        >
          <Message id="forum.overviewPage.createTopic" />
        </Button>
      </Box>
      <Box>
        {user && (
          <Forum
            topicPreviews={forumTopicsPreviews}
            isLoading={isLoading}
            onDelete={(id: string) => {
              dispatch(deleteForumTopic(id));
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ForumOverviewPage;
