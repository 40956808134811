import DOMPurify from 'dompurify';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    limitImageSize: {
      maxWidth: '800px',
      '& > img': {
        maxWidth: '460px',
      },
      fontFamily: "'Roboto', sans-serif",
    },
  })
);

type SafeHtmlRendererProps = {
  html: string;
};

const SafeHtmlRenderer = ({ html }: SafeHtmlRendererProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.limitImageSize}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(html),
      }}
    ></div>
  );
};

export default SafeHtmlRenderer;
