import { makeStyles, createStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getStaticContentImage } from '../../api/instance-api';
import { homeUrl } from '../../routes/paths';
import { WhitelabelContext } from '../../whitelabel-config/WhitelabelProvider';

type DrawerLogoProps = {
  drawerOpen: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoContainer: {
      width: '100%',
      display: 'flex',
      height: 80,
      flexDirection: 'column',
      margin: theme.spacing(2, 0),
      cursor: 'pointer',
      transition: theme.transitions.create(['height', 'width'], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      }),
      position: 'relative',
    },
    containerSmall: {
      height: 50,
    },
    image: {
      transition: theme.transitions.create(['opacity'], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      }),
      position: 'absolute',
    },
    wideLogo: {
      height: 80,
      width: `calc(100% - ${theme.spacing(4)}px)`,
      objectFit: 'contain',
      left: theme.spacing(2),
    },
    squareLogo: {
      height: 50,
      width: 50,
      objectFit: 'contain',
      alignSelf: 'center',
    },
    transparent: {
      opacity: 0,
    },
  })
);

const DrawerLogo = ({ drawerOpen }: DrawerLogoProps) => {
  const { getImage } = useContext(WhitelabelContext);
  const logo = getImage('logotype.logo');
  const logoSquare = getImage('logotype.logoSquare');
  const [logoUrl, setLogoUrl] = useState('');
  const [squareLogoUrl, setSquareLogoUrl] = useState('');

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const fetchImage = async (
      imageUrl: string,
      setImage: React.Dispatch<React.SetStateAction<string>>
    ) => {
      const logoData = await getStaticContentImage(imageUrl);
      const imageSrcUrl = window.URL.createObjectURL(logoData);
      setImage(imageSrcUrl);
    };
    const fetchOrSetImage = (
      imageIdOrUrl: string,
      setImage: React.Dispatch<React.SetStateAction<string>>
    ) => {
      if (imageIdOrUrl.startsWith('/') || imageIdOrUrl.startsWith('data')) {
        setImage(imageIdOrUrl);
      } else if (imageIdOrUrl && imageIdOrUrl.length > 0) {
        fetchImage(imageIdOrUrl, setImage);
      }
    };

    fetchOrSetImage(logo, setLogoUrl);
    fetchOrSetImage(logoSquare, setSquareLogoUrl);
  }, [logo, logoSquare]);

  return (
    <div
      className={clsx(classes.logoContainer, {
        [classes.containerSmall]: !drawerOpen,
      })}
      onClick={() => history.push(homeUrl)}
    >
      <img
        src={logoUrl}
        className={clsx(classes.image, classes.wideLogo, {
          [classes.transparent]: !drawerOpen,
        })}
        alt="Logo"
      />
      <img
        src={squareLogoUrl}
        className={clsx(classes.image, classes.squareLogo, {
          [classes.transparent]: drawerOpen,
        })}
        alt="Small logo"
      />
    </div>
  );
};

export default DrawerLogo;
