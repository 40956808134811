import { ChangeEvent, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AppBar,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import {
  fetchRelatedUsers,
  fetchPermissions,
  userAreaLoadingSelector,
  userAreaSelector,
  permissionsForUserAreaSelector,
  fetchTabs,
  tabsSelector,
  setUserArea,
} from '../../store/userAreaSlice';
import {
  instanceModulesSelector,
  activeSubModulesSelector,
} from '../../store/instanceSlice';
import { relatedUsersBasedOnActorSelector } from '../../store/actorsSlice';
import { useParams, generatePath, useHistory } from 'react-router-dom';
import ListRelationsPage from './ListRelationsPage';
import PrivateForumPage from './PrivateForumPage';
import PrivateMasterclassesPage from './PrivateMasterclassesPage';
import {
  privateForumUrl,
  privateMasterClassUrl,
  privateSettingsUrl,
  actorsPageUrl,
} from '../../routes/paths';
import Message from '../../components/translation/Message';
import mixpanel from 'mixpanel-browser';
import PrivateSettingsPage from './PrivateSettingsPage';
import ActorNamesCaption from '../../components/actors/ActorNamesCaption';
import { Actor, isAdmin, Tab as TabType } from '../../types';
import { getActorList } from '../../utils';
import {
  currentGroupMembersSelector,
  currentGroupSelector,
  fetchGroupMembers,
  groupsSelector,
  setCurrentGroup,
} from '../../store/groupSlice';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import { fetchForumCategories } from '../../store/forumCategoriesSlice';
import { FeatureThemeContext } from '../../theming/FeatureThemeProvider';
import defaultTheme, { groupTheme } from '../../theme';
import { userSelector } from '../../store/userSlice';

const UserAreaPage = () => {
  const { setTheme } = useContext(FeatureThemeContext);
  const [tabValue, setTabValue] = useState<number>(0);
  const [userAreaActors, setUserAreaActors] = useState<Actor[]>([]);
  let params: { id: string; slug: string } = useParams();

  const userArea = useSelector(userAreaSelector);
  const userAreaLoading = useSelector(userAreaLoadingSelector);
  const relatedUsersBasedOnActor = useSelector(
    relatedUsersBasedOnActorSelector
  );
  const groups = useSelector(groupsSelector);
  const currentGroup = useSelector(currentGroupSelector);
  const groupMembers = useSelector(currentGroupMembersSelector);
  const permissions = useSelector(permissionsForUserAreaSelector);

  const activeSubModules = useSelector(activeSubModulesSelector);
  const activeModules = useSelector(instanceModulesSelector);
  const tabs = useSelector(tabsSelector);
  const user = useSelector(userSelector);

  const history = useHistory();
  const dispatch = useDispatch();

  const privateOrGroupForumIsOn = useCallback(() => {
    if (currentGroup) {
      return (
        activeModules?.includes('Forum') &&
        activeSubModules?.Forum?.includes('GroupForum')
      );
    }

    return (
      activeModules?.includes('Forum') &&
      activeSubModules?.Forum?.includes('PrivateForum')
    );
  }, [activeModules, activeSubModules?.Forum, currentGroup]);

  const privateOrGroupCourseOrVideosIsOn = useCallback(() => {
    if (currentGroup) {
      return (
        activeModules?.includes('Course') &&
        (activeSubModules?.Course?.includes('GroupCourse') ||
          activeSubModules?.Course?.includes('GroupVideos'))
      );
    }

    return (
      activeModules?.includes('Course') &&
      (activeSubModules?.Course?.includes('PrivateCourse') ||
        activeSubModules?.Course?.includes('PrivateVideos'))
    );
  }, [activeModules, activeSubModules?.Course, currentGroup]);

  const getSettingsIndex = useCallback((): number => {
    let index = 1;
    if (privateOrGroupCourseOrVideosIsOn() && tabs) {
      index = index + tabs.length;
    }
    if (privateOrGroupForumIsOn()) {
      index = index + 1;
    }
    return index;
  }, [privateOrGroupCourseOrVideosIsOn, privateOrGroupForumIsOn, tabs]);

  const setTabAfterRefresh = useCallback(() => {
    if (!permissions && !currentGroup) {
      setTabValue(0);
      return;
    }

    if (
      tabs &&
      tabs.length > 0 &&
      (permissions?.includes('ReadCourse') || currentGroup)
    ) {
      setTabValue(privateOrGroupForumIsOn() ? 2 : 1);
    } else {
      setTabValue(privateOrGroupForumIsOn() ? 1 : 0);
    }
  }, [currentGroup, permissions, privateOrGroupForumIsOn, tabs]);

  const sendDataToMixpanel = useCallback(() => {
    if (userArea && tabValue === 0) {
      mixpanel.track('USERAREA_PAGELOAD_ACTORS', {
        userAreaId: userArea.id,
        userAreaName: userArea.name,
      });
    }
    if (
      userArea &&
      privateOrGroupForumIsOn() &&
      tabValue === 1 &&
      !params.slug
    ) {
      mixpanel.track('USERAREA_PAGELOAD_PRIVATE_FORUM', {
        userAreaId: userArea.id,
        userAreaName: userArea.name,
      });
    }
    if (
      userArea &&
      privateOrGroupForumIsOn() &&
      tabValue === 1 &&
      params.slug
    ) {
      mixpanel.track('FORUM_TOPIC_PAGELOAD_PRIVATE', {
        userAreaId: userArea.id,
        userAreaName: userArea.name,
        topicSlug: params.slug,
      });
    }
    if (
      userArea &&
      privateOrGroupCourseOrVideosIsOn() &&
      ((privateOrGroupForumIsOn() && tabValue === 2) ||
        (!privateOrGroupForumIsOn() && tabValue === 1))
    ) {
      mixpanel.track('USERAREA_PAGELOAD_PRIVATE_COURSES', {
        userAreaId: userArea.id,
        userAreaName: userArea.name,
      });
    }
  }, [
    params.slug,
    privateOrGroupCourseOrVideosIsOn,
    privateOrGroupForumIsOn,
    tabValue,
    userArea,
  ]);

  const isGroupAdmin = (): boolean => {
    if (!groupMembers || !user || !currentGroup) return false;
    return !!groupMembers.find((gm) => gm.id === user.userInstanceId)
      ?.isGroupAdmin;
  };

  // Fetching useEffects
  useEffect(() => {
    if (!privateOrGroupCourseOrVideosIsOn()) return;
    dispatch(
      fetchTabs({ userAreaId: userArea?.id, groupId: currentGroup?.id })
    );
  }, [dispatch, privateOrGroupCourseOrVideosIsOn, userArea, currentGroup]);

  useEffect(() => {
    if (!privateOrGroupForumIsOn()) return;

    dispatch(
      fetchForumCategories({
        userAreaId: userArea?.id,
        groupId: currentGroup?.id,
      })
    );
  }, [dispatch, privateOrGroupForumIsOn, userArea, currentGroup]);

  useEffect(() => {
    if (currentGroup) {
      dispatch(fetchGroupMembers(currentGroup.id));
    }
  }, [currentGroup, dispatch]);

  useEffect(() => {
    if (userArea) {
      setUserAreaActors(getActorList(userArea));
    }
  }, [userArea]);

  // Correct tab useEffect
  useEffect(() => {
    if (!permissions && !currentGroup) {
      setTabValue(0);
      return;
    }

    const currentPath = window.location.pathname;
    if (currentGroup && currentPath.includes('new-group')) {
      setTabValue(0);
    } else if (
      currentPath.includes('tab') &&
      !currentPath.includes('videos') &&
      tabs &&
      tabs.length > 0 &&
      (permissions?.includes('ReadCourse') || currentGroup)
    ) {
      const split = currentPath.split('/');
      const currentTabId = split[split.length - 1];
      const tab = tabs.find((t) => t.id === currentTabId);
      const tabIndex = tabs.findIndex((t) => t.id === tab?.id);
      setTabValue(privateOrGroupForumIsOn() ? tabIndex + 2 : tabIndex + 1);
    } else if (
      currentPath.includes('privateForum') &&
      privateOrGroupForumIsOn()
    ) {
      setTabValue(1);
    } else if (
      currentPath.includes('settings') &&
      (permissions?.includes('WriteForumCategories') ||
        permissions?.includes('AssignAndRemoveCourse') ||
        currentGroup)
    ) {
      setTabValue(getSettingsIndex());
    } else if (currentPath.includes('actors')) {
      setTabValue(0);
    } else {
      setTabAfterRefresh();
    }
  }, [
    tabs,
    permissions,
    params.id,
    privateOrGroupForumIsOn,
    getSettingsIndex,
    setTabAfterRefresh,
    currentGroup,
  ]);

  useEffect(() => {
    if (userArea && !currentGroup && !permissions) {
      dispatch(fetchPermissions(userArea.id));
      dispatch(fetchRelatedUsers(params.id));
      sendDataToMixpanel();
    }

    // If refresh
    if (
      ((!userArea && !currentGroup) || params.slug) &&
      (relatedUsersBasedOnActor || groups)
    ) {
      const group = groups?.find((g) => g.id === params.id);
      if (group) {
        dispatch(setCurrentGroup(group));
      } else if (relatedUsersBasedOnActor) {
        const chosenArea = relatedUsersBasedOnActor.find(
          (u) => u.id === params.id
        );
        if (chosenArea) dispatch(setUserArea(chosenArea));
      } else {
        //history.push(generatePath(homeUrl));
      }
    }
  }, [
    params.id,
    params.slug,
    relatedUsersBasedOnActor,
    userArea,
    sendDataToMixpanel,
    history,
    currentGroup,
    groups,
  ]);

  // Theme useEffect
  useEffect(() => {
    if (currentGroup) {
      setTheme(groupTheme);
    } else {
      setTheme(defaultTheme);
    }
  }, [setTheme, currentGroup]);

  const getTabPanelIndex = (tabIndex: number): number => {
    let index = tabIndex + 1;
    if (privateOrGroupForumIsOn()) {
      index = index + 1;
    }
    return index;
  };

  const handleTabChange = (_event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box p={4}>
      {!currentGroup && (!userArea || userAreaLoading || !permissions) && (
        <CircularProgress size={64} />
      )}
      {(currentGroup || (userArea && !userAreaLoading && permissions)) &&
        user && (
          <Box>
            <Box mb={2}>
              <Typography variant="h4" component="h2">
                {currentGroup?.name || userArea?.name}
              </Typography>
              <ActorNamesCaption
                actorNames={
                  currentGroup && groupMembers
                    ? [
                        groupMembers.length > 1
                          ? groupMembers.length +
                            ' ' +
                            getMessage('userArea.members.membersAddButton')
                          : groupMembers.length +
                            ' ' +
                            getMessage('userArea.members.memberAddButton'),
                      ]
                    : userAreaActors.map((a) => a.name)
                }
              />
            </Box>
            <AppBar position="static">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="user-area-page-tabs"
                max-width="1000px"
                variant="scrollable"
              >
                <Tab
                  label={<Message id="userArea.tabActors" />}
                  onClick={() => {
                    history.push(
                      generatePath(actorsPageUrl, {
                        id: currentGroup ? currentGroup.id : userArea?.id,
                      })
                    );
                  }}
                />
                {privateOrGroupForumIsOn() &&
                  (permissions?.includes('ReadForum') || currentGroup) && (
                    <Tab
                      label={<Message id="userArea.tabPrivateForum" />}
                      onClick={() => {
                        history.push(
                          generatePath(privateForumUrl, {
                            id: currentGroup ? currentGroup.id : userArea?.id,
                          })
                        );
                      }}
                    />
                  )}
                {privateOrGroupCourseOrVideosIsOn() &&
                  (permissions?.includes('ReadCourse') || currentGroup) &&
                  tabs &&
                  tabs.map((tab) => (
                    <Tab
                      key={tab.id + 'tab'}
                      label={tab.name}
                      onClick={() => {
                        history.push(
                          generatePath(privateMasterClassUrl, {
                            id: currentGroup ? currentGroup.id : userArea?.id,
                            tabId: tab.id,
                          })
                        );
                      }}
                    />
                  ))}
                {(isAdmin(user) ||
                  isGroupAdmin() ||
                  (!currentGroup &&
                    (permissions?.includes('WriteForumCategories') ||
                      permissions?.includes('AssignAndRemoveCourse')))) && (
                  <Tab
                    label={<Message id="userArea.tabSettings" />}
                    onClick={() => {
                      history.push(
                        generatePath(privateSettingsUrl, {
                          id: currentGroup ? currentGroup.id : userArea?.id,
                        })
                      );
                    }}
                  />
                )}
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              <ListRelationsPage userAreaActors={userAreaActors} />
            </TabPanel>
            {privateOrGroupForumIsOn() &&
              (permissions?.includes('ReadForum') || currentGroup) && (
                <TabPanel value={tabValue} index={1}>
                  <PrivateForumPage />
                </TabPanel>
              )}
            {privateOrGroupCourseOrVideosIsOn() &&
              (permissions?.includes('ReadCourse') || currentGroup) &&
              tabs &&
              tabs.map((tab: TabType, tabIndex: number) => (
                <TabPanel
                  key={tab.id + 'panel'}
                  value={tabValue}
                  index={getTabPanelIndex(tabIndex)}
                >
                  <PrivateMasterclassesPage tab={tab} />
                </TabPanel>
              ))}
            {((!currentGroup &&
              (permissions?.includes('WriteForumCategories') ||
                permissions?.includes('AssignAndRemoveCourse'))) ||
              isGroupAdmin() ||
              isAdmin(user)) && (
              <TabPanel value={tabValue} index={getSettingsIndex()}>
                <PrivateSettingsPage />
              </TabPanel>
            )}
          </Box>
        )}
    </Box>
  );
};

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default UserAreaPage;
