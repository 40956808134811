import {
    Box,
    Typography,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';

type StyleProps = {
    channelUrl: string,
    currentChannelUrl: string,
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: (props: StyleProps) => ({
            height: '72px',
            padding: '8px 8px 8px 16px',
            cursor: 'pointer',
            backgroundColor: `${props.channelUrl === props.currentChannelUrl ? theme.palette.secondary.light : ''}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${theme.palette.grey[100]}`
        }),
        flex: {
            display: 'flex'
        },
        names: (props: StyleProps) => ({
            fontSize: '14px',
            maxWidth: '225px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: '8px',
            color: `${props.channelUrl === props.currentChannelUrl ? theme.palette.primary.main : ''}`
        }),
        nrOfChannelMembers: {
            opacity: '50%',
            marginRight: '8px'
        },
        lastMessage: {
            opacity: '50%',
            width: '225px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block'
        },
        unreadMessagesContainer: {
            display: 'flex',
            height: '100%',
            alignItems: 'center'
        },
        unreadMessages: {
            borderRadius: '50%',
            width: '16px',
            height: '16px',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '8px'
        },
        currentChannelMarker: (props: StyleProps) => ({
            width: '8px',
            height: '100%',
            backgroundColor: `${props.channelUrl === props.currentChannelUrl ? theme.palette.primary.main : ''}`,
            borderRadius: '4px'
        })
    })
);

type CustomChannelPreviewInnerProps = {
    names: string | JSX.Element,
    channelUrl: string,
    currentChannelUrl: string,
    nrOfChannelMembers: number,
    lastMessage: string,
    unreadMessageCount: number
}
const CustomChannelPreviewInner = ({
    names,
    channelUrl,
    currentChannelUrl,
    nrOfChannelMembers,
    lastMessage,
    unreadMessageCount
}: CustomChannelPreviewInnerProps) => {
    const classes = useStyles({ channelUrl, currentChannelUrl });
    return (
        <Box className={classes.wrapper}>
            <Box>
                <Box className={classes.flex}>
                    <Typography variant='subtitle2' className={classes.names}>
                        {names}
                    </Typography>
                    <Typography variant='body2' className={classes.nrOfChannelMembers}>
                        {nrOfChannelMembers}
                    </Typography>
                </Box>
                <Typography variant='caption' className={classes.lastMessage}>
                    {lastMessage}
                </Typography>
            </Box>
            <Box className={classes.unreadMessagesContainer}>
                {unreadMessageCount > 0 &&
                    <Box className={classes.unreadMessages}>
                        <Typography variant='caption'>
                            {unreadMessageCount}
                        </Typography>
                    </Box>
                }
                <Box className={classes.currentChannelMarker}></Box>
            </Box>
        </Box>
    )
}

export default CustomChannelPreviewInner;
