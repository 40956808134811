import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridRowModel,
} from '@material-ui/data-grid';
import {
  deleteRelationship,
  setRelationshipOnUser,
  relatedUsersSelector,
  userAreaLoadingSelector,
  userAreaSelector,
  deleteRelationErrorMsgSelector,
  setHasAddRelationshipError,
  hasAddRelationshipErrorSelector,
  setCheckedAddedMemberIds,
} from '../../../store/userAreaSlice';
import {
  actorsSelector,
  fetchActors,
  fetchActorsUsersDetailsList,
  assignableActorsInRelationToUserSelector,
  fetchAssignableActorsInRelationToUser,
  actorsUsersDetailsListSelector,
} from '../../../store/actorsSlice';
import {
  Actor,
  ActorsUsersDetails,
  GroupMember,
  isAdmin,
} from '../../../types';
import Message from '../../../components/translation/Message';
import { getMessage } from '../../../whitelabel-config/WhitelabelProvider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useParams } from 'react-router-dom';
import DataGridHeader from '../../../components/DataGridHeader';
import InfoDialog from '../../../components/InfoDialog';
import { userSelector } from '../../../store/userSlice';
import PageOrSectionHeader from '../../../components/typography/PageOrSectionHeader';
import AddMembersDialog from './AddMembersDialog';
import DeleteButton from '../../../components/DeleteButton';
import ActorNamesCaption from '../../../components/actors/ActorNamesCaption';
import {
  currentGroupMembersSelector,
  currentGroupSelector,
  fetchGroupMembers,
} from '../../../store/groupSlice';
import { deleteGroupMember } from '../../../api/user-api';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeRelations: {
      display: 'block',
      cursor: 'pointer',
      color: theme.palette.grey[500],
      '&:hover': {
        color: theme.palette.grey[600],
      },
    },
  })
);

type ListRelationsPageProps = {
  userAreaActors: Actor[];
};
const ListRelationsPage = ({ userAreaActors }: ListRelationsPageProps) => {
  const [fromUser, setFromUser] = useState<ActorsUsersDetails | null>(null);
  const [actorToUser, setActorToUser] = useState<Actor | null>(null);
  const [assignableActorsIds, setAssignableActorsIds] = useState<string[]>([]);
  const [relationToDelete, setRelationToDelete] = useState<any | null>(null);
  const [isAddMembersDialogOpen, setIsAddMembersDialogOpen] =
    useState<boolean>(false);

  let params: { id: string } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector(userSelector);
  const userAreaLoading = useSelector(userAreaLoadingSelector);
  const userArea = useSelector(userAreaSelector);
  const relatedUsers = useSelector(relatedUsersSelector);
  const currentGroup = useSelector(currentGroupSelector);
  const groupMembers = useSelector(currentGroupMembersSelector);

  const assignableActorsInRelationToUser = useSelector(
    assignableActorsInRelationToUserSelector
  );
  const actors = useSelector(actorsSelector);
  const actorUserDetails = useSelector(actorsUsersDetailsListSelector);
  const hasAddRelationshipError = useSelector(hasAddRelationshipErrorSelector);
  const deleteRelationError = useSelector(deleteRelationErrorMsgSelector);

  useEffect(() => {
    dispatch(setCheckedAddedMemberIds([]));
  });

  useEffect(() => {
    if (!actors) {
      dispatch(fetchActors());
    }
  }, [actors, dispatch, params.id]);

  useEffect(() => {
    if (!actorUserDetails) {
      dispatch(fetchActorsUsersDetailsList());
    }
  }, [actorUserDetails, dispatch, params.id]);

  useEffect(() => {
    if (!currentGroup) {
      dispatch(fetchAssignableActorsInRelationToUser(params.id));
    }
  }, [params.id, dispatch, currentGroup]);

  useEffect(() => {
    if (assignableActorsInRelationToUser) {
      setAssignableActorsIds(Object.values(assignableActorsInRelationToUser));
    }
  }, [assignableActorsInRelationToUser, params.id]);

  const isGroupAdmin = (): boolean => {
    if (!groupMembers || !user || !currentGroup) return false;
    return !!groupMembers.find((gm) => gm.id === user.userInstanceId)
      ?.isGroupAdmin;
  };

  const handleDeleteMember = (id: string) => {
    if (currentGroup && groupMembers) {
      deleteGroupMember(currentGroup.id, id)
        .then(() => {
          enqueueSnackbar(
            <Message id="userArea.members.deleteMemberSuccess" />,
            {
              variant: 'success',
            }
          );
          dispatch(fetchGroupMembers(currentGroup.id));
        })
        .catch((error) => {
          enqueueSnackbar(
            <Message id="userArea.members.deleteMemberFailed" />,
            {
              variant: 'error',
            }
          );
        });
    }
  };

  const getAssignableActorsToThisActor = (): Actor[] => {
    if (!actors || !actorUserDetails || actorUserDetails.length === 0 || !user)
      return [];

    const userAreaActorIds = userAreaActors.map((a) => a.id);
    const currentActorDetails = actorUserDetails.find(
      (au) => au.id === user.id || au.id === user.userInstanceId
    );
    if (!currentActorDetails) return [];

    let assignableActors: Actor[] = [];
    if (isAdmin(user)) {
      assignableActors = actors.filter((a) =>
        a.assignableAsRelationshipOnlyToActorIds.find((assignableToActorId) =>
          userAreaActorIds.includes(assignableToActorId)
        )
      );
    }

    if (!isAdmin(user) && relatedUsers) {
      const currentRelatedUser = relatedUsers.filter(
        (rU) => rU.id === user.id || rU.id === user.userInstanceId
      );
      const currentActorIds = currentRelatedUser
        .map((rU) => rU.actors)
        .map((a) => a[0].id);
      const currentCanAssignActorIds = actors
        .filter((a) => currentActorIds.includes(a.id))
        .map((a) => a.canAssignActorIds)
        .flat(1);

      assignableActors = actors.filter((a) =>
        a.assignableAsRelationshipOnlyToActorIds.find(
          (assignableToActorId) =>
            userAreaActorIds.includes(assignableToActorId) &&
            currentCanAssignActorIds.includes(a.id)
        )
      );
    }

    return assignableActors;
  };

  const addNewRelationship = () => {
    if (!fromUser || !actorToUser || !userArea) {
      return;
    }
    dispatch(setRelationshipOnUser(fromUser, actorToUser, userArea.id));
    setFromUser(null);
    setActorToUser(null);
  };

  const rows = useMemo(() => {
    if (currentGroup && groupMembers) {
      return groupMembers.map((member) => ({
        ...member,
        actors: member.actors.length > 0 ? member.actors : '',
      }));
    }

    if (relatedUsers) {
      return relatedUsers.map((user) => ({
        ...user,
        actor: user.actors[0]?.name,
      }));
    }

    return [];
  }, [currentGroup, groupMembers, relatedUsers]);

  const onDelete = (row: GridRowModel) => {
    const relationshipToDelete = {
      toUserId: params.id,
      fromUserId: row.id,
      actorId: row.actors[0].id,
    };
    dispatch(deleteRelationship(relationshipToDelete));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      renderHeader: () => (
        <DataGridHeader text={getMessage('handleUser.name')} />
      ),
      flex: 1,
    },
    {
      field: 'email',
      renderHeader: () => (
        <DataGridHeader text={getMessage('handleUser.email')} />
      ),
      flex: 1,
    },
    {
      field: 'actor',
      hide: !actors || actors.length === 0,
      renderHeader: () => (
        <DataGridHeader text={getMessage('handleActors.actor')} />
      ),
      renderCell: (params: GridCellParams) => {
        const rowActors = params.row.actors as Actor[];
        return (
          <ActorNamesCaption
            actorNames={
              rowActors && rowActors.length > 0
                ? rowActors.map((a) => a.name)
                : ['']
            }
          />
        );
      },
      flex: 1,
    },
    {
      field: 'isAdmin',
      hide: !currentGroup,
      renderHeader: () => (
        <DataGridHeader text={getMessage('userArea.settings.groupAdmins')} />
      ),
      renderCell: (params: GridCellParams) => {
        if ((params.row as GroupMember).isGroupAdmin) {
          return (
            <Box>
              <Typography variant="caption">
                <Message id="userArea.admin" />
              </Typography>
            </Box>
          );
        }
        return <></>;
      },
      flex: 1,
    },
    {
      field: 'removeRelations',
      renderHeader: () => <Box pt={4} pb={4} />,
      sortable: false,
      width: 60,
      renderCell: (params: GridCellParams) => {
        if (currentGroup && user && (isGroupAdmin() || isAdmin(user))) {
          return (
            <Box width="100%" display="flex" justifyContent="center">
              <DeleteButton
                dialogTitle={getMessage('userArea.members.removeMember')}
                deleteDesc={getMessage(
                  'userArea.members.removeMemberDialogDesc'
                )}
                itemCategory={getMessage('userArea.members.member')}
                itemName={(params.row as GroupMember).name}
                button={
                  <Box>
                    <HighlightOffIcon className={classes.removeRelations} />
                  </Box>
                }
                onDelete={() =>
                  handleDeleteMember((params.row as GroupMember).id)
                }
              />
            </Box>
          );
        }

        if (
          assignableActorsIds &&
          assignableActorsIds.length > 0 &&
          assignableActorsIds.includes(params.row.actors[0]?.id)
        ) {
          return (
            <Box onClick={() => setRelationToDelete(params.row as any)}>
              <HighlightOffIcon className={classes.removeRelations} />
            </Box>
          );
        }

        return <></>;
      },
    },
  ];

  if (userAreaLoading) {
    return (
      <Box py={2}>
        <CircularProgress size={64} />
      </Box>
    );
  }

  return (
    <Box py={2}>
      {(userArea || currentGroup) && user && (
        <Box maxWidth={1100}>
          {userArea &&
            actors &&
            actorUserDetails &&
            actorUserDetails.length > 0 && (
              <Box>
                <PageOrSectionHeader>
                  <Message id="userArea.members.addRelations" />
                </PageOrSectionHeader>

                <Box pt={2} display="flex">
                  {/* Users som går assigna något relationship till denna usern */}
                  <Autocomplete
                    id="from-user"
                    options={actorUserDetails.filter(
                      (a) => a.id !== userArea.id
                    )}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    style={{ width: 300 }}
                    onChange={(_e, value, reason) => {
                      if (reason === 'select-option' && value) {
                        setFromUser(value);
                        dispatch(setHasAddRelationshipError(false));
                      } else {
                        setFromUser(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${getMessage('handleActors.fromUser')}`}
                        variant="outlined"
                      />
                    )}
                    value={fromUser}
                  />

                  {/* De actors som går att assigna (som relationship) till denna userns actors */}
                  <Autocomplete
                    id="actors"
                    options={getAssignableActorsToThisActor()}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    style={{ width: 300 }}
                    onChange={(_e, value, reason) => {
                      if (reason === 'select-option' && value) {
                        setActorToUser(value);
                      } else {
                        setActorToUser(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${getMessage('handleActors.actor')}`}
                        variant="outlined"
                      />
                    )}
                    value={actorToUser}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ alignSelf: 'center', marginLeft: '15px' }}
                    disabled={!actorToUser || !fromUser}
                    onClick={addNewRelationship}
                  >
                    <Message id="siteSettings.save" />
                  </Button>
                </Box>
                {hasAddRelationshipError && (
                  <Typography variant="body2" component="p" color="error">
                    <Message id="handleActors.relationAlreadyExistError" />
                  </Typography>
                )}
              </Box>
            )}
          <Box my={currentGroup ? 0 : 4} width="100%" maxWidth="966px" pt={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <PageOrSectionHeader>
                {currentGroup ? (
                  <Message id="userArea.members.groupMembers" />
                ) : (
                  <>
                    <Message id="userArea.members.listedRelations" />
                    {' ' + userArea?.name}
                  </>
                )}
              </PageOrSectionHeader>
              {currentGroup && (isGroupAdmin() || isAdmin(user)) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddMembersDialogOpen(true)}
                >
                  <Message id="userArea.members.addMembers" />
                </Button>
              )}
            </Box>
            {(relatedUsers || groupMembers) && (
              <Box>
                <Box pt={2}>
                  <DataGrid
                    autoHeight
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                  />
                </Box>
                {!!relationToDelete && userArea && (
                  <InfoDialog
                    open={!!relationToDelete}
                    onClose={() => setRelationToDelete(null)}
                    action={() => onDelete(relationToDelete)}
                  >
                    <DialogTitle>
                      <Message id="handleActors.removeRelationship" />
                    </DialogTitle>
                    <DialogContent>
                      <Message id="siteSettings.deleteDialog" />
                      <Box pt={2}>
                        <Typography variant="subtitle2" component="span">
                          <Message id="handleActors.fromUser" />
                          {': '}
                        </Typography>
                        <Typography component="span">
                          {relationToDelete.name}
                        </Typography>
                      </Box>
                      <Box pt={2}>
                        <Typography variant="subtitle2" component="span">
                          <Message id="handleActors.relationship" />
                          {': '}
                        </Typography>
                        <Typography component="span">
                          {relationToDelete.actor}
                        </Typography>
                      </Box>
                      <Box pt={2}>
                        <Typography variant="subtitle2" component="span">
                          <Message id="handleActors.toUser" />
                          {': '}
                        </Typography>
                        <Typography component="span">
                          {userArea.name}
                        </Typography>
                      </Box>
                    </DialogContent>
                  </InfoDialog>
                )}
                {deleteRelationError && (
                  <Box pt={2}>
                    <Typography color="error">{deleteRelationError}</Typography>
                  </Box>
                )}
              </Box>
            )}
            {!relatedUsers && !currentGroup && (
              <Box pt={2}>
                <Message id="userArea.members.noRelations" />
              </Box>
            )}
          </Box>
          <AddMembersDialog
            isOpen={isAddMembersDialogOpen}
            onClose={() => setIsAddMembersDialogOpen(false)}
          />
        </Box>
      )}
    </Box>
  );
};

export default ListRelationsPage;
