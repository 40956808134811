import { ForumTopicPreview, isAdmin, Notification } from '../../types';
import {
  Box,
  Theme,
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  makeStyles,
  createStyles,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { instanceModulesSelector } from '../../store/instanceSlice';
import { notificationsSelector } from '../../store/notificationsSlice';
import { getForumTopicUrl, getPrivateForumTopicUrl } from '../../routes/paths';
import theme, { getInstructorColor } from '../../theme';
import { formatFullDate, isUserActive } from '../../utils';
import Message from '../translation/Message';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import { userSelector } from '../../store/userSlice'
import DeleteButton from '../DeleteButton';
import TextEllipsis from '../TextEllipsis';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    forumTopicTable: {
      width: '100%',
      maxWidth: 1300,
    },
    titlePage: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2em',
    },
    topicsColumns: {
      padding: theme.spacing(),
      paddingLeft: theme.spacing(2),
      justifyContent: 'space-between',
      '& > *': {
        alignSelf: 'center',
      },
    },
    topicsHeader: {
      color: '#4D555B',
    },
    topicReplies: {
      textAlign: 'center',
    },
    authorAvatar: {
      width: '32px',
      height: '32px',
      fontSize: '18px',
      margin: 'auto',
    },
    categoriesSelect: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '1em',
    },
    selectCategories: {
      padding: '10px 14px',
    },
    numberOfReplies: {
      width: '70%',
    },
    backgroundUneven: {
      backgroundColor: theme.palette.background.default
    },
    backgroundEven: {
      backgroundColor: theme.palette.grey[100]
    }
  })
);

type ForumProps = {
  topicPreviews: ForumTopicPreview[];
  isLoading?: boolean;
  onDelete?: (id: string) => void;
};

const Forum = ({
  topicPreviews,
  isLoading,
  onDelete,
}: ForumProps) => {
  const activeModules = useSelector(instanceModulesSelector);
  const notifications = useSelector(notificationsSelector);
  const user = useSelector(userSelector);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const isTopicInUserArea = (topic: ForumTopicPreview) => {
    return topic.privateForumUserId
      && topic.privateForumUserId !== '00000000-0000-0000-0000-000000000000';
  }

  const isTopicInGroup = (topic: ForumTopicPreview) => {
    return topic.groupId
      && topic.groupId !== '00000000-0000-0000-0000-000000000000';
  }

  const isTopicInNotification = (topicId: string): boolean => {
    return !!notifications?.find(
      (notification: Notification) => notification.referenceId === topicId
    );
  }

  const getTopicUrl = (topic: ForumTopicPreview) => {
    if (isTopicInUserArea(topic)) {
      return getPrivateForumTopicUrl(
        topic.slug,
        topic.privateForumUserId || ''
      );
    }
    if (isTopicInGroup(topic)) {
      return getPrivateForumTopicUrl(
        topic.slug,
        topic.groupId || ''
      );
    }

    return getForumTopicUrl(topic.slug);
  }

  const handleDeleteTopic = (id: string): void => {
    if (id && onDelete) {
      onDelete(id);
    }
  };


  return (
    <Box>
      {/* Header */}
      <Grid container className={classes.topicsColumns}>
        <Grid item xs={2} md={2}>
          <Typography
            variant="subtitle2"
            component="p"
            className={classes.topicsHeader}
          ><Message id="forum.overviewPage.titleSubject" />
          </Typography>
        </Grid>
        {!isXs && (
          <Grid item xs={2} md={2}>
            <Typography
              variant="subtitle2"
              component="p"
              className={classes.topicsHeader}
            >
              <Message id="forum.overviewPage.titleCategory" />
            </Typography>
          </Grid>
        )}
        <Grid
          item xs={2} md={2}
          className={isDesktop ? '' : classes.topicReplies}
        >
          <Typography
            variant="subtitle2"
            component="p"
            className={classes.topicsHeader}
          >
            <Message id="forum.overviewPage.titleAuthor" />
          </Typography>
        </Grid>
        {!isXs &&
          <Grid item xs={2} md={2}>
            <Typography
              variant="subtitle2"
              component="p"
              className={classes.topicsHeader}
            >
              <Message id="forum.overviewPage.titleActivity" />
            </Typography>
          </Grid>
        }
        {!isSm &&
          <>
            <Grid item xs={2} md={1} className={classes.topicReplies}>
              <Box display="flex">
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={`${classes.topicsHeader} ${classes.numberOfReplies}`}
                >
                  <Message id="forum.overviewPage.titleReplies" />
                </Typography>
              </Box>
            </Grid>
            {onDelete &&
              <Grid item xs={2} md={1} className={classes.topicReplies}>
              </Grid>
            }
          </>
        }
      </Grid>

      <Divider component="div" />

      {/* Row content */}
      {isLoading && (
        <Box p={5} textAlign={'center'}>
          <CircularProgress size={64} />
        </Box>
      )}
      {!isLoading && topicPreviews.length <= 0 && (
        <Box p={5} textAlign={'center'}>
          <Message id="forum.overviewPage.missingTopics" />
        </Box>
      )}
      {!isLoading &&
        topicPreviews.map((topic, index) => (
          <Grid
            key={topic.id}
            container
            className={`${classes.topicsColumns} ${index % 2 === 0
              ? classes.backgroundEven
              : classes.backgroundUneven}`
            }
          >
            <Grid item xs={2} md={2}>
              <Link
                component={RouterLink}
                to={getTopicUrl(topic)}
              >
                <Typography
                  color="inherit"
                  variant={
                    activeModules?.includes('Notification')
                      && isTopicInNotification(topic.id)
                      ? 'subtitle2'
                      : 'body1'
                  }
                >
                  <TextEllipsis width={180}>{topic.subject}</TextEllipsis>
                </Typography>
              </Link>
            </Grid>
            {!isXs && (
              <Grid item xs={2} md={2}>
                <Typography>{topic.category.name}</Typography>
              </Grid>
            )}
            <Grid
              item xs={1} md={2}
              className={isDesktop ? '' : classes.topicReplies}
            >
              {isDesktop ?
                <Typography>
                  {topic.author.name
                    ? <TextEllipsis width={180}>{topic.author.name}</TextEllipsis>
                    : <Message id="profile.inactiveUser" />
                  }
                </Typography>
                :
                <Avatar
                  className={`${classes.authorAvatar} ${classes.topicReplies}`}
                  style={{
                    backgroundColor: isUserActive(topic.author.id)
                      ? getInstructorColor(topic.author.id)
                      : 'grey',
                  }}
                >
                  {getInitials(topic.author.name) || null}
                </Avatar>
              }
            </Grid>
            {!isXs &&
              <>
                <Grid item xs={2} md={2}>
                  <Typography>{formatFullDate(topic.updatedAt)}</Typography>
                </Grid>
                {!isSm &&
                  <Grid item xs={2} md={1} className={classes.topicReplies}>
                    <Typography className={classes.numberOfReplies}>
                      {topic.numberOfReplies}
                    </Typography>
                  </Grid>
                }
                {!isSm &&
                  <Grid item xs={2} md={1} className={classes.topicReplies}>
                    <Box display="flex" width='100%' justifyContent='center' alignItems='center'>
                      {onDelete && user && (isAdmin(user) || user.userInstanceId === topic.author.id)
                        ? <DeleteButton
                          itemCategory={getMessage('forum.overviewPage.topic')}
                          itemName={topic.subject}
                          dialogTitle={getMessage('forum.overviewPage.deleteTopic')}
                          icon={<HighlightOffIcon />}
                          onDelete={() => handleDeleteTopic(topic.id)}
                        />
                        : <Box p={3} />
                      }
                    </Box>
                  </Grid>
                }
              </>
            }
          </Grid>
        ))
      }
    </Box >
  );
};

const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((n) => n[0])
    .join('');
};

export default Forum;
