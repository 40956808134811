import {
    Box,
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core"
import Message from "../../components/translation/Message"
import { Actor } from "../../types"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actions: {
            paddingBottom: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
        }
    })
);

type RemoveActorDialogProps = {
    actor: Actor | null,
    onClose: () => void,
    onDelete: () => void
}
const RemoveActorDialog = ({
    actor,
    onClose,
    onDelete
}: RemoveActorDialogProps) => {
    const classes = useStyles();
    return (
        <Dialog open={!!actor} onClose={onClose} fullWidth>
            <DialogTitle>
                <Message id='handleActors.removeActor' />
            </DialogTitle>

            <DialogContent>
                <Box>
                    <Typography variant='subtitle2' component='span'>
                        <Message id='handleActors.actor' />
                        {': '}
                    </Typography>
                    <Typography component='span'>{actor?.name}</Typography>
                </Box>
                <Box py={2}>
                    <Typography color='error'>
                        <Message id='handleActors.warningRemoveActor' />
                    </Typography>
                </Box>
            </DialogContent>

            <DialogActions className={classes.actions}>
                <Button
                    variant='contained'
                    onClick={onClose}
                >
                    <Message id='handleActors.cancel' />
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => { onDelete(); onClose() }}
                >
                    <Message id='handleActors.delete' />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveActorDialog;