import {
    Dialog,
    Box,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    Checkbox,
    Divider
} from "@material-ui/core";
import Message from "../../../components/translation/Message";
import {
    checkedAddedMembersIdsSelector,
    filteredActorUserDetailsSelector,
    setCheckedAddedMemberIds,
    toggleCheckedAddedMemberId
} from "../../../store/userAreaSlice";
import { useDispatch, useSelector } from "react-redux";
import { actorsSelector, actorsUsersDetailsListSelector } from "../../../store/actorsSlice";
import { useState } from "react";
import { getAUById } from "../utils";
import ActorNamesCaption from "../../../components/actors/ActorNamesCaption";
import ActorUserListWithSearchAndFilter from "../../../components/actors/ActorUserListWithSearchAndFilter";
import {
    currentGroupMembersSelector,
    currentGroupSelector,
    fetchGroupMembers,
} from "../../../store/groupSlice";
import { useSnackbar } from "notistack";
import { editGroupMembers } from "../../../api/user-api";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchField: {
            width: '280px',
        },
        listItem: {
            backgroundColor: theme.palette.grey[100],
            border: `1px solid ${theme.palette.grey[100]}`,
            borderRadius: theme.shape.borderRadius
        },
        checkedListItem: {
            backgroundColor: theme.palette.secondary.light
        },
        alreadyAddedListItem: {
            backgroundColor: theme.palette.background.default,
            border: `1px solid ${theme.palette.grey[200]}`,
            color: theme.palette.grey[500]
        },
        memberBadge: {
            color: theme.palette.grey[900],
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            borderRadius: theme.shape.borderRadius
        },
        textEllipsis: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    })
);

type AddMembersDialogProps = {
    isOpen: boolean,
    onClose: () => void
}
const AddMembersDialog = ({
    isOpen,
    onClose,
}: AddMembersDialogProps) => {
    const checkedAddedMembersIds = useSelector(checkedAddedMembersIdsSelector);
    const currentGroup = useSelector(currentGroupSelector);
    const groupMembers = useSelector(currentGroupMembersSelector);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = () => {
        if (currentGroup && groupMembers) {
            const newMemberIds = groupMembers
                .filter(gm => gm.isGroupAdmin)
                .map(gm => gm.id)
                .concat(checkedAddedMembersIds);
            editGroupMembers(currentGroup.id, newMemberIds)
                .then(() => {
                    enqueueSnackbar(<Message id="userArea.members.addMemberSuccess" />, {
                        variant: 'success',
                    });
                    dispatch(fetchGroupMembers(currentGroup.id));
                })
                .catch((error) => {
                    enqueueSnackbar(<Message id="userArea.members.addMemberFailed" />, {
                        variant: 'error',
                    });
                });
        }

        handleClose();
    }

    const handleClose = () => {
        dispatch(setCheckedAddedMemberIds([]));
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth='md'
            fullWidth
        >
            <Box height='90vh' display='flex' flexDirection='column'>
                <DialogTitle>
                    <Message id='userArea.members.addMembers' />
                </DialogTitle>
                <DialogContent>
                    <Box pt={1}>
                        <ActorUserListWithSearchAndFilter
                            listComponent={MemberListItem}
                            listHeaderComponent={MemberListHeader}
                            listItemsPerPage={20}
                            paginationPosition='bottom'
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box p={2} display='flex'>
                        <Button
                            variant='contained'
                            onClick={handleClose}
                        >
                            <Message id='handleActors.cancel' />
                        </Button>
                        <Box mr={2} />
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleSave}
                            disabled={checkedAddedMembersIds.length === 0}
                        >
                            <Message id='userArea.add' />
                            {' ' + checkedAddedMembersIds.length + ' '}
                            {checkedAddedMembersIds.length === 1
                                ? <Message id='userArea.members.memberAddButton' />
                                : <Message id='userArea.members.membersAddButton' />
                            }
                        </Button>
                    </Box>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

type ListItemProps = {
    name: string,
    id: string,
    actors?: string[],
}
const MemberListItem = ({
    name,
    actors,
    id,
}: ListItemProps) => {
    const checkedAddedMembersIds = useSelector(checkedAddedMembersIdsSelector);
    const groupMembers = useSelector(currentGroupMembersSelector);
    const actorUserDetails = useSelector(actorsUsersDetailsListSelector);

    const classes = useStyles();
    const dispatch = useDispatch();

    const getIsChecked = (): boolean => {
        return checkedAddedMembersIds.includes(id);
    }

    const getIsAlreadyAMember = (): boolean => {
        return !!groupMembers?.find(gm => gm.id === id);
    }
    const isAlreadyAMember = getIsAlreadyAMember();

    const actorUser = getAUById(id, (actorUserDetails || []));

    return (
        <Box
            display='flex' alignItems='center' px={2} mb={1}
            position='relative'
            className={isAlreadyAMember
                ? `${classes.listItem} ${classes.alreadyAddedListItem}`
                : getIsChecked()
                    ? `${classes.listItem} ${classes.checkedListItem}`
                    : classes.listItem}
        >
            <Box pr={2}>
                <Checkbox
                    checked={getIsChecked()}
                    onChange={(_event, checked) => {
                        dispatch(toggleCheckedAddedMemberId({ id, checked }));
                    }}
                    disabled={isAlreadyAMember}
                    color='primary'
                />
            </Box>

            <Box width={224} className={classes.textEllipsis}>
                <Typography variant='body1' color='inherit'>{name}</Typography>
            </Box>

            <Box width={236} className={classes.textEllipsis}>
                <Typography variant='caption'>{actorUser?.email}</Typography>
            </Box>

            {actors &&
                <Box width={224} className={classes.textEllipsis}>
                    <ActorNamesCaption actorNames={actors} />
                </Box>
            }

            {isAlreadyAMember &&
                <Box className={classes.memberBadge} position='absolute' right={6}>
                    <Typography noWrap color='inherit' variant='caption'>
                        <Message id='userArea.members.alreadyMember' />
                    </Typography>
                </Box>
            }
        </Box>
    );
}

const MemberListHeader = () => {
    const [allAreSelected, setAllAreSelected] = useState<boolean>(false);

    const actorUserDetails = useSelector(actorsUsersDetailsListSelector);
    const filteredActorUserDetails = useSelector(filteredActorUserDetailsSelector);
    const actors = useSelector(actorsSelector);
    const dispatch = useDispatch();

    const toggleSelectAllFiltered = () => {
        if (allAreSelected) {
            dispatch(setCheckedAddedMemberIds([]));
            setAllAreSelected(false);
        }

        // TODO : Don't select alreadyMembers
        if (!allAreSelected && filteredActorUserDetails) {
            dispatch(setCheckedAddedMemberIds(filteredActorUserDetails.map(au => au.id)));
            setAllAreSelected(true);
        }
        if (!allAreSelected && !filteredActorUserDetails && actorUserDetails) {
            dispatch(setCheckedAddedMemberIds(actorUserDetails.map(au => au.id)));
            setAllAreSelected(true);
        }
    }

    return (
        <Box
            width='100%' display='flex' alignItems='center'
            px={2} mt={2} mb={1}
        >
            <Box pr={2}>
                <Checkbox
                    checked={allAreSelected}
                    onChange={() => toggleSelectAllFiltered()}
                    color='primary'
                />
            </Box>

            <Box width={224}>
                <Typography variant='subtitle2'>
                    <Message id='userArea.members.name' />
                </Typography>
            </Box>

            <Box width={236}>
                <Typography variant='subtitle2'>
                    <Message id='userArea.members.email' />
                </Typography>
            </Box>

            {actors && actors.length > 0 &&
                <Box width={224}>
                    <Typography variant='subtitle2'>
                        <Message id='userArea.members.actor' />
                    </Typography>
                </Box>
            }
            <Divider />
        </Box>
    )
}

export default AddMembersDialog;