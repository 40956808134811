import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { isSuperAdmin, Session } from '../../types';
import { format } from 'date-fns';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import DownloadIcon from '@material-ui/icons/GetApp';
import { downloadFile } from '../../utils';
import { getSessionDocument } from '../../api/course-api';
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridSortModel,
} from '@material-ui/data-grid';
import DeleteButton from '../DeleteButton';
import { deleteSessionDocument } from '../../store/editCourseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../store/userSlice';

type DocumentHistoryProps = {
  session: Session;
};

const DocumentHistory = ({ session }: DocumentHistoryProps) => {
  const [isOpen, setIsOpen] = useState(false); //NEW STATE INTRODUCED
  const [isDownloading, setIsDownloading] = useState<Set<number>>(new Set());
  const [sortModel] = useState<GridSortModel>([
    {
      field: 'revision',
      sort: 'desc',
    },
  ]);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const handleDownload = async (row: GridRowModel) => {
    setIsDownloading((old) => {
      old.add(row.id);
      return new Set(old);
    });
    const documentBlob = await getSessionDocument(session.id, row.id);
    if (!documentBlob) {
      return;
    }
    downloadFile(documentBlob, row.name);
    setIsDownloading((old) => {
      old.delete(row.id);
      return new Set(old);
    });
  };

  const handleDeleteDocument = (row: GridRowModel) => {
    const documentToDelete = session.documents.find((x) => x.id === row.id);
    if (!documentToDelete) {
      return;
    }
    dispatch(deleteSessionDocument(session, documentToDelete));
  };

  const rows = useMemo(() => {
    if (session) {
      return session.documents.map((doc) => ({
        ...doc,
        revision: session.documents.indexOf(doc) + 1,
      }));
    }
    return [];
  }, [session]);

  const columns: GridColDef[] = [
    {
      field: 'revision',
      headerName: getMessage(
        'course.courseAdmin.editCoursePage.documentRevisions'
      ),
      editable: false,
      flex: 1,
    },
    {
      field: 'name',
      headerName: getMessage('course.courseAdmin.editCoursePage.documentName'),
      editable: false,
      flex: 1,
    },
    {
      field: 'uploadedBy',
      headerName: getMessage('course.uploadedBy'),
      type: 'number',
      editable: false,
      flex: 1,
    },
    {
      field: 'uploadedAt',
      headerName: getMessage('course.uploadedAt'),
      type: 'number',
      valueFormatter: ({ row }) =>
        format(new Date(row.uploadedAt), 'yyyy-MM-dd kk:mm:ss'),
      editable: false,
      flex: 1,
    },
    {
      field: 'download',
      renderHeader: () => (
        <text style={{ textAlign: 'right', width: '100%' }}>
          {getMessage('course.download')}
        </text>
      ),
      headerName: getMessage('course.download'),
      renderCell: ({ row }) => (
        <Button
          variant="outlined"
          color="primary"
          startIcon={
            isDownloading.has(row.id) ? (
              <CircularProgress size={20} />
            ) : (
              <DownloadIcon />
            )
          }
          onClick={() => handleDownload(row)}
        ></Button>
      ),
      disableClickEventBubbling: true,
      flex: 1,
      align: 'right',
    },
    {
      field: 'delete',
      hide: !isSuperAdmin(user),
      renderHeader: () => (
        <text style={{ textAlign: 'right', width: '100%' }}>
          {getMessage('course.courseAdmin.editCoursePage.deleteDocument')}
        </text>
      ),
      headerName: getMessage(
        'course.courseAdmin.editCoursePage.deleteDocument'
      ),
      renderCell: ({ row }) => (
        <DeleteButton
          itemCategory={getMessage('course.document')}
          itemName={row.name}
          onDelete={() => handleDeleteDocument(row)}
        />
      ),
      disableClickEventBubbling: true,
      flex: 1,
      align: 'right',
    },
  ];

  return (
    <Box>
      <Box>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setIsOpen(true)}
        >
          {getMessage('course.courseAdmin.editCoursePage.documentRevisions')}
        </Button>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogTitle style={{ textAlign: 'center' }}>
            {' '}
            <Typography variant="h3">
              {getMessage(
                'course.courseAdmin.editCoursePage.documentRevisions'
              )}
            </Typography>
          </DialogTitle>
          <Box pt={2}>
            <DataGrid
              autoHeight
              rows={rows}
              columns={columns}
              pageSize={10}
              sortModel={sortModel}
            />
          </Box>
          <DialogActions>
            <Button variant="contained" onClick={() => setIsOpen(false)}>
              {getMessage('handleActors.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default DocumentHistory;
