import {
  makeStyles,
  createStyles,
} from '@material-ui/core';
import * as MaterialIcons from '@material-ui/icons'

type MaterialIconProps = {
  iconName: string;
  fontSize?: string;
  color?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center'
    }
  })
);

const MaterialIcon = ({ iconName, fontSize, color }: MaterialIconProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {(MaterialIcons as any)[iconName]?.type.render({ fontSize, color })}
    </div>
  )
}

export default MaterialIcon;
