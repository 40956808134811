const authorityBaseUrl =
  process.env.NODE_ENV === 'development'
    // ? 'https://web-dev.conductonline.devies.se'
    ? 'https://dev.webapp.conductme.com/'
    : window.location.origin;

const oidcConfiguration = {
  client_id: 'conductonline',
  redirect_uri: `${window.location.origin}/authentication/callback`,
  response_type: 'code',
  post_logout_redirect_uri: '',
  scope: 'openid profile email roles api',
  authority: `${authorityBaseUrl}/signin`,
  silent_redirect_uri: `${window.location.origin}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

export default oidcConfiguration;
