import { userHttpClient as httpClient } from './httpClient'; // get from user port
import { Actor, ActorsUsersDetails, Permission } from '../types';

export const getActorsUsersDetails = async (): Promise<
  ActorsUsersDetails[]
> => {
  const response = await httpClient.get(`user/actor/useractordetails`);
  return response.data;
};

export const getAssignableActors = async (): Promise<Actor[]> => {
  const response = await httpClient.get(`user/actor/assignableactors/`);
  return response.data;
};

export const getAssignableActorsInRelationToUser = async (
  toUserId: string
): Promise<Record<string, string>> => {
  const response = await httpClient.get(
    `user/actor/assignableactors/${toUserId}`
  );
  return response.data;
};

export const setActorOnUser = async (setActorObj: {
  toUserId: string;
  actorId: string;
}): Promise<ActorsUsersDetails> => {
  const response = await httpClient.post(`user/actor/user`, setActorObj);
  return response.data;
};

export const deleteActorFromUser = async (deleteActorObj: {
  toUserId: string;
  actorId: string;
}): Promise<ActorsUsersDetails> => {
  const config = {
    data: deleteActorObj,
  };
  const response = await httpClient.delete(`user/actor/user`, config);
  return response.data;
};

export const setRelationshipOnUser = async (setRelationshipObj: {
  toUserId: string;
  fromUserId: string;
  actorId: string;
}) => {
  const response = await httpClient.post(
    `user/actor/relationship`,
    setRelationshipObj
  );
  return response.data;
};

export const getRelatedUsersBasedOnActors = async (
  actorIds: string[]
): Promise<ActorsUsersDetails[]> => {
  let path = '';
  if (actorIds.length > 0) {
    path = path.concat('?');
    actorIds.forEach((id, index) => {
      if (index > 0) path = path.concat('&');
      path = path.concat(`actorIds=${id}`);
    });
  }

  const response = await httpClient.get(`user/actor/visibleusers${path}`);
  return response.data;
};

export const getRelatedUsers = async (
  userId: string
): Promise<ActorsUsersDetails[]> => {
  const response = await httpClient.get(`user/actor/${userId}/relatedusers`);
  return response.data;
};

export const deleteRelationship = async (relationshipObj: {
  toUserId: string;
  fromUserId: string;
  actorId: string;
}) => {
  const config = { data: relationshipObj };
  const response = await httpClient.delete(`user/actor/relationship`, config);
  return response.data;
};

export const getAssignableUsersBasedOnRelationship = async (
  actorId: string
): Promise<ActorsUsersDetails[]> => {
  const response = await httpClient.get(
    `user/actor/${actorId}/relationshipassignableusers`
  );
  return response.data;
};

export const getPermissionsForUserArea = async (
  toUserId: string
): Promise<Permission[]> => {
  const response = await httpClient.get(
    `user/actor/user/${toUserId}/permissions`
  );
  return response.data.actorPermissions;
};

export const getActors = async (): Promise<Actor[]> => {
  const response = await httpClient.get(`user/Actor/actors`);
  return response.data;
};

export const createActors = async (createObj: {
  actors: { name: string }[];
}): Promise<Actor[]> => {
  const response = await httpClient.post(`user/Actor/actors`, createObj);
  return response.data;
};

export const updateActor = async (actorToUpdate: Actor): Promise<Actor> => {
  const response = await httpClient.put(`user/Actor/actor`, actorToUpdate);
  return response.data;
};

export const deleteActor = async (id: string): Promise<Actor[]> => {
  const response = await httpClient.delete(`user/Actor/actor/${id}`);
  return response.data;
};

export const getPermissions = async (): Promise<{
  actorPermissions: Permission[];
}> => {
  const response = await httpClient.get(`user/Actor/permissions`);
  return response.data;
};
