import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import {
  correctFreeTextRequest as correctFreeTextAPI,
  fetchAllResultsBySession as fetchAllResultsBySessionAPI,
  fetchAllUserCourseResults as fetchAllUserCourseResultsAPI,
  fetchCourseResultsForAllUsers as fetchCourseResultsForAllUsersAPI,
  fetchAllUserResultsByCourse as fetchAllUserResultsByCourseAPI,
} from '../api/course-api';
import { CourseResult, ExtendedResult, UserCourseResult } from '../types';

export interface ResultState {
  sessionId: string | null;
  results: ExtendedResult[] | null;
  userCoursesResults: CourseResult[] | null;
  isLoading: boolean;
  isCorrecting: boolean;
  error: string | null;
  allUsersCourseResults: UserCourseResult[] | null;
  updatingUserCourseList: boolean;
  courseResults: CourseResult[] | null;
}

const initialState = {
  sessionId: null,
  results: null,
  userCoursesResults: null,
  isLoading: false,
  isCorrecting: false,
  error: null,
  allUsersCourseResults: null,
  updatingUserCourseList: false,
  courseResults: null,
} as ResultState;

function startLoading(state: ResultState) {
  state.isLoading = true;
}

function loadingFailed(state: ResultState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const allResultsSlice = createSlice({
  name: 'results',
  initialState: initialState,
  reducers: {
    startLoading,
    loadingFailed,
    updateTableStart(state: ResultState) {
      state.updatingUserCourseList = true;
    },
    correctingStarted(state: ResultState) {
      state.isCorrecting = true;
    },
    correctingFailed(state: ResultState, action: PayloadAction<string>) {
      state.isCorrecting = false;
      state.error = action.payload;
    },
    setSessionResultsStart: startLoading,
    setSessionResults(
      state: ResultState,
      action: PayloadAction<{ sessionId: string; data: ExtendedResult[] }>
    ) {
      const { sessionId, data } = action.payload;
      state.isLoading = false;
      state.isCorrecting = false;
      state.sessionId = sessionId;
      state.results = data;
    },
    setSessionResultsFailure(
      state: ResultState,
      action: PayloadAction<string>
    ) {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchUsersCoursesStart: startLoading,
    fetchUsersCourses(
      state: ResultState,
      action: PayloadAction<CourseResult[]>
    ) {
      state.isLoading = false;
      state.userCoursesResults = action.payload;
    },
    fetchUsersCoursesFailure(
      state: ResultState,
      action: PayloadAction<string>
    ) {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchUsersCourseResultsAllStart: startLoading,
    fetchUsersCourseResultsAll(
      state: ResultState,
      action: PayloadAction<UserCourseResult[]>
    ) {
      state.isLoading = false;
      state.allUsersCourseResults = action.payload;
    },
    fetchUsersCourseResultsAllFailure(
      state: ResultState,
      action: PayloadAction<string>
    ) {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetState(state: ResultState) {
      const temp = state.allUsersCourseResults;
      state = { ...initialState };
      state.allUsersCourseResults = temp;
    },
    updateTable(state: ResultState, action: PayloadAction<UserCourseResult[]>) {
      state.updatingUserCourseList = false;
      state.allUsersCourseResults = action.payload;
    },
    fetchAllCourseResultsStart: startLoading,
    fetchAllCourseResults(
      state: ResultState,
      action: PayloadAction<CourseResult[]>
    ) {
      state.isLoading = false;
      state.courseResults = action.payload;
    },
    fetchAllUserCourseResultsFail(
      state: ResultState,
      action: PayloadAction<string>
    ) {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetCourseResults(state: ResultState) {
      state.courseResults = null;
    },
  },
});

export const {
  correctingStarted,
  correctingFailed,
  setSessionResults,
  setSessionResultsStart,
  setSessionResultsFailure,
  fetchUsersCourses,
  fetchUsersCoursesStart,
  fetchUsersCoursesFailure,
  fetchUsersCourseResultsAll,
  fetchUsersCourseResultsAllStart,
  fetchUsersCourseResultsAllFailure,
  resetState,
  updateTableStart,
  updateTable,
  fetchAllCourseResultsStart,
  fetchAllCourseResults,
  fetchAllUserCourseResultsFail,
  resetCourseResults,
} = allResultsSlice.actions;
export default allResultsSlice.reducer;

// DISPATCH FUNCTIONS
export const fetchAllSessionResults =
  (sessionId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setSessionResultsStart());
      const response = await fetchAllResultsBySessionAPI(sessionId);
      dispatch(
        setSessionResults({ sessionId: sessionId, data: response.data })
      );
    } catch (err: any) {
      dispatch(setSessionResultsFailure(err.toString()));
    }
  };

export const correctFreeText =
  (
    sessionId: string,
    userId: string,
    pass: boolean,
    questionIndex: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(correctingStarted());
      await correctFreeTextAPI(sessionId, userId, pass, questionIndex);
      dispatch(fetchAllSessionResults(sessionId));
    } catch (err: any) {
      dispatch(correctingFailed(err.toString()));
    }
  };

export const fetchUserCourseResults = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchUsersCoursesStart());
    const response = await fetchAllUserCourseResultsAPI();
    if (response.status === 200) {
      dispatch(fetchUsersCourses(response.data));
    } else {
      dispatch(fetchUsersCoursesFailure(response.statusText));
    }
  } catch (err: any) {
    dispatch(fetchUsersCoursesFailure(err.toString()));
  }
};

export const fetchAllUsersCourseResults = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchUsersCourseResultsAllStart());
    const response = await fetchCourseResultsForAllUsersAPI();
    if (response.status === 200) {
      dispatch(fetchUsersCourseResultsAll(response.data));
    } else {
      dispatch(fetchUsersCourseResultsAllFailure(response.statusText));
    }
  } catch (err: any) {
    dispatch(fetchUsersCourseResultsAllFailure(err.toString()));
  }
};

export const fetchAllResultsForCourse =
  (courseId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchAllCourseResultsStart());
      const response = await fetchAllUserResultsByCourseAPI(courseId);
      dispatch(fetchAllCourseResults(response ? response : []));
    } catch (err: any) {
      dispatch(fetchAllUserCourseResultsFail(err.toString()));
    }
  };

export const updateAllUsersCoursesResults =
  (): AppThunk => async (dispatch) => {
    try {
      dispatch(updateTableStart());
      const response = await fetchCourseResultsForAllUsersAPI();
      if (response.status === 200) {
        dispatch(updateTable(response.data));
      }
    } catch (err: any) {
      dispatch(fetchUsersCourseResultsAllFailure(err.toString()));
    }
  };
