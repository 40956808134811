import {
    Box,
} from '@material-ui/core';
import Message from '../../../components/translation/Message';
import UserAccordion from './UserAccordion';
import PageOrSectionHeader from '../../../components/typography/PageOrSectionHeader';
import ActorUserListWithSearchAndFilter from '../../../components/actors/ActorUserListWithSearchAndFilter';

const UsersStatisticsPage = () => {
    return (
        <Box p={4}>
            <PageOrSectionHeader>
                <Message id='statistics.users' />
            </PageOrSectionHeader>

            <ActorUserListWithSearchAndFilter
                listComponent={UserAccordion}
            />
        </Box>
    );
};

export default UsersStatisticsPage;
