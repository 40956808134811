import { Actor, ActorsUsersDetails } from "./types";

export function getDurationTimeStamp(durationInSeconds: number): string {
  if (durationInSeconds < 60) {
    return `${durationInSeconds}s`;
  }
  const totalMinutes = durationInSeconds / 60;
  if (totalMinutes < 60) {
    const totalInMinutes = Math.floor(totalMinutes);
    const durationMinutes =
      totalInMinutes >= 10 ? totalInMinutes : `0${totalInMinutes}`;
    return `00:${durationMinutes}`;
  }

  const durationInMinutes = Math.floor(totalMinutes % 60);
  const durationMinutes =
    durationInMinutes >= 10 ? durationInMinutes : `0${durationInMinutes}`;
  const durationInHours = Math.floor(totalMinutes / 60);
  const durationHours =
    durationInHours >= 10 ? durationInHours : `0${durationInHours}`;
  return `${durationHours}:${durationMinutes}`;
}

export function getDurationText(durationInSeconds: number): string {
  if (durationInSeconds < 60) {
    return `${durationInSeconds}s`;
  }
  const durationInMinutes = durationInSeconds / 60;
  const durationHours = Math.floor(durationInMinutes / 60);
  const durationMinutes = Math.floor(durationInMinutes % 60);
  return durationHours > 0
    ? `${durationHours}h ${durationMinutes}m`
    : `${durationMinutes}m`;
}

export function downloadFile(blob: Blob, fileName: string) {
  const fileNameWithoutWhitespace = fileName.replace(/\s/g, '_');
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileNameWithoutWhitespace;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  // eslint-disable-next-line
  window.addEventListener('focus', (e) => URL.revokeObjectURL(link.href), {
    once: true,
  });
}

export function formatFullDate(date: string, showTime: boolean = true) {
  const newDate = new Date(date);

  // getMonth() gives a number from 0 to 11
  const month = formatData(newDate.getMonth() + 1);
  const day = formatData(newDate.getDate());

  const hours = formatData(newDate.getHours());
  const minutes = formatData(newDate.getMinutes());

  const fullDate = `${newDate.getFullYear()}-${month}-${day}`;

  return `${fullDate} ${showTime ? `${hours}:${minutes}` : ''}`;
};

function formatData(dateEntity: number) {
  return dateEntity < 10 ? `0${dateEntity}` : dateEntity;
}

export function isUserActive(id: string): boolean {
  return id !== "00000000-0000-0000-0000-000000000000"
}

export const getCourseTypeName = (pathname: string) => {
  return pathname.split('-').join(' ').split('/')[2] || '';
};

export const getDaysInMonth = (monthNumber: number, year: number) => {
  return new Date(year, monthNumber + 1, 0).getDate();
}

export const getMonthString = (monthIndex: number, locale?: string) => {
  let dateObj = new Date();
  dateObj.setDate(1);
  dateObj.setMonth(monthIndex);

  const defaultLocale = 'en-us';
  const month = dateObj.toLocaleString(locale || defaultLocale, { month: "long" });

  return month;
}

export const getActorList = (actorUser: ActorsUsersDetails): Actor[] => {
  let actorList: Actor[] = [];
  actorUser.actors.forEach(a => actorList.push(a));
  actorUser.actorRelationships.forEach(ar => {
    if (!actorList.find(a => a.id === ar.relationship.id)) {
      actorList.push(ar.relationship);
    }
  });
  return actorList;
}