import { useState, useCallback } from "react";
import {
    Box,
    Button,
    createStyles,
    Divider,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import Message from "../../../components/translation/Message";
import PageOrSectionHeader from "../../../components/typography/PageOrSectionHeader";
import AddTabDialog from "./AddTabDialog";
import Categories from "../../../components/forum/categories";
import EditableTitle from "../../../components/EditableTitle";
import DeleteButton from "../../../components/DeleteButton";
import { getMessage } from "../../../whitelabel-config/WhitelabelProvider";
import { useDispatch, useSelector } from "react-redux";
import {
    addTab,
    updateTab,
    deleteTab,
    tabsSelector,
    permissionsForUserAreaSelector,
    userAreaSelector
} from "../../../store/userAreaSlice";
import { GroupMember, Tab, TabDTO } from "../../../types";
import AddGroupAdminDialog from "./AddGroupAdminDialog";
import {
    currentGroupMembersSelector,
    currentGroupSelector,
    editGroupName,
    fetchGroupMembers,
    resetGroupMembers,
    setCurrentGroup
} from "../../../store/groupSlice";
import { homeUrl } from "../../../routes/paths";
import { generatePath, useHistory } from "react-router-dom";
import { deleteGroup, editGroupAdmins } from "../../../api/user-api";
import { useSnackbar } from "notistack";
import TextEllipsis from "../../../components/TextEllipsis";
import {
    instanceModulesSelector,
    activeSubModulesSelector
} from '../../../store/instanceSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backgroundHover: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '280px',
            borderRadius: theme.shape.borderRadius,
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        staticTab: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '280px',
        },
        textOverflow: {
            width: '216px'
        },
        deleteContainer: {
            height: 48
        }
    })
);

const PrivateSettingsPage = () => {
    const [isAddTabDialogOpen, setIsAddTabDialogOpen] = useState<boolean>(false);
    const [isAddGroupAdminDialogOpen, setIsAddGroupAdminDialogOpen] = useState<boolean>(false);

    const permissions = useSelector(permissionsForUserAreaSelector);
    const currentGroup = useSelector(currentGroupSelector);
    const groupMembers = useSelector(currentGroupMembersSelector);
    const userArea = useSelector(userAreaSelector);

    const activeSubModules = useSelector(activeSubModulesSelector);
    const activeModules = useSelector(instanceModulesSelector);
    const tabs = useSelector(tabsSelector);

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const privateOrGroupForumIsOn = useCallback(() => {
        if (currentGroup) {
            return activeModules?.includes('Forum')
                && activeSubModules?.Forum?.includes('GroupForum');
        }

        return activeModules?.includes('Forum')
            && activeSubModules?.Forum?.includes('PrivateForum');
    }, [activeModules, activeSubModules?.Forum, currentGroup]);

    const privateOrGroupCourseOrVideosIsOn = useCallback(() => {
        if (currentGroup) {
            return activeModules?.includes('Course')
                && (
                    activeSubModules?.Course?.includes('GroupCourse')
                    || activeSubModules?.Course?.includes('GroupVideos')
                );
        }

        return activeModules?.includes('Course')
            && (
                activeSubModules?.Course?.includes('PrivateCourse')
                || activeSubModules?.Course?.includes('PrivateVideos')
            );
    }, [activeModules, activeSubModules?.Course, currentGroup]);

    const getGroupAdmins = (): GroupMember[] => {
        if (!groupMembers) return [];
        return groupMembers.filter(member => member.isGroupAdmin);
    }

    const handleChangeTabName = (tab: Tab, newName: string) => {
        const updateObj: TabDTO = {
            name: newName,
            privateUserId: tab.privateUserId,
            groupId: tab.groupId,
            tabCourses: tab.tabCourses
        }
        dispatch(updateTab(tab.id, updateObj));
    }

    const handleSaveNewTab = (name: string) => {
        const createObj: TabDTO = {
            name,
            tabCourses: []
        };
        dispatch(addTab(
            createObj,
            { userAreaId: userArea?.id, groupId: currentGroup?.id }
        ));
    }

    const handleDeleteTab = (tabId: string) => {
        dispatch(deleteTab(tabId, { userAreaId: userArea?.id, groupId: currentGroup?.id }));
    }

    const handleDeleteGroupAdmin = (adminId: string) => {
        if (!currentGroup || !groupMembers) return;

        const newGroupAdminIds = groupMembers
            .filter(m => m.isGroupAdmin && m.id !== adminId)
            .map(m => m.id);
        editGroupAdmins(currentGroup.id, newGroupAdminIds)
            .then(() => {
                enqueueSnackbar(<Message id="userArea.settings.deleteGroupAdminsSuccess" />, {
                    variant: 'success',
                });
                dispatch(fetchGroupMembers(currentGroup.id));
            })
            .catch((error) => {
                enqueueSnackbar(<Message id="userArea.settings.deleteGroupAdminsFailed" />, {
                    variant: 'error',
                });
            });
    }

    const handleDeleteGroup = () => {
        if (!currentGroup) return;
        deleteGroup(currentGroup.id)
            .then(() => {
                dispatch(setCurrentGroup(null));
                dispatch(resetGroupMembers());
                enqueueSnackbar(<Message id="userArea.settings.deleteGroupSuccess" />, {
                    variant: 'success',
                });
            })
            .catch((error) => {
                enqueueSnackbar(<Message id="userArea.settings.deleteGroupFailed" />, {
                    variant: 'error',
                });
            });
        history.push(generatePath(homeUrl));
    }

    const handleChangeGroupName = (newName: string) => {
        if (!currentGroup) return;
        dispatch(editGroupName(currentGroup.id, newName));
    }

    return (
        <Box>
            {(
                currentGroup ||
                (permissions && permissions.includes('AssignAndRemoveCourse'))
            ) &&
                <Box>
                    {currentGroup &&
                        <Box mb={2} maxWidth={400}>
                            <PageOrSectionHeader>
                                <Message id='userArea.settings.groupName' />
                            </PageOrSectionHeader>
                            <Box className={`${classes.textOverflow} ${classes.backgroundHover}`} px={1}>
                                <EditableTitle
                                    title={currentGroup.name}
                                    variant='body1'
                                    component='p'
                                    onChange={(name) => handleChangeGroupName(name)}
                                />
                            </Box>
                            <PaddedDivider />
                        </Box>
                    }

                    {currentGroup &&
                        <SectionContainer
                            headerText={<Message id='userArea.settings.groupAdmins' />}
                            buttonText={<Message id='userArea.settings.addGroupAdmin' />}
                            onButtonClick={() => setIsAddGroupAdminDialogOpen(true)}
                        >
                            <>
                                <Box>
                                    {getGroupAdmins().map(admin => (
                                        <Box key={admin.id} display='flex' alignItems='center'>
                                            <TextEllipsis width={280}>
                                                {admin.email}
                                            </TextEllipsis>
                                            <Box className={classes.deleteContainer}>
                                                <DeleteButton
                                                    itemCategory={getMessage('userArea.settings.groupAdmins')}
                                                    itemName={admin.name}
                                                    onDelete={() => handleDeleteGroupAdmin(admin.id)}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                                    {getGroupAdmins().length === 0 &&
                                        <Box mt={5}>
                                            <Typography>
                                                <Message id='userArea.settings.noAdmins' />
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                <PaddedDivider />
                                <AddGroupAdminDialog
                                    isOpen={isAddGroupAdminDialogOpen}
                                    onClose={() => setIsAddGroupAdminDialogOpen(false)}
                                />
                            </>
                        </SectionContainer>
                    }

                    {privateOrGroupCourseOrVideosIsOn() && tabs &&
                        <TabSettings
                            tabs={tabs}
                            isAddTabDialogOpen={isAddTabDialogOpen}
                            onOpenAddTabDialog={() => setIsAddTabDialogOpen(true)}
                            onCloseAddTabDialog={() => setIsAddTabDialogOpen(false)}
                            onSaveNewTab={handleSaveNewTab}
                            onDeleteTab={handleDeleteTab}
                            onChangeTabName={handleChangeTabName}
                        />
                    }

                </Box>
            }

            {(privateOrGroupForumIsOn() &&
                (currentGroup ||
                    (permissions && permissions.includes('WriteForumCategories')))
            ) &&
                <Box maxWidth={400} my={3}>
                    <Categories
                        userAreaId={userArea?.id}
                        groupId={currentGroup?.id}
                    />
                    <PaddedDivider />
                </Box>
            }

            {
                currentGroup &&
                <Box my={3}>
                    <PageOrSectionHeader>
                        <Message id='userArea.settings.removeGroup' />
                    </PageOrSectionHeader>
                    <Box mt={2}>
                        <DeleteButton
                            itemCategory={'Group'}
                            itemName={currentGroup.name}
                            onDelete={handleDeleteGroup}
                            button={
                                <Box color='red'>
                                    <Button
                                        variant='contained'
                                        color='inherit'
                                    >
                                        <Message id='userArea.settings.removeGroup' />
                                    </Button>
                                </Box>
                            }
                        />
                    </Box>
                </Box>
            }
        </Box >
    )
}

type TabSettingsProps = {
    onOpenAddTabDialog: () => void,
    onCloseAddTabDialog: () => void,
    onSaveNewTab: (name: string) => void,
    isAddTabDialogOpen: boolean,
    tabs: Tab[],
    onChangeTabName: (tab: Tab, name: string) => void,
    onDeleteTab: (tabId: string) => void
}
const TabSettings = ({
    tabs,
    isAddTabDialogOpen,
    onOpenAddTabDialog,
    onCloseAddTabDialog,
    onSaveNewTab,
    onDeleteTab,
    onChangeTabName
}: TabSettingsProps) => {
    const classes = useStyles();
    return (
        <SectionContainer
            headerText={<Message id='userArea.settings.tabs' />}
            buttonText={<Message id="userArea.settings.addTab" />}
            onButtonClick={onOpenAddTabDialog}
        >
            <>
                <AddTabDialog
                    isOpen={isAddTabDialogOpen}
                    onClose={onCloseAddTabDialog}
                    onSave={onSaveNewTab}
                />
                <Box>
                    <Box className={classes.staticTab} px={1}>
                        <Box className={classes.textOverflow}>
                            <Typography variant="body1" component="p" >
                                <Message id='userArea.tabActors' />
                            </Typography>
                        </Box>
                        <Box className={classes.deleteContainer} />
                    </Box>
                    <Box className={classes.staticTab} px={1}>
                        <Box className={classes.textOverflow}>
                            <Typography variant="body1" component="p" >
                                <Message id='userArea.tabPrivateForum' />
                            </Typography>
                        </Box>
                        <Box className={classes.deleteContainer} />
                    </Box>
                    {tabs && tabs.map((tab: any) => (
                        <Box key={tab.id} className={classes.backgroundHover} px={1}>
                            <Box className={classes.textOverflow}>
                                <EditableTitle
                                    title={tab.name}
                                    variant="body1"
                                    component="p"
                                    onChange={(name) => onChangeTabName(tab, name)}
                                />
                            </Box>
                            <Box className={classes.deleteContainer}>
                                <DeleteButton
                                    itemCategory={getMessage('userArea.settings.tab')}
                                    itemName={tab.name}
                                    onDelete={() => onDeleteTab(tab.id)}
                                />
                            </Box>
                        </Box>
                    ))}
                    <Box className={classes.staticTab} px={1}>
                        <Box className={classes.textOverflow}>
                            <Typography variant="body1" component="p" >
                                <Message id='userArea.tabSettings' />
                            </Typography>
                        </Box>
                        <Box className={classes.deleteContainer} />
                    </Box>
                </Box>
                <PaddedDivider />
            </>
        </SectionContainer>
    )
}

const PaddedDivider = () => (
    <Box mt={5} mb={5}>
        <Divider />
    </Box>
)

type SectionContainerProps = {
    children: JSX.Element
    onButtonClick: () => void,
    headerText: string | JSX.Element,
    buttonText: string | JSX.Element
}
const SectionContainer = ({
    children,
    onButtonClick,
    headerText,
    buttonText
}: SectionContainerProps) => (
    <Box maxWidth={400} mt={3} mb={2}>
        <Box display='flex' justifyContent='space-between' alignItems='end' mb={2}>
            <PageOrSectionHeader>
                {headerText}
            </PageOrSectionHeader>
            <Button
                variant='contained'
                color='primary'
                onClick={onButtonClick}
            >
                {buttonText}
            </Button>
        </Box>
        {children}
    </Box>
)

export default PrivateSettingsPage;