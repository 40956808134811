import Typography from '@material-ui/core/Typography';

const PageOrSectionHeader = ({
  children,
  noGutter,
}: {
  children: JSX.Element | string;
  noGutter?: boolean;
}) => {
  return (
    <Typography
      variant="h5"
      component="h2"
      gutterBottom={!noGutter}
      style={{ marginTop: 20 }}
    >
      {children}
    </Typography>
  );
};

export default PageOrSectionHeader;
