import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import reportWebVitals from './reportWebVitals';
import './index.css';
import theme from './theme';
import App from './App';
import store from './store';
import WhitelabelProvider from './whitelabel-config/WhitelabelProvider';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import serviceWorker from './service-worker-registration';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <WhitelabelProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </Provider>
      </WhitelabelProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
