import { createStyles, makeStyles, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        inputRoot: {
            '&[class*="MuiOutlinedInput-root"]': {
                padding: 6.5
            }
        }
    })
);

type SearchFieldProps = {
    options: string[],
    value: string,
    onChangeValue: (value: string) => void,
    label: string,
    placeholder: string
}
const SearchField = ({
    options,
    value,
    onChangeValue,
    label,
    placeholder
}: SearchFieldProps) => {
    const classes = useStyles();
    return (
        <Autocomplete
            fullWidth
            freeSolo
            id='search-actor-user-list'
            value={value}
            onChange={(_event, value, reason) => {
                onChangeValue(value || '');
            }}
            classes={classes}
            options={options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    variant='outlined'
                    InputProps={{
                        ...params.InputProps,
                        // type: 'search', Removed because an extra cross appeared.
                    }}
                />
            )}
        />
    )
}

export default SearchField;