import {
  AppBar,
  Box,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExtendedQuestionComponent } from '../../components/question/ExtendedQuestionComponent';
import Message from '../../components/translation/Message';
import { fetchAllResultsForCourse } from '../../store/allResultsSlice';
import {
  currentCourseSelector,
  resultStateSelector,
} from '../../store/selectors';
import { CourseResult, ExtendedResult, QuestionAnswer } from '../../types';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import { renderMandatoryIcon } from '../HandleUserCourses/UserCoursesTable';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ViewAllResultProps = {
  isOpen: boolean;
  onClose: () => void;
  courseId: string;
  courseName: string;
};

export const ViewAllResults = (props: ViewAllResultProps) => {
  const { isOpen, onClose, courseId, courseName } = props;
  const [searchInput, setSearchInput] = useState<string>('');
  const resultState = useSelector(resultStateSelector);
  const [fetchedCourseResults, setFetchedCourseResults] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setFetchedCourseResults(false);
    setSearchInput('');
    onClose();
  };

  useEffect(() => {
    if (!resultState.courseResults && !fetchedCourseResults) {
      dispatch(fetchAllResultsForCourse(courseId));
      setFetchedCourseResults(true);
    }
    if (
      resultState.courseResults &&
      resultState.courseResults.length > 0 &&
      resultState.courseResults[0].courseId !== courseId
    ) {
      dispatch(fetchAllResultsForCourse(courseId));
    }
  }, [courseId, dispatch, fetchedCourseResults, resultState.courseResults]);

  const listToRender = () => {
    return resultState.courseResults!.filter((res) => {
      const name = res.sessionResults[0].username;
      return name.toLowerCase().includes(searchInput.toLowerCase());
    });
  };

  const renderTable = () => {
    return (
      <Paper>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell width="5%" />
                <TableCell>
                  <Typography variant="subtitle2">
                    <Message id="course.username" />
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    <Message id="course.points" />
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle2">
                    <Message id="handleUserCourses.passed" />
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle2">
                    <Message id="handleUserCourses.mandatory" />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultState.courseResults &&
                listToRender().map((row) => (
                  <ViewAllResultsRow
                    key={row.sessionResults[0].userId}
                    row={row}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        style: { backgroundColor: '#CBE6DF' },
      }}
    >
      <AppBar position="relative">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose}>
            <Close />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', flex: 1 }}>
            <Message id="course.results" /> {` - ${courseName}`}
          </Typography>
          <TextField
            inputProps={{ style: { color: 'white' } }}
            variant="standard"
            type="search"
            placeholder={getMessage('course.courseAdmin.searchByUserName')}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <Search />
        </Toolbar>
      </AppBar>
      <Box pt={1} pb={1} />
      <DialogContent>
        {resultState.isLoading && (
          <Box width="100%" display="flex" justifyContent="space-around">
            <CircularProgress color="primary" />
          </Box>
        )}
        {!resultState.isLoading && renderTable()}
      </DialogContent>
      <Box pb={1} />
    </Dialog>
  );
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
});

type RowProps = {
  row: CourseResult;
};

const ViewAllResultsRow = ({ row }: RowProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useRowStyles();

  const getTimestamp = (dateStr: string) => {
    const [dateComp, timeCompz] = dateStr.split('T');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [timeComp, rest] = timeCompz.split('.');
    return `${dateComp} ${timeComp}`;
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="medium"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1">
            {row.sessionResults[0].username}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="subtitle1">
            {row.totalPoints} / {row.maxPoints}
          </Typography>
        </TableCell>
        <TableCell align="left" width="200px">
          <Typography variant="subtitle1">
            {row.datePassed && getTimestamp(row.datePassed)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {row.isMandatory ? renderMandatoryIcon(row) : <></>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: '#f9f9f91',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.sessionResults.map((res, i) => (
              <ViewSessionResultRow
                key={`vsrw_row_${i}`}
                result={res}
                sessionIndex={i}
              />
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

type SessionResultProp = {
  result: ExtendedResult;
  sessionIndex: number;
};

const ViewSessionResultRow = (props: SessionResultProp) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useRowStyles();
  const course = useSelector(currentCourseSelector);
  const { result } = props;

  const session = course?.sessions.find(
    (session) => session.id === result.sessionId
  );

  const renderQuestion = (qa: QuestionAnswer) => {
    return (
      <ExtendedQuestionComponent
        key={`vsrr_eqc_${qa.index}`}
        result={result}
        qa={qa}
      />
    );
  };

  return (
    <TableContainer>
      <Table aria-label="collapsible table" size="small">
        <TableBody>
          <TableRow className={classes.root}>
            <TableCell width="10%" align="right">
              {result.maxPoints !== 0 ? (
                <IconButton
                  aria-label="expand row"
                  size="medium"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell width="50%">
              <Typography variant="subtitle1">{result.sessionName}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">
                {result.totalPoints}/{result.maxPoints}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="5%" />
            <TableCell colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {session &&
                  session.questionAnswers.map((qa) => renderQuestion(qa))}
              </Collapse>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
