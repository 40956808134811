import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import Message from '../../components/translation/Message';
import ImageUploader from '../../components/ImageUploader';
import MessageConfig from '../../components/MessageConfig';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageBox: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '1100px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'column',
        '& > div': {
          marginBottom: theme.spacing(4),
        },
      },
      '& > div': {
        paddingLeft: theme.spacing(1),
      },
    },
  })
);



const ContentSettingsPage = () => {
  const classes = useStyles();
  
  return (
    <Box p={4}>
      <MessageConfig />
      <Box pt={4}>
        <Typography variant="h6" component="h2" gutterBottom>
          <Message id="siteSettings.images" />
        </Typography>
        <Box className={classes.imageBox}>
          <Box>
            <Typography variant="subtitle1" component="p" gutterBottom>
              <Message id="siteSettings.logo" />
            </Typography>
            <Typography variant="body2" component="p" gutterBottom></Typography>
            <Box>
              <ImageUploader path="logotype.logo" />
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="p" gutterBottom>
              <Message id="siteSettings.logoSquare" />
            </Typography>
            <ImageUploader path="logotype.logoSquare" />
          </Box>
          <Box>
            <Typography variant="subtitle1" component="p" gutterBottom>
              <Message id="siteSettings.defaultImage" />
            </Typography>
            <Box>
              <ImageUploader path="defaultMapImage" />
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="p" gutterBottom>
              <Message id="siteSettings.favicon" />
            </Typography>
            <Box>
              <ImageUploader path="favicon" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentSettingsPage;
