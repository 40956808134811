import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  IconButton,
} from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ResetZoomIcon from '@material-ui/icons/ZoomOutMap';
import { throttle } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import theme from '../../theme';
import Message from '../translation/Message';

type DocumentDialogProps = {
  file: Blob;
  numPages: number | null;
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  onClose: () => void;
  isOpen: boolean;
  options: object;
  title?: string;
};
const DocumentDialog = ({
  file,
  numPages,
  onLoadSuccess,
  onClose,
  isOpen,
  options,
  title,
}: DocumentDialogProps) => {
  const [width, setWidth] = useState<number>(0);
  const [hasCustomWidth, setHasCustomWidth] = useState<boolean>(false);
  const pdfWrapperRef = useRef<HTMLDivElement | null>(null);

  const setDivSize = () => {
    if (pdfWrapperRef.current) {
      setWidth(pdfWrapperRef.current.getBoundingClientRect().width);
    }
  };

  const throttledSetWidth = throttle(setDivSize, 500);

  useEffect(() => {
    window.addEventListener('resize', throttledSetWidth);
    if (isOpen && width === 0) {
      throttledSetWidth();
    }

    return () => {
      window.removeEventListener('resize', throttledSetWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throttledSetWidth]);

  useEffect(() => {
    if (isOpen && width === 0) {
      throttledSetWidth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, throttledSetWidth]);

  const zoomIn = () => {
    const newWidth = width + 100;
    setWidth(newWidth);
    setHasCustomWidth(true);
  };

  const zoomOut = () => {
    const newWidth = width - 100;
    setWidth(newWidth);
    setHasCustomWidth(true);
  };

  const resetZoom = () => {
    throttledSetWidth();
    setHasCustomWidth(false);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>{title || <Message id="course.document" />}</DialogTitle>
      <DialogContent>
        <div style={{ height: '100vh', width: '100vw', display: 'flex' }}>
          <div style={{ width: '90vw' }} ref={pdfWrapperRef}>
            <Document
              file={file}
              options={options}
              noData="Loading pdf..."
              onLoadSuccess={onLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={width || undefined}
                />
              ))}
            </Document>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Box
          p={2}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          color={theme.palette.text.primary}
        >
          <IconButton onClick={zoomOut} color="inherit">
            <ZoomOutIcon />
          </IconButton>
          <IconButton
            onClick={resetZoom}
            disabled={!hasCustomWidth}
            color="inherit"
          >
            <ResetZoomIcon />
          </IconButton>
          <IconButton onClick={zoomIn} color="inherit">
            <ZoomInIcon />
          </IconButton>
          <Box position="absolute" right={0} mr={2}>
            <Button variant="contained" onClick={onClose}>
              <Message id="course.close" />
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentDialog;
