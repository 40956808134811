import { MSWordIcon } from '../icons/MSWordIcon';
import { Box } from '@material-ui/core';
import Messages from '../translation/Message';
import { format } from 'date-fns';
import React from 'react';
import { Document } from '../../types';
import DocumentViewer from './DocumentViewer';
import { ImageIcon } from '../icons/ImageIcon';
import { MSExcelIcon } from '../icons/MSExcelIcon';
import { MSPowerPointIcon } from '../icons/MSPowerPointIcon';
import ImageViewerComponent from '../ImageViewerComponent';

export const DocumentFileType: {
  [index: string]: (
    doc: Document,
    file: Blob,
    pdf: Blob | null,
    sessionName: string
  ) => JSX.Element;
} = {
  docx: (doc: Document, _file: Blob, pdf: Blob | null, sessionName: string) => (
    <Box>
      <MSWordIcon fontSize="large" style={{ marginTop: 10 }} />
      <Box>{doc.name}</Box>
      {pdf && <DocumentViewer file={pdf} documentTitle={sessionName} />}
      {doc.uploadedBy && (
        <>
          <Box>
            <Messages id="course.uploadedBy" />
            {doc.uploadedBy}
          </Box>
          <Box>
            <Messages id={'course.uploadedAt'} />
            {format(new Date(doc.uploadedAt), 'yyyy-MM-dd kk:mm:ss')}
          </Box>
        </>
      )}{' '}
    </Box>
  ),
  xls: (doc: Document) => (
    <Box>
      <MSExcelIcon fontSize="large" style={{ marginTop: 10 }} />
      <Box>{doc.name}</Box>
      {doc.uploadedBy && (
        <>
          <Box>
            <Messages id="course.uploadedBy" />
            {doc.uploadedBy}
          </Box>
          <Box>
            <Messages id={'course.uploadedAt'} />
            {format(new Date(doc.uploadedAt), 'yyyy-MM-dd kk:mm:ss')}
          </Box>
        </>
      )}{' '}
    </Box>
  ),
  ppt: (doc: Document, _file: Blob, pdf: Blob | null, sessionName: string) => (
    <Box>
      <MSPowerPointIcon fontSize="large" style={{ marginTop: 10 }} />
      <Box>{doc.name}</Box>
      {pdf && <DocumentViewer file={pdf} documentTitle={sessionName} />}
      {doc.uploadedBy && (
        <>
          <Box>
            <Messages id="course.uploadedBy" />
            {doc.uploadedBy}
          </Box>
          <Box>
            <Messages id={'course.uploadedAt'} />
            {format(new Date(doc.uploadedAt), 'yyyy-MM-dd kk:mm:ss')}
          </Box>
        </>
      )}{' '}
    </Box>
  ),
  pdf: (doc: Document, file: Blob, _pdf: Blob | null, sessionName: string) => (
    <Box>
      <DocumentViewer file={file} documentTitle={sessionName} />
      <Box>{doc.name}</Box>
      {doc.uploadedBy && (
        <>
          <Box>
            <Messages id="course.uploadedBy" />
            {doc.uploadedBy}
          </Box>
          <Box>
            <Messages id={'course.uploadedAt'} />
            {format(new Date(doc.uploadedAt), 'yyyy-MM-dd kk:mm:ss')}
          </Box>
        </>
      )}{' '}
    </Box>
  ),
  jpeg: (doc: Document, file: Blob) => (
    <Box>
      <ImageViewerComponent object={file} />
      <Box marginTop="2%">
        <ImageIcon fontSize="large" style={{ marginTop: 10 }} />
        <Box>{doc.name}</Box>
        {doc.uploadedBy && (
          <>
            <Box>
              <Messages id="course.uploadedBy" />
              {doc.uploadedBy}
            </Box>
            <Box>
              <Messages id={'course.uploadedAt'} />
              {format(new Date(doc.uploadedAt), 'yyyy-MM-dd kk:mm:ss')}
            </Box>
          </>
        )}{' '}
      </Box>
    </Box>
  ),
  png: (doc: Document) => (
    <Box>
      <ImageIcon fontSize="large" style={{ marginTop: 10 }} />
      <Box>{doc.name}</Box>
      {doc.uploadedBy && (
        <>
          <Box>
            <Messages id="course.uploadedBy" />
            {doc.uploadedBy}
          </Box>
          <Box>
            <Messages id={'course.uploadedAt'} />
            {format(new Date(doc.uploadedAt), 'yyyy-MM-dd kk:mm:ss')}
          </Box>
        </>
      )}{' '}
    </Box>
  ),
};

export const DocumentIcon = (
  typename: string
): ((
  doc: Document,
  file: Blob,
  pdf: Blob | null,
  sessionName: string
) => JSX.Element) => {
  switch (typename?.toLowerCase()) {
    case 'xls':
    case 'xlsx':
      return DocumentFileType['xls'];
    case 'docx':
    case 'doc':
      return DocumentFileType['docx'];
    case 'ppt':
    case 'pptx':
      return DocumentFileType['ppt'];
    case 'pdf':
      return DocumentFileType['pdf'];
    case 'jpeg':
    case 'jpg':
    case 'jfiff':
    case 'png':
      return DocumentFileType['jpeg'];
    default:
      return () => <></>;
  }
};
