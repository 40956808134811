import React, { useState, useEffect } from 'react';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';
import {
  SearchItem,
  SimplifiedCourse,
  User,
} from '../../types';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  makeStyles,
  Theme,
  createStyles,
  TextField,
} from '@material-ui/core';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import MenuIcon from '../icons/MenuIcon';
import AccountCircle from '../icons/AccountCircleIcon';
import UserProfile from '../UserProfile';
import clsx from 'clsx';
import {
  showProfileSelector,
  showProfile,
  updateProfile,
} from '../../store/userSlice';
import {
  notificationsSelector,
  updateAsOpenedNotifications,
} from '../../store/notificationsSlice';
import {
  activeSubModulesSelector,
  instanceModulesSelector,
} from '../../store/instanceSlice';
import {
  fetchAllCoursesSimplified,
  fetchAllDocumentsSimplified,
  fetchCourseTypes,
} from '../../store/coursesSlice';
import {
  allCoursesSimplifiedSelector,
  allDocumentsSimplifiedSelector,
  courseTypesSelector,
} from '../../store/selectors';
import Notifications from './Notifications';
import AccountMenu from './AccountMenu';
import { UserResults } from '../../pages/HomePage/UserResults';
import { Autocomplete } from '@material-ui/lab';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import { useHistory } from 'react-router-dom';
import { groupAndCustomerOnlySelector } from '../../store/userAreaSlice';

interface NavStyleProps {
  fullyCollapseDrawer: boolean;
  drawerOpen: boolean;
}

const drawerWidthOpen = 280;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    toolbar: {},
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appBar: (props: NavStyleProps) => ({
      left: props.fullyCollapseDrawer ? 0 : theme.spacing(9) + 1,
      width: props.fullyCollapseDrawer
        ? '100%'
        : `calc(100% - ${theme.spacing(9) + 1}px)`,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        left: 0,
      },
    }),
    appBarShift: () => ({
      [theme.breakpoints.up('sm')]: {
        left: drawerWidthOpen,
        width: `calc(100% - ${drawerWidthOpen}px)`,
        transition: theme.transitions.create(['width', 'left'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    }),
    rightSide: {
      display: 'flex',
      alignItems: 'center',
    },
    userName: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    notificationIconContainer: {
      position: 'relative',
    },
    notificationBubble: {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: 'red',
      position: 'absolute',
      top: '14px',
      right: '12px',
    },
    notificationBubbleText: {
      color: 'white',
      position: 'absolute',
      top: '-2px',
      right: '3px',
    },
  }),
);

const getCurrentDimension = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

type NavbarProps = {
  title: string;
  user: User | null;
  drawerOpen: boolean;
  toggleDrawer: () => void;
  fullyCollapseDrawer: boolean;
};
const Navbar = ({
  title,
  user,
  drawerOpen,
  toggleDrawer,
  fullyCollapseDrawer,
}: NavbarProps) => {
  const classes = useStyles({ fullyCollapseDrawer, drawerOpen });
  const history = useHistory();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    useState<null | HTMLElement>(null);

  const openProfile = useSelector(showProfileSelector);
  const activeModules = useSelector(instanceModulesSelector);
  const activeSubModules = useSelector(activeSubModulesSelector);
  const notifications = useSelector(notificationsSelector);
  const courseTypes = useSelector(courseTypesSelector);
  const allCourses: SimplifiedCourse[] | null = useSelector(
    allCoursesSimplifiedSelector,
  );
  const allDocumentsSimplified: SearchItem[] | null = useSelector(
    allDocumentsSimplifiedSelector,
  );
  const groupAndCustomerOnly = useSelector(groupAndCustomerOnlySelector);
  const [showMyExams, setShowMyExams] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { logout } = useReactOidc();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    if (activeModules && activeModules.includes('Course') && !courseTypes) {
      dispatch(fetchCourseTypes());
    }
  }, [courseTypes, activeModules, dispatch]);

  useEffect(() => {
    if (!allCourses) {
      dispatch(fetchAllCoursesSimplified());
      dispatch(fetchAllDocumentsSimplified());
    }
  }, [allCourses, dispatch]);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const getRelevantNotifs = () => {
    if (!notifications) return [];
    let relevantNotifs = [...notifications];

    // Remove PublicForum notifs
    if (
      !activeModules?.includes('Forum') ||
      !activeSubModules?.Forum.includes('PublicForum') ||
      !activeSubModules.Notification.includes('ForumNotifications')
    ) {
      relevantNotifs = relevantNotifs.filter(
        (n) =>
          !(
            n.notificationType.includes('Forum') &&
            !n.notificationType.includes('Private')
          ),
      );
    }

    // Remove PrivateForum notifs
    if (
      !activeModules?.includes('Forum') ||
      !activeSubModules?.Forum.includes('PrivateForum') ||
      !activeSubModules.Notification.includes('ForumNotifications')
    ) {
      relevantNotifs = relevantNotifs.filter(
        (n) => !n.notificationType.includes('PrivateForum'),
      );
    }

    // Remove PublicCourse notifs
    if (
      !activeModules?.includes('Course') ||
      !activeSubModules?.Course.includes('PublicCourse') ||
      !activeSubModules.Notification.includes('CourseNotifications')
    ) {
      relevantNotifs = relevantNotifs.filter(
        (n) =>
          !(
            n.notificationType.includes('Course') &&
            !n.notificationType.includes('Private')
          ),
      );
    }

    // Remove PrivateCourse notifs
    if (
      !activeModules?.includes('Course') ||
      !activeSubModules?.Course.includes('PrivateCourse') ||
      !activeSubModules.Notification.includes('CourseNotifications')
    ) {
      relevantNotifs = relevantNotifs.filter(
        (n) => !n.notificationType.includes('PrivateCourse'),
      );
    }

    // Remove Video notifs
    if (
      !activeModules?.includes('Course') ||
      !activeSubModules?.Course.includes('PrivateVideos')
    ) {
      relevantNotifs = relevantNotifs.filter(
        (n) => !n.notificationType.includes('Video'),
      );
    }

    return relevantNotifs;
  };

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleNotificationsOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationsAnchorEl(event.currentTarget);

    const notificationsToUpdate = notifications?.filter(
      (notification) => !notification.wasNotifiedByEmail,
    );
    const notificationIds = notificationsToUpdate?.map(
      (notification) => notification.id,
    );
    if (notificationIds && notificationIds.length > 0) {
      dispatch(updateAsOpenedNotifications(notificationIds));
    }
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };

  const handleExamOpen = () => {
    handleNotificationsClose();
    setShowMyExams(true);
  };

  const handleExamClose = () => {
    setShowMyExams(false);
  };

  const customFilter = (options: SearchItem[], input: string): SearchItem[] => {
    const newOptions: SearchItem[] = [];
    options.forEach((opt) => {
      if (opt.name.toLowerCase().includes(input)) {
        newOptions.push(opt);
      }
    });
    return newOptions.sort(x => x.name.localeCompare(x.name));
  };

  return (
    <div>
      <AppBar
        position="fixed"
        color="default"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          <div className={classes.grow} />
          {allDocumentsSimplified && !groupAndCustomerOnly && (
            <div style={{ flex: 'auto' }}>
              <Box mr={2} width={screenSize.width < 600 ? 120 : 500}>
                <Autocomplete
                  freeSolo
                  clearOnBlur={true}
                  options={allDocumentsSimplified}
                  value={''}
                  getOptionLabel={(option) => option.name || ''} // Display name as the main label
                  filterOptions={(options, state) =>
                    customFilter(options, state.inputValue.toLowerCase())
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={getMessage('nav.search')}
                      margin="dense"
                      variant="outlined"
                    />
                  )}
                  renderOption={(props) => (
                    <Box
                      {...props}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '8px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <Typography variant="body1">{props.name}</Typography>
                      {props.courseSlug && (
                        <Typography variant="caption" color="textSecondary">
                          {getMessage('course.courseAdmin.courseType')}: {props.courseSlug} | {getMessage('course.session')}: {props.sessionName || 'N/A'}
                        </Typography>
                      )}
                    </Box>
                  )}
                  onChange={(e, v) => {
                    if (e.type === 'keydown' && v !== null) {
                      if (typeof v === 'string') {
                        const filter = customFilter(
                          allDocumentsSimplified,
                          v as string,
                        );
                        if (filter.length > 0) {
                          const c = filter[0];
                          if (c.courseSlug) {
                            history.push(
                              `/masterclass/${c.courseSlug}/${c.sessionId}`,
                            );
                          } else {
                            history.push(`/masterclass/${c.courseSlug}`);
                          }
                        }
                        return;
                      }
                      const asCourse: SimplifiedCourse = v as SimplifiedCourse;
                      if (asCourse.slug !== undefined) {
                        history.push(`/masterclass/${asCourse.slug}`);
                        return;
                      }
                    } else if (v !== null && v !== undefined) {
                      const c = v as SimplifiedCourse;
                      if (c.sessionId) {
                        history.push(
                          `/masterclass/${c.courseSlug}/${c.sessionId}`,
                        );
                      } else {
                        history.push(`/masterclass/${c.slug}`);
                      }
                    }
                  }}
                />
              </Box>
            </div>
          )}
          <div className={classes.rightSide}>
            <Typography className={classes.userName}>{user?.name}</Typography>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleAccountMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            {activeModules?.includes('Notification') && (
              <IconButton
                edge="end"
                aria-label="notifications"
                aria-controls="notification-menu"
                aria-haspopup="true"
                onClick={handleNotificationsOpen}
                color="inherit"
                className={classes.notificationIconContainer}
              >
                <NotificationsOutlinedIcon />
                {notifications && getRelevantNotifs().length !== 0 && (
                  <Box className={classes.notificationBubble} />
                )}
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <AccountMenu
        anchorEl={menuAnchorEl}
        onClose={handleAccountMenuClose}
        onLogout={() => logout()}
        onShowProfile={() => dispatch(showProfile(true))}
        onShowExams={handleExamOpen}
      />
      {activeModules?.includes('Notification') && (
        <Notifications
          anchorEl={notificationsAnchorEl}
          onClose={handleNotificationsClose}
          notifications={getRelevantNotifs()}
        />
      )}
      {user && (
        <UserProfile
          changeable={true}
          open={openProfile}
          onClose={() => dispatch(showProfile(false))}
          updateProfile={(value) => dispatch(updateProfile(value))}
          userProfileData={user.userProfile}
          user={user}
          isLoggedInUsersProfile
          emailConfirmed
        />
      )}
      {user && <UserResults isOpen={showMyExams} onClose={handleExamClose} />}
    </div>
  );
};

export default Navbar;
