import { notificationsHttpClient as httpClient } from './httpClient';
import { Notification } from '../types';

export const getNotifications = async (): Promise<Notification[]> => {
    const response = await httpClient.get('notification');
    return response.data;
};

export const deleteNotification = async (id: string) => {
    const response = await httpClient.delete(`notification/${id}`);
    return response.data;
};

export const updateAsOpenedNotifications = async (ids: string[]) => {
    const response = await httpClient.patch(`notification`, { notificationIds: ids });
    return response.data;
};

