import { Box, Card, CardMedia } from '@material-ui/core';
import React from 'react';

function ImageViewerComponent(props: { object: Blob }) {
  return (
    <Box maxWidth="30%" maxHeight="750px" marginTop="20px">
      <Card>
        <CardMedia
          component="img"
          image={URL.createObjectURL(props.object)}
          alt="Image"
          style={{ objectFit: 'contain' }}
        />
      </Card>
    </Box>
  );
}

export default ImageViewerComponent;
