import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomChip from '../../components/CustomChip';
import Message from '../../components/translation/Message';
import {
  allExamCoursesSimplifiedSelector,
  coursesIsLoadingSelector,
} from '../../store/selectors';
import { SimplifiedCourse } from '../../types';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';

type ChangeMandatoryDialogProps = {
  name: string;
  mandatoryCourses: SimplifiedCourse[];
  isOpen: boolean;
  onClose: () => void;
  handleSave: (courses: SimplifiedCourse[]) => void;
};

const useStyles = makeStyles((_theme) => ({
  autocompleteInput: {
    '& input': {
      padding: '12px !important',
    },
  },
}));

export const ChangeMandatoryDialog = (props: ChangeMandatoryDialogProps) => {
  const { name, isOpen, mandatoryCourses, onClose, handleSave } = props;
  const allCourses = useSelector(allExamCoursesSimplifiedSelector);
  const isLoading = useSelector(coursesIsLoadingSelector);
  const classes = useStyles();
  const [newMandatory, setNewMandatoryCourses] =
    useState<SimplifiedCourse[]>(mandatoryCourses);

  const isEqual = () => {
    return JSON.stringify(newMandatory) === JSON.stringify(mandatoryCourses);
  };

  const onSave = () => {
    handleSave(newMandatory);
  };

  const handleChange = (newValue: string[]) => {
    let newCourses: SimplifiedCourse[] = [];
    for (const value of newValue) {
      const [courseType, courseName] = value.split(' - ');
      const simpl = allCourses?.find(
        (course) =>
          course.name === courseName && course.courseType?.name === courseType
      );
      if (!simpl) {
        continue;
      }
      newCourses.push(simpl);
    }
    setNewMandatoryCourses(newCourses);
  };

  const handleClose = () => {
    setNewMandatoryCourses(mandatoryCourses);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={() => handleClose()} fullWidth maxWidth="md">
      <DialogTitle>
        {getMessage('handleUserCourses.mandatoryDialog.title')} {name}
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box pt={1}>
            <Autocomplete
              id="mandatoryCourses"
              multiple
              disableCloseOnSelect
              options={
                allCourses?.map(
                  (course) => `${course.courseType?.name} - ${course.name}`
                ) ?? []
              }
              value={newMandatory.map(
                (course) => `${course.courseType?.name} - ${course.name}`
              )}
              onChange={(_e, newValue) => handleChange(newValue)}
              renderInput={(params) => (
                <Box>
                  <TextField
                    {...params}
                    classes={{ root: classes.autocompleteInput }}
                    required
                  />
                </Box>
              )}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <CustomChip {...getTagProps({ index })} label={option} />
                ));
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <Button variant="text" onClick={handleClose}>
            <Message id="handleUserCourses.mandatoryDialog.cancel" />
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={isEqual()}
            onClick={onSave}
            style={{ marginLeft: 5 }}
          >
            <Message id="handleUserCourses.mandatoryDialog.save" />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
