import { userHttpClient as httpClient } from './httpClient';
import {
  TermsAndConditions,
  User,
  InstanceUser,
  InviteUser,
  InstanceRole,
  MyUserProfile,
  Statistic,
  StatisticParams,
  Group,
  GroupDTO,
  GroupMember,
} from '../types';

// USERS
export const getUser = async (): Promise<User> => {
  const response = await httpClient.get(`user`);
  return response.data;
};

export const getUsers = async (): Promise<InstanceUser[]> => {
  const response = await httpClient.get(`user/invite`);
  return response.data;
};

export const deleteUser = async (id: string) => {
  const response = await httpClient.delete(`user/${id}`);
  return response.data;
};

export const inviteUser = async (
  newUser: InviteUser
): Promise<InstanceUser> => {
  const response = await httpClient.post(`user/invite`, newUser);
  return response.data;
};

export const resendInvitations = async (): Promise<User[]> => {
  const response = await httpClient.post(`user/resendInvitation`);
  return response.data;
};

export const getRoles = async (): Promise<InstanceRole[]> => {
  const response = await httpClient.get(`user/invite/roles`);
  return response.data;
};

export const resendUserInvite = async (userId: string): Promise<void> => {
  const response = await httpClient.post('user/invite/sendMassEmailInvites', {
    userIds: [userId],
  });
  return response.data;
};

// TERMS AND CONDITIONS
export const getTermsAndConditions = async (): Promise<TermsAndConditions> => {
  const response = await httpClient.get(`user/TermCondition`);
  return response.data;
};

export const approveTermsAndConditions = async (
  termsOfConditionId: string
): Promise<TermsAndConditions> => {
  const response = await httpClient.post(
    `user/TermCondition/${termsOfConditionId}`
  );
  return response.data;
};

// USER PROFILE
export const getHonorifics = async (): Promise<string[]> => {
  const response = await httpClient.get(`user/userprofile/honorifics`);
  return response.data;
};

export const getUserProfileRoles = async (): Promise<string[]> => {
  const response = await httpClient.get(`user/userprofile/roles`);
  return response.data;
};

export const getUserProfil = async (userId: string): Promise<MyUserProfile> => {
  const response = await httpClient.get(`user/userprofile/${userId}`);
  return response.data;
};

export const getProfilePicture = async (id: string): Promise<Blob> => {
  const response = await httpClient.get<Blob>(
    `user/userprofile/picture/${id}`,
    {
      responseType: 'blob',
    }
  );
  return response.data;
};

export const updateProfile = async (
  userProfile: MyUserProfile,
  externalId: string
): Promise<MyUserProfile> => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(userProfile)) {
    if (key === 'languages') {
      value.forEach((language: string) => {
        formData.append(key, language);
      });
    } else {
      formData.append(key, value);
    }
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const response = await httpClient.post(
    `user/userprofile/?userExternalId=${externalId}`,
    formData,
    config
  );
  return response.data;
};

// GROUPS
export const getGroups = async (): Promise<Group[]> => {
  const response = await httpClient.get(`user/Group`);
  return response.data;
};

export const getGroupsCount = async (): Promise<number> => {
  const response = await httpClient.get(`user/Group/count`);
  return response.data;
};

export const createGroup = async ({
  name,
  members,
  admins,
}: GroupDTO): Promise<Group> => {
  const response = await httpClient.post(`user/Group`, {
    name,
    members,
    admins,
  });
  return response.data;
};

export const deleteGroup = async (groupId: string): Promise<Group> => {
  const response = await httpClient.delete(`user/Group/${groupId}`);
  return response.data;
};

export const getGroupMembers = async (
  groupId: string
): Promise<GroupMember[]> => {
  const response = await httpClient.get(`user/Group/members/${groupId}`);
  return response.data;
};

export const deleteGroupMember = async (
  groupId: string,
  userId: string
): Promise<GroupMember[]> => {
  const response = await httpClient.delete(
    `user/Group/member/${groupId}?userId=${userId}`
  );
  return response.data;
};

export const editGroupName = async (
  groupId: string,
  name: string
): Promise<Group> => {
  const response = await httpClient.patch(`user/Group/name/${groupId}`, {
    name,
  });
  return response.data;
};

export const editGroupMembers = async (
  groupId: string,
  members: string[]
): Promise<Group> => {
  const response = await httpClient.patch(`user/Group/members/${groupId}`, {
    members,
  });
  return response.data;
};

export const editGroupAdmins = async (
  groupId: string,
  admins: string[]
): Promise<Group> => {
  const response = await httpClient.patch(`user/Group/admins/${groupId}`, {
    admins,
  });
  return response.data;
};

// STATISTICS
// Dates: YYYY-MM-DD
export const getStatistics = async ({
  toDate,
  fromDate,
  events,
  userInstanceId,
}: StatisticParams): Promise<Statistic[]> => {
  let path = '';
  let hasAddedToPath = false;

  if (toDate) {
    path = path.concat(`${hasAddedToPath ? '&' : '?'}toDate=${toDate}`);
    hasAddedToPath = true;
  }
  if (fromDate) {
    path = path.concat(`${hasAddedToPath ? '&' : '?'}fromDate=${fromDate}`);
    hasAddedToPath = true;
  }
  if (events) {
    path = path.concat(`${hasAddedToPath ? '&' : '?'}events=${events}`);
    hasAddedToPath = true;
  }
  if (userInstanceId) {
    path = path.concat(
      `${hasAddedToPath ? '&' : '?'}userInstanceId=${userInstanceId}`
    );
  }

  const response = await httpClient.get(`user/Statistics${path}`);
  return response.data;
};
