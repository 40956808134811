import { Box, Typography } from '@material-ui/core';

const DataGridHeader = ({ text }: { text: string }) => {
    return (
        <Box pt={2} pb={2}>
            <Typography variant='subtitle2'>
                {text}
            </Typography>
        </Box>
    )
}

export default DataGridHeader;