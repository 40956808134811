import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import {
  Box,
  Button,
  createStyles,
  FormControlLabel,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SafeHtmlRenderer from '../../../components/SafeHtmlRenderer';
import { Session, Document, QuestionAnswer } from '../../../types';
import HtmlEditor from '../../../components/HtmlEditor';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSessionDocument,
  deleteSessionDocument,
  updateSession,
  updateSessionDocument,
} from '../../../store/editCourseSlice';
import EditableTitle from '../../../components/EditableTitle';
import Message from '../../../components/translation/Message';
import DeleteButton from '../../../components/DeleteButton';
import { getMessage } from '../../../whitelabel-config/WhitelabelProvider';
import VideoUrlSection from './VideoUrlSection';
import DocumentSection from '../../../components/documents/DocumentSection';
import { activeSubModulesSelector } from '../../../store/instanceSlice';
import { EditQuestions } from '../../../components/question/EditQuestions';
import { isEqual } from 'lodash';

interface EditState {
  formattedText?: string;
}
type SessionProps = {
  session: Session;
  onDeleteSession: (sessionId: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    flexColumnCenter: {
      display: 'flex',
      flexDirection: 'column',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    documentDivider: {
      margin: theme.spacing(1, 0),
    },
  })
);

const EditSessionSubPage = ({ session, onDeleteSession }: SessionProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editState, setEditState] = useState<EditState>({});
  const [isFileDownloadable, setIsFileDownloadable] = useState<boolean>(
    session.isFileDownloadable
  );
  const [isAnswerMandatory, setIsAnswerMandatory] = useState<boolean>(
    session.mandatoryToAnswer ?? true
  );
  const [isLiveStreamSession, setIsLiveStreamSession] =
    useState<boolean>(false);
  const [latestDocument, setLatestDocument] = useState<Document | null>(null);

  const activeSubModules = useSelector(activeSubModulesSelector);

  const classes = useStyles();
  const dispatch = useDispatch();

  const startEditing = () => setIsEditing(true);

  const liveStreamIsOn = useCallback((): boolean => {
    return (
      !!activeSubModules &&
      (activeSubModules.Course.includes('PublicLiveStream') ||
        activeSubModules.Course.includes('PrivateLiveStream') ||
        activeSubModules.Course.includes('GroupLiveStream'))
    );
  }, [activeSubModules]);

  const isExamOn = useCallback((): boolean => {
    return !!activeSubModules && activeSubModules.Course.includes('ExamCourse');
  }, [activeSubModules]);

  const saveEdit = () => {
    setIsEditing(false);
    if (!editState.formattedText) return;
    const updatedSession: Session = { ...session };
    updatedSession.formattedText = editState.formattedText || '';
    updatedSession.formattedDescription = editState.formattedText || '';
    dispatch(updateSession(updatedSession));
  };

  const onFormattedTextChange = (formattedText: string) => {
    setEditState({ formattedText });
  };

  const handleChangeVideoUrl = (url: string) => {
    const updatedSession: Session = { ...session };
    if (isLiveStreamSession) {
      updatedSession.liveStreamUrl = url;
      updatedSession.vimeoUrl = '';
    } else {
      updatedSession.vimeoUrl = url;
      updatedSession.liveStreamUrl = '';
    }
    dispatch(updateSession(updatedSession));
    setIsEditing(false);
  };

  const handleVideoDuration = (seconds: number) => {
    if (session.length !== seconds) {
      const updatedSession: Session = { ...session };
      updatedSession.length = seconds;
      dispatch(updateSession(updatedSession));
    }
  };

  const handleChangeSessionName = (name: string) => {
    dispatch(updateSession({ ...session, name }));
  };

  const handleDocumentUploadFinish = (
    document: Document,
    isFileDownloadable: boolean
  ) => {
    // handleDeleteAllDocuments();
    dispatch(addSessionDocument({ session, document, isFileDownloadable }));
  };

  const handleDeleteAllDocuments = () => {
    if (session.documents.length > 0) {
      session.documents.forEach((document) =>
        dispatch(deleteSessionDocument(session, document))
      );
    }
  };

  const handleDeleteCurrentDocument = () => {
    if (session.documents.length > 0 && latestDocument != null) {
      dispatch(deleteSessionDocument(session, latestDocument));
    }
  };

  const handleChangeDownloadable = (isDownloadable: boolean) => {
    setIsFileDownloadable(isDownloadable);
    const updatedSessionDoc: Document = { ...session.documents[0] };
    updatedSessionDoc.isFileDownloadable = isDownloadable;
    dispatch(updateSessionDocument(session, updatedSessionDoc));
  };

  const handleChangeAnswersMandatory = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIsAnswerMandatory(checked);
    dispatch(updateSession({ ...session, mandatoryToAnswer: checked }));
  };

  const handleQuestionsChange = (
    questions: QuestionAnswer[],
    minimumPoints: number
  ) => {
    if (
      !isEqual(questions, session.questionAnswers) ||
      session.minimumPoints !== minimumPoints
    ) {
      dispatch(
        updateSession({
          ...session,
          questionAnswers: questions,
          minimumPoints: minimumPoints,
        })
      );
    }
  };

  useEffect(() => {
    setEditState({});

    if (session.documents.length >= 1) {
      setLatestDocument(session.documents[session.documents.length - 1]);
    } else {
      setLatestDocument(session.documents[0]);
    }

    if (liveStreamIsOn()) {
      setIsLiveStreamSession(!!session.liveStreamUrl);
    } else {
      setIsLiveStreamSession(false);
    }
  }, [session, activeSubModules, liveStreamIsOn]);

  return (
    <Box>
      <Box className={classes.flexCenter} justifyContent="space-between" mb={4}>
        <EditableTitle
          title={session.name}
          onChange={handleChangeSessionName}
        />
        <DeleteButton
          itemCategory={getMessage('course.session')}
          itemName={session.name}
          onDelete={() => onDeleteSession(session.id)}
          dialogTitle={getMessage(
            'course.courseAdmin.editCoursePage.deleteSession'
          )}
        />
      </Box>

      <Box maxWidth={800} mb={6}>
        <VideoUrlSection
          url={
            isLiveStreamSession ? session.liveStreamUrl || '' : session.vimeoUrl
          }
          onChange={handleChangeVideoUrl}
          isLiveStream={isLiveStreamSession}
          liveStreamIsOn={liveStreamIsOn()}
          onChangeIsLiveStream={(value: boolean) =>
            setIsLiveStreamSession(value)
          }
          onDuration={handleVideoDuration}
        />
      </Box>

      <Box mb={6}>
        <Typography variant="subtitle2">
          <Message id="course.courseAdmin.editCoursePage.description" />
        </Typography>

        {isEditing ? (
          <HtmlEditor
            html={session.formattedText || session.formattedDescription}
            onChange={onFormattedTextChange}
          />
        ) : (
          <SafeHtmlRenderer
            html={
              editState.formattedText ||
              session.formattedDescription ||
              session.formattedText ||
              ''
            }
          />
        )}
        <Box mt={2}>
          {isEditing ? (
            <Button variant="contained" color="primary" onClick={saveEdit}>
              <Message id="course.courseAdmin.editCoursePage.done" />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={startEditing}
            >
              <Message id="course.courseAdmin.editCoursePage.edit" />
            </Button>
          )}
        </Box>
      </Box>

      <DocumentSection
        session={session}
        onDocumentUploadFinish={handleDocumentUploadFinish}
        onDeleteAllDocuments={handleDeleteAllDocuments}
        onDeleteCurrentDocument={handleDeleteCurrentDocument}
        isFileDownloadable={isFileDownloadable}
        onChangeDownloadable={handleChangeDownloadable}
      />
      {isExamOn() && (
        <Box>
          <EditQuestions
            key={session.index}
            sessionId={session.id}
            questionsAnswers={session.questionAnswers}
            minimumPoints={session.minimumPoints}
            maximumPoints={session.maximumPoints}
            handleQuestionsChange={handleQuestionsChange}
          />
          <Box marginTop={'20px'}>
            <FormControlLabel
              control={
                <Switch
                  checked={isAnswerMandatory}
                  onChange={handleChangeAnswersMandatory}
                  name="answer-mandatory"
                  color="primary"
                />
              }
              label={getMessage(
                'course.courseAdmin.editCoursePage.answerToMoveOn'
              )}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditSessionSubPage;
