import { useState, useEffect, useContext, useMemo } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  makeStyles,
  createStyles,
  Divider,
  Button,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HtmlEditor from '../../components/HtmlEditor';
import Message from '../../components/translation/Message';
import { WhitelabelContext } from '../../whitelabel-config/WhitelabelProvider';
import { userSelector } from '../../store/userSlice';
import { isUserActive } from '../../utils';
import {
  fetchPermissions,
  permissionsForUserAreaSelector,
  userAreaAddingReplySelector,
  userAreaSelector,
} from '../../store/userAreaSlice';
import { AppThunk } from '../../store';
import { ForumTopic as ForumTopicType } from '../../types';
import ForumTopicHeader from './ForumTopicHeader';
import ForumTopicItem from './ForumTopicItem';
import {
  deleteForumReply,
  forumTopicsIsAddingReplySelector,
  updateCurrentForumTopic,
  updateForumReply,
} from '../../store/forumSlice';
import {
  updateCurrentPrivateForumTopic,
  updatePrivateForumReply,
  deletePrivateForumReply,
} from '../../store/userAreaSlice';
import mixpanel from 'mixpanel-browser';
import PageOrSectionHeader from '../typography/PageOrSectionHeader';
import { getAuthorColor, getMainPageUrl } from './utils';
import {
  notificationsSelector,
  removeNotificationsForReferenceId,
} from '../../store/notificationsSlice';
import { currentGroupSelector } from '../../store/groupSlice';

const useStyles = makeStyles(() =>
  createStyles({
    forumAuthor: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    submitButton: {
      float: 'right',
      marginTop: '1em',
    },
    editorContainer: {
      height: '600px',
      marginBottom: '30px',
    },
  })
);

type ForumTopicProps = {
  addForumReply: (formattedReply: string) => AppThunk;
  fetchTopic: (slug: string) => AppThunk;
  forumTopic: ForumTopicType | null;
  isLoading: boolean;
};

const ForumTopic = ({
  addForumReply,
  fetchTopic,
  forumTopic,
  isLoading,
}: ForumTopicProps) => {
  const [replyText, setReplyText] = useState<string>('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [stateBustingKey, setStateBustingKey] = useState(0);
  const { getMessage } = useContext(WhitelabelContext);
  let params: { slug: string } = useParams();

  const userArea = useSelector(userAreaSelector);
  const currentGroup = useSelector(currentGroupSelector);
  const permissionsForUserArea = useSelector(permissionsForUserAreaSelector);
  const user = useSelector(userSelector);
  const notifications = useSelector(notificationsSelector);
  const isAddingPublicReply = useSelector(forumTopicsIsAddingReplySelector);
  const isAddingPrivateReply = useSelector(userAreaAddingReplySelector);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const getBackUrl = () => {
    let userAreaOrGroupId = null;
    if (
      forumTopic?.groupId &&
      forumTopic?.groupId !== '00000000-0000-0000-0000-000000000000'
    ) {
      userAreaOrGroupId = forumTopic?.groupId;
    }

    if (
      forumTopic?.privateForumUserId &&
      forumTopic?.privateForumUserId !== '00000000-0000-0000-0000-000000000000'
    ) {
      userAreaOrGroupId = forumTopic?.privateForumUserId;
    }

    return getMainPageUrl(history.location.pathname, userAreaOrGroupId);
  };

  const getAuthorColorById = useMemo(getAuthorColor, [
    forumTopic,
    getAuthorColor,
  ]);

  useEffect(() => {
    if (!notifications || !forumTopic) return;
    notifications.forEach((notification) => {
      if (notification.referenceId === forumTopic.id) {
        dispatch(removeNotificationsForReferenceId(notification.referenceId));
      }
    });
  }, [notifications, forumTopic, dispatch]);

  useEffect(() => {
    if (forumTopic === null || forumTopic.slug !== params.slug) {
      dispatch(fetchTopic(params.slug));
    }

    if (userArea && !permissionsForUserArea && forumTopic) {
      dispatch(fetchPermissions(userArea.id));
    }
  }, [
    forumTopic,
    dispatch,
    params.slug,
    permissionsForUserArea,
    fetchTopic,
    userArea,
  ]);

  const handleAddReply = () => {
    if (replyText.length < 8) {
      setHelperText(getMessage('forum.topicPage.descriptionHelper'));
      setError(true);
    } else {
      if (forumTopic && userArea) {
        mixpanel.track('ADD_REPLY_TO_PRIVATE_FORUM_TOPIC', {
          topicId: forumTopic.id,
          userAreaId: forumTopic.privateForumUserId,
        });
      }
      dispatch(addForumReply(replyText));
      setReplyText('');
      setError(false);
      setHelperText('');
      setStateBustingKey(stateBustingKey + 1);
    }
  };

  const canReplyToTopic = () => {
    if (currentGroup) {
      return true;
    }

    if (
      userArea &&
      forumTopic &&
      permissionsForUserArea &&
      permissionsForUserArea.includes('WriteForum')
    ) {
      return true;
    }

    if (!userArea && forumTopic) {
      return true;
    }

    return false;
  };

  const handleSaveTopicEdit = (topicId: string, updateTopicText: string) => {
    if (!forumTopic) return;
    const updatedTopicItem = {
      subject: forumTopic.subject,
      formattedText: updateTopicText,
      categoryId: forumTopic.category.id,
    };

    if (userArea) {
      mixpanel.track('EDIT_PRIVATE_FORUM_TOPIC', {
        topicId: forumTopic.id,
        userAreaId: forumTopic.privateForumUserId,
      });
    }

    if (userArea || currentGroup) {
      dispatch(updateCurrentPrivateForumTopic(topicId, updatedTopicItem));
    } else {
      dispatch(updateCurrentForumTopic(topicId, updatedTopicItem));
    }
  };

  const handleSaveReplyEdit = (replyId: string, updateReplyText: string) => {
    if (userArea) {
      mixpanel.track('EDIT_PRIVATE_FORUM_TOPIC_REPLY', {
        replyId: replyId,
        topicId: forumTopic?.id || '',
        userAreaId: forumTopic?.privateForumUserId || '',
      });
    }

    if (userArea || currentGroup) {
      dispatch(updatePrivateForumReply(replyId, updateReplyText));
    } else {
      dispatch(updateForumReply(replyId, updateReplyText));
    }
  };

  const handleSaveSubjectCategoryEdit = ({
    updatedSubject,
    updatedCategoryId,
  }: {
    updatedSubject?: string;
    updatedCategoryId?: string;
  }) => {
    if (!forumTopic) return;
    const updatedTopicItem = {
      subject: updatedSubject || forumTopic.subject,
      formattedText: forumTopic.formattedText,
      categoryId: updatedCategoryId || forumTopic.category.id,
    };

    if (userArea) {
      mixpanel.track('EDIT_PRIVATE_FORUM_TOPIC_SUBJECT', {
        topicId: forumTopic.id,
        userAreaId: forumTopic.privateForumUserId,
      });
    }

    if (userArea || currentGroup) {
      dispatch(updateCurrentPrivateForumTopic(forumTopic.id, updatedTopicItem));
    } else {
      dispatch(updateCurrentForumTopic(forumTopic.id, updatedTopicItem));
    }
  };

  if (isLoading) {
    return (
      <Box p={{ xs: 1, md: 4 }} maxWidth="50em">
        <CircularProgress size={64} />
      </Box>
    );
  }

  if (!isLoading && forumTopic === null) {
    return (
      <Box pt={4} textAlign="center">
        <PageOrSectionHeader>
          <Message id="userArea.deniedEntry" />
        </PageOrSectionHeader>
      </Box>
    );
  }

  return (
    <Box p={{ xs: 1, md: 4 }} maxWidth="60em">
      {forumTopic && (
        <Box>
          <Box>
            <ForumTopicHeader
              linkTo={getBackUrl()}
              headerText={forumTopic.subject}
              category={forumTopic.category}
              canEdit={forumTopic.author.id === user?.userInstanceId}
              onSaveEdit={handleSaveSubjectCategoryEdit}
            />
            <Divider component="div" />

            <ForumTopicItem
              topicItem={forumTopic}
              canEdit={forumTopic.author.id === user?.userInstanceId}
              avatarColor={
                isUserActive(forumTopic.author.id)
                  ? getAuthorColorById(forumTopic.author.id)
                  : 'grey'
              }
              onSaveEdit={handleSaveTopicEdit}
            />
          </Box>
          <Box ml={8}>
            {forumTopic.replies.map((reply) => (
              <Box key={reply.id}>
                <ForumTopicItem
                  topicItem={reply}
                  canEdit={reply.author.id === user?.userInstanceId}
                  avatarColor={
                    isUserActive(reply.author.id)
                      ? getAuthorColorById(reply.author.id)
                      : 'grey'
                  }
                  onSaveEdit={handleSaveReplyEdit}
                  onDelete={(id: string) => {
                    if (userArea || currentGroup) {
                      dispatch(deletePrivateForumReply(id));
                    } else {
                      dispatch(deleteForumReply(id));
                    }
                  }}
                />
              </Box>
            ))}
            {canReplyToTopic() && (
              <Box mt={4} className={classes.editorContainer}>
                <Typography component="h2" variant="h6" gutterBottom>
                  <Message id="forum.topicPage.replyTitle" />
                </Typography>
                <FormControl error={error}>
                  <HtmlEditor key={stateBustingKey} onChange={setReplyText} />
                  <FormHelperText>{helperText}</FormHelperText>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={handleAddReply}
                  disabled={isAddingPublicReply || isAddingPrivateReply}
                >
                  <Message id="forum.createTopicPage.submitTopic" />
                </Button>
              </Box>
            )}
            {!canReplyToTopic() && (
              <Box pt={2}>
                <Typography component="p" variant="body2" gutterBottom>
                  * <Message id="userArea.deniedReply" />
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ForumTopic;
