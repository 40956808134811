import {
  Box,
  Card,
  Checkbox,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { AddCircle, Cancel } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteOptionImage, uploadOptionImage } from '../../api/course-api';
import {
  startImageUploading,
  stopImageUploading,
} from '../../store/coursesSlice';
import { AnswerOption, AnswerType, QuestionAnswer } from '../../types';
import Message from '../translation/Message';
import { EditOptionComponent } from './EditOptionComponent';

type EditQComponentProps = {
  questionAnswer: QuestionAnswer;
  sessionId: string;
  index: number;
  saveQuestions: (questions: QuestionAnswer, index: number) => void;
  addMaxPoints?: (amount: number) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      width: '95%',
    },
    flexColumnCenter: {
      display: 'flex',
      flexDirection: 'column',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    documentDivider: {
      margin: theme.spacing(1, 0),
    },
  })
);

export const EditQuestionComponent = ({
  saveQuestions,
  index,
  questionAnswer,
  sessionId,
  addMaxPoints = undefined,
}: EditQComponentProps) => {
  const [questionState, setQuestionState] =
    useState<QuestionAnswer>(questionAnswer);
  const classes = useStyles();
  const dispatch = useDispatch();

  const emptyOption = {
    text: '',
    image: undefined,
    isCorrect: false,
  };

  const addOption = () => {
    let options = questionState.answerOptions;
    options.push(emptyOption);
    setQuestionState({ ...questionState, answerOptions: options });
  };

  const isMultipleChoice = (options: AnswerOption[]) => {
    const sum = options
      .map((option) => (option.isCorrect ? 1 : 0))
      .reduce((prev: number, curr: number) => prev + curr, 0);

    return sum > 1;
  };

  const handleFreeTextCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      // is free text
      setQuestionState({
        ...questionState,
        answerOptions: [],
        answerType: 'FreeText',
      });
    } else {
      // is not free text
      let newOptions = questionState.answerOptions.map((o) => o);
      if (newOptions.length === 0) {
        newOptions.push(emptyOption);
      }
      setQuestionState({
        ...questionState,
        answerOptions: newOptions,
        answerType: isMultipleChoice(newOptions)
          ? 'MultipleChoice'
          : 'SingleChoice',
      });
    }
  };

  const handleRemoveOption = (index: number) => {
    const removed = questionState.answerOptions.map((o) => o);
    removed.splice(index, 1);

    setQuestionState({ ...questionState, answerOptions: removed });
  };

  const handleOptionChange = (option: AnswerOption, index: number) => {
    let options: AnswerOption[] = [];
    for (let i = 0; i < questionAnswer.answerOptions.length; i++) {
      const oldOpt = questionAnswer.answerOptions[i];
      if (i === index) {
        options.push(option);
        if (addMaxPoints) {
          if (oldOpt.isCorrect && !option.isCorrect) {
            addMaxPoints(-1);
          } else if (!oldOpt.isCorrect && option.isCorrect) {
            addMaxPoints(1);
          }
        }
      } else {
        options.push(oldOpt);
      }
    }
    setQuestionState({ ...questionState, answerOptions: options });
  };

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionState({ ...questionState, question: event.target.value });
  };

  useEffect(() => {
    const answerType: AnswerType =
      questionState.answerOptions.length === 0
        ? 'FreeText'
        : isMultipleChoice(questionState.answerOptions)
        ? 'MultipleChoice'
        : 'SingleChoice';

    const nState: QuestionAnswer = { ...questionState, answerType: answerType };
    saveQuestions(nState, index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionState]);

  const handleQuestionImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files) return;
    dispatch(startImageUploading());
    try {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      const url = await uploadOptionImage(sessionId, file);
      if (url !== null) {
        setQuestionState({ ...questionState, imageUrl: url });
      } else {
        setQuestionState(questionState);
      }
    } catch (e: any) {
      // User canceled
    }
    dispatch(stopImageUploading());
  };

  const handleDeleteImage = async () => {
    dispatch(startImageUploading());
    if (questionState.imageUrl) {
      const splitted = questionState.imageUrl.split('exam-pictures');
      if (splitted.length === 2) {
        let fileName = splitted[1].substring(1);
        if (await deleteOptionImage(fileName)) {
          setQuestionState({ ...questionState, imageUrl: undefined });
        }
      }
    }
    dispatch(stopImageUploading());
  };

  return (
    <Card
      style={{
        marginTop: 10,
        padding: 20,
        background: '#f5f5f5',
        width: '75%',
      }}
    >
      <Box mb={1}>
        <Typography variant="subtitle1" className={classes.flexCenter}>
          <Message id="course.courseAdmin.editCoursePage.question" />{' '}
          {index + 1}:
        </Typography>
        <div className={classes.flexCenter}>
          <TextField
            value={questionState.question}
            onChange={handleQuestionChange}
            style={{ width: '80%' }}
          />
          <Checkbox
            checked={questionState.answerOptions.length === 0}
            onChange={handleFreeTextCheckbox}
            color="primary"
          />
          <Typography variant="subtitle2" style={{ marginLeft: -5 }}>
            <Message id="course.courseAdmin.editCoursePage.freeText" />
          </Typography>
        </div>
        <Box>
          {questionState.imageUrl && questionState.imageUrl !== '' && (
            <div className={classes.flexCenter}>
              <img
                alt=""
                src={questionState.imageUrl}
                height="150px"
                width="auto"
              />
              <IconButton onClick={handleDeleteImage}>
                <Cancel fontSize="large" color="action" />
              </IconButton>
            </div>
          )}
          <input
            style={{ marginTop: 5, marginLeft: 15 }}
            type="file"
            accept="image/*"
            onChange={handleQuestionImageUpload}
          />
        </Box>
      </Box>
      {questionState.answerOptions.map(
        (option: AnswerOption, index: number) => (
          <EditOptionComponent
            key={index}
            answerOption={option}
            sessionId={sessionId}
            index={index}
            handleRemoveOption={handleRemoveOption}
            handleOptionChange={handleOptionChange}
          />
        )
      )}
      <Typography
        className={classes.flexCenter}
        style={{ marginTop: 10 }}
        variant="subtitle1"
      >
        <IconButton onClick={addOption}>
          <AddCircle fontSize="large" color="primary" />
        </IconButton>
        <Message id="course.courseAdmin.editCoursePage.addOption" />
      </Typography>
    </Card>
  );
};
