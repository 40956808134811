import { SvgIcon, SvgIconProps } from "@material-ui/core";

const HomeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M12 1.9455L11.4608 2.46075L1.71075 12.2107L2.79 13.29L3.75 12.3277V21H10.5V13.5H13.5V21H20.25V12.3277L21.2108 13.2892L22.2893 12.2107L12.5393 2.46075L12 1.9455ZM12 4.0785L18.75 10.8285V19.5H15V12H9V19.5H5.25V10.8277L12 4.0785Z" />
    </SvgIcon>
  );
}

export default HomeIcon;