import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  createStyles,
  debounce,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Radio,
  RadioGroup,
  RadioProps,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { CheckCircle, Close, HourglassFull } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { resultStateSelector } from '../../store/selectors';
import { courseTheme } from '../../theme';
import { AnswerOption, QuestionAnswer, Result } from '../../types';
import HtmlEditor from '../HtmlEditor';
import SafeHtmlRenderer from '../SafeHtmlRenderer';
import Message from '../translation/Message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

const MyRadio = withStyles({
  root: {
    '&$checked': {
      color: '#009A80',
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const MyCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#009A80',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

type QuestionProps = {
  questionAnswer: QuestionAnswer;
  handleAnswer: (index: number, userAnswer: AnswerOption[]) => void;
  editable?: boolean;
  results?: Result[];
  freeTextCorrection?: (value: boolean) => void;
  disabled?: boolean;
};

export const QuestionComponent = ({
  questionAnswer,
  handleAnswer,
  editable = false,
  results = undefined,
  freeTextCorrection = undefined,
  disabled = false,
}: QuestionProps) => {
  const { question, imageUrl, answerOptions, answerType, index } =
    questionAnswer;
  const [userAnswers, setUserAnswers] = useState<AnswerOption[]>([]);
  const classes = useStyles();
  const resultState = useSelector(resultStateSelector);

  const createStringValue = (option: AnswerOption) => {
    return JSON.stringify({ text: option.text, image: option.image });
  };

  const correctAnswer = (option: AnswerOption) => {
    const userResult = results!.find((res) => res.index === index);
    if (!userResult) {
      return undefined;
    }
    const userOption = userResult.answerOptions.find(
      (userOpt) => createStringValue(userOpt) === createStringValue(option)
    );
    if (!userOption) {
      return false;
    }
    if (userOption.selected) {
      return userOption.isCorrect;
    }
  };

  const answerComponent = (option: AnswerOption) => {
    return (
      <Box>
        {option.image && (
          <img
            alt=""
            src={option.image}
            height="200px"
            width="auto"
            style={{
              marginLeft: 5,
              objectFit: 'contain',
            }}
          />
        )}
        {option.text && (
          <Typography variant="subtitle1"> {option.text} </Typography>
        )}
      </Box>
    );
  };

  const handleMultiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = JSON.parse(event.target.value) as AnswerOption;
    let newAnswers = [...userAnswers];
    if (event.target.checked) {
      newAnswers.push(selected);
    } else {
      newAnswers = userAnswers.filter(
        (ua) => createStringValue(ua) !== createStringValue(selected)
      );
    }
    setUserAnswers(newAnswers);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = JSON.parse(event.target.value) as AnswerOption;
    setUserAnswers([value]);
  };

  const handleFreeTextChange = (html: string) => {
    if (html !== '') {
      handleTextChange(html);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTextChange = useCallback(
    debounce((value: string) => {
      setUserAnswers([{ text: value, image: undefined }]);
    }, 500),
    []
  );

  useEffect(() => {
    handleAnswer(index, userAnswers);
  }, [userAnswers, handleAnswer, index, results]);

  const validResults = () => {
    return results && results.length > 0;
  };

  const isChecked = useCallback(
    (value: string) => {
      if (editable) {
        return answerOptions
          .filter((o) => o.isCorrect)
          .map((o) => createStringValue(o))
          .includes(value);
      }
      if (results && results.length !== 0) {
        const userResults = results.find((res) => res.index === index);
        if (!userResults) {
          return false;
        }
        return userResults.answerOptions.find(
          (opt) => createStringValue(opt) === value
        )?.selected;
      }
      return userAnswers.map((a) => createStringValue(a)).includes(value);
    },
    [editable, results, userAnswers, answerOptions, index]
  );

  const choiceResult = (option: AnswerOption) => {
    const correct = correctAnswer(option);
    if (correct === undefined) {
      return;
    } else {
      return correctAnswer(option) ? (
        <CheckCircle color="primary" />
      ) : (
        <Close color="error" />
      );
    }
  };

  const checkboxGroup = () => {
    const choices = answerOptions.map((option: AnswerOption, i: number) => {
      const optionValue = createStringValue(option);
      return (
        <div
          key={`cb_div_${i}`}
          className={classes.flexCenter}
          style={{ marginTop: 5 }}
        >
          <FormControlLabel
            key={`cb_fcl_${i}`}
            value={optionValue}
            control={
              <MyCheckbox
                key={`cb_fcl_c_${i}_${optionValue}`}
                onChange={handleMultiChange}
              />
            }
            label={answerComponent(option)}
            checked={isChecked(optionValue)}
            disabled={editable || validResults() || disabled}
          />
          {validResults() && choiceResult(option)}
        </div>
      );
    });

    return <FormGroup>{choices}</FormGroup>;
  };

  const radioGroup = () => {
    let index = 1;
    let choices = answerOptions.map((option) => {
      const optionValue = createStringValue(option);
      return (
        <div key={index++} className={classes.flexCenter}>
          <FormControlLabel
            key={index++}
            value={optionValue}
            control={<MyRadio />}
            label={answerComponent(option)}
            checked={isChecked(optionValue)}
            disabled={editable || validResults() || disabled}
          />
          {validResults() && choiceResult(option)}
        </div>
      );
    });

    choices = [
      <div key={0}>
        <FormControlLabel
          style={{ visibility: 'hidden', height: 0 }}
          control={<MyRadio />}
          label={''}
          disabled={editable || disabled}
        />
      </div>,
      ...choices,
    ];

    return <RadioGroup onChange={handleRadioChange}>{choices}</RadioGroup>;
  };

  const freeTextBody = (): string => {
    if (validResults()) {
      const userResult = results!.find((r) => r.index === index);
      const userOption = userResult?.answerOptions[0];
      if (
        userResult &&
        userResult.answerType === 'FreeText' &&
        userOption !== undefined
      ) {
        return userOption.text ?? '';
      }
    }
    return '';
  };

  const freeTextResult = () => {
    if (validResults()) {
      const userResult = results!.find((r) => r.index === index);
      if (userResult && userResult.answerType === 'FreeText') {
        const option = userResult.answerOptions[0];
        if (option && option.selected) {
          if (option.isCorrect === null) {
            const message = (
              <Typography style={{ color: 'white', fontSize: 14 }}>
                <Message id="course.waitingForCorrection" />
              </Typography>
            );
            return (
              <Tooltip arrow title={message}>
                <HourglassFull fontSize="large" htmlColor="#FFC300" />
              </Tooltip>
            );
          } else {
            return option.isCorrect ? (
              <CheckCircle color="primary" />
            ) : (
              <Close color="error" />
            );
          }
        }
      }
    }
  };

  const freeText = () => {
    if (editable) {
      return (
        <Typography
          style={{
            marginTop: 5,
            fontSize: 18,
            fontStyle: 'italic',
          }}
          variant="subtitle1"
        >
          <Message id="course.courseAdmin.editCoursePage.freeText" />
        </Typography>
      );
    }

    if (validResults() || disabled) {
      return (
        <Typography variant="subtitle1">
          <SafeHtmlRenderer html={freeTextBody()!} />
        </Typography>
      );
    }

    return (
      <HtmlEditor
        showPreview={validResults()}
        html={freeTextBody()}
        onChange={handleFreeTextChange}
      />
    );
  };

  const getGroup = () => {
    switch (answerType) {
      case 'SingleChoice':
        return radioGroup();
      case 'MultipleChoice':
        return checkboxGroup();
      case 'FreeText':
        return freeText();
      default:
        return <></>;
    }
  };

  const getGroupMessageId = () => {
    switch (answerType) {
      case 'SingleChoice':
        return 'course.singleChoice';
      case 'MultipleChoice':
        return 'course.multipleChoice';
      case 'FreeText':
        return 'course.freeText';
    }
  };

  const shouldRenderFreeTextCorrecting = () => {
    if (answerType === 'FreeText' && validResults()) {
      const userResult = results!.find((r) => r.index === index);
      if (userResult) {
        const option = userResult.answerOptions[0];
        if (option && option.selected) {
          return option.isCorrect === null || !option.isCorrect;
        }
      }
    } else {
      return false;
    }
  };

  const renderFreeTextCorrecting = () => {
    if (!freeTextCorrection) {
      return <></>;
    }
    return !resultState.isCorrecting ? (
      <Box mb={4} mt={1} className={classes.flexCenter}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => freeTextCorrection(true)}
        >
          <Message id="course.correct" />
        </Button>
        <Button variant="contained" onClick={() => freeTextCorrection(false)}>
          <Message id="course.incorrect" />
        </Button>
      </Box>
    ) : (
      <CircularProgress />
    );
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <Box className={classes.flexCenter}>
        <Typography variant="h5">
          {index + 1}. {question}
        </Typography>
        {validResults() && freeTextResult()}
      </Box>
      {imageUrl && imageUrl !== '' && (
        <img alt="" src={imageUrl} style={{ height: '200px', width: 'auto' }} />
      )}
      {!editable && (
        <>
          <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>
            {getGroupMessageId() && <Message id={getGroupMessageId()!} />}
          </Typography>
          <Box
            width="100px"
            height="2px"
            style={{
              marginLeft: -2,
              backgroundColor: courseTheme.palette.secondary.main,
            }}
          />
        </>
      )}
      <Box>
        {getGroup()}
        {shouldRenderFreeTextCorrecting() && renderFreeTextCorrecting()}
      </Box>
    </div>
  );
};
