import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const QuestionMarkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47716 15.5228 0 10 0C4.47716 0 0 4.47716 0 10C0 15.5228 4.47716 20 10 20ZM9 12.5455V12.6534H10.625V12.5455C10.6326 12.1098 10.6818 11.7443 10.7727 11.4489C10.8636 11.1534 11.0076 10.8977 11.2045 10.6818C11.4053 10.4659 11.6667 10.2576 11.9886 10.0568C12.3295 9.85228 12.6212 9.61743 12.8636 9.35228C13.1098 9.08713 13.2992 8.78598 13.4318 8.44887C13.5644 8.10796 13.6307 7.72537 13.6307 7.30113C13.6307 6.68372 13.4848 6.14394 13.1932 5.68182C12.9053 5.21591 12.5 4.85417 11.9773 4.59659C11.4545 4.33522 10.8447 4.20454 10.1477 4.20454C9.51137 4.20454 8.92993 4.32576 8.40341 4.56818C7.88068 4.81061 7.46022 5.16856 7.14204 5.64204C6.82387 6.11174 6.65152 6.6875 6.625 7.36932H8.35228C8.37878 6.9678 8.47917 6.64015 8.65341 6.38637C8.82765 6.12878 9.04735 5.93939 9.3125 5.81818C9.57765 5.69698 9.85606 5.63637 10.1477 5.63637C10.4697 5.63637 10.7614 5.70454 11.0227 5.84091C11.2879 5.97348 11.5 6.16287 11.6591 6.40909C11.8182 6.6553 11.8977 6.94698 11.8977 7.28409C11.8977 7.56439 11.8447 7.82007 11.7386 8.05113C11.6326 8.2822 11.4886 8.48674 11.3068 8.66478C11.125 8.8428 10.9205 9.00378 10.6932 9.14772C10.3409 9.35985 10.0398 9.5947 9.78978 9.85228C9.53978 10.1098 9.34659 10.447 9.21022 10.8636C9.07765 11.2803 9.00757 11.8409 9 12.5455ZM9.05682 15.7784C9.2803 15.9981 9.54735 16.108 9.85796 16.108C10.0663 16.108 10.2557 16.0568 10.4261 15.9545C10.5966 15.8523 10.733 15.7159 10.8352 15.5455C10.9413 15.3712 10.9943 15.1799 10.9943 14.9716C10.9943 14.661 10.8807 14.3958 10.6534 14.1761C10.4299 13.9527 10.1648 13.8409 9.85796 13.8409C9.54735 13.8409 9.2803 13.9527 9.05682 14.1761C8.83333 14.3958 8.72159 14.661 8.72159 14.9716C8.72159 15.286 8.83333 15.5549 9.05682 15.7784Z"
      />
    </SvgIcon>
  );
};

export default QuestionMarkIcon;
