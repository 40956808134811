import { useState, useEffect } from 'react';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SafeHtmlRenderer from './SafeHtmlRenderer';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { addImage } from '../api/forum-api';
import { useSelector } from 'react-redux';
import { userAreaSelector } from '../store/userAreaSlice';
import { currentGroupSelector } from '../store/groupSlice';

type HtmlEditorProps = {
  html?: string;
  showPreview?: boolean;
  imagesEnabled?: boolean;
  onChange: (html: string) => void;
  privateForumUserId?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editorWrapper: {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
    },
    editor: {
      padding: theme.spacing(2),
      '& > div > div > div > div > figure > span > span > img': {
        maxWidth: '520px',
      },
    },
    editorToolbar: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  })
);

const HtmlEditor = ({
  html,
  showPreview,
  imagesEnabled = true,
  onChange,
  privateForumUserId,
}: HtmlEditorProps) => {
  const [editorState, setEditorState] = useState<EditorState>();
  const classes = useStyles();

  const userArea = useSelector(userAreaSelector);
  const currentGroup = useSelector(currentGroupSelector);

  useEffect(() => {
    if (html) {
      const blocksFromHtml = htmlToDraft(html);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [html]);

  const onEditorStateChange = (editorState: EditorState) => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  };

  const uploadImageCallback = async (imageFile: File) => {
    const response = await addImage(imageFile, {
      userAreaId: userArea?.id,
      groupId: currentGroup?.id,
    })
      .then((url) => {
        if (window.location.origin.includes('localhost')) {
          return 'http://localhost:5004' + url;
        } else {
          return window.location.origin + url;
        }
      })
      .catch((error) => {
        console.log('error', error);
      });

    return { data: { link: response } };
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        editorStyle={{ fontFamily: "'Roboto', sans-serif" }}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName={classes.editorWrapper}
        editorClassName={classes.editor}
        toolbarClassName={classes.editorToolbar}
        handlePastedText={() => false}
        toolbar={{
          options: imagesEnabled
            ? [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'image',
                'emoji',
                'history',
              ]
            : [
                'inline',
                'blockType',
                'fontSize',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'history',
              ],
          link: {
            showOpenOptionOnHover: true,
            inDropdown: true,
            defaultTargetOption: '_blank',
          },
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            uploadCallback: uploadImageCallback,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            previewImage: true,
            alt: { present: false, mandatory: false },
            // defaultSize:
          },
          textAlign: { inDropdown: true },
        }}
      />
      {showPreview && editorState && (
        <SafeHtmlRenderer
          html={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />
      )}
    </div>
  );
};

export default HtmlEditor;
