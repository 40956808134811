import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  CircularProgress
} from '@material-ui/core';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label
} from 'recharts';
import Message from '../../../components/translation/Message';
import theme from '../../../theme';
import { getStatistics } from '../../../api/user-api';
import { Statistic } from '../../../types';
import { getMonthString } from '../../../utils';
import { getLoginStatisticsInMonth } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitle: {
      marginBottom: '2em',
    },
    chartTitle: {
      marginBottom: '2em'
    },
    chartContainer: {
      paddingBottom: theme.spacing(4)
    }
  })
);

const OverviewStatisticsPage = () => {
  const [isLoadingStats, setIsLoadingStats] = useState<boolean>(false);
  const [allStats, setAllStats] = useState<Statistic[]>([]);
  const [currentDate] = useState<Date>(new Date());
  const classes = useStyles();

  useEffect(() => {
    setIsLoadingStats(true);
    getStatistics({}).then(stats => {
      setAllStats(stats);
      setIsLoadingStats(false);
    });
  }, []);

  const getCurrentMonthNumber = () => {
    return currentDate.getMonth();
  };

  return (
    <Box p={4}>
      <Typography variant='h5' component='h2' className={classes.pageTitle}>
        <Message id="statistics.overview" />
      </Typography>

      <Box className={classes.chartTitle}>
        <Typography variant='h6'>
          <Message id='statistics.logins' />
        </Typography>
        <Typography variant='caption' className={classes.chartTitle}>
          <Message id='statistics.thisMonth' />
          {' ' + getMonthString(currentDate.getMonth())} {currentDate.getFullYear()}
        </Typography>
        <Typography variant='caption' className={classes.chartTitle} style={{ display: 'block' }}>
          <Message id='statistics.allUsers' />
        </Typography>
      </Box>
      {!isLoadingStats &&
        <Box className={classes.chartContainer}>
          <BarChart
            width={800}
            height={400}
            data={getLoginStatisticsInMonth(allStats, getCurrentMonthNumber())}
            margin={{ top: 0, right: 0, bottom: 8, left: 0 }}
          >
            <Bar
              type='monotone'
              dataKey='amount'
              fill={theme.palette.primary.main}
              name='Logins'
            />
            <CartesianGrid
              stroke={theme.palette.grey[200]}
            />
            <XAxis dataKey='day'>
              <Label value='Day in month' position='insideBottom' offset={-6} />
            </XAxis>
            <YAxis dataKey='amount' allowDecimals={false}>
              {/* <Label value='Logins' offset={0} position='inside' /> */}
            </YAxis>
            <Tooltip />
          </BarChart>
        </Box>
      }
      {isLoadingStats &&
        <CircularProgress size={64} />
      }
    </Box>
  );
};

export default OverviewStatisticsPage;