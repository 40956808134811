import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import {
  ActorsUsersDetails,
  Actor,
  Permission,
  SimplifiedCourse,
} from '../types';
import { AppThunk } from './index';
import {
  getActorsUsersDetails as getActorsUsersDetailsAPI,
  getActors as getActorsAPI,
  createActors as createActorsAPI,
  updateActor as updateActorAPI,
  deleteActor as deleteActorAPI,
  getAssignableActors as getAssignableActorsAPI,
  getAssignableActorsInRelationToUser as getAssignableActorsInRelationToUserAPI,
  setActorOnUser as setActorOnUserAPI,
  deleteActorFromUser as deleteActorFromUserAPI,
  getRelatedUsersBasedOnActors as getRelatedUsersBasedOnActorsAPI,
  getAssignableUsersBasedOnRelationship as getAssignableUsersBasedOnRelationshipAPI,
  setRelationshipOnUser as setRelationshipOnUserAPI,
  getPermissions as getPermissionsAPI,
} from '../api/actors-api';

export interface ActorsState {
  actorsUsersDetails: null | ActorsUsersDetails[];
  actors: null | Actor[];
  assignableActors: [] | Actor[];
  assignableActorsInRelationToUser: Record<string, string> | null;
  actorToUserError: boolean;
  relatedUsersBasedOnActor: null | ActorsUsersDetails[];
  addRelationshipError: boolean;
  assignableUserForRelationship: [] | ActorsUsersDetails[];
  permissions: Permission[] | null;
}

const initialState = {
  actorsUsersDetails: null,
  actors: null,
  assignableActors: [],
  assignableActorsInRelationToUser: null,
  actorToUserError: false,
  relatedUsersBasedOnActor: null,
  assignableUserForRelationship: [],
  addRelationshipError: false,
  permissions: null,
} as ActorsState;

const actorsSlice = createSlice({
  name: 'actors',
  initialState: initialState,
  reducers: {
    setActorsUsersDetails(state, action: PayloadAction<ActorsUsersDetails[]>) {
      state.actorsUsersDetails = action.payload;
    },
    setActors(state, action: PayloadAction<Actor[]>) {
      state.actors = action.payload;
    },
    setAssignableActors(state, action: PayloadAction<Actor[]>) {
      state.assignableActors = action.payload;
    },
    setAssignableActorsInRelationToUser(
      state,
      action: PayloadAction<Record<string, string>>
    ) {
      state.assignableActorsInRelationToUser = action.payload;
    },
    setActorToUserError(state, action: PayloadAction<boolean>) {
      state.actorToUserError = action.payload;
    },
    setPermissions(state, action: PayloadAction<Permission[]>) {
      state.permissions = action.payload;
    },
    addNewActorToUser(state, action: PayloadAction<ActorsUsersDetails>) {
      if (!state.actorsUsersDetails) {
        state.actorToUserError = true;
        return;
      }

      state.actorsUsersDetails = state.actorsUsersDetails?.map((user) => {
        if (user.id === action.payload.id) {
          user.actors = action.payload.actors;
        }
        return user;
      });
    },
    setRelatedUsers(state, action: PayloadAction<ActorsUsersDetails[]>) {
      state.relatedUsersBasedOnActor = action.payload;
    },
    setAssignableUserForRelationship(
      state,
      action: PayloadAction<ActorsUsersDetails[]>
    ) {
      state.assignableUserForRelationship = action.payload;
    },
    addRelationship(
      state,
      action: PayloadAction<{
        fromUser: ActorsUsersDetails;
        toUser: ActorsUsersDetails;
        actor: Actor;
      }>
    ) {
      const { fromUser, toUser, actor } = action.payload;
      if (!state.actorsUsersDetails) {
        return;
      }

      state.actorsUsersDetails.map((user) => {
        if (user.id === fromUser.id) {
          user.actorRelationships.push({
            fromUser: fromUser.name,
            toUser: toUser.name,
            relationship: actor,
          });
        }
        return user;
      });
    },
    setAddRelationshipError(state, action: PayloadAction<boolean>) {
      state.addRelationshipError = action.payload;
    },
  },
});

export const {
  setActorsUsersDetails,
  setActors,
  setAssignableActors,
  setAssignableActorsInRelationToUser,
  setActorToUserError,
  setPermissions,
  addNewActorToUser,
  setRelatedUsers,
  setAssignableUserForRelationship,
  addRelationship,
  setAddRelationshipError,
} = actorsSlice.actions;

export default actorsSlice.reducer;

export const fetchActorsUsersDetailsList = (): AppThunk => async (dispatch) => {
  try {
    const userList = await getActorsUsersDetailsAPI();
    dispatch(setActorsUsersDetails(userList));
  } catch (err: any) {
    console.log(err);
  }
};

export const fetchActors = (): AppThunk => async (dispatch) => {
  try {
    const actors = await getActorsAPI();
    dispatch(setActors(actors));
  } catch (err: any) {
    console.log(err);
  }
};

export const fetchPermissions = (): AppThunk => async (dispatch) => {
  try {
    const permissions = await getPermissionsAPI();
    dispatch(setPermissions(permissions.actorPermissions));
  } catch (err: any) {
    console.log(err);
  }
};

export const createInstanceActors =
  (createObj: {
    actors: {
      name: string;
      assignableToUser: boolean;
      assignableAsRelationship: boolean;
      hasPrivateRoom: boolean;
      assignableAsRelationshipOnlyToActorIds: string[];
      permissions: string;
      canAssignActorIds: string[];
      mandatoryCourses: SimplifiedCourse[];
    }[];
  }): AppThunk =>
  async (dispatch) => {
    try {
      await createActorsAPI(createObj);
      dispatch(fetchActors());
    } catch (err: any) {
      console.log(err);
    }
  };

export const updateInstanceActor =
  (actorToUpdate: Actor): AppThunk =>
  async (dispatch) => {
    try {
      await updateActorAPI(actorToUpdate);
      dispatch(fetchActors());
    } catch (err: any) {
      console.log(err);
    }
  };

export const deleteInstanceActor =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      await deleteActorAPI(id);
      dispatch(fetchActors());
    } catch (err: any) {
      console.log(err);
    }
  };

export const fetchAssignableActors = (): AppThunk => async (dispatch) => {
  try {
    const actors = await getAssignableActorsAPI();
    dispatch(setAssignableActors(actors));
  } catch (err: any) {
    console.log(err);
  }
};

export const fetchAssignableActorsInRelationToUser =
  (toUserId: string): AppThunk =>
  async (dispatch) => {
    try {
      const actors = await getAssignableActorsInRelationToUserAPI(toUserId);
      dispatch(setAssignableActorsInRelationToUser(actors));
    } catch (err: any) {
      console.log(err);
    }
  };

export const fetchAssignableUsersBasedOnRelationship =
  (actorId: string): AppThunk =>
  async (dispatch) => {
    try {
      const users = await getAssignableUsersBasedOnRelationshipAPI(actorId);
      dispatch(setAssignableUserForRelationship(users));
    } catch (err: any) {
      console.log(err);
    }
  };

export const setActorOnUser =
  (toUserId: string, actorId: string): AppThunk =>
  async (dispatch) => {
    try {
      const updatedUser = await setActorOnUserAPI({ toUserId, actorId });
      dispatch(addNewActorToUser(updatedUser));
      dispatch(setActorToUserError(false));
    } catch (err) {
      dispatch(setActorToUserError(true));
    }
  };

export const removeActorFromUser =
  (toUserId: string, actorId: string): AppThunk =>
  async (dispatch) => {
    try {
      const updatedUser = await deleteActorFromUserAPI({ toUserId, actorId });
      dispatch(addNewActorToUser(updatedUser));
      dispatch(setActorToUserError(false));
    } catch (err) {
      dispatch(setActorToUserError(true));
    }
  };

export const setRelationship =
  (
    fromUser: ActorsUsersDetails,
    toUser: ActorsUsersDetails,
    actor: Actor
  ): AppThunk =>
  async (dispatch) => {
    try {
      await setRelationshipOnUserAPI({
        fromUserId: fromUser.id,
        toUserId: toUser.id,
        actorId: actor.id,
      });
      dispatch(addRelationship({ fromUser, toUser, actor }));
      dispatch(setAddRelationshipError(false));
    } catch (err: any) {
      dispatch(setAddRelationshipError(true));
    }
  };

export const setHasRelationshipError =
  (hasError: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(setAddRelationshipError(hasError));
  };

export const fetchRelatedUsersBasedOnActors =
  (actorIds: string[]): AppThunk =>
  async (dispatch) => {
    try {
      const users = await getRelatedUsersBasedOnActorsAPI(actorIds);
      dispatch(setRelatedUsers(users));
    } catch (err: any) {
      console.log(err);
    }
  };

const selectActorState = (rootState: RootState) => rootState.actors;
export const actorsUsersDetailsListSelector = createSelector(
  selectActorState,
  (actorsState: ActorsState) => actorsState.actorsUsersDetails
);

export const actorsSelector = createSelector(
  selectActorState,
  (actorsState: ActorsState) => actorsState.actors
);

export const assignableActorsSelector = createSelector(
  selectActorState,
  (actorsState: ActorsState) => actorsState.assignableActors
);

export const assignableActorsInRelationToUserSelector = createSelector(
  selectActorState,
  (actorsState: ActorsState) => actorsState.assignableActorsInRelationToUser
);

export const actorToUserErrorSelector = createSelector(
  selectActorState,
  (actorState: ActorsState) => actorState.actorToUserError
);

export const relatedUsersBasedOnActorSelector = createSelector(
  selectActorState,
  (actorState: ActorsState) => actorState.relatedUsersBasedOnActor
);

export const assignableUserForRelationshipSelector = createSelector(
  selectActorState,
  (actorState: ActorsState) => actorState.assignableUserForRelationship
);

export const addRelationshipErrorSelector = createSelector(
  selectActorState,
  (actorState: ActorsState) => actorState.addRelationshipError
);

export const permissionsSelector = createSelector(
  selectActorState,
  (actorState: ActorsState) => actorState.permissions
);
