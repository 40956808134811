import {
  Box,
  Checkbox,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Cancel, RemoveCircle } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteOptionImage, uploadOptionImage } from '../../api/course-api';
import {
  startImageUploading,
  stopImageUploading,
} from '../../store/coursesSlice';
import { AnswerOption } from '../../types';
import Message from '../translation/Message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

type EditOptionComponentProps = {
  answerOption: AnswerOption;
  sessionId: string;
  index: number;
  handleRemoveOption: (index: number) => void;
  handleOptionChange: (option: AnswerOption, index: number) => void;
};

export const EditOptionComponent = ({
  index,
  answerOption,
  sessionId,
  handleRemoveOption,
  handleOptionChange,
}: EditOptionComponentProps) => {
  const classes = useStyles();
  const [optionState, setOptionState] = useState<AnswerOption>(answerOption);
  const dispatch = useDispatch();

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOptionState({
      text: event.target.value,
      image: optionState.image,
      isCorrect: optionState.isCorrect,
    });
  };

  const removeOption = () => {
    handleRemoveOption(index);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files) {
      return;
    }
    dispatch(startImageUploading());
    try {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      const imageUrl = await uploadOptionImage(sessionId, file);
      if (imageUrl !== null) {
        setOptionState({
          text: optionState.text,
          image: imageUrl,
          isCorrect: optionState.isCorrect,
        });
      } else {
        setOptionState(optionState);
      }
    } catch (e: any) {
      // User canceled
    }
    dispatch(stopImageUploading());
  };

  const handleDeleteImage = async () => {
    dispatch(startImageUploading());
    if (optionState.image) {
      const splitted = optionState.image.split('exam-pictures');
      if (splitted.length === 2) {
        let fileName = splitted[1].substring(1);
        if (await deleteOptionImage(fileName)) {
          setOptionState({
            text: optionState.text,
            isCorrect: optionState.isCorrect,
          });
        }
      }
    }
    dispatch(stopImageUploading());
  };

  const handleCorrectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptionState({
      text: optionState.text,
      image: optionState.image,
      isCorrect: event.target.checked,
    });
  };

  useEffect(() => {
    handleOptionChange(optionState, index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionState]);

  return (
    <div style={{ marginBottom: 10 }}>
      <Box className={classes.flexCenter}>
        <IconButton onClick={removeOption} style={{ marginRight: -10 }}>
          <RemoveCircle fontSize="default" color="error" />
        </IconButton>
        <Typography variant="subtitle1">
          <Message id="course.courseAdmin.editCoursePage.option" /> {index + 1}:
        </Typography>
        <TextField
          value={optionState.text}
          onChange={handleTextChange}
          style={{ width: '50%' }}
        />

        <Checkbox
          color="primary"
          checked={optionState.isCorrect ?? false}
          onChange={handleCorrectChange}
          disabled={
            !optionState.image &&
            (!optionState.text || optionState.text.trim() === '')
          }
        />
        <Typography style={{ marginLeft: -2 }}>
          <Message id="course.courseAdmin.editCoursePage.correct" />
        </Typography>
      </Box>
      {answerOption.image && answerOption.image !== '' && (
        <div className={classes.flexCenter}>
          <img alt="" src={answerOption.image} height="150px" width="auto" />
          <IconButton onClick={handleDeleteImage}>
            <Cancel fontSize="large" color="action" />
          </IconButton>
        </div>
      )}
      <input
        style={{ marginTop: 5, marginLeft: 15 }}
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
      />
    </div>
  );
};
