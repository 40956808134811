import { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCourseFromSlug,
  fetchResultsByCourse,
} from '../../store/coursesSlice';
import {
  coursesIsLoadingSelector,
  currentCourseSelector,
  sessionResultsSelector,
} from '../../store/selectors';
import { useParams } from 'react-router-dom';
import Introduction from './Introduction';
import SessionSubpage from './SessionSubpage';
import NotFoundPage from '../NotFoundPage';
import mixpanel from 'mixpanel-browser';
import {
  notificationsSelector,
  removeNotificationsForReferenceId,
} from '../../store/notificationsSlice';
import { permissionsForUserAreaSelector } from '../../store/userAreaSlice';

const CoursePage = () => {
  const course = useSelector(currentCourseSelector);
  const isLoading = useSelector(coursesIsLoadingSelector);
  const notifications = useSelector(notificationsSelector);
  const sessionResults = useSelector(sessionResultsSelector);
  const [fetchedCourseResults, setFetchedCourseResults] = useState(false);
  const permissions = useSelector(permissionsForUserAreaSelector);

  const dispatch = useDispatch();
  let params: { slug: string; id: string } = useParams();
  let session = course ? course.sessions.find((s) => s.id === params.id) : null;

  if (permissions?.includes('GroupAndCustomer')) {
    window.location.href = window.location.origin;
  }

  useEffect(() => {
    if (course === null || course.slug !== params.slug) {
      dispatch(fetchCourseFromSlug(params.slug));
    }

    if (course) {
      mixpanel.track('COURSE_PAGELOAD', {
        courseId: course.id,
      });
    }
  }, [course, dispatch, params.slug]);

  useEffect(() => {
    if (course && session) {
      mixpanel.track('SESSION_PAGELOAD', {
        courseId: course.id,
        sessionId: session.id,
      });
    }
  }, [session, course]);

  useEffect(() => {
    if (!notifications || !course) return;
    notifications.forEach((notification) => {
      if (notification.referenceId === course.id) {
        dispatch(removeNotificationsForReferenceId(notification.referenceId));
      }
    });
  }, [notifications, course, dispatch]);

  useEffect(() => {
    if (!fetchedCourseResults && course) {
      dispatch(fetchResultsByCourse(course.id));
      setFetchedCourseResults(true);
    }
  }, [course, dispatch, fetchedCourseResults]);

  useEffect(() => {
    if (course) {
      dispatch(fetchResultsByCourse(course.id));
    }
  }, [course, dispatch, sessionResults]);

  if (!course) {
    return <></>;
  }

  if (!course?.isActive) {
    return <NotFoundPage />;
  }

  if (isLoading || !course) {
    return <CircularProgress size={64} />;
  }

  const sessionComponent = () => {
    return session ? (
      <SessionSubpage session={session} course={course} />
    ) : (
      <Introduction course={course} />
    );
  };

  return course.isActive ? sessionComponent() : <NotFoundPage />;
};

export default CoursePage;
