import { useContext, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import {
  AuthenticationProvider,
  OidcSecure,
  useReactOidc,
  getUserManager,
} from '@axa-fr/react-oidc-context';
import oidcConfiguration from './oidc-configuration';
import { addAccessToken, setLogoutFunction } from './api/httpClient';
import AppRoutes from './routes/AppRoutes';
import Authenticating from './components/authentication/Authenticating';
import Connected from './components/authentication/Connected';
import NotAuthenticated from './components/authentication/NotAuthenticated';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, showProfile, userSelector } from './store/userSlice';
import localforage from 'localforage';
import AppLayout from './components/layouts/AppLayout';
import {
  WhitelabelContext,
  InstanceMetaData,
} from './whitelabel-config/WhitelabelProvider';
import { getStaticContent, getFavicon, getMetaData } from './api/instance-api';
import { instanceIdSelector, instanceSelector } from './store/instanceSlice';
import FeatureThemeProvider from './theming/FeatureThemeProvider';
import TermsAndConditionsDialog from './components/TermsAndConditionsDialog';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { fetchInstance } from './store/instanceSlice';
import Toast from './components/Toast';
import Chat from './components/chat/Chat';
import { instanceModulesSelector } from './store/instanceSlice';
import { useHistory } from 'react-router-dom';
import { CustomEvents } from '@axa-fr/react-oidc-context/dist/oidcContext/AuthenticationContext.hooks';

class LocalStorage {
  constructor() {
    return window.localStorage;
  }
}

type SecureAppProps = {
  forceLogout: boolean;
};

function App() {
  const [forceLogout, setForceLogout] = useState<boolean>(false);

  const customEvents: CustomEvents = {
    onUserLoaded: () => {},
    onUserUnloaded: () => {},
    onSilentRenewError: () => {},
    onUserSignedOut: () => {
      setForceLogout(true);
    },
    onUserSessionChanged: () => {},
    onAccessTokenExpiring: () => {},
    onAccessTokenExpired: () => {},
  };

  return (
    <AuthenticationProvider
      customEvents={customEvents}
      configuration={oidcConfiguration}
      authenticating={Authenticating}
      callbackComponentOverride={Connected}
      notAuthenticated={NotAuthenticated}
      UserStore={LocalStorage}
    >
      <OidcSecure>
        <SecureApp forceLogout={forceLogout} />
      </OidcSecure>
    </AuthenticationProvider>
  );
}

export default App;

const SecureApp = ({ forceLogout }: SecureAppProps) => {
  const [tokenIsSet, setTokenIsSet] = useState<boolean>(false);
  const [staticContentFetched, setStaticContentFetched] =
    useState<boolean>(false);
  const [metaData, setMetaData] = useState<InstanceMetaData | null>(null);
  const [faviconURL, setFaviconURL] = useState<string>('');
  const [lastActionTime, setLastActionTime] = useState<number>(Date.now());

  const history = useHistory();
  const dispatch = useDispatch();
  const { events, oidcUser, logout } = useReactOidc();
  const { mergeStaticContent } = useContext(WhitelabelContext);
  const { getImage } = useContext(WhitelabelContext);
  const favicon = getImage('favicon');

  const user = useSelector(userSelector);
  const instanceId = useSelector(instanceIdSelector);
  const instance = useSelector(instanceSelector);
  const activeModules = useSelector(instanceModulesSelector);

  useEffect(() => {
    const fetchMetaData = async () => {
      const metaData = await getMetaData();
      setMetaData(metaData);
    };
    fetchMetaData();

    mixpanel.init('d91edeb2f03f1d61c65f21473222b3aa', { debug: false });

    window.localStorage.setItem('lastActionTime', Date.now().toPrecision());
  }, []);

  useEffect(() => {
    if (forceLogout) {
      const userManager = getUserManager();
      userManager.removeUser();
      window.location.href = window.location.href + '';
    }
  }, [forceLogout]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      const now = Date.now();
      window.localStorage.setItem('lastActionTime', now.toPrecision());
      setLastActionTime(now);
    });

    return () => unlisten();
  }, [history]);

  useEffect(() => {
    const listener = setTimeout(() => {
      const localStorageTimeString =
        window.localStorage.getItem('lastActionTime');
      if (!localStorageTimeString) {
        logout();
      }

      if (localStorageTimeString) {
        const localStorageTime = parseInt(localStorageTimeString);
        if (localStorageTime > lastActionTime) {
          setLastActionTime(localStorageTime);
        } else {
          logout();
        }
      }
    }, 10800000);

    return () => {
      clearTimeout(listener);
    };
  }, [lastActionTime, logout]);

  useEffect(() => {
    const fetchFaviconImage = async () => {
      const logoData = await getFavicon();
      setFaviconURL(window.URL.createObjectURL(logoData));
    };
    fetchFaviconImage();
  }, [favicon]);

  useEffect(() => {
    const addUserSignedOut = () => {
      const userManager = getUserManager();
      userManager.removeUser();
      window.location.href = window.location.href + '';
    };

    getUserManager().clearStaleState();
    addAccessToken(oidcUser.access_token);
    localforage.setItem('authToken', oidcUser.access_token);
    setLogoutFunction(logout);
    setTokenIsSet(true);
    dispatch(fetchUser());
    dispatch(fetchInstance());

    if (!events) return;
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events, oidcUser, dispatch, logout]);

  useEffect(() => {
    if (user) {
      mixpanel.identify(user.userInstanceId);
    }
  }, [user]);

  useEffect(() => {
    if (instanceId) {
      mixpanel.register_once({
        instanceId: instanceId,
      });
    }
  }, [instanceId]);

  useEffect(() => {
    if (tokenIsSet && !staticContentFetched && instance) {
      getStaticContent().then((staticContent) => {
        if (
          Object.keys(staticContent.messages).length > 0 ||
          Object.keys(staticContent.images).length > 0
        ) {
          mergeStaticContent(
            staticContent,
            instance?.instanceLanguage ?? 'English'
          );
        }
      });
      setStaticContentFetched(true);
    }
  }, [tokenIsSet, staticContentFetched, mergeStaticContent, instance]);

  if (!tokenIsSet) {
    return null;
  }

  return (
    <FeatureThemeProvider>
      <Helmet>
        <title>{`${metaData && metaData.metaTitle}`}</title>
        <link rel="icon" href={faviconURL} />
        <meta
          name="description"
          content={`${metaData && metaData.metaDescription}`}
        />
      </Helmet>
      <AppLayout>
        <AppRoutes />
        {activeModules?.includes('Chat') && <Chat />}
      </AppLayout>
      <TermsAndConditionsDialog />
      {user && !user.userProfile && (
        <Toast
          message={'nav.updateProfileLabel'}
          buttonText={'nav.update'}
          variant={'warning'}
          showSecondaryButton={true}
          secondaryButtonAction={() => {
            dispatch(showProfile(true));
          }}
        />
      )}
      <ScrollToTop />
    </FeatureThemeProvider>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
