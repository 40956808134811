import { ReactNode, useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link as MuiLink,
  makeStyles,
  createStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SupportIcon from '@material-ui/icons/HeadsetMic';
import { useLocation, matchPath } from 'react-router-dom';
import Navbar from './Navbar';
import * as paths from '../../routes/paths';
import { currentCourseSelector } from '../../store/selectors';
import { userSelector } from '../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Course } from '../../types';
import CourseDrawer from '../courses/CourseDrawer';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import DrawerLogo from './DrawerLogo';
import DrawerList from './DrawerList';
import {
  instanceExternalLinksSelector,
  instanceModulesSelector,
} from '../../store/instanceSlice';
import { getCourseTypeName } from '../../utils';
import { fetchNotifications } from '../../store/notificationsSlice';

interface StyleProps {
  fullyCollapseDrawer: boolean;
}

const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      paddingBottom: theme.spacing(4),
      backgroundColor: theme.palette.grey[100],
    },
    drawerOpen: {
      width: drawerWidth,
      overflowX: 'hidden',
      transition: theme.transitions.create(['width', 'transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: (props: StyleProps) => ({
      width: props.fullyCollapseDrawer ? 0 : theme.spacing(9) + 1,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    drawerClosePaper: (props: StyleProps) => ({
      transition: theme.transitions.create(['width', 'transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: props.fullyCollapseDrawer ? 0 : theme.spacing(9) + 1,
      transform: props.fullyCollapseDrawer
        ? `translateX(-${drawerWidth}px)`
        : '',
    }),
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(3),
      },
      transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    truncateText: {
      width: '150px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

type AppLayoutProps = {
  children: ReactNode;
};
const AppLayout = ({ children }: AppLayoutProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(true);

  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const course = useSelector(currentCourseSelector);
  const activeModules = useSelector(instanceModulesSelector);
  const externalLinks = useSelector(instanceExternalLinksSelector);
  const title = getTitle(location.pathname, course);
  const isCoursePage = matchPath(location.pathname, { path: paths.courseUrl });
  const fullyCollapseDrawer = Boolean(isCoursePage);
  const classes = useStyles({ fullyCollapseDrawer: fullyCollapseDrawer });

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (activeModules?.includes('Notification')) {
      dispatch(fetchNotifications());
    }
  }, [activeModules, dispatch]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const drawerContent = isCoursePage ? (
    <CourseDrawer />
  ) : (
    <nav className={classes.drawerContent}>
      <div>
        <DrawerLogo drawerOpen={open} />
        <DrawerList onDrawerToggle={handleDrawerToggle} />
      </div>
      <List>
        {externalLinks?.MainPageLink && (
          <ListItem
            button
            component={MuiLink}
            href={`https://${externalLinks?.MainPageLink}`}
          >
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.truncateText }}
              primary={getMessage('nav.mainPageLinkText')}
            />
          </ListItem>
        )}
        {externalLinks?.SupportEmail && (
          <ListItem
            button
            component={MuiLink}
            href={`mailto:${externalLinks?.SupportEmail}`}
          >
            <ListItemIcon>
              <SupportIcon />
            </ListItemIcon>
            <ListItemText primary="Support" />
          </ListItem>
        )}
      </List>
    </nav>
  );

  return (
    <div>
      <Navbar
        title={title}
        user={user}
        drawerOpen={open}
        toggleDrawer={handleDrawerToggle}
        fullyCollapseDrawer={fullyCollapseDrawer}
      />
      <div className={classes.root}>
        <Hidden smUp>
          <Drawer
            variant="temporary"
            open={open}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClosePaper]: !open,
              }),
            }}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </div>
  );
};

export default AppLayout;

const getTitle = (pathname: string, course: Course | null): string => {
  if (matchPath(pathname, { path: paths.courseUrl })) {
    return course?.name || '...';
  }
  if (matchPath(pathname, { path: paths.homeUrl, exact: true })) {
    return getMessage('nav.home');
  }
  if (matchPath(pathname, { path: paths.coursesUrl, exact: true })) {
    return getMessage('nav.courses');
  }
  if (matchPath(pathname, { path: paths.forumUrl })) {
    return getMessage('nav.forum');
  }
  if (matchPath(pathname, { path: paths.editProvidersDashboardUrl })) {
    return getMessage('nav.editProviders');
  }
  if (matchPath(pathname, { path: paths.siteSettingsPageUrl })) {
    return getMessage('nav.siteSettings');
  }
  if (matchPath(pathname, { path: paths.contentSetingsPageUrl })) {
    return getMessage('nav.contentSettings');
  }
  if (matchPath(pathname, { path: paths.courseAdminDashboardUrl })) {
    return getMessage('nav.editCourses');
  }
  if (matchPath(pathname, { path: paths.editCourseUrl })) {
    return `Edit ${course?.name || getMessage('course.course')}`;
  }
  if (matchPath(pathname, { path: paths.forumAdminPage })) {
    return getMessage('nav.editForum');
  }
  if (matchPath(pathname, { path: paths.homeAdminPageUrl })) {
    return getMessage('nav.editHome');
  }
  if (matchPath(pathname, { path: paths.handleUsersUrl })) {
    return getMessage('nav.handleUsers');
  }
  if (matchPath(pathname, { path: paths.actorsAdminPageUrl })) {
    return getMessage('nav.handleActors');
  }
  if (matchPath(pathname, { path: paths.courseTypeUrl })) {
    return getCourseTypeName(pathname);
  }
  if (matchPath(pathname, { path: paths.statisticsPageUrl })) {
    return getMessage('nav.statistics.statistics');
  }

  return '';
};
