import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as paths from './paths';
import {
  instanceModulesSelector,
  activeSubModulesSelector,
} from '../store/instanceSlice';

import HomePage from '../pages/HomePage/';
import AllCoursesPage from '../pages/AllCoursesPage';
import CoursePage from '../pages/CoursePage';
import NotFoundPage from '../pages/NotFoundPage';
import CourseAdminDashboardPage from '../pages/course-admin/CourseAdminDashboardPage/index';
import EditCoursePage from '../pages/course-admin/EditCoursePage';
import EditProvidersDashboardPage from '../pages/course-admin/EditProvidersDashboardPage';
import EditProviderPage from '../pages/course-admin/EditProviderPage';
import ForumOverviewPage from '../pages/ForumOverviewPage';
import CreateNewTopic from '../components/forum/CreateNewTopic';
import ForumTopicPage from '../pages/ForumTopicPage';
import { courseTheme, forumTheme } from '../theme';
import TermsOfServicePage from '../pages/TermsOfServicePage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import HandleUsers from '../pages/HandelUsers';
import { FeatureThemeContext } from '../theming/FeatureThemeProvider';
import defaultTheme from '../theme';
import HomeAdminPage from '../pages/HomeAdminPage';
import SiteSettingsPage from '../pages/SiteSettingsPage';
import ContentSettingsPage from '../pages/ContentSettingsPage';
import ActorAdminPage from '../pages/ActorAdminPage';
import UserAreaPage from '../pages/UserAreaPage';
import OverviewStatisticsPage from '../pages/StatisticsPage/OverviewStatisticsPage/';
import UsersStatisticsPage from '../pages/StatisticsPage/UserStatisticsPage/';
import ForumAdminPage from '../pages/ForumAdminPage';
import AcceptableUsePolicyPage from '../pages/AcceptableUsePolicyPage';
import CookiePolicyPage from '../pages/CookiePolicyPage';
import UserCoursesPage from '../pages/HandleUserCourses';
import { groupAndCustomerOnlySelector } from '../store/userAreaSlice';

const AppRoutes = () => {
  const groupAndCustomerOnly = useSelector(groupAndCustomerOnlySelector);

  return (
    <Switch>
      <Route
        exact
        path={paths.termsOfServiceUrl}
        component={TermsOfServicePage}
      />
      <Route
        exact
        path={paths.privacyPolicyUrl}
        component={PrivacyPolicyPage}
      />
      <Route
        exact
        path={paths.acceptableUsePolicyUrl}
        component={AcceptableUsePolicyPage}
      />
      <Route exact path={paths.cookiePolicyUrl} component={CookiePolicyPage} />
      <Route exact path="/authentication/callback">
        <Redirect to={paths.homeUrl} />
      </Route>
      <Route exact path={homeFeaturesPaths} component={HomeRoutes} />
      <Route exact path={userAreaFeaturesPaths} component={UserAreaRoutes} />

      {!groupAndCustomerOnly && (
        <>
          <Route exact path={courseFeaturesPaths} component={CourseRoutes} />
          <Route exact path={forumFeaturesPaths} component={ForumRoutes} />
          <Route exact path={adminFeaturesPaths} component={AdminRoutes} />
        </>
      )}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default AppRoutes;

const homeFeaturesPaths = [paths.homeUrl, paths.homeAdminPageUrl];

const HomeRoutes = () => {
  const { theme, setTheme } = React.useContext(FeatureThemeContext);

  React.useEffect(() => {
    if (theme !== defaultTheme) {
      setTheme(defaultTheme);
    }
  }, [theme, setTheme]);

  return (
    <Switch>
      <Route exact path={paths.homeUrl} component={HomePage} />
      <Route exact path={paths.homeAdminPageUrl} component={HomeAdminPage} />
    </Switch>
  );
};

const userAreaFeaturesPaths = [
  paths.userAreaPageUrl,
  paths.actorsPageUrl,
  paths.privateMasterClassUrl,
  paths.privateVideosUrl,
  paths.privateForumTopicUrl,
  paths.privateCreateTopicUrl,
  paths.privateForumUrl,
  paths.privateSettingsUrl,
  paths.newPrivateGroupUrl,
];

const UserAreaRoutes = () => {
  const activeModules = useSelector(instanceModulesSelector);
  const activeSubModules = useSelector(activeSubModulesSelector);

  return (
    <Switch>
      <Route exact path={paths.actorsPageUrl} component={UserAreaPage} />
      <Route
        exact
        path={paths.privateMasterClassUrl}
        component={UserAreaPage}
      />
      <Route exact path={paths.privateVideosUrl} component={UserAreaPage} />
      <Route exact path={paths.privateSettingsUrl} component={UserAreaPage} />
      <Route exact path={paths.userAreaPageUrl} component={UserAreaPage} />
      <Route exact path={paths.newPrivateGroupUrl} component={UserAreaPage} />
      {activeModules?.includes('Forum') &&
      (activeSubModules?.Forum?.includes('PrivateForum') ||
        activeSubModules?.Forum?.includes('GroupForum')) ? (
        <Switch>
          <Route
            exact
            path={paths.privateForumTopicUrl}
            component={UserAreaPage}
          />
          <Route exact path={paths.privateForumUrl} component={UserAreaPage} />
          <Route
            exact
            path={paths.privateCreateTopicUrl}
            component={UserAreaPage}
          />
        </Switch>
      ) : (
        <Switch>
          <Route exact path={paths.privateForumUrl} component={NotFoundPage} />
        </Switch>
      )}
    </Switch>
  );
};

const courseFeaturesPaths = [
  paths.coursesUrl,
  paths.courseUrl,
  paths.sessionUrl,
  paths.courseAdminDashboardUrl,
  paths.editCourseUrl,
  paths.editSessionUrl,
  paths.editProvidersDashboardUrl,
  paths.editProviderUrl,
  paths.courseTypeUrl,
];

const CourseRoutes = () => {
  const { theme, setTheme } = React.useContext(FeatureThemeContext);

  React.useEffect(() => {
    if (theme !== courseTheme) {
      setTheme(courseTheme);
    }
  }, [theme, setTheme]);

  return (
    <Switch>
      <Route exact path={paths.coursesUrl} component={AllCoursesPage} />
      <Route exact path={paths.courseUrl} component={CoursePage} />
      <Route exact path={paths.sessionUrl} component={CoursePage} />
      <Route exact path={paths.courseTypeUrl} component={AllCoursesPage} />
      <Route
        exact
        path={paths.courseAdminDashboardUrl}
        component={CourseAdminDashboardPage}
      />
      <Route exact path={paths.editCourseUrl} component={EditCoursePage} />
      <Route exact path={paths.editSessionUrl} component={EditCoursePage} />
      <Route
        exact
        path={paths.editProvidersDashboardUrl}
        component={EditProvidersDashboardPage}
      />
      <Route exact path={paths.editProviderUrl} component={EditProviderPage} />
    </Switch>
  );
};

const forumFeaturesPaths = [
  paths.forumUrl,
  paths.forumCreateTopicUrl,
  paths.forumTopicUrl,
  paths.forumAdminPage,
];

const ForumRoutes = () => {
  const { theme, setTheme } = React.useContext(FeatureThemeContext);
  const activeModules = useSelector(instanceModulesSelector);
  const activeSubModules = useSelector(activeSubModulesSelector);

  React.useEffect(() => {
    if (theme !== forumTheme) {
      setTheme(forumTheme);
    }
  }, [theme, setTheme]);

  if (
    activeModules?.includes('Forum') &&
    activeSubModules?.Forum?.includes('PublicForum')
  ) {
    return (
      <Switch>
        <Route exact path={paths.forumUrl} component={ForumOverviewPage} />
        <Route
          exact
          path={paths.forumCreateTopicUrl}
          component={CreateNewTopic}
        />
        <Route exact path={paths.forumTopicUrl} component={ForumTopicPage} />
        <Route exact path={paths.forumAdminPage} component={ForumAdminPage} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path={paths.forumUrl} component={NotFoundPage} />
    </Switch>
  );
};

const adminFeaturesPaths = [
  paths.siteSettingsPageUrl,
  paths.contentSetingsPageUrl,
  paths.handleUsersUrl,
  paths.actorsAdminPageUrl,
  paths.statisticsOverviewPageUrl,
  paths.statisticsUsersPageUrl,
  paths.allCoursesPageUrl,
];

const AdminRoutes = () => {
  const { theme, setTheme } = React.useContext(FeatureThemeContext);

  React.useEffect(() => {
    if (theme !== defaultTheme) {
      setTheme(defaultTheme);
    }
  }, [theme, setTheme]);

  return (
    <Switch>
      <Route
        exact
        path={paths.siteSettingsPageUrl}
        component={SiteSettingsPage}
      />
      <Route
        exact
        path={paths.contentSetingsPageUrl}
        component={ContentSettingsPage}
      />
      <Route exact path={paths.handleUsersUrl} component={HandleUsers} />
      <Route exact path={paths.actorsAdminPageUrl} component={ActorAdminPage} />
      <Route exact path={paths.allCoursesPageUrl} component={UserCoursesPage} />
      <Route
        exact
        path={paths.statisticsOverviewPageUrl}
        component={OverviewStatisticsPage}
      />
      <Route
        exact
        path={paths.statisticsUsersPageUrl}
        component={UsersStatisticsPage}
      />
    </Switch>
  );
};
