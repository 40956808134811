import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const AccountCircleIcon = (props:SvgIconProps)=> {
    return (
      <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" >
        <path d="M12 2.25C6.624 2.25 2.25 6.624 2.25 12C2.25 17.376 6.624 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.624 17.376 2.25 12 2.25ZM12 3.75C16.5645 3.75 20.25 7.4355 20.25 12C20.25 16.5645 16.5645 20.25 12 20.25C7.4355 20.25 3.75 16.5645 3.75 12C3.75 7.4355 7.4355 3.75 12 3.75ZM12 6C9.9375 6 8.25 7.6875 8.25 9.75C8.25 10.887 8.78025 11.8973 9.58575 12.5858C8.73113 13.0288 8.01466 13.6981 7.51457 14.5207C7.01448 15.3432 6.75 16.2874 6.75 17.25H8.25C8.25 15.1702 9.92025 13.5 12 13.5C14.0798 13.5 15.75 15.1702 15.75 17.25H17.25C17.25 15.225 16.1017 13.4625 14.4142 12.5858C15.2197 11.8973 15.75 10.887 15.75 9.75C15.75 7.6875 14.0625 6 12 6ZM12 7.5C13.251 7.5 14.25 8.499 14.25 9.75C14.25 11.001 13.251 12 12 12C10.749 12 9.75 11.001 9.75 9.75C9.75 8.499 10.749 7.5 12 7.5Z"/>
      </SvgIcon>
    );   
}

export default AccountCircleIcon;