import { Box, Typography } from "@material-ui/core";

type ActorNamesCaptionProps = {
    actorNames: string[]
}
const ActorNamesCaption = ({
    actorNames
}: ActorNamesCaptionProps) => {
    return (
        <Box>
            {actorNames && actorNames.length > 0
                && actorNames.map((actor: string, index: number) => (
                    <Typography variant='caption' key={index}>
                        {actor}
                        {index !== (actorNames.length - 1) ? ', ' : ''}
                    </Typography>
                ))}
        </Box>
    )
}

export default ActorNamesCaption;