import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Message from '../../components/translation/Message';

type ModuleInfoDialogProps = {
  isOpen: boolean;
  infoModule: string;
  onClose: () => void;
};
const ModuleInfoDialog = ({
  isOpen,
  infoModule,
  onClose,
}: ModuleInfoDialogProps) => {
  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <Message id={`siteSettings.modules.${infoModule}`} />
      </DialogTitle>
      <DialogContent>
        <Message id={`siteSettings.modules.info.${infoModule}`} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <Message id="siteSettings.settingsDialogueBtn" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModuleInfoDialog;
