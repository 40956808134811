import { useDispatch, useSelector } from 'react-redux';
import { SimplifiedCourse, UserCourseResult } from '../../types';
import { resultStateSelector } from '../../store/selectors';
import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import Message from '../../components/translation/Message';
import { NoteAdd } from '@material-ui/icons';
import { ChangeMandatoryDialog } from './ChangeMandatoryDialog';
import { updateMandatoryCourses } from '../../api/course-api';
import { updateAllUsersCoursesResults } from '../../store/allResultsSlice';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';

type UserCoursesCardsViewProps = {
  listToRender: UserCourseResult[];
};

export const UserCoursesCardsView = ({
  listToRender,
}: UserCoursesCardsViewProps) => {
  const resultState = useSelector(resultStateSelector);

  useEffect(() => {}, [resultState.allUsersCourseResults]);

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      flexWrap={'wrap'}
      justifyContent={'center'}
    >
      {listToRender.map((cardData, i) => (
        <UserCard data={cardData} key={`hu_userCard_${i}`} />
      ))}
    </Box>
  );
};

type UserCardProps = { data: UserCourseResult };
export const UserCard = ({ data }: UserCardProps) => {
  const [showMandatory, setShowMandatory] = useState(false);
  const dispatch = useDispatch();
  const handleMandatorySave = async (newCourses: SimplifiedCourse[]) => {
    const response = await updateMandatoryCourses(newCourses, data.userId);
    if (response.status === 200) {
      dispatch(updateAllUsersCoursesResults());
    }
    setShowMandatory(false);
  };

  const countStartedMandatory = () => {
    return data.courseResults.filter((course) => course.isMandatory).length;
  };

  const userRoles = () => {
    if (data.roles.length === 0)
      return getMessage('course.courseAdmin.noRoles');
    return data.roles.join(', ');
  };

  return (
    <Card
      style={{
        padding: 2,
        marginRight: 30,
        marginBottom: 20,
        background: '#fafafa',
        width: 275,
      }}
    >
      <CardContent>
        <Typography variant="h6">{data.username}</Typography>
        <Typography variant="body2" gutterBottom>
          {userRoles()}
        </Typography>
        <Typography color="textSecondary" variant="subtitle1">
          <Message id="handleUserCourses.totalCarriedOutCourses" />:{' '}
          {data.courseResults.length}
        </Typography>
        <Typography color="textSecondary" variant="subtitle1">
          <Message id="handleUserCourses.mandatory" />:{' '}
          {countStartedMandatory()} /{' '}
          {countStartedMandatory() + data.notStarted.length}
        </Typography>
        <ChangeMandatoryDialog
          name={data.username}
          mandatoryCourses={data.mandatoryCourses}
          isOpen={showMandatory}
          onClose={() => setShowMandatory(false)}
          handleSave={handleMandatorySave}
        />
      </CardContent>
      <CardActions
        disableSpacing
        style={{
          display: 'flex',
          alignSelf: 'stretch',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          style={{ color: 'black' }}
          onClick={() => setShowMandatory(true)}
        >
          <NoteAdd />
        </IconButton>
      </CardActions>
    </Card>
  );
};
