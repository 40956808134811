import { useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Message from './translation/Message';
import InfoDialog from './InfoDialog';
import { getMessage } from '../whitelabel-config/WhitelabelProvider';

type DeleteButtonProps = {
  itemCategory: string,
  itemName: string,
  dialogTitle?: string,
  deleteDesc?: string,
  onDelete: () => void;
  icon?: JSX.Element,
  button?: JSX.Element
};
export default function DeleteButton({
  itemCategory,
  itemName,
  dialogTitle,
  deleteDesc,
  onDelete,
  icon,
  button
}: DeleteButtonProps) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  return (
    <Box>
      {!button &&
        <IconButton aria-label="delete" onClick={() => setIsDeleteModalOpen(true)}>
          {icon || <DeleteIcon />}
        </IconButton>
      }
      {button &&
        <Box aria-label="delete" onClick={() => setIsDeleteModalOpen(true)}>
          {button}
        </Box>
      }
      <InfoDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        action={onDelete}
        title={dialogTitle || getMessage('course.homeAdmin.delete')}
        actionLabel={getMessage('course.homeAdmin.delete')}
      >
        {deleteDesc
          ? <Typography>{deleteDesc}</Typography>
          : <Typography>
            <Message id="siteSettings.deleteDialog" />
          </Typography>
        }
        {itemName !== '' &&
          <Box pt={2}>
            <Typography variant='subtitle2' component='span'>
              {itemCategory + ': '}
            </Typography>
            <Typography component='span'>{itemName}</Typography>
          </Box>
        }
      </InfoDialog>
    </Box>
  );
}
