import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Provider } from '../types';
import { AppThunk } from './index';
import { RootState } from './rootReducer';
import { getProviders } from '../api/course-api';

export interface ProviderState {
  providers: Provider[] | null;
  isLoading: boolean;
  error: string | null;
}

const initialState = {
  providers: null,
  isLoading: false,
  error: null,
} as ProviderState;

function startLoading(state: ProviderState) {
  state.isLoading = true;
}

function loadingFailed(state: ProviderState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const providersSlice = createSlice({
  name: 'providers',
  initialState: initialState,
  reducers: {
    getProvidersStart: startLoading,
    getProvidersSuccess(state, action: PayloadAction<Provider[]>) {
      state.providers = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getProvidersFailure: loadingFailed,
  },
});

export const {
  getProvidersStart,
  getProvidersSuccess,
  getProvidersFailure,
} = providersSlice.actions;

export default providersSlice.reducer;

// Thunks

export const fetchProviders = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getProvidersStart());
    const providers = await getProviders();
    dispatch(getProvidersSuccess(providers));
  } catch (err: any) {
    dispatch(getProvidersFailure(err.toString()));
  }
};

// Selectors

const selectProviderState = (rootState: RootState) => rootState.providers;

export const providersIsLoadingSelector = createSelector(
  selectProviderState,
  (providerState: ProviderState) => providerState.isLoading
);

export const providersSelector = createSelector(
  selectProviderState,
  (providerState: ProviderState) => providerState.providers
);
