import React from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardActionArea,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../../components/translation/Message';
import {
  providersSelector,
  providersIsLoadingSelector,
  fetchProviders,
} from '../../../store/providersSlice';
import AddProviderButton from './AddProviderButton';
import { Link } from 'react-router-dom';
import { getEditProviderUrl } from '../../../routes/paths';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    allProvidersList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: theme.spacing(4),
    },
    providerCard: {
      padding: theme.spacing(2),
    },
    addProviderCell: {
      display: 'flex',
      alignItems: 'center',
      width: 300,
    },
  })
);

const EditProvidersDashboardPage = () => {
  const dispatch = useDispatch();
  const providers = useSelector(providersSelector);
  const isLoading = useSelector(providersIsLoadingSelector);
  const classes = useStyles();

  React.useEffect(() => {
    if (!providers) {
      dispatch(fetchProviders());
    }
  }, [providers, dispatch]);

  return (
    <Box p={4}>
      <Box>
        <Typography variant="h5" component="h2" gutterBottom>
          <Message id="course.courseAdmin.editProvidersDashboardPage.providers" />
        </Typography>
        {isLoading && !providers && <CircularProgress size={64} />}

        {!isLoading && providers && (
          <Box className={classes.allProvidersList}>
            {providers.length === 0 && (
              <Box>
                <Typography variant="body1" component="p" gutterBottom>
                  <Message id="course.courseAdmin.editProvidersDashboardPage.missingProviders" />
                </Typography>
              </Box>
            )}

            {providers.length > 0 &&
              providers.map((provider) => (
                <Card key={provider.id} variant="outlined">
                  <CardActionArea
                    to={getEditProviderUrl(provider.id)}
                    component={Link}
                    className={classes.providerCard}
                  >
                    <Typography variant="h6" component="h2" gutterBottom>
                      {provider.name}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      <Message id="course.numberOfInstructors" />:
                      {provider.instructors.length}
                    </Typography>
                  </CardActionArea>
                </Card>
              ))}
            <Box className={classes.addProviderCell}>
              <AddProviderButton />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EditProvidersDashboardPage;
