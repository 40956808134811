import { SvgIcon, SvgIconProps } from "@material-ui/core";

const CourseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" >
      <path d="M3 4.5V6H19.5V18H9V19.5H22.5V18H21V4.5H3ZM6.0015 6.75C5.20656 6.75276 4.44495 7.06968 3.8827 7.63164C3.32045 8.19361 3.00316 8.95506 3 9.75C3 11.3992 4.353 12.75 6.0015 12.75C6.79604 12.7468 7.55711 12.4297 8.1188 11.8677C8.68048 11.3058 8.99724 10.5445 9 9.75C9 8.10225 7.6485 6.75 6.0015 6.75ZM10.5 7.5V9H14.25V7.5H10.5ZM15.75 7.5V9H18V7.5H15.75ZM6.0015 8.25C6.837 8.25 7.5 8.91225 7.5 9.75C7.5 10.59 6.83775 11.25 6.0015 11.25C5.1615 11.25 4.5 10.59 4.5 9.75C4.5 8.91225 5.16225 8.25 6.0015 8.25V8.25ZM10.5 10.5V12H18V10.5H10.5ZM3 13.5V19.5H4.5V15H6.75V19.5H8.25V15.4935L9.798 16.3125C10.2368 16.545 10.764 16.5442 11.202 16.3125V16.314L13.8495 14.9138L13.1498 13.5862L10.5015 14.9865L8.1795 13.761C7.85544 13.5898 7.49451 13.5002 7.128 13.5H3Z" />
    </SvgIcon>
  );
}

export default CourseIcon;