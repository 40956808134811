import { Button, Box, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import theme from "../theme"
import BackIcon from "./icons/BackIcon"
import Message from "./translation/Message"

type BackButtonProps = {
    onClick?: () => void
    messageId?: string
    text?: string
    linkTo?: string
}
const BackButton = ({
    onClick = () => { },
    messageId,
    linkTo,
    text
}: BackButtonProps) => {
    if (linkTo) {
        return (
            <Link
                to={linkTo}
                style={{
                    textDecoration: 'none',
                    color: theme.palette.text.primary,
                }}
            >
                <Button>
                    <Box pr={1} display='flex' alignItems='center'>
                        <BackIcon />
                        <Box pr={1} />
                        {!text && <Message id={messageId || 'course.back'} />}
                        {text && <Typography>{text}</Typography>}
                    </Box>
                </Button>
            </Link>
        )
    }

    return (
        <Button onClick={onClick}>
            <Box pr={1} display='flex' alignItems='center'>
                <BackIcon />
                <Box pr={1} />
                {!text && <Message id={messageId || 'course.back'} />}
                {text && <Typography>{text}</Typography>}
            </Box>
        </Button>
    )
}

export default BackButton;