import React from 'react';
import get from 'lodash/get';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import defaultMessages from './default-messages.json';
import defaultMessagesSwe from './default-messages-swe.json';
import { InstanceLanguage } from '../types';

interface Messages {
  [key: string]: string | Messages;
}

type Images = {
  [path: string]: string | Images;
};

type IncomingImages = Partial<Images>;

export interface StaticContent {
  messages: Messages;
  images: Images;
}

export interface IncomingStaticContent {
  messages: Messages;
  images: IncomingImages;
}

export interface InstanceMetaData {
  metaTitle: string;
  metaDescription: string;
}

interface IWhitelabelContext {
  messages: Messages;
  getMessage: (id: string) => string;
  images: Images;
  getImage: (id: string) => string;
  mergeStaticContent: (
    incomingStaticContent: IncomingStaticContent,
    lang: InstanceLanguage
  ) => void;
}

let defaultImages = {};

const initialConfig: IWhitelabelContext = {
  messages: cloneDeep(defaultMessages),
  getMessage: (id: string) => id,
  images: defaultImages,
  getImage: (id: string) => id,
  mergeStaticContent: (
    incomingStaticContent: IncomingStaticContent,
    lang: InstanceLanguage
  ) => {},
};

export const WhitelabelContext =
  React.createContext<IWhitelabelContext>(initialConfig);

type WhitelabelProviderProps = {
  children: React.ReactNode;
};

const WhitelabelProvider = ({ children }: WhitelabelProviderProps) => {
  const [staticContent, setStaticContent] = React.useState<StaticContent>({
    messages: cloneDeep(defaultMessages),
    images: defaultImages,
  });

  const mergeStaticContent = (
    incomingStaticContent: IncomingStaticContent,
    lang: InstanceLanguage
  ) => {
    const cDeep = cloneDeep(
      lang === 'English' ? defaultMessages : defaultMessagesSwe
    );
    const mergedMessages = merge(cDeep, incomingStaticContent.messages);

    const mergedImages = merge(
      { ...staticContent.images },
      incomingStaticContent.images
    );
    appMessages = mergedMessages;
    appImages = mergedImages;

    setStaticContent({ messages: mergedMessages, images: mergedImages });
  };

  return (
    <WhitelabelContext.Provider
      value={{
        messages: staticContent.messages,
        getMessage: getMessage,
        images: staticContent.images,
        getImage: getImage,
        mergeStaticContent,
      }}
    >
      {children}
    </WhitelabelContext.Provider>
  );
};

export default WhitelabelProvider;
export const defaultMessagess: Messages = defaultMessages;
export const defaultMessagessSwe: Messages = defaultMessagesSwe;

let appMessages: Messages = cloneDeep(defaultMessages);
let appImages: Images = defaultImages;

export const getMessage = (path: string): string => {
  return get(appMessages, path, 'Missing message') as string;
};

export const getImage = (path: string): string => {
  return get(appImages, path, '') as string;
};
