import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import theme from '../../theme';
import DocumentDialog from './DocumentDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clickable: {
      cursor: 'zoom-in',
    },
  })
);

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

type DocumentViewerProps = {
  file: Blob;
  documentTitle?: string;
};
const DocumentViewer = ({ file, documentTitle }: DocumentViewerProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  const handleLoadSuccess = ({
    numPages: nextNumPages,
  }: {
    numPages: number;
  }) => {
    setNumPages(nextNumPages);
  };

  const handlePageClick = () => {
    setIsDialogOpen(true);
  };

  return (
    <Box>
      <Box border={`1px solid ${theme.palette.grey[200]}`} width="fit-content">
        <Document
          file={file}
          options={options}
          noData="Loading pdf..."
          onLoadSuccess={handleLoadSuccess}
          className={classes.clickable}
        >
          <div>
            {Array.from(new Array(numPages), (_el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={isXs ? 300 : isSm ? 500 : 750}
                onClick={handlePageClick}
              />
            ))}
          </div>
        </Document>
      </Box>
      <DocumentDialog
        isOpen={isDialogOpen}
        file={file}
        numPages={numPages}
        onLoadSuccess={handleLoadSuccess}
        onClose={() => setIsDialogOpen(false)}
        options={options}
        title={documentTitle}
      />
    </Box>
  );
};

export default DocumentViewer;
