import { Course, isAdmin, isCourseAdmin } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { userSelector } from '../../store/userSlice';
import { resetCourseResults } from '../../store/allResultsSlice';
import { ViewAllResults } from './ViewAllResults';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getSessionUrl } from '../../routes/paths';
import PlayCourseIcon from '../../components/icons/PlayCourseIcon';
import Message from '../../components/translation/Message';
import InstructorItem from '../../components/courses/InstructorItem';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { getDurationText } from '../../utils';
import CustomChip from '../../components/CustomChip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    startButton: {
      width: '30ch',
      marginBottom: theme.spacing(8),
    },
    tags: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

type IntroductionMetadataProps = {
  course: Course;
};

const IntroductionMetadata = ({ course }: IntroductionMetadataProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showResults, setShowResults] = useState(false);
  const user = useSelector(userSelector);

  const handleCloseResultView = () => {
    setShowResults(false);
    dispatch(resetCourseResults());
  };

  return (
    <Box width={300}>
      <ViewAllResults
        key={`viewres_${course.id}`}
        courseId={course.id}
        courseName={course.name}
        isOpen={showResults}
        onClose={handleCloseResultView}
      />
      {user && (isAdmin(user) || isCourseAdmin(user)) && (
        <Button
          variant="contained"
          color="primary"
          style={{
            marginBottom: 10,
          }}
          onClick={() => setShowResults(true)}
        >
          View Results
        </Button>
      )}
      {course.sessions.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={getSessionUrl(course.slug, course.sessions[0].id)}
          className={classes.startButton}
          startIcon={<PlayCourseIcon />}
        >
          <Message id="course.coursePage.startToLearn" />
        </Button>
      )}
      <Box mb={8}>
        <Typography variant="h5" component="h2" gutterBottom>
          <Message id="course.instructors" />
        </Typography>
        {course.instructors.map((instructor) => (
          <InstructorItem
            instructor={instructor}
            showName={true}
            showTitle={true}
            key={instructor.id}
          />
        ))}
      </Box>
      <Box mb={8}>
        <Typography variant="h5" component="h2" gutterBottom>
          <Message id="course.coursePage.content" />
        </Typography>
        <Box className={classes.flexCenter} mb={8}>
          <ScheduleIcon />
          <Typography>{getDurationText(course.length)}</Typography>
        </Box>
      </Box>
      <Box mb={8}>
        <Typography variant="h5" component="h2" gutterBottom>
          <Message id="course.provider" />
        </Typography>
        <Typography>{course.provider?.name}</Typography>
      </Box>
      {course.tags.length > 0 && (
        <Box mb={8}>
          <Typography variant="h5" component="h2" gutterBottom>
            <Message id="course.tags" />
          </Typography>
          <Box className={classes.tags}>
            {course.tags.map((tag) => (
              <CustomChip label={tag} key={tag} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default IntroductionMetadata;
