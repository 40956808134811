import React, { useState } from 'react';
import {
    Box,
    Typography,
    Avatar,
    FormControl,
    FormHelperText,
    IconButton,
    Button,
    makeStyles,
    createStyles,
    Theme,
    Divider
} from '@material-ui/core';
import HtmlEditor from '../../components/HtmlEditor';
import SafeHtmlRender from '../../components/SafeHtmlRenderer';
import EditIcon from '@material-ui/icons/Edit';
import Message from '../translation/Message';
import { formatFullDate, isUserActive } from '../../utils';
import { ForumReply, ForumTopic, isAdmin } from '../../types';
import DeleteButton from '../DeleteButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import { userSelector } from '../../store/userSlice';
import { useSelector } from 'react-redux';

const getInitials = (name: string) => {
    return name
        .split(' ')
        .map((n) => n[0])
        .join('');
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.grey[100],
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
        },
        spaceBetween: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        rightItems: {
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
        },
        cancelButton: {
            float: 'right',
            marginTop: '1em',
            marginRight: '1em'
        },
        submitButton: {
            float: 'right',
            marginTop: '1em',
        }
    })
);

type ForumTopicItemProps = {
    topicItem: ForumTopic | ForumReply,
    canEdit?: boolean,
    onSaveEdit?: (itemId: string, newText: string) => void,
    avatarColor: string,
    onDelete?: (id: string) => void;
}
const ForumTopicItem = ({
    topicItem,
    canEdit = false,
    avatarColor,
    onSaveEdit,
    onDelete
}: ForumTopicItemProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [updatedText, setUpdatedText] = useState<string>('');
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');
    const [stateBustingKey, setStateBustingKey] = useState(0);

    const classes = useStyles();
    const user = useSelector(userSelector);


    const handleSaveEdit = () => {
        if (!onSaveEdit) return;
        onSaveEdit(topicItem.id, updatedText);

        setUpdatedText('');
        setError(false);
        setHelperText('');
        setStateBustingKey(stateBustingKey + 1);
        setIsEditing(false);
    };

    const handleDeleteTopic = (id: string): void => {
        if (id && onDelete) {
            onDelete(id);
        }
    };

    return (
        <Box key={topicItem.id} width='100%'>
            <Box className={classes.flex} width='100%'>
                <Box className={classes.header} width='100%'>
                    <Box display='flex' alignItems='center'>
                        <Avatar style={{ backgroundColor: avatarColor, marginRight: '16px' }}>
                            {isUserActive(topicItem.author.id)
                                ? getInitials(topicItem.author.name)
                                : null
                            }
                        </Avatar>
                        <Box>
                            <Typography variant='subtitle2'>
                                {topicItem.author.name || <Message id="profile.inactiveUser" />}
                            </Typography>
                            <Typography variant='caption'>
                                {formatFullDate(topicItem.createdAt)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.rightItems}>
                        {topicItem.edited &&
                            <Box mr={1}>
                                <Typography variant='caption'>
                                    <Message id='forum.topicPage.edited' />
                                </Typography>
                            </Box>
                        }
                        {canEdit && onSaveEdit &&
                            <IconButton onClick={() => setIsEditing(true)}>
                                <EditIcon />
                            </IconButton>
                        }
                        {onDelete && user && (isAdmin(user) || user.userInstanceId === topicItem.author.id) &&
                            <DeleteButton
                                itemCategory={getMessage('forum.overviewPage.reply')}
                                itemName={''}
                                dialogTitle={getMessage('forum.overviewPage.deleteReply')}
                                icon={<HighlightOffIcon />}
                                onDelete={() => handleDeleteTopic(topicItem.id)}
                            />
                        }
                    </Box>
                </Box>

                <Box px={2} mb={2} width="100%">
                    <Box className={classes.spaceBetween} mb={2}>
                        {!isEditing &&
                            <SafeHtmlRender html={topicItem.formattedText} />
                        }
                        {isEditing &&
                            <Box mt={1}>
                                <FormControl error={error}>
                                    <HtmlEditor
                                        key={stateBustingKey}
                                        onChange={setUpdatedText}
                                        html={topicItem.formattedText}
                                    />
                                    <FormHelperText>{helperText}</FormHelperText>
                                </FormControl>

                                <Button
                                    variant='contained'
                                    color='primary'
                                    className={classes.submitButton}
                                    onClick={handleSaveEdit}
                                    disabled={!updatedText || updatedText === topicItem.formattedText}
                                >
                                    <Message id='forum.topicPage.saveEdit' />
                                </Button>
                                <Button
                                    variant='contained'
                                    className={classes.cancelButton}
                                    onClick={() => setIsEditing(false)}
                                >
                                    <Message id='forum.topicPage.cancelEdit' />
                                </Button>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <Divider />
        </Box>
    )
}

export default ForumTopicItem;
