import { combineReducers } from 'redux';
import coursesReducer from './coursesSlice';
import editCourseReducer from './editCourseSlice';
import providersReducer from './providersSlice';
import userReducer from './userSlice';
import forumReducer from './forumSlice';
import forumCategoriesReducer from './forumCategoriesSlice';
import instanceReducer from './instanceSlice';
import actorsReducer from './actorsSlice';
import userAreaReducer from './userAreaSlice';
import notificationsReducer from './notificationsSlice';
import groupReducer from './groupSlice';
import allResultsReducer from './allResultsSlice';

const rootReducer = combineReducers({
  courses: coursesReducer,
  editCourse: editCourseReducer,
  providers: providersReducer,
  user: userReducer,
  forum: forumReducer,
  forumCategories: forumCategoriesReducer,
  instance: instanceReducer,
  actors: actorsReducer,
  userArea: userAreaReducer,
  notifications: notificationsReducer,
  groups: groupReducer,
  results: allResultsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
