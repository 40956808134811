import { SvgIcon, SvgIconProps } from "@material-ui/core";

const InfoIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="24" height="24" viewBox="0 0 64 64">
            <path
                d="M32 2.667C48.2 2.667 61.333 15.8 61.333 32S48.2 61.333 32 61.333 2.667 48.2 2.667 32 15.8 2.667 32 2.667zM32 8C18.745 8 8 18.745 8 32s10.745 24 24 24 24-10.745 24-24S45.255 8 32 8zm1.667 21.333c.552 0 1 .448 1 1v14c0 .553-.448 1-1 1h-3.334c-.552 0-1-.447-1-1v-14c0-.552.448-1 1-1h3.334zm-1.667-8c1.473 0 2.667 1.194 2.667 2.667 0 1.473-1.194 2.667-2.667 2.667-1.473 0-2.667-1.194-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667z"
                transform="translate(-498 -911) translate(498 911)"
            />
        </SvgIcon>
    );
}

export default InfoIcon;