import {
  Container,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centeredContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(5, 0),
      textAlign: 'center',
    },
    icon: {
      fontSize: '40px',
    },
  })
);

const Authenticating = () => {
  const classes = useStyles();

  return (
    <Container>
      <main className={classes.centeredContainer}>
        <Typography variant="h2" component="h1" gutterBottom>
          Logged in <CheckIcon className={classes.icon} />
        </Typography>
      </main>
    </Container>
  );
};

export default Authenticating;
