import { Box, Typography, TextField } from "@material-ui/core";

type TextInputProps = {
    title: JSX.Element | string,
    value: string;
    onInput: (e: any) => void;
    inputProps?: any
}
const TextInput = ({
    title,
    value,
    onInput,
    inputProps
}: TextInputProps) => {
    return (
        <Box mb={4}>
            <Typography variant="subtitle2" component="h4" gutterBottom>
                {title}
            </Typography>
            <TextField
                style={{ width: '350px' }}
                value={value}
                onInput={(e: any) => onInput(e)}
                InputProps={inputProps}
            />
        </Box>
    )
}

export default TextInput;