
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Typography,
    makeStyles,
    createStyles,
    withStyles,
    Theme,
    CircularProgress,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {
    ActorRelationship,
    ActorsUsersDetails,
    Statistic
} from '../../../types';
import theme from '../../../theme';
import {
    userStatsLoadingSelector,
    userStatisticsSelector,
    fetchStatistics
} from '../../../store/userSlice';
import { getLoginStatisticsInMonth } from '../utils';
import Message from '../../../components/translation/Message';
import { getMonthString } from '../../../utils';
import { getRelatedUsers } from '../../../api/actors-api';
import CustomerStatsGrid from './CustomerStatsGrid';
import TimeDropdown from './TimeDropdown';
import { getMessage } from '../../../whitelabel-config/WhitelabelProvider';
import ActorNamesCaption from '../../../components/actors/ActorNamesCaption';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        userCard: {
            width: '100%',
            backgroundColor: theme.palette.grey[100],
            padding: theme.spacing(2),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            justifyContent: 'space-between'
        },
        summary: {
            display: 'flex',
            alignItems: 'center'
        },
        section: {
            paddingTop: '8px',
            paddingBottom: '16px'
        },
        actorName: {
            width: '240px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: '8px'
        }
    })
);

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        marginBottom: theme.spacing(1),
        '&:before': {
            display: 'none',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: theme.palette.grey[100],
    },
    content: {},
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        backgroundColor: theme.palette.grey[200],
        display: 'flex',
        flexDirection: 'column',
        padding: '1em 2em'
    },
})(MuiAccordionDetails);

type UserAccordionProps = {
    name: string,
    id: string,
    actors?: string[],
    relationships?: ActorRelationship[],
}
const UserAccordion = ({
    name,
    actors,
    id,
    relationships
}: UserAccordionProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const [userStats, setUserStats] = useState<Statistic[] | null>(null);
    const [relatedUsers, setRelatedUsers] = useState<ActorsUsersDetails[]>([]);

    const [timeFrame, setTimeFrame] = useState<string>('statistics.thisMonth');
    const [showingMonth, setShowingMonth] = useState<number>(0);

    const userStatsLoading = useSelector(userStatsLoadingSelector);
    const allFetchedUserStats = useSelector(userStatisticsSelector);

    const dispatch = useDispatch();
    const classes = useStyles();
    const userId = useMemo(() => id, [id]);
    const currentDate = useMemo(() => new Date(), []);

    useEffect(() => {
        if (!isExpanded) return;

        const loadStatsThisMonth = (currentYear: number, currentMonth: number) => {
            let currentMonthString: string = currentMonth + '';
            if (currentMonth < 10) currentMonthString = '0' + currentMonth;

            const todayDate: number = currentDate.getDate();
            let todayDateString: string = todayDate + '';
            if (todayDate < 10) todayDateString = '0' + todayDate;

            dispatch(fetchStatistics(userId, {
                toDate: `${currentYear}-${currentMonthString}-${todayDateString}`,
                fromDate: `${currentYear}-${currentMonthString}-01`,
                userInstanceId: `${userId}`
            }));
            setShowingMonth(currentMonth - 1);
        }

        const loadStatsLastMonth = (currentYear: number, currentMonth: number) => {
            if (currentMonth === 1) currentYear = currentYear - 1;

            let lastMonth: number = currentMonth - 1;
            let lastMonthString: string = lastMonth + '';
            if (lastMonth === 0) {
                lastMonth = 12;
                lastMonthString = '12';
            }
            if (lastMonth > 0 && lastMonth < 10) {
                lastMonthString = '0' + lastMonth;
            }

            const lastDayOfLastMonth: number = new Date(currentYear, lastMonth, 0).getDate();
            let lastDayOfMonthString: string = lastDayOfLastMonth + '';
            if (lastDayOfLastMonth < 10) {
                lastDayOfMonthString = '0' + lastDayOfLastMonth;
            }

            dispatch(fetchStatistics(userId, {
                toDate: `${currentYear}-${lastMonthString}-${lastDayOfMonthString}`,
                fromDate: `${currentYear}-${lastMonthString}-01`,
                userInstanceId: `${userId}`
            }));
            setShowingMonth(lastMonth - 1);
        }

        let currentYear: number = currentDate.getFullYear();
        const currentMonth: number = currentDate.getMonth() + 1;

        if (timeFrame === 'statistics.thisMonth') {
            loadStatsThisMonth(currentYear, currentMonth);
        } else if (timeFrame === 'statistics.lastMonth') {
            loadStatsLastMonth(currentYear, currentMonth);
        }
    }, [
        timeFrame,
        userId,
        currentDate,
        isExpanded,
        dispatch
    ]);

    useEffect(() => {
        if (allFetchedUserStats && allFetchedUserStats[userId]) {
            setUserStats(allFetchedUserStats[userId]);
        }
    }, [
        allFetchedUserStats,
        userId
    ]);

    // TODO: use dynamic actors
    useEffect(() => {
        if (!relatedUsers && actors && actors.includes('Customer')) {
            getRelatedUsers(userId).then(users => setRelatedUsers(users));
        }
    }, [
        userId,
        actors,
        relatedUsers
    ]);

    const getLoginStats = useCallback(() => {
        if (!userStats) return [];
        return getLoginStatisticsInMonth(userStats, showingMonth);
    }, [userStats, showingMonth]);

    // TODO: use dynamic actors
    const getAssociatedCustomers = useCallback(() => {
        if (!relationships) return [];
        const assistantRelationships = relationships.filter(r => r.relationship.name === 'Assistant');
        if (assistantRelationships.length === 0) return [];

        let associatedCustomers: { id: string, name: string }[] = [];
        assistantRelationships.forEach(r => associatedCustomers.push({ id: r.toUserId!, name: r.toUser }));
        return associatedCustomers;
    }, [relationships]);

    const isUserStatsLoading = useCallback(() => {
        return userStatsLoading.includes(userId);
    }, [userStatsLoading, userId]);

    const loginsToShow = getLoginStats();
    const hasLoginsToShow = loginsToShow.find(l => l.amount > 0);

    return (
        <Accordion
            expanded={isExpanded}
            onChange={() => setIsExpanded(!isExpanded)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={name}
                id={name}
            >
                <Box className={classes.summary}>
                    <Typography variant='subtitle2' className={classes.actorName}>
                        {name}
                    </Typography>
                    <ActorNamesCaption actorNames={actors || []} />
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box className={classes.section}>
                    <TimeDropdown
                        value={timeFrame}
                        onChange={(timeFrame: string) => setTimeFrame(timeFrame)}
                    />
                </Box>
                <Box className={classes.section}>
                    <Typography variant='subtitle1'>
                        <Message id='statistics.logins' />
                    </Typography>
                    <Typography variant='caption' display='block'>
                        {getMonthString(showingMonth) + ' ' + currentDate.getFullYear()}
                    </Typography>
                    <Box className={classes.section} mt={1}>
                        {isUserStatsLoading() &&
                            <Box>
                                <CircularProgress size={64} />
                            </Box>
                        }
                        {!isUserStatsLoading() && hasLoginsToShow &&
                            <BarChart
                                width={800}
                                height={360}
                                data={loginsToShow}
                                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                            >
                                <CartesianGrid stroke={theme.palette.background.default} />
                                <XAxis dataKey="day" />
                                <YAxis dataKey='amount' allowDecimals={false} />
                                <Tooltip />
                                <Bar dataKey="amount" fill={theme.palette.primary.main} />
                            </BarChart>
                        }
                        {!isUserStatsLoading() && !hasLoginsToShow &&
                            <Typography>
                                {name + ' '}
                                <Message id='statistics.hasntLoggedInThisMonth' />
                            </Typography>
                        }
                    </Box>
                </Box>
                {actors && actors.find(a => a === 'Assistant') &&
                    <Box>
                        <Box className={classes.section}>
                            <Typography variant='subtitle1'>
                                <Message id='statistics.activity' />
                            </Typography>
                            <Typography variant='caption' display='block'>
                                {getMonthString(showingMonth) + ' ' + currentDate.getFullYear()}
                            </Typography>
                            {isUserStatsLoading() && timeFrame === 'statistics.thisMonth' &&
                                <Box mt={2}>
                                    <CircularProgress size={64} />
                                </Box>
                            }
                            {!isUserStatsLoading() && timeFrame === 'statistics.thisMonth' &&
                                <Box className={classes.section} mt={1}>
                                    <CustomerStatsGrid
                                        assistantName={name}
                                        assistantStats={userStats}
                                        customers={getAssociatedCustomers()}
                                        timeFrame={getMessage(timeFrame)}
                                    />
                                </Box>
                            }
                            {timeFrame !== 'statistics.thisMonth' &&
                                <Box mt={2}>
                                    <Typography>
                                        Cannot show accurate data for this time frame.
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                }
                {relatedUsers.length > 0 &&
                    <Box className={classes.section}>
                        <Typography variant='subtitle1'>
                            <Message id='statistics.relatedActors' />
                        </Typography>
                        {relatedUsers.map(related =>
                            <Box display='flex' key={related.id}>
                                <Typography style={{ marginRight: '16px' }}>
                                    {related.name}
                                </Typography>
                                {related.actors.map(a => (
                                    <Typography key={a.id}>{a.name}</Typography>
                                ))}
                            </Box>
                        )}
                    </Box>
                }
            </AccordionDetails>
        </Accordion>
    )
}

export default UserAccordion;