import { PropTypes } from '@material-ui/core';
import { CourseType } from '../../types';
import { useSortable } from '@dnd-kit/sortable';
import { CourseTypeButton } from './CourseTypeButton';

type SortableButtonProps = {
  id: any;
  courseType: CourseType;
  color?: PropTypes.Color | undefined;
};

const SortableCourseTypeButton = (props: SortableButtonProps) => {
  const { id, courseType, color } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
    });

  const style = {
    transform: JSON.stringify(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      <CourseTypeButton
        style={style}
        key={courseType.id}
        color={color}
        courseType={courseType}
      >
        {courseType.name}
      </CourseTypeButton>
    </div>
  );
};

export default SortableCourseTypeButton;
