import { Button } from '@material-ui/core';
import { useSortable } from '@dnd-kit/sortable';

type SortableButtonProps = {
  id: any;
  name: string;
};

const SortableTextButton = (props: SortableButtonProps) => {
  const { id, name } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
    });

  const style = {
    transform: JSON.stringify(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      <Button style={style} key={id}>
        {name}
      </Button>
    </div>
  );
};

export default SortableTextButton;
