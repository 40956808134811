import { createSelector } from '@reduxjs/toolkit';
import { CourseResultDict, SessionResultDict } from '../types';
import { ResultState } from './allResultsSlice';
import { CourseState } from './coursesSlice';
import { RootState } from './rootReducer';

const selectCoursesState = (rootState: RootState) => rootState.courses;
const selectResultsState = (rootState: RootState) => rootState.results;

export const coursesIsLoadingSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState) => courseState.isLoading
);

export const currentCourseSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState) => courseState.currentCourse
);

export const sessionResultsSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState): SessionResultDict => courseState.sessionResults
);

export const courseResultsSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState): CourseResultDict => courseState.courseResults
);

export const resultStateSelector = createSelector(
  selectResultsState,
  (resultState: ResultState) => resultState
);

export const allExamCoursesSimplifiedSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState) => courseState.simplifiedExamCourses
);

export const allCoursesSimplifiedSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState) => courseState.simplifiedCourses
);

export const allDocumentsSimplifiedSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState) => [
    ...(courseState.simplifiedCourses ?? []),
    ...(courseState.simplifiedDocuments?.filter(x => x.courseSlug != null) ?? []),
  ]
);

export const featuredCoursesSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState) => courseState.featuredCourses
);

export const courseTypesSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState) => courseState.courseTypes
);

export const privateCoursePreviewsSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState) => courseState.privateCoursePreviews
);

export const tabVideosSelector = createSelector(
  selectCoursesState,
  (courseState: CourseState) => courseState.privateVideos
);
