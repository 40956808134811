import {
    Box,
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle, Typography
} from "@material-ui/core";
import Message from "../../translation/Message";

type RemoveCategoryDialogProps = {
    isOpen: boolean,
    onClose: () => void,
    onRemove: () => void,
    categoryName: string
}
const RemoveCategoryDialog = ({
    isOpen,
    onClose,
    onRemove,
    categoryName
}: RemoveCategoryDialogProps) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
        >
            <DialogTitle>
                <Message id='forum.adminPage.deleteHeader' />
            </DialogTitle>
            <DialogContent>
                <Typography color='error'>
                    <Box pb={2}>
                        <Typography variant='subtitle2' component='span'>
                            <Message id='forum.overviewPage.titleCategory' />
                            {': '}
                        </Typography>
                        <Typography component='span'>{categoryName}</Typography>
                    </Box>
                    <Message id='forum.adminPage.warningRemove' />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Box p={2} display='flex'>
                    <Button
                        variant='contained'
                        onClick={onClose}
                    >
                        <Message id='handleActors.cancel' />
                    </Button>
                    <Box mr={2} />
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => { onRemove(); onClose() }}
                    >
                        <Message id='handleActors.delete' />
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveCategoryDialog;
