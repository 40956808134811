import {
    Dialog,
    Box,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@material-ui/core"
import DeleteButton from '../../../components/DeleteButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Message from '../../../components/translation/Message'
import { VideoData } from "../../../types"
import Video from "./Video"

type ViewVideoDialogProps = {
    video: VideoData,
    isOpen: boolean,
    onClose: () => void,
    onDelete: () => void,
    canDelete?: boolean,
    canDownload?: boolean,
    canPublish?: boolean,
    onPublish: () => void,
}
const ViewVideoDialog = ({
    video,
    isOpen,
    onClose,
    onDelete,
    canDelete = false,
    canDownload = false,
    canPublish = false,
    onPublish
}: ViewVideoDialogProps) => {
    const getReceiver = () => {
        if (window.location.origin.includes('localhost')) {
            return "https://dev.webapp.conductme.com/";
        }
        return window.location.origin;
    }

    return (
        <Dialog
            open={isOpen} onClose={onClose}
            fullWidth maxWidth='xl' PaperProps={{ style: { height: '100%', maxWidth: '100%' } }}
        >
            <Box pt={1} />
            <DialogTitle>
                {video.fileName}
            </DialogTitle>
            <DialogContent>
                <Box mt={2} display='flex' height='100%' maxWidth='100%'>
                    <Video
                        videoUrl={getReceiver() + video.filePath}
                        canDownload={canDownload}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box
                    p={2} width='100%'
                    display='flex' justifyContent='space-between' alignItems='center'
                >
                    {canDelete
                        ? <DeleteButton
                            itemCategory='video'
                            itemName=''
                            onDelete={onDelete}
                            button={
                                <Button
                                    variant='contained'
                                    startIcon={<DeleteIcon color='error' />}
                                >
                                    <Message id='userArea.videos.removeVideo' />
                                </Button>
                            }
                        />
                        : <Box />
                    }
                    {(video.isPublished || !canPublish) &&
                        <Button
                            onClick={onClose}
                            variant='contained' color='primary'
                        >
                            <Message id='userArea.videos.done' />
                        </Button>
                    }
                    {!video.isPublished && canPublish &&
                        < Box display='flex'>
                            <Button variant='contained' onClick={onClose}>
                                <Message id='userArea.videos.cancel' />
                            </Button>
                            <Box mr={2} />
                            <Button
                                onClick={onPublish}
                                variant='contained' color='primary'
                            >
                                <Message id='userArea.videos.publish' />
                            </Button>
                        </Box>
                    }
                </Box>
            </DialogActions>
        </Dialog >
    )
}

export default ViewVideoDialog;