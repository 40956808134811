import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@material-ui/core";
import React, { ChangeEvent, useState } from 'react';
import Message from "../../translation/Message";

type AddCategoryDialogProps = {
    isOpen: boolean,
    onClose: () => void,
    onSave: (name: string) => void
}
const AddCategoryDialog = ({
    isOpen,
    onClose,
    onSave
}: AddCategoryDialogProps) => {
    const [newCategoryName, setNewCategoryName] = useState<string>('');

    const handleNameChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setNewCategoryName((event.target as HTMLInputElement).value);
    };

    const handleSave = () => {
        onSave(newCategoryName);
        setNewCategoryName('');
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth='xs'
            fullWidth
        >
            <DialogTitle>
                <Message id="forum.adminPage.addCategory" />
            </DialogTitle>
            <DialogContent>
                <Box pt={1}>
                    <Typography variant='subtitle2'>
                        <Message id='handleActors.name' />
                    </Typography>
                    <TextField
                        autoFocus
                        margin='dense'
                        fullWidth
                        required
                        value={newCategoryName}
                        onChange={handleNameChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box p={2} display='flex'>
                    <Button
                        variant='contained'
                        onClick={onClose}
                    >
                        <Message id='handleActors.cancel' />
                    </Button>
                    <Box mr={2} />
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleSave}
                    >
                        <Message id='handleActors.save' />
                    </Button>
                </Box>
            </DialogActions>
        </Dialog >
    )
}

export default AddCategoryDialog;