import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        textEllipsis: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    })
);

type TextEllipsisProps = {
    width: number,
    children: any
}
const TextEllipsis = ({
    width,
    children
}: TextEllipsisProps) => {
    const classes = useStyles();
    return (
        <Box width={width} className={classes.textEllipsis}>
            {children}
        </Box>
    )
}

export default TextEllipsis;