import { Box, Button, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { publishAnswers } from '../../store/coursesSlice';
import {
  AnswerOption,
  ExtendedResult,
  QuestionAnswer,
  Session,
  UserAnswer,
} from '../../types';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import Message from '../translation/Message';
import { QuestionComponent } from './QuestionComponent';

type SessionQuestionsProps = {
  session: Session;
  sessionResult?: ExtendedResult;
};

export const SessionQuestions = ({
  session,
  sessionResult,
}: SessionQuestionsProps) => {
  const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([]);
  const questions = session.questionAnswers;
  const dispatch = useDispatch();
  const [resultHolder, setResultHolder] = useState<ExtendedResult | undefined>(
    sessionResult
  );

  const handleSessionAnswer = (index: number, userAnswer: AnswerOption[]) => {
    const question = questions.find((q) => q.index === index);

    // Question, for some reason, didn't exists
    if (!question) {
      return;
    }
    const usObj: UserAnswer = {
      questionAnswer: question,
      sessionId: session.id,
      userAnswer: userAnswer,
    };
    const answerIndex = userAnswers.findIndex(
      (ua) => ua.questionAnswer === question
    );
    if (answerIndex === -1) {
      // If user has not answered this question
      setUserAnswers([usObj, ...userAnswers]);
    } else {
      // User has answered this question
      const newAnswers = userAnswers;
      newAnswers[answerIndex] = usObj;
      setUserAnswers(newAnswers);
    }
  };

  const submitAnswers = (answers: UserAnswer[]) => {
    const filteredAndSorted = answers
      .filter((a) => a.sessionId === session.id)
      .sort((a, b) => a.questionAnswer.index - b.questionAnswer.index);
    dispatch(publishAnswers(session.id, filteredAndSorted));
  };

  const restart = () => {
    setResultHolder(undefined);
  };

  const showResultStatistic = () => {
    if (!resultHolder) {
      return;
    }
    const maxPoints = session.maximumPoints;
    const totalPoints = resultHolder.totalPoints;
    const minPoints = session.minimumPoints;

    const percentage = (totalPoints / maxPoints) * 100;

    return (
      <>
        <Typography
          key={`sq_stat_${session.id}`}
          style={{ marginBottom: minPoints ? 0 : 20 }}
          variant="subtitle2"
        >
          {getMessage('course.yourResult')} {totalPoints}/{maxPoints} (
          {percentage === 100 ? percentage : percentage.toFixed(1)} %)
        </Typography>
        {minPoints && (
          <Typography
            key={`sq_stat_min_${session.id}`}
            style={{ marginBottom: 20 }}
            variant="subtitle2"
          >
            {getMessage('course.courseAdmin.editCoursePage.minimumPoints')}:{' '}
            {minPoints}
          </Typography>
        )}
      </>
    );
  };

  const validResults = (seshRes?: ExtendedResult) => {
    return seshRes && seshRes.maxPoints > 0;
  };

  const correctResults = () => {
    if (resultHolder) {
      const filterFreeText = resultHolder.answerResults.filter(
        (res) => res.answerType === 'FreeText'
      );
      const filterPoints = filterFreeText
        .map((res) => res.maxPoints)
        .reduce((sum, curr) => sum + curr, 0);

      const minPoints = session.minimumPoints
        ? session.minimumPoints
        : resultHolder.maxPoints;

      const pointsCheck =
        minPoints - filterPoints === session.maximumPoints - filterPoints;
      return pointsCheck && resultHolder.pass;
    }
  };

  const updateQuestionAnswer = (qa: QuestionAnswer) => {
    return qa;
  };

  return (
    <Box>
      {validResults(resultHolder) && showResultStatistic()}
      {questions.map((qa) => {
        return (
          <QuestionComponent
            key={qa.index}
            questionAnswer={updateQuestionAnswer(qa)}
            handleAnswer={handleSessionAnswer}
            results={resultHolder?.answerResults ?? undefined}
          />
        );
      })}
      {!correctResults() && !validResults(resultHolder) && (
        <Button
          style={{ justifySelf: 'bottom' }}
          variant="contained"
          color="primary"
          onClick={() => submitAnswers(userAnswers)}
        >
          <Message id="course.submitAnswer" />
        </Button>
      )}
      {!correctResults() && validResults(resultHolder) && (
        <Button
          style={{ justifySelf: 'bottom' }}
          variant="contained"
          color="primary"
          onClick={() => restart()}
        >
          <Message id="course.restart" />
        </Button>
      )}
      <div style={{ height: 200 }}></div>
    </Box>
  );
};
