import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import {
  Actor,
  ActorsUsersDetails,
  ForumReply,
  ForumTopic,
  ForumTopicPreview,
  ForumTopicUpdate,
  Permission,
  Tab,
  TabDTO,
  UserAreaOrGroupId,
} from '../types';
import { AppThunk } from './index';
import {
  getRelatedUsers as getRelatedUsersAPI,
  deleteRelationship as deleteRelationshipAPI,
  setRelationshipOnUser as setRelationshipOnUserAPI,
  getPermissionsForUserArea as getPermissionsForUserAreaAPI,
} from '../api/actors-api';
import { getMessage } from '../whitelabel-config/WhitelabelProvider';
import {
  createForumReply,
  getForumTopicFromSlug,
  getForumTopicPreviews,
  updateForumTopic as updateForumTopicAPI,
  updateForumReply as updateForumReplyAPI,
  deleteForumTopic as deleteForumTopicAPI,
  deleteForumReply as deleteForumReplyAPI,
} from '../api/forum-api';
import {
  getTabs as getTabsAPI,
  createTab as createTabAPI,
  updateTab as updateTabAPI,
  deleteTab as deleteTabAPI,
} from '../api/course-api';

export interface UserAreaState {
  relatedUsers: null | ActorsUsersDetails[];
  userArea: null | ActorsUsersDetails;
  userAreaLoading: boolean;
  errorMsg: string;
  hasAddRelationshipError: boolean;
  privateForumPreviews: ForumTopicPreview[];
  currentPrivateForumTopic: ForumTopic | null;
  permissionsForUserArea: Permission[] | null;
  groupAndCustomerOnly: boolean | null;
  privateForumIsLoading: boolean;
  tabs: null | Tab[];
  isAddingReply: boolean;
  checkedAddedMembersIds: string[];
  filteredActorUserDetails: ActorsUsersDetails[] | null;
}

const initialState = {
  relatedUsers: null,
  userArea: null,
  userAreaLoading: false,
  errorMsg: '',
  hasAddRelationshipError: false,
  privateForumPreviews: [],
  permissionsForUserArea: null,
  groupAndCustomerOnly: null,
  currentPrivateForumTopic: null,
  privateForumIsLoading: false,
  tabs: null,
  isAddingReply: false,
  checkedAddedMembersIds: [],
  filteredActorUserDetails: null,
} as UserAreaState;

const userAreaSlice = createSlice({
  name: 'userArea',
  initialState: initialState,
  reducers: {
    setRelatedUsers(state, action: PayloadAction<ActorsUsersDetails[]>) {
      state.relatedUsers = action.payload;
    },
    setHasAddRelationshipError(state, action: PayloadAction<boolean>) {
      state.hasAddRelationshipError = action.payload;
    },
    setUserArea(state, action: PayloadAction<ActorsUsersDetails | null>) {
      state.userArea = action.payload;
    },
    setUserAreaLoading(state, action: PayloadAction<boolean>) {
      state.userAreaLoading = action.payload;
    },
    removeRelationship(state, action: PayloadAction<string>) {
      if (!state.relatedUsers) {
        return;
      }
      state.relatedUsers = state.relatedUsers.filter(
        (relation) => relation.id !== action.payload
      );
    },
    setErrorMsg(state, action: PayloadAction<string>) {
      state.errorMsg = action.payload;
    },
    getPrivateForumPreviews(state, action: PayloadAction<ForumTopicPreview[]>) {
      state.privateForumPreviews = action.payload;
    },
    getPermissions(state, action: PayloadAction<Permission[] | null>) {
      state.permissionsForUserArea = action.payload;
    },
    checkUserLimit(state, action: PayloadAction<Permission[] | null>) {
      if (action.payload?.includes('GroupAndCustomer')) {
        state.groupAndCustomerOnly = true;
      } else if (state.groupAndCustomerOnly === null) {
        state.groupAndCustomerOnly = false;
      }
    },
    setPrivateForumIsLoading(state, action: PayloadAction<boolean>) {
      state.privateForumIsLoading = action.payload;
    },
    getCurrentPrivateForumTopic(state, action: PayloadAction<ForumTopic>) {
      state.currentPrivateForumTopic = action.payload;
    },
    addPrivateForumReply(state, action: PayloadAction<ForumReply>) {
      state.currentPrivateForumTopic?.replies.push(action.payload);
    },
    startAddingReply(state) {
      state.isAddingReply = true;
    },
    stopAddingReply(state) {
      state.isAddingReply = false;
    },
    setTabs(state, action: PayloadAction<Tab[] | null>) {
      state.tabs = action.payload;
    },
    removePrivateForumTopic(state, action: PayloadAction<{ id: string }>) {
      state.privateForumPreviews = state.privateForumPreviews.filter(
        (topic) => topic.id !== action.payload.id
      );
    },
    removePrivateForumReply(state, action: PayloadAction<{ id: string }>) {
      if (state.currentPrivateForumTopic) {
        state.currentPrivateForumTopic.replies =
          state.currentPrivateForumTopic?.replies.filter(
            (reply) => reply.id !== action.payload.id
          );
      }
    },
    toggleCheckedAddedMemberId(
      state,
      action: PayloadAction<{ id: string; checked: boolean }>
    ) {
      if (action.payload.checked) {
        state.checkedAddedMembersIds = [
          ...state.checkedAddedMembersIds,
          action.payload.id,
        ];
      } else {
        state.checkedAddedMembersIds = state.checkedAddedMembersIds.filter(
          (id) => id !== action.payload.id
        );
      }
    },
    setCheckedAddedMemberIds(state, action: PayloadAction<string[]>) {
      state.checkedAddedMembersIds = action.payload;
    },
    setFilteredActorUserDetails(
      state,
      action: PayloadAction<ActorsUsersDetails[] | null>
    ) {
      state.filteredActorUserDetails = action.payload;
    },
  },
});

export const {
  setRelatedUsers,
  setHasAddRelationshipError,
  setUserArea,
  setUserAreaLoading,
  setErrorMsg,
  removeRelationship,
  getPrivateForumPreviews,
  getPermissions,
  checkUserLimit,
  setPrivateForumIsLoading,
  getCurrentPrivateForumTopic,
  addPrivateForumReply,
  startAddingReply,
  stopAddingReply,
  removePrivateForumTopic,
  removePrivateForumReply,
  setTabs,
  toggleCheckedAddedMemberId,
  setFilteredActorUserDetails,
  setCheckedAddedMemberIds,
} = userAreaSlice.actions;

export default userAreaSlice.reducer;

export const fetchRelatedUsers =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setUserAreaLoading(true));
      const users = await getRelatedUsersAPI(userId);
      dispatch(setRelatedUsers(users));
      dispatch(setUserAreaLoading(false));
    } catch (err: any) {
      dispatch(setUserAreaLoading(false));
      console.error('Unexpected error', err);
    }
  };

export const fetchTabs =
  ({ userAreaId, groupId }: UserAreaOrGroupId): AppThunk =>
  async (dispatch) => {
    const tabs = await getTabsAPI({ userAreaId, groupId });
    dispatch(setTabs(tabs));
  };

export const addTab =
  (createObj: TabDTO, userOrGroupId: UserAreaOrGroupId): AppThunk =>
  async (dispatch) => {
    try {
      const newTabs = await createTabAPI(createObj, userOrGroupId);
      dispatch(setTabs(newTabs));
    } catch (err: any) {
      dispatch(setErrorMsg('Could not add tab'));
      console.error('Unexpected error', err);
    }
  };

export const updateTab =
  (tabId: string, updateObj: TabDTO): AppThunk =>
  async (dispatch) => {
    try {
      await updateTabAPI(tabId, updateObj);
      dispatch(
        fetchTabs({
          userAreaId: updateObj.privateUserId,
          groupId: updateObj.groupId,
        })
      );
    } catch (err: any) {
      dispatch(setErrorMsg('Could not update tab'));
      console.error('Unexpected error', err);
    }
  };

export const deleteTab =
  (tabId: string, userAreaOrGroupId: UserAreaOrGroupId): AppThunk =>
  async (dispatch) => {
    try {
      await deleteTabAPI(tabId);
      dispatch(fetchTabs(userAreaOrGroupId));
    } catch (err: any) {
      dispatch(setErrorMsg('Could not delete tab'));
      console.error('Unexpected error', err);
    }
  };

export const deleteRelationship =
  (relationshipObj: {
    toUserId: string;
    fromUserId: string;
    actorId: string;
  }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setErrorMsg(''));
      await deleteRelationshipAPI(relationshipObj);
      dispatch(removeRelationship(relationshipObj.fromUserId));
    } catch (err: any) {
      if (err.response?.status === 404 || err.response?.status === 405) {
        console.error('Not authorized to delete relation', err);
        dispatch(
          setErrorMsg(`${getMessage('userArea.members.errorMsgNotAuthorized')}`)
        );
      } else {
        dispatch(setErrorMsg(`${getMessage('handleActors.addActorError')}`));
        console.error('Unexpected error', err);
      }
    }
  };

export const setRelationshipOnUser =
  (fromUser: ActorsUsersDetails, actor: Actor, toUserId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setErrorMsg(''));
      const addRelationship = {
        toUserId,
        fromUserId: fromUser.id,
        actorId: actor.id,
      };

      await setRelationshipOnUserAPI(addRelationship);
      const users = await getRelatedUsersAPI(toUserId);
      dispatch(setRelatedUsers(users));
      dispatch(setHasAddRelationshipError(false));
    } catch (err: any) {
      dispatch(setHasAddRelationshipError(true));
    }
  };

export const fetchPrivateForumPreviews =
  (userOrGroupId: UserAreaOrGroupId, categoryId?: string): AppThunk =>
  async (dispatch, getState) => {
    const activeModules = getState().instance.instance?.activeModules;
    const instanceIsLoading = getState().instance.updateInstanceLoading;

    if (
      !instanceIsLoading &&
      activeModules &&
      activeModules?.includes('Forum')
    ) {
      try {
        dispatch(setPrivateForumIsLoading(true));
        const forumTopics = await getForumTopicPreviews(
          userOrGroupId,
          categoryId
        );
        dispatch(getPrivateForumPreviews(forumTopics));
        dispatch(setPrivateForumIsLoading(false));
      } catch (err: any) {
        console.error(err);
        dispatch(setPrivateForumIsLoading(false));
      }
    }
  };

export const fetchPrivateForumTopic =
  (slug: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setPrivateForumIsLoading(true));
      const currentForumTopic = await getForumTopicFromSlug(slug);
      dispatch(getCurrentPrivateForumTopic(currentForumTopic));
      dispatch(setPrivateForumIsLoading(false));
    } catch (err: any) {
      dispatch(setPrivateForumIsLoading(false));
    }
  };

export const updateCurrentPrivateForumTopic =
  (id: string, topic: ForumTopicUpdate): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setPrivateForumIsLoading(true));
      const currentForumTopic = await updateForumTopicAPI(id, topic);
      dispatch(getCurrentPrivateForumTopic(currentForumTopic));
      dispatch(setPrivateForumIsLoading(false));
    } catch (err: any) {
      dispatch(setPrivateForumIsLoading(false));
    }
  };

export const updatePrivateForumReply =
  (id: string, formattedText: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setPrivateForumIsLoading(true));
      await updateForumReplyAPI(id, formattedText);
      const currentForumTopicSlug =
        getState().userArea.currentPrivateForumTopic?.slug;
      if (currentForumTopicSlug) {
        dispatch(fetchPrivateForumTopic(currentForumTopicSlug));
      }
    } catch (err: any) {
      dispatch(setPrivateForumIsLoading(false));
    }
  };

export const deletePrivateForumTopic =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      await deleteForumTopicAPI(id);
      dispatch(removePrivateForumTopic({ id }));
    } catch (err: any) {
      console.error(err);
    }
  };

export const deletePrivateForumReply =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      await deleteForumReplyAPI(id);
      dispatch(removePrivateForumReply({ id }));
    } catch (err: any) {
      console.error(err);
    }
  };

export const createPrivateForumReply =
  (formattedReply: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(startAddingReply());
      const forumTopicId = (
        getState().userArea.currentPrivateForumTopic as ForumTopic
      ).id;
      const forumReply = await createForumReply(forumTopicId, formattedReply);
      dispatch(addPrivateForumReply(forumReply));
      dispatch(stopAddingReply());
    } catch (err: any) {
      dispatch(setPrivateForumIsLoading(false));
      dispatch(stopAddingReply());
    }
  };

export const fetchPermissions =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const permissions = await getPermissionsForUserAreaAPI(id);
      dispatch(getPermissions(permissions));
    } catch (err: any) {
      console.error(err);
    }
  };

export const checkUserPermissions =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const permissions = await getPermissionsForUserAreaAPI(id);
      dispatch(checkUserLimit(permissions));
    } catch (err: any) {
      console.error(err);
    }
  };

// Selectors

const selectUserAreaState = (rootState: RootState) => rootState.userArea;

export const relatedUsersSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.relatedUsers
);

export const userAreaSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.userArea
);

export const userAreaLoadingSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.userAreaLoading
);

export const deleteRelationErrorMsgSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.errorMsg
);

export const privateForumPreviewsSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.privateForumPreviews
);

export const permissionsForUserAreaSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.permissionsForUserArea
);

export const privateForumIsLoadingSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.privateForumIsLoading
);

export const currentPrivateForumTopicSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.currentPrivateForumTopic
);

export const hasAddRelationshipErrorSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.hasAddRelationshipError
);

export const tabsSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.tabs
);

export const userAreaAddingReplySelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.isAddingReply
);

export const checkedAddedMembersIdsSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.checkedAddedMembersIds
);

export const filteredActorUserDetailsSelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.filteredActorUserDetails
);

export const groupAndCustomerOnlySelector = createSelector(
  selectUserAreaState,
  (userAreaState: UserAreaState) => userAreaState.groupAndCustomerOnly
);
