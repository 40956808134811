import { Box } from "@material-ui/core"
import ChatIcon from '@material-ui/icons/SmsOutlined';
import CourseIcon from './CourseIcon';
import ForumIcon from './ForumIcon';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/InputOutlined';
import { Module } from "../../types";

export const getModuleIcon = (moduleName: Module): JSX.Element => {
    switch (moduleName) {
        case 'External':
            return <InputIcon fontSize='small' />;

        case 'Forum':
            return <ForumIcon fontSize='small' />;

        case 'Course':
            return <CourseIcon fontSize='small' />;

        case 'Notification':
            return <NotificationsOutlinedIcon fontSize='small' />;

        case 'Chat':
            return <ChatIcon fontSize='small' />;

        default:
            return <Box />;
    }
}