import { Box } from "@material-ui/core"
import Categories from "../../components/forum/categories"

type ForumAdminPageProps = {
    userAreaId?: string
}
const ForumAdminPage = ({ userAreaId }: ForumAdminPageProps) => {
    return (
        <Box p={4} maxWidth={480}>
            <Categories userAreaId={userAreaId} />
        </Box>
    )
}

export default ForumAdminPage;