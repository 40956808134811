import {
    Box,
    Typography,
    IconButton,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import InfoIcon from '../icons/InfoIcon';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            minHeight: '64px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '16px',
            borderBottom: `1px solid ${theme.palette.grey[200]}`
        },
        headerTextWrapper: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px'
        },
        headerText: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '360px'
        },
        iconsWrapper: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px'
        },
        hideCloseIcon: {
            opacity: '0%'
        }
    })
);

type CustomChatHeaderInnerProps = {
    headerText: string | JSX.Element,
    onInfo: () => void
}
const CustomChatHeaderInner = ({ headerText, onInfo }: CustomChatHeaderInnerProps) => {
    const classes = useStyles();
    return (
        <Box className={classes.wrapper}>
            <Box className={classes.headerTextWrapper}>
                <Typography variant='subtitle2' className={classes.headerText}>
                    {headerText}
                </Typography>
            </Box>
            <Box className={classes.iconsWrapper}>
                <IconButton onClick={onInfo}>
                    <InfoIcon color='primary' />
                </IconButton>
                <IconButton className={classes.hideCloseIcon}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box >
    )
}

export default CustomChatHeaderInner;
