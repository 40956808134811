import { Box, Button, CircularProgress, Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SafeHtmlRenderer from '../../components/SafeHtmlRenderer';
import {
  termsAndConditionsSelector,
  termsAndConditionsIsLoadingSelector,
  fetchTermsAndConditions,
} from '../../store/userSlice';
import { useHistory } from 'react-router-dom';
import { userSelector } from '../../store/userSlice';

const TermsAndConditionsPage = () => {
  const user = useSelector(userSelector);
  const termsOfCondition = useSelector(termsAndConditionsSelector);
  const termsOfConditionIsLoading = useSelector(
    termsAndConditionsIsLoadingSelector
  );
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!termsOfConditionIsLoading && !termsOfCondition) {
      dispatch(fetchTermsAndConditions());
    }
  });

  if (!termsOfCondition) {
    return <CircularProgress size={64} />;
  }

  return (
    <Container maxWidth="md">
      {user && !user.hasApprovedTermsAndConditions && (
        <Box my={4}>
          <Button
            size="large"
            startIcon={<ArrowBackIcon />}
            onClick={history.goBack}
          >
            Back
          </Button>
        </Box>
      )}
      <SafeHtmlRenderer html={termsOfCondition.termsOfService} />
    </Container>
  );
};

export default TermsAndConditionsPage;
