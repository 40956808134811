import { useSelector } from 'react-redux';
import {
  fetchForumTopic,
  forumTopicSelector,
  addForumReply,
  forumTopicsIsLoadingSelector,
} from '../../store/forumSlice';
import ForumTopic from '../../components/forum/ForumTopic';
import { Box } from '@material-ui/core';

const ForumTopicPage = () => {
  const forumTopic = useSelector(forumTopicSelector);
  const isLoading = useSelector(forumTopicsIsLoadingSelector);

  return (
    <Box p={2}>
      <ForumTopic
        addForumReply={addForumReply}
        fetchTopic={fetchForumTopic}
        forumTopic={forumTopic}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default ForumTopicPage;
