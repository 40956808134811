import { ChipProps, Chip } from "@material-ui/core";

const CustomChip = (props: ChipProps) => {
    return (
        <Chip
            color={props.color || 'secondary'}
            {...props}
        />
    );
};

export default CustomChip;