
import { useState, useEffect, useContext } from 'react';
import {
    Box,
    Paper,
    Theme,
    useTheme,
    createStyles,
    makeStyles,
    IconButton,
    Typography,
    Button
} from '@material-ui/core';
import {
    getChatUser,
    addUserToChat,
    getRelatedChatUsers,
    issueSessionToken
} from '../../api/chat-api';
import {
    instanceUpdateLoading,
    chatApplicationIdSelector,
    activeSubModulesSelector
} from '../../store/instanceSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    ChatUserBackend,
    ChatUserFrontend,
    ChatSessionToken
} from '../../types';
import { WhitelabelContext } from '../../whitelabel-config/WhitelabelProvider';
import {
    SendBirdProvider,
    ChannelList,
    ChannelSettings,
    Channel,
    RenderChatHeaderProps,
    RenderChannelPreviewProps,
    GroupChannelType,
    ClientUserMessage,
} from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import './chat.css';
import CustomChannelListHeader from './CustomChannelHeader';
import CustomChannelProfile from './CustomChannelProfile';
import CustomChatHeaderInner from './CustomChatHeaderInner';
import CustomChannelPreviewInner from './CustomChannelPreviewInner';
import ChatButton from './ChatButton';

import CloseIcon from '@material-ui/icons/Close';
import Message from '../translation/Message';
import { showProfile, userSelector } from '../../store/userSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatContainer: {
            position: 'fixed',
            bottom: 0,
            right: 0,
            marginRight: '32px',
            zIndex: 1220
        },
        chatWidget: {
            width: '816px',
            height: '600px',
            minHeight: '600px',
            border: `1px solid ${theme.palette.grey[200]}`
        },
        chatWrapper: {
            display: 'flex',
            position: 'relative',
            height: '100%'
        },
        channelWrapper: {
            height: '600px',
            width: '100%',
        },
        channelSettingsWrapper: {
            height: '600px',
            position: 'absolute',
            right: 0
        },
        closeIconWrapper: {
            position: 'absolute',
            top: '8px',
            right: 0,
            marginRight: '8px'
        },
        noChatUser: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }
    })
);

const getChatColors = (theme: Theme) => {
    return {
        '--sendbird-light-primary-500': theme.palette.primary.dark,
        '--sendbird-light-primary-400': theme.palette.primary.dark,
        '--sendbird-light-primary-300': theme.palette.primary.main,
        '--sendbird-light-primary-200': theme.palette.primary.main,
        '--sendbird-light-primary-100': theme.palette.secondary.main,
    }
}

class CustomUserPaginatedQuery {
    hasNext: boolean;
    constructor() {
        // Required public property to determine if more data is available.
        this.hasNext = false;
    }

    // Required public property.
    next(callback: (users: ChatUserFrontend[]) => void) {
        getRelatedChatUsers().then((limitedUsers: ChatUserBackend[]) => {
            const mappedUsers: ChatUserFrontend[] = limitedUsers.map((user: ChatUserBackend) => ({
                userId: user.user_id,
                profileUrl: user.profile_url,
                nickname: user.nickname
            }));

            this.hasNext = false;
            callback(mappedUsers);
        });
    }
}
const CustomUserPaginatedQueryFactory = () => new CustomUserPaginatedQuery();

const getMembersString = (channel: GroupChannelType, user: ChatUserBackend | null) => {
    if (!!channel?.members && !!user) {
        let membersString = '';
        const otherMembers = channel.members.filter((member: ChatUserFrontend) => member.userId !== user.user_id);
        const sortedMembers = otherMembers.sort((a: ChatUserFrontend, b: ChatUserFrontend) => a.nickname.localeCompare(b.nickname));
        sortedMembers.forEach((member: ChatUserFrontend, index: number) => {
            membersString = (
                membersString
                + member.nickname
                + `${sortedMembers.length > 1 && index !== sortedMembers.length - 1 ? ', ' : ''}`
            );
        })
        return membersString;
    }
}

const Chat = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showChannelSettings, setShowChannelSettings] = useState<boolean>(false);
    const [chatUser, setChatUser] = useState<ChatUserBackend | null>(null);
    const [currentChannel, setCurrentChannel] = useState<string>('');
    const [sessionToken, setSessionToken] = useState<ChatSessionToken | null>(null);

    const updateInstanceLoading = useSelector(instanceUpdateLoading);
    const activeSubModules = useSelector(activeSubModulesSelector);
    const appId = useSelector(chatApplicationIdSelector);
    const user = useSelector(userSelector);

    const whiteLabelContext = useContext(WhitelabelContext);

    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user && user.userProfile && !chatUser && !updateInstanceLoading) {
            getChatUser()
                .then(getResp => {
                    setChatUser(getResp);
                })
                .catch(error => {
                    addUserToChat()
                        .then(addResp => setChatUser(addResp));
                });
        }
        if (chatUser) {
            issueSessionToken().then(token => setSessionToken(token));
        }
    }, [user, chatUser, updateInstanceLoading]);

    useEffect(() => {
        if (sessionToken) {
            const now = new Date().getTime();
            const milliseconds = sessionToken.expires_at - now - 60000;
            setTimeout(() => {
                issueSessionToken().then(token => {
                    setSessionToken(token);
                })
            }, milliseconds);
        }
    }, [sessionToken]);

    const getSendBirdMessages = () => {
        const { messages } = whiteLabelContext;
        const chatMessages = (messages.chat as Record<string, string>);
        return chatMessages;
    }

    const CustomChatHeader = ({ channel, user }: RenderChatHeaderProps) => {
        return (
            <CustomChatHeaderInner
                headerText={
                    getMembersString(channel, chatUser)
                    || <span>{chatUser?.nickname} <Message id='chat.you' /></span>
                }
                onInfo={() => setShowChannelSettings(true)}
            />
        )
    }

    const CustomChannelPreview = ({ channel }: RenderChannelPreviewProps) => {
        return (
            <CustomChannelPreviewInner
                names={
                    getMembersString(channel, chatUser)
                    || <span>{chatUser?.nickname} <Message id='chat.you' /></span>
                }
                channelUrl={channel.url}
                currentChannelUrl={currentChannel}
                nrOfChannelMembers={channel.members.length}
                lastMessage={(channel.lastMessage as ClientUserMessage)?.message}
                unreadMessageCount={channel.unreadMessageCount}
            />
        )
    }

    return (
        <Box className={classes.chatContainer}>
            {isOpen && chatUser && appId && sessionToken &&
                <SendBirdProvider
                    appId={appId}
                    userId={chatUser.user_id}
                    accessToken={sessionToken.token}
                    nickname={chatUser.nickname}
                    profileUrl={chatUser.profile_url}
                    colorSet={getChatColors(theme)}
                    stringSet={getSendBirdMessages()}
                    userListQuery={
                        activeSubModules?.Chat?.includes('PrivateChat')
                            ? CustomUserPaginatedQueryFactory
                            : undefined
                    }
                >
                    <Paper elevation={2}>
                        <Box className={classes.chatWidget}>
                            <Box className={classes.chatWrapper}>
                                <ChannelList
                                    onChannelSelect={channel => channel ? setCurrentChannel(channel?.url) : () => { }}
                                    renderHeader={CustomChannelListHeader}
                                    renderChannelPreview={CustomChannelPreview}
                                />
                                <Box className={classes.channelWrapper}>
                                    <Channel
                                        channelUrl={currentChannel}
                                        renderChatHeader={CustomChatHeader}
                                    />
                                </Box>
                                {showChannelSettings &&
                                    <Box className={classes.channelSettingsWrapper}>
                                        <ChannelSettings
                                            channelUrl={currentChannel}
                                            onCloseClick={() => setShowChannelSettings(false)}
                                            renderChannelProfile={CustomChannelProfile}
                                        />
                                    </Box>
                                }
                                {!showChannelSettings &&
                                    <Box className={classes.closeIconWrapper}>
                                        <IconButton onClick={() => setIsOpen(false)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Paper>
                </SendBirdProvider>
            }
            {isOpen && !chatUser &&
                <Paper elevation={2}>
                    <Box className={classes.chatWidget}>
                        <Box className={classes.noChatUser}>
                            <Typography>
                                <Message id='chat.chatUserMissing' />
                            </Typography>
                            <Box mt={2}>
                                <Button variant='contained' color='primary' onClick={() => dispatch(showProfile(true))}>
                                    <Message id='nav.update' />
                                </Button>
                            </Box>
                        </Box>
                        <Box className={classes.closeIconWrapper}>
                            <IconButton onClick={() => setIsOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Paper>
            }
            {!isOpen &&
                <ChatButton onClick={() => setIsOpen(true)} />
            }
        </Box>
    )
}

export default Chat;

