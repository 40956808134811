import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  TableRow,
  TableCell,
  makeStyles,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  Collapse,
  Tooltip,
  Typography,
  Slide,
  AppBar,
  Toolbar,
  TextField,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  HourglassFull,
  Close,
  Search,
  CheckCircle,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExtendedQuestionComponent } from '../../components/question/ExtendedQuestionComponent';
import Message from '../../components/translation/Message';
import { fetchAllSessionResults } from '../../store/allResultsSlice';
import { resultStateSelector } from '../../store/selectors';
import { ExtendedResult, QuestionAnswer, Session } from '../../types';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
});

type RowProps = {
  data: ExtendedResult;
  questionAnswers: QuestionAnswer[];
};

export const ViewResultRow = ({ data, questionAnswers }: RowProps) => {
  const [open, setOpen] = useState<boolean>();
  const classes = useRowStyles();

  const renderQuestion = (qa: QuestionAnswer) => {
    return <ExtendedQuestionComponent result={data} qa={qa} />;
  };

  const renderGrade = () => {
    if (data.pass === null && data.totalPoints < data.maxPoints) {
      const message = (
        <Typography style={{ color: 'white', fontSize: 14 }}>
          <Message id="course.waitingForCorrection" />
        </Typography>
      );
      return (
        <Tooltip arrow title={message}>
          <HourglassFull htmlColor="#FFC300" />
        </Tooltip>
      );
    } else {
      return data.pass ? <CheckCircle color="primary" /> : <></>;
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="medium"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1">{data.username}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="subtitle1">
            {data.totalPoints} / {data.maxPoints}
          </Typography>
        </TableCell>
        <TableCell align="left">{renderGrade()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: '#f9f9f9',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={2}>
              {questionAnswers.map((qa, i) => (
                <div key={i}>{renderQuestion(qa)}</div>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

type ViewResultProps = {
  isOpen: boolean;
  sessionId: string;
  session: Session;
  onClose: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ViewResults = (props: ViewResultProps) => {
  const { isOpen, onClose, sessionId, session } = props;
  const resultState = useSelector(resultStateSelector);
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState<string>('');

  const handleClose = () => {
    setSearchInput('');
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchAllSessionResults(sessionId));
    }
  }, [dispatch, sessionId, isOpen]);

  const listToRender = () => {
    if (searchInput === '') {
      return resultState.results!;
    } else {
      return (
        resultState.results?.filter((res) =>
          res.username.toLowerCase().includes(searchInput.toLowerCase())
        ) ?? []
      );
    }
  };

  const renderTable = () => {
    return (
      <Paper>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell width="5%" />
                <TableCell>
                  <Typography variant="subtitle2">
                    <Message id="course.username" />
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    <Message id="course.points" />
                  </Typography>
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultState.results &&
                listToRender().map((row) => (
                  <ViewResultRow
                    key={row.userId}
                    data={row}
                    questionAnswers={session.questionAnswers}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        style: { backgroundColor: '#CBE6DF' },
      }}
    >
      <AppBar position="relative">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose}>
            <Close />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', flex: 1 }}>
            <Message id="course.results" />
          </Typography>
          <TextField
            inputProps={{ style: { color: 'white' } }}
            variant="standard"
            type="search"
            placeholder={getMessage('course.courseAdmin.searchByUserName')}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <Search />
        </Toolbar>
      </AppBar>
      <Box pt={1} pb={1} />
      <DialogContent>
        <Box width="100%" display="flex" justifyContent="space-around">
          {resultState.isLoading && (
            <CircularProgress color="primary" size="5em" />
          )}
        </Box>
        {!resultState.isLoading && renderTable()}
      </DialogContent>
      <Box pb={1} />
    </Dialog>
  );
};
