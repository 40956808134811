import {
  Box,
  createStyles,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNotification,
  removeNotificationsForReferenceId,
} from '../../store/notificationsSlice';
import { Notification } from '../../types';
import Message from '../translation/Message';
import {
  getForumTopicUrl,
  getPrivateForumTopicUrl,
  getCourseUrl,
  getCourseTypeUrl,
  getPrivateVideosUrl,
} from '../../routes/paths';
import { courseTypesSelector } from '../../store/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationRemoveIcon: {
      fontSize: '20px',
    },
    notificationText: {
      marginRight: '8px',
    },
    noNotificationsText: {
      whiteSpace: 'nowrap',
    },
    notificationMenuItem: {
      cursor: 'default',
      display: 'flex',
      justifyContent: 'space-between',
      whiteSpace: 'normal',
      width: '328px',
      borderBottom: '1px solid #D7DADD',
      marginRight: '-8px',
    },
    noNotificationsContainer: {
      width: '328px',
      marginRight: '-8px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
    },
  })
);

type NotificationData = {
  urlPath: string;
  normalText?: (JSX.Element | string)[];
  boldText?: string;
};
type NotificationsProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  notifications: Notification[];
};
const Notifications = ({
  anchorEl,
  onClose,
  notifications,
}: NotificationsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const courseTypes = useSelector(courseTypesSelector);

  const getNotifsGroupedByReference = () => {
    const result: Record<string, Notification[]> = {};
    // Video-notifications shouldn't be grouped by referenceId, others should.
    notifications.forEach((n) => {
      if (
        n.notificationType === 'VideoUploaded' ||
        n.notificationType === 'VideoPublished'
      ) {
        result[n.additionalData + n.notificationType] = [n];
      } else {
        if (!result[n.referenceId]) {
          result[n.referenceId] = [n];
        } else {
          result[n.referenceId].push(n);
        }
      }
    });
    return result;
  };

  const getNotificationData = (
    notification: Notification,
    nrOfNotifications: number
  ): NotificationData | null => {
    switch (notification.notificationType) {
      case 'ForumTopicPublished':
        return {
          urlPath: getForumTopicUrl(notification.referenceSlug),
          normalText: [<Message id="nav.notifications.newForumTopic" />],
          boldText:
            ' ' +
            (notification.referenceTitle ? notification.referenceTitle : ''),
        };

      case 'ForumTopicReply':
        return {
          urlPath: getForumTopicUrl(notification.referenceSlug),
          normalText:
            nrOfNotifications > 1
              ? [
                  <Message id="nav.notifications.forumTopicBeforeNr" />,
                  '' + nrOfNotifications,
                  <Message id="nav.notifications.forumTopicAfterNr" />,
                ]
              : [<Message id="nav.notifications.forumTopicSingle" />],
          boldText:
            ' ' +
            (notification.referenceTitle ? notification.referenceTitle : ''),
        };

      case 'PrivateForumTopicPublished':
      case 'GroupForumTopicPublished':
        return {
          urlPath: getPrivateForumTopicUrl(
            notification.referenceSlug,
            notification.secondReferenceId
          ),
          normalText: [<Message id="nav.notifications.newForumTopic" />],
          boldText:
            ' ' +
            (notification.referenceTitle ? notification.referenceTitle : ''),
        };

      case 'PrivateForumTopicReply':
      case 'GroupForumTopicReply':
        return {
          urlPath: getPrivateForumTopicUrl(
            notification.referenceSlug,
            notification.secondReferenceId
          ),
          normalText:
            nrOfNotifications > 1
              ? [
                  <Message id="nav.notifications.forumTopicBeforeNr" />,
                  '' + nrOfNotifications,
                  <Message id="nav.notifications.forumTopicAfterNr" />,
                ]
              : [<Message id="nav.notifications.forumTopicSingle" />],
          boldText:
            ' ' +
            (notification.referenceTitle ? notification.referenceTitle : ''),
        };

      case 'CourseTypePublished':
        return {
          urlPath: getCourseTypeUrl(notification.referenceTitle),
          normalText: [<Message id="nav.notifications.newCourseType" />],
          boldText:
            ' ' +
            (notification.referenceTitle ? notification.referenceTitle : ''),
        };

      case 'CoursePublished':
        if (
          notification.secondReferenceId ===
          '00000000-0000-0000-0000-000000000000'
        ) {
          return {
            urlPath: getCourseUrl(notification.referenceSlug),
            normalText: [<Message id="nav.notifications.newCourse" />],
            boldText:
              ' ' +
              (notification.referenceTitle ? notification.referenceTitle : ''),
          };
        } else {
          return {
            urlPath: getCourseUrl(notification.referenceSlug),
            normalText: [
              <Message id="nav.notifications.newTypedCourse" />,
              ' ' + notification.additionalData,
            ],
            boldText:
              ' ' +
              (notification.referenceTitle ? notification.referenceTitle : ''),
          };
        }

      case 'MandatoryCourseAssigned':
        return {
          urlPath: getCourseUrl(notification.referenceSlug),
          normalText: [<Message id="nav.notifications.mandatoryAssigned" />],
          boldText:
            ' ' +
            (notification.referenceTitle ? notification.referenceTitle : ''),
        };

      case 'CourseReset':
        return {
          urlPath: getCourseUrl(notification.referenceSlug),
          normalText: [<Message id="nav.notifications.courseReset" />],
          boldText:
            ' ' +
            (notification.referenceTitle ? notification.referenceTitle : ''),
        };

      case 'PrivateCoursePublished':
      case 'GroupCoursePublished':
        if (
          notification.secondReferenceId ===
          '00000000-0000-0000-0000-000000000000'
        ) {
          return {
            urlPath: getCourseUrl(notification.referenceSlug),
            normalText: [<Message id="nav.notifications.newCourse" />],
            boldText:
              ' ' +
              (notification.referenceTitle ? notification.referenceTitle : ''),
          };
        } else {
          const courseType = courseTypes?.find(
            (ct) => ct.courseType.id === notification.secondReferenceId
          );
          return {
            urlPath: getCourseUrl(notification.referenceSlug),
            normalText: [
              <Message id="nav.notifications.newTypedCourse" />,
              ' ' + courseType?.courseType.name,
            ],
            boldText:
              ' ' +
              (notification.referenceTitle ? notification.referenceTitle : ''),
          };
        }

      case 'VideoUploaded':
      case 'GroupVideoUploaded':
        return {
          urlPath: getPrivateVideosUrl(
            notification.secondReferenceId,
            notification.referenceId
          ),
          normalText: [<Message id="nav.notifications.videoUploaded" />],
          boldText:
            ' ' +
            (notification.referenceTitle ? notification.referenceTitle : ''),
        };

      case 'VideoPublished':
      case 'GroupVideoPublished':
        return {
          urlPath: getPrivateVideosUrl(
            notification.secondReferenceId,
            notification.referenceId
          ),
          normalText: [<Message id="nav.notifications.videoPublished" />],
          boldText:
            ' ' +
            (notification.referenceTitle ? notification.referenceTitle : ''),
        };

      default:
        return null;
    }
  };

  const handleRemoveNotifications = (notifications: Notification[]) => {
    if (notifications[0].notificationType === 'CourseTypePublished') {
      handleDeleteNotifications(notifications);
    } else {
      dispatch(removeNotificationsForReferenceId(notifications[0].referenceId));
    }
    onClose();
  };

  const handleDeleteNotifications = (notificationsToDelete: Notification[]) => {
    notificationsToDelete.forEach((notification) => {
      dispatch(deleteNotification(notification));
    });
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="notification-menu"
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={!!anchorEl}
      onClose={onClose}
      PaperProps={{ style: { maxHeight: `400px` } }}
    >
      {notifications.length > 0 &&
        Object.entries(getNotifsGroupedByReference())
          .reverse()
          .map((entry) => {
            const notificationListForRefId = entry[1];
            const data = getNotificationData(
              notificationListForRefId[0],
              notificationListForRefId.length
            );
            if (data) {
              return (
                <MenuItem
                  key={notificationListForRefId[0].id}
                  className={classes.notificationMenuItem}
                >
                  <Link
                    component={RouterLink}
                    className={classes.notificationText}
                    variant="inherit"
                    color="inherit"
                    to={data.urlPath}
                    onClick={() =>
                      handleRemoveNotifications(notificationListForRefId)
                    }
                  >
                    <Typography variant="body2">
                      {data &&
                        data.normalText?.map(
                          (text: JSX.Element | string, index: number) => (
                            <span key={index}>{text}</span>
                          )
                        )}
                    </Typography>
                    <Typography variant="subtitle2" color="inherit">
                      {data.boldText}
                    </Typography>
                  </Link>
                  <IconButton
                    color="inherit"
                    aria-label="remove notification"
                    onClick={() =>
                      handleDeleteNotifications(notificationListForRefId)
                    }
                  >
                    <HighlightOffIcon
                      className={classes.notificationRemoveIcon}
                    />
                  </IconButton>
                </MenuItem>
              );
            } else {
              return <Box />;
            }
          })}
      {notifications.length === 0 && (
        <Box pl={2} pr={2}>
          <Box className={classes.noNotificationsContainer}>
            <Typography className={classes.noNotificationsText}>
              <Message id="nav.notifications.noNotifications" />
            </Typography>
          </Box>
        </Box>
      )}
    </Menu>
  );
};

export default Notifications;
