import { useSelector } from 'react-redux';
import {
  fetchPrivateForumTopic,
  createPrivateForumReply,
  currentPrivateForumTopicSelector,
  privateForumIsLoadingSelector,
} from '../../../store/userAreaSlice';
import ForumTopic from '../../../components/forum/ForumTopic';
import Box from '@material-ui/core/Box';

const PrivateTopicPage = () => {
  const forumTopic = useSelector(currentPrivateForumTopicSelector);
  const isLoading = useSelector(privateForumIsLoadingSelector);

  return (
    <Box maxWidth='630px'>
      <ForumTopic
        addForumReply={createPrivateForumReply}
        fetchTopic={fetchPrivateForumTopic}
        forumTopic={forumTopic}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default PrivateTopicPage;
