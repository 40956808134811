import {
  Container,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centeredContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(5, 0),
      textAlign: 'center',
    },
  })
);

const NotAuthenticated = () => {
  const classes = useStyles();

  const getMessage = () => {
    const regex = new RegExp('message=(.*)');
    const match = regex.exec(decodeURI(window.location.search));
    if (match !== null && match.length > 1) {
      return match[1];
    } else {
      return 'You are not authenticated';
    }
  };

  const getMoreInfo = () => {
    if (getMessage() === 'No matching state found in storage') {
      return 'The URL you are using is no longer valid. Please try again.';
    }
    return;
  };

  return (
    <Container>
      <main className={classes.centeredContainer}>
        <Typography variant="h2" component="h1" gutterBottom>
          Access denied
        </Typography>
        <Box
          padding={2}
          bgcolor={'#FFBCBC'}
          borderColor={'#000000'}
          border={2}
          borderRadius={'10px'}
        >
          <Typography variant="subtitle1" component="p" gutterBottom>
            {getMessage()}
          </Typography>
          {getMoreInfo() && (
            <Typography variant="subtitle1" component="p" gutterBottom>
              {getMoreInfo()}
            </Typography>
          )}
        </Box>
        <Box mt={3} padding={1} borderRadius={'15px'}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.replace(window.location.origin)}
          >
            Retry
          </Button>
        </Box>
      </main>
    </Container>
  );
};

export default NotAuthenticated;
