import { chatHttpClient as httpClient } from './httpClient';
import { ChatUserBackend, ChatSessionToken } from '../types';

export const addUserToChat = async (): Promise<ChatUserBackend> => {
  const response = await httpClient.post(`chat/user`);
  return response.data;
};

export const getChatUser = async (): Promise<ChatUserBackend> => {
  const response = await httpClient.get(`chat/user`);
  return response.data;
};

export const issueSessionToken = async (): Promise<ChatSessionToken> => {
  const response = await httpClient.post(`chat/sessiontoken`);
  return response.data;
};

export const getRelatedChatUsers = async (): Promise<ChatUserBackend[]> => {
  const response = await httpClient.get(`chat/relatedusers`);
  return response.data;
};

export const getUnreadMessageCount = async (): Promise<number> => {
  const response = await httpClient.get(`chat/user/unreadmessagecount`);
  return response.data;
};
