import { LoginStatistic, Statistic } from "../../types";
import { getDaysInMonth } from "../../utils";
import _groupBy from 'lodash/groupBy';

export const getLoginStatisticsInMonth = (stats: Statistic[], month: number) => {
    const loginStats = stats.filter(s =>
        s.eventType === 'LOGGED_IN'
        && new Date(s.timeOfEvent).getMonth() === month
    ).map(stat => ({
        ...stat,
        day: new Date(stat.timeOfEvent).getDate().toString()
    }));
    const groupedLogins = _groupBy(loginStats, 'day');
    const dateOfStats = new Date(loginStats[0]?.timeOfEvent);
    const daysInTheMonth = getDaysInMonth(dateOfStats.getMonth(), dateOfStats.getFullYear());

    const result: LoginStatistic[] = [];
    for (let index = 1; index <= daysInTheMonth; index++) {
        result.push({
            day: index,
            amount: groupedLogins[index] ? groupedLogins[index].length : 0
        })
    }
    return result;
}
