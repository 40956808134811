import {
    Dialog,
    DialogContent,
    Box,
    DialogContentText,
    LinearProgress,
    DialogActions,
    Button
} from "@material-ui/core";
import Message from "../../components/translation/Message";

type SaveActiveModulesDialogProps = {
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
}
const SaveActiveModulesDialog = ({
    isOpen,
    isLoading,
    onClose
}: SaveActiveModulesDialogProps) => {
    return (
        <Dialog open={isOpen} fullWidth={true} maxWidth='sm'>
            <DialogContent>
                {isLoading ? (
                    <Box>
                        <DialogContentText>
                            <Message id="siteSettings.settingsUpdating" />
                        </DialogContentText>
                        <LinearProgress />
                    </Box>
                ) : (
                    <Box display='flex'>
                        <DialogContentText>
                            <Message id="siteSettings.settingsSaved" />
                        </DialogContentText>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                    disabled={isLoading}
                >
                    <Message id="siteSettings.settingsDialogueBtn" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SaveActiveModulesDialog;