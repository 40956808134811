import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Theme,
  IconButton,
} from '@material-ui/core';
import Message from '../translation/Message';
import HtmlEditor from '../HtmlEditor';
import { WhitelabelContext } from '../../whitelabel-config/WhitelabelProvider';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getForumTopicUrl, getPrivateForumTopicUrl } from '../../routes/paths';
import { createForumTopic } from '../../api/forum-api';
import { forumCategoriesSelector } from '../../store/forumCategoriesSlice';
import mixpanel from 'mixpanel-browser';
import PageOrSectionHeader from '../typography/PageOrSectionHeader';
import BackIcon from '../../components/icons/BackIcon';
import { Link } from 'react-router-dom';
import { getMainPageUrl } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subject: {
      width: '32em',
    },
    categories: {
      width: '16em',
    },
    errorText: {
      color: theme.palette.error.main,
    },
    editorContaier: {
      minHeight: '508px',
      marginBottom: '60px',
    },
  })
);

type CreateTopicProps = {
  userAreaId?: string;
  groupId?: string;
};
const CreateNewTopic = ({ userAreaId, groupId }: CreateTopicProps) => {
  const [subject, setSubject] = useState<string>('');
  const [forumCategory, setForumCategory] = useState<string>('');
  const [innerText, setInnerText] = useState<string>('');
  const [helperText, setHelperText] = useState('');
  const [subjectHelperText, setSubjectHelperText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { getMessage } = React.useContext(WhitelabelContext);

  const categories = useSelector(forumCategoriesSelector);

  const history = useHistory();
  const classes = useStyles();

  const main = getMainPageUrl(history.location.pathname, userAreaId);

  const handleSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject((event.target as HTMLInputElement).value);
  };

  const handleCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    setForumCategory(event.target.value as string);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (innerText.length < 10) {
      setHelperText(getMessage('forum.createTopicPage.descriptionHelper'));
    } else {
      setHelperText('');
    }

    if (subject.length < 5) {
      setSubjectHelperText(getMessage('forum.createTopicPage.subjectHelper'));
    } else {
      setSubjectHelperText('');
    }

    if (innerText.length >= 10 && subject.length >= 5) {
      setIsSubmitting(true);
      const newTopic = {
        subject: subject,
        categoryId: forumCategory,
        formattedText: innerText,
        privateForumUserId: userAreaId,
        groupId: groupId,
      };

      createForumTopic(newTopic).then((topic) => {
        setIsSubmitting(false);

        if (userAreaId) {
          mixpanel.track('POST_IN_PRIVATE_FORUM', {
            userAreaId: userAreaId,
            topicId: topic.id,
          });
        }

        if (userAreaId || groupId) {
          history.push(
            getPrivateForumTopicUrl(topic.slug, userAreaId || groupId || '')
          );
        } else {
          history.push(getForumTopicUrl(topic.slug));
        }
      });
    }
  };

  return (
    <Box p={{ xs: 1, md: 4 }} maxWidth={800}>
      <Box mb={2} display="flex" alignItems="center" width="100%">
        <IconButton component={Link} to={main}>
          <BackIcon />
        </IconButton>
        <PageOrSectionHeader noGutter>
          <Message id="forum.createTopicPage.createForumTopic" />
        </PageOrSectionHeader>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box mb={2} mt={2}>
          <FormControl>
            <TextField
              label={<Message id="forum.overviewPage.titleSubject" />}
              required
              variant="outlined"
              fullWidth
              className={classes.subject}
              onChange={handleSubject}
            />
            <FormHelperText className={classes.errorText}>
              {subjectHelperText || ' '}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box mb={5}>
          <FormControl variant="outlined" required>
            <InputLabel>
              <Message id="forum.overviewPage.titleCategory" />
            </InputLabel>
            <Select
              label="Category"
              value={forumCategory}
              onChange={handleCategory}
              className={classes.categories}
            >
              {categories?.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.editorContaier}>
          <FormControl>
            <HtmlEditor
              onChange={setInnerText}
              privateForumUserId={userAreaId}
            />
            <FormHelperText className={classes.errorText}>
              {helperText || ' '}
            </FormHelperText>
          </FormControl>
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="end"
            pt={1}
          >
            <Button variant="contained" onClick={() => history.goBack()}>
              <Message id="forum.createTopicPage.cancel" />
            </Button>
            <Box mr={1} />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              <Message id="forum.createTopicPage.submitTopic" />
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default CreateNewTopic;
