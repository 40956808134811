const baseUrl = '/';

export const homeUrl = baseUrl;

export const coursesUrl = `${baseUrl}masterclasses`;

export const courseUrl = `${baseUrl}masterclass/:slug`;

export const getCourseUrl = (slug: string) => {
  return `${baseUrl}masterclass/${slug}/`;
};

export const sessionUrl = `${courseUrl}/:id`;

export const getSessionUrl = (slug: string, sessionId: string) => {
  return `${getCourseUrl(slug)}${sessionId}`;
};

export const courseTypeUrl = `${coursesUrl}/:courseType`;

export const getCourseTypeUrl = (courseType: string) => {
  if (!courseType) return `${coursesUrl}`;
  const courseTypeURL = courseType.split(' ').join('-');
  return `${coursesUrl}/${courseTypeURL}`;
};

export const courseAdminDashboardUrl = `${baseUrl}masterclass-admin`;

export const editCourseUrl = `${courseAdminDashboardUrl}/edit-masterclass/:slug`;

export const getEditCourseUrl = (slug: string) => {
  return `${courseAdminDashboardUrl}/edit-masterclass/${slug}/`;
};

export const editSessionUrl = `${editCourseUrl}/:id`;

export const getEditSessionUrl = (slug: string, sessionId: string) => {
  return `${getEditCourseUrl(slug)}${sessionId}`;
};

export const editProvidersDashboardUrl = `${courseAdminDashboardUrl}/providers`;

export const editProviderUrl = `${courseAdminDashboardUrl}/edit-provider/:id`;

export const getEditProviderUrl = (id: string) => {
  return `${courseAdminDashboardUrl}/edit-provider/${id}/`;
};

export const forumUrl = `${baseUrl}forum`;

export const forumCreateTopicUrl = `${forumUrl}/create-topic`;

export const forumTopicUrl = `${forumUrl}/:slug`;

export const getForumTopicUrl = (slug: string) => {
  return `${forumUrl}/${slug}/`;
};
export const forumAdminPage = `${baseUrl}forum-admin`;

export const termsOfServiceUrl = `${baseUrl}terms-of-service`;

export const privacyPolicyUrl = `${baseUrl}privacy-policy`;

export const acceptableUsePolicyUrl = `${baseUrl}acceptable-use`;

export const cookiePolicyUrl = `${baseUrl}cookie-policy`;

export const handleUsersUrl = `${baseUrl}handle-users`;

export const homeAdminPageUrl = `${baseUrl}home-admin`;

export const siteSettingsPageUrl = `${baseUrl}settings`;

export const contentSetingsPageUrl = `${baseUrl}content-settings`;

export const actorsAdminPageUrl = `${baseUrl}handle-actors`;

export const statisticsPageUrl = `${baseUrl}activity-statistics/`;

export const allCoursesPageUrl = `${baseUrl}handle-user-courses`;

export const statisticsOverviewPageUrl = `${statisticsPageUrl}overview`;

export const statisticsUsersPageUrl = `${statisticsPageUrl}users`;

export const userAreaPageUrl = `${baseUrl}user-area/:id`;

export const getUserAreaPageUrl = (id: string) => {
  return `${baseUrl}user-area/${id}/`;
};

export const actorsPageUrl = `${userAreaPageUrl}/actors`;

export const privateForumUrl = `${userAreaPageUrl}/privateForum`;

export const getPrivateForumUrl = (id: string) =>
  `${getUserAreaPageUrl(id)}privateForum`;

export const privateForumTopicUrl = `${privateForumUrl}/:slug`;

export const getPrivateForumTopicUrl = (slug: string, id: string) => {
  return `${getPrivateForumUrl(id)}/${slug}`;
};

export const privateCreateTopicUrl = `${privateForumUrl}/create-topic`;

export const getPrivateCreateTopicUrl = (id: string) =>
  `${getPrivateForumUrl(id)}/create-topic`;

export const privateMasterClassUrl = `${userAreaPageUrl}/tab/:tabId`;
export const getPrivateMasterClassUrl = (id: string, tabId: string) =>
  `${getUserAreaPageUrl(id)}tab/${tabId}`;

export const privateVideosUrl = `${privateMasterClassUrl}/videos/`;
export const getPrivateVideosUrl = (id: string, tabId: string) =>
  `${getPrivateMasterClassUrl(id, tabId)}/videos/`;

export const privateSettingsUrl = `${userAreaPageUrl}/settings`;

export const newPrivateGroupUrl = `${baseUrl}new-group`;