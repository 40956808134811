import { Session, SimplifiedCourse } from '../../../types';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import Message from '../../../components/translation/Message';
import { getEditSessionUrl } from '../../../routes/paths';
import { Link } from 'react-router-dom';
import DeleteButton from '../../../components/DeleteButton';
import { getMessage } from '../../../whitelabel-config/WhitelabelProvider';
import React from 'react';
import { LinkRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sessionLink: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    flexColumnCenter: {
      display: 'flex',
      flexDirection: 'column',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    documentDivider: {
      margin: theme.spacing(1, 0),
    },
    title: {
      marginTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  })
);

type SessionProps = {
  session: Session;
  course: SimplifiedCourse | undefined;
  onDisconnectSession: (session: Session) => void;
};

const BorrowedSessionSubPage = ({
  session,
  course,
  onDisconnectSession,
}: SessionProps) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.flexCenter} justifyContent="space-between" mb={4}>
        <Typography variant={'h5'} component={'h2'} className={classes.title}>
          {session.name}
        </Typography>
        <DeleteButton
          itemCategory={getMessage('course.session')}
          itemName={session.name}
          onDelete={() => onDisconnectSession(session)}
          dialogTitle={getMessage(
            'course.courseAdmin.editCoursePage.deleteConnection'
          )}
        />
      </Box>

      <Box justifyContent="space-between" mb={4}>
        <Typography variant="subtitle2">
          <Message
            id={'course.courseAdmin.editCoursePage.sessionBelongsToCourse'}
          />
          {course?.name}
        </Typography>
        <Message id={'course.courseAdmin.editCoursePage.editInCourse'} />
      </Box>

      <Box maxWidth={800} mb={6}>
        {course && (
          <Link
            to={`${getEditSessionUrl(course.slug, session.id)}`}
            key={session.id}
            className={classes.sessionLink}
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<LinkRounded />}
            >
              <Message id={'course.courseAdmin.editCoursePage.goToOriginal'} />
            </Button>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default BorrowedSessionSubPage;
