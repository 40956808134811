import {
    Box,
    Card,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import Message from "../../../components/translation/Message";
import theme from "../../../theme";
import { VideoData } from "../../../types";
import Video from "./Video";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        videoCard: {
            cursor: 'pointer',
            width: '100%',
            maxWidth: 840,
            margin: theme.spacing(1),
            '&:hover': {
                backgroundColor: theme.palette.grey[100]
            }
        },
        published: {

        },
        notPublished: {
            backgroundColor: theme.palette.grey[100],
            '&:hover': {
                backgroundColor: theme.palette.grey[200]
            }
        },
        truncateText: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: 'inherit'
        }
    })
);

type VideoCardWithMetaDataProps = {
    video: VideoData,
    canPublish?: boolean,
    onClick: () => void
}
const ViewCardWithMetaData = ({
    video,
    canPublish = false,
    onClick
}: VideoCardWithMetaDataProps) => {
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const getReceiver = () => {
        if (window.location.origin.includes('localhost')) {
            return "https://dev.webapp.conductme.com/";
        }
        return window.location.origin;
    }

    return (
        <Card
            variant='outlined'
            className={`${classes.videoCard} ${video.isPublished
                ? classes.published
                : classes.notPublished}`
            }
            onClick={onClick}
        >
            <Box display='flex' justifyContent='space-between' width='100%'>
                <Box display='flex'>
                    <Box width={240} minWidth={240} height={135}>
                        <Video
                            videoUrl={getReceiver() + video.filePath}
                            canDownload={false}
                            previewOnly
                        />
                    </Box>
                    <Box m={1} display='flex' flexDirection='column' justifyContent='space-between'>
                        <Box width={isSm ? 186 : 340}>
                            <Typography variant='subtitle2' className={classes.truncateText}>
                                {video.fileName}
                            </Typography>
                            {video.isPublished && video.publishedAt &&
                                <Typography variant='caption'>
                                    <Message id='userArea.videos.published' />
                                    {new Date(video.publishedAt).toLocaleString().slice(0, 17)}
                                </Typography>
                            }
                            {!video.isPublished &&
                                <Typography variant='caption'>
                                    <Message id='userArea.videos.uploaded' />
                                    {new Date(video.uploadedAt).toLocaleString().slice(0, 17)}
                                </Typography>
                            }
                        </Box>
                        <Typography variant='caption'>
                            <Message id='userArea.videos.by' />
                            {video.uploadedBy.name}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    display='flex' flexDirection='column' alignItems='space-between' justifyContent='center'
                    width={80} m={3}
                >
                    {video.isPublished &&
                        <Play />
                    }
                    {!video.isPublished &&
                        <PreviewAndPublish canPublish={canPublish} />
                    }
                </Box>
            </Box>
        </Card >
    )
}

export default ViewCardWithMetaData;

const Play = () => {
    return (
        <Box
            p={2}
            display='flex' justifyContent='center' alignItems='center'
        >
            <PlayIcon />
            <Box mr={1} />
            <Typography variant='button' color='textPrimary'>
                <Message id='userArea.videos.play' />
            </Typography>
        </Box>
    )
}

const PreviewAndPublish = ({ canPublish }: { canPublish: boolean }) => {
    return (
        <Box
            p={2}
            display='flex' justifyContent='center' alignItems='center'
        >
            <Typography variant='button' color='textPrimary' align='center'>
                {canPublish
                    ? <Message id='userArea.videos.previewAndPublish' />
                    : <Message id='userArea.videos.preview' />}
            </Typography>
        </Box>
    )
}