import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    makeStyles,
    createStyles,
    Theme,
} from '@material-ui/core';
import {
    actorsSelector,
    fetchActors,
} from '../../store/actorsSlice';
import Message from '../translation/Message';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingBottom: '1em',
            width: '100%'
        },
        selectlabel: {
            position: 'absolute',
            top: '-7px',
            left: '14px'
        },
        select: {
            padding: '1em',
        },
    })
);

type ActorsDropdownProps = {
    value: any,
    onChange: any,
    multiple?: boolean,
}
const ActorsDropdown = ({
    value,
    onChange,
    multiple
}: ActorsDropdownProps) => {
    const actors = useSelector(actorsSelector);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        if (!actors) {
            dispatch(fetchActors());
        }
    }, [dispatch, actors]);

    return (
        <Box className={classes.container}>
            {actors && actors.length >= 2 && (
                <FormControl fullWidth>
                    <InputLabel className={classes.selectlabel}>
                        <Message id='statistics.actors' />
                    </InputLabel>
                    <Select
                        label={getMessage('statistics.actors')}
                        value={value}
                        onChange={(event) => onChange(event.target.value)}
                        variant='outlined'
                        classes={{ outlined: classes.select }}
                        displayEmpty
                        multiple={multiple}
                    >
                        <MenuItem value='Show all actors'>
                            <Message id='statistics.showAllActors' />
                        </MenuItem>
                        {actors.map((actor) => (
                            <MenuItem key={actor.id} value={actor.id}>
                                {actor.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </Box>
    )
}

export default ActorsDropdown;
