import React, { useState, useEffect } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '../store/userSlice';
import * as paths from '../routes/paths';
import { approveTermsAndConditions } from '../store/userSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGroup: {
      fontWeight: 700,
      margin: theme.spacing(4, 0),
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const TermsAndConditionsDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [approveState, setApproveState] = useState({
    checkedPrivacyPolicy: false,
    checkedAcceptableUsePolicy: false,
    checkedCookiePolicy: false,
  });

  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const location = useLocation();
  const isPrivacyPolicy = matchPath(location.pathname, {
    path: paths.privacyPolicyUrl,
  });
  const isAcceptableUsePolicy = matchPath(location.pathname, {
    path: paths.acceptableUsePolicyUrl,
  });
  const isCookiePolicy = matchPath(location.pathname, {
    path: paths.cookiePolicyUrl,
  });

  useEffect(() => {
    if (user && !user.hasApprovedTermsAndConditions) {
      if (isPrivacyPolicy || isAcceptableUsePolicy || isCookiePolicy) {
        setOpen(false);
      } else if (!open) {
        setOpen(true);
      }
    } else if (user && user.hasApprovedTermsAndConditions) {
      setOpen(false);
    }
  }, [user, open, isPrivacyPolicy, isAcceptableUsePolicy, isCookiePolicy]);

  const handleOk = () => {
    if (
      approveState.checkedPrivacyPolicy &&
      approveState.checkedAcceptableUsePolicy &&
      approveState.checkedCookiePolicy
    ) {
      dispatch(approveTermsAndConditions());
      setIsLoading(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApproveState({
      ...approveState,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        Terms and Conditions
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          To use our service you need to accept the Acceptable Use Policy,
          Privacy Policy, and Cookie Policy.
        </Typography>
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            control={
              <Switch
                checked={approveState.checkedPrivacyPolicy}
                onChange={handleChange}
                name="checkedPrivacyPolicy"
                color="primary"
              />
            }
            label={
              <Typography>
                I accept the{' '}
                <Link to={paths.privacyPolicyUrl}>privacy policy</Link>
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={approveState.checkedAcceptableUsePolicy}
                onChange={handleChange}
                name="checkedAcceptableUsePolicy"
                color="primary"
              />
            }
            label={
              <Typography>
                I accept the{' '}
                <Link to={paths.acceptableUsePolicyUrl}>
                  acceptable use policy
                </Link>
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={approveState.checkedCookiePolicy}
                onChange={handleChange}
                name="checkedCookiePolicy"
                color="primary"
              />
            }
            label={
              <Typography>
                I accept the{' '}
                <Link to={paths.cookiePolicyUrl}>cookie policy</Link>
              </Typography>
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleOk}
          >
            Save
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
