import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Box,
  Collapse,
  IconButton,
  makeStyles,
  TableContainer,
  Tooltip,
  Select,
  MenuItem,
} from '@material-ui/core';

import {
  Check,
  KeyboardArrowDown,
  KeyboardArrowRight,
  NoteAdd,
  PriorityHigh,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMandatoryCourses } from '../../api/course-api';
import Message from '../../components/translation/Message';
import {
  fetchAllUsersCourseResults,
  updateAllUsersCoursesResults,
} from '../../store/allResultsSlice';
import { fetchAllExamCoursesSimplified } from '../../store/coursesSlice';
import {
  allExamCoursesSimplifiedSelector,
  resultStateSelector,
} from '../../store/selectors';
import { CourseResult, SimplifiedCourse, UserCourseResult } from '../../types';
import { ChangeMandatoryDialog } from './ChangeMandatoryDialog';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
});

type UserCourseTableRowProps = {
  data: UserCourseResult;
  onMandatoryChange: (mandatory: SimplifiedCourse[], userId: string) => void;
};

export const renderMandatoryIcon = (result: CourseResult) => {
  if (result.totalPoints === result.maxPoints) {
    return (
      <Tooltip
        title={
          <Typography style={{ color: 'white', fontSize: 14 }}>
            <Message id="handleUserCourses.tooltipComplete" />
          </Typography>
        }
      >
        <Check color="primary" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        title={
          <Typography style={{ color: 'white', fontSize: 14 }}>
            <Message id="handleUserCourses.tooltipNotComplete" />
          </Typography>
        }
      >
        <PriorityHigh color="primary" />
      </Tooltip>
    );
  }
};

export const UserCourseTableRow = ({
  data,
  onMandatoryChange,
}: UserCourseTableRowProps) => {
  const classes = useRowStyles();
  const [open, setOpen] = useState<boolean>();
  const [showMandatory, setShowMandatory] = useState<boolean>(false);

  const getTimestamp = (dateStr: string) => {
    const [dateComp, timeCompz] = dateStr.split('T');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [timeComp, rest] = timeCompz.split('.');
    return `${dateComp} ${timeComp}`;
  };

  const countStartedMandatory = () => {
    return data.courseResults.filter((course) => course.isMandatory).length;
  };

  const handleMandatorySave = async (newCourses: SimplifiedCourse[]) => {
    const response = await updateMandatoryCourses(newCourses, data.userId);
    if (response.status === 200) {
      onMandatoryChange(response.data, data.userId);
    }
    setShowMandatory(false);
  };

  const renderStartedCourses = () => {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="5%">
              <Typography variant="subtitle2">
                <Message id="handleUserCourses.mandatoryShort" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">
                <Message id="handleUserCourses.carriedOutCourses" />
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2">
                <Message id="handleUserCourses.points" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">
                <Message id="handleUserCourses.passed" />
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.courseResults.map((result, i) => {
            return (
              <TableRow key={`startedcourses_row_${i}`}>
                <TableCell>
                  {result.isMandatory ? renderMandatoryIcon(result) : <></>}
                </TableCell>
                <TableCell>{result.courseName}</TableCell>
                <TableCell align="right">
                  {result.totalPoints}/{result.maxPoints}
                </TableCell>
                <TableCell>
                  {result.datePassed &&
                    (result.datesPassed === undefined ||
                      result.datesPassed.length === 0) && (
                      <Typography>{getTimestamp(result.datePassed)}</Typography>
                    )}
                  {result.datesPassed && result.datesPassed.length > 0 && (
                    <Select key={`startedcourses_select_${i}`} value={0}>
                      {result.datesPassed.map((d, j) => (
                        <MenuItem key={`s_c_dp_${j}`} value={j}>
                          {getTimestamp(d)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const renderNotStartedCourses = () => {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="5%">
              <Typography variant="subtitle2">
                <Message id="handleUserCourses.mandatoryShort" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">
                <Message id="handleUserCourses.mandatoryCouresNotStarted" />
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow />
        </TableHead>
        <TableBody>
          {data.notStarted.map((course, i) => (
            <TableRow key={`notstartedcourses_row_${i}`}>
              <TableCell>
                {course.isMandatory ? (
                  <PriorityHigh fontSize="small" color="primary" />
                ) : (
                  <></>
                )}
              </TableCell>
              <TableCell>{course.courseName}</TableCell>
              <TableCell>
                {course.datesPassed && course.datesPassed.length > 0 && (
                  <Select key={`notstartedcourses_select_${i}`} value={0}>
                    <MenuItem value={0}>
                      <Typography variant="subtitle2">
                        <Message id="handleUserCourses.passedDates" />
                      </Typography>
                    </MenuItem>
                    {course.datesPassed.map((d, j) => (
                      <MenuItem key={`ns_c_dp_${j}`} value={j + 1}>
                        {getTimestamp(d)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="medium"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1">{data.username}</Typography>
        </TableCell>
        <TableCell>{data.roles.join(', ')}</TableCell>
        <TableCell align="left">
          <Typography variant="subtitle1">
            {data.courseResults.length}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle1">
            {countStartedMandatory()} /{' '}
            {countStartedMandatory() + data.notStarted.length}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <IconButton
            style={{ color: 'black' }}
            onClick={() => setShowMandatory(true)}
          >
            <NoteAdd />
          </IconButton>
          <ChangeMandatoryDialog
            name={data.username}
            mandatoryCourses={data.mandatoryCourses}
            isOpen={showMandatory}
            onClose={() => setShowMandatory(false)}
            handleSave={handleMandatorySave}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: '#f9f9f9',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={2}>{renderStartedCourses()}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: '#f9f9f9',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={2}>{renderNotStartedCourses()}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

type UserCoursesTableProps = {
  listToRender: UserCourseResult[];
};

export const UserCoursesTable = ({ listToRender }: UserCoursesTableProps) => {
  const resultState = useSelector(resultStateSelector);
  const allCourses = useSelector(allExamCoursesSimplifiedSelector);
  const [fetchedCourseResults, setFetchedCourseResults] = useState(false);
  const [fetchedAllCourses, setFetchedAllCourses] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!resultState.allUsersCourseResults && !fetchedCourseResults) {
      dispatch(fetchAllUsersCourseResults());
      setFetchedCourseResults(true);
    }
  }, [dispatch, resultState, fetchedCourseResults]);

  useEffect(() => {
    if (!allCourses && !fetchedAllCourses) {
      dispatch(fetchAllExamCoursesSimplified());
      setFetchedAllCourses(true);
    }
  }, [allCourses, dispatch, fetchedAllCourses]);

  useEffect(() => {}, [resultState.allUsersCourseResults]);

  const handleMandatoryChange = () => {
    if (resultState.allUsersCourseResults) {
      dispatch(updateAllUsersCoursesResults());
    }
  };

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell width="5%" />
            <TableCell>
              <Typography variant="subtitle2">
                <Message id="course.username" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">
                <Message id="handleUser.roles" />
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="subtitle2">
                <Message id="handleUserCourses.totalCarriedOutCourses" />
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="subtitle2">
                <Message id="handleUserCourses.mandatoryCourses" />
              </Typography>
            </TableCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {listToRender.map((rowData, i) => (
            <UserCourseTableRow
              key={`usercoursetable_rowdata_${i}`}
              data={rowData}
              onMandatoryChange={handleMandatoryChange}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
