import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import {
  bodoniModa6ptSemiBold,
  bodoniModa10ptSemiBold,
  bodoniModa10ptMedium,
  bodoniModa7pt,
  robotoBold,
  robotoMedium,
  robotoRegular,
} from './fonts';

const brandColors = {
  Viola: '#8552A1',
  Velvet: '#4D5EAA',
  Elsa: '#84D2E2',
  Bulleye: '#EF3F6B',
  Mandarin: '#F58345',
  Duckweed: '#ECE956',
  'Granny Smith': '#8DC63F',
};

export const getRandomBrandColor = () => {
  const colors = Object.values(brandColors);
  const randomIndex = Math.floor(Math.random() * colors.length);
  const randomColor = colors[randomIndex];
  return randomColor;
};

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#3652CC',
    },
    secondary: {
      main: '#CDD3F2',
    },
    background: {
      default: '#fff',
    },
    error: {
      main: '#CB1839',
    },
    text: {
      primary: '#141718',
    },
  },
  typography: {
    h1: {
      fontFamily: "'Bodoni Moda 10pt Medium'",
      fontSize: '96px',
      fontStyle: 'Medium',
      lineHeight: '112px',
      letterSpacing: '-1.5px',
      color: '#141718',
    },
    h2: {
      fontFamily: "'Bodoni Moda 10pt SemiBold'",
      fontSize: '60px',
      fontStyle: 'SemiBold',
      lineHeight: '72px',
      letterSpacing: '-0.5px',
      color: '#141718',
    },
    h3: {
      fontFamily: "'Bodoni Moda 7pt'",
      fontSize: '48px',
      fontStyle: 'ExtraBold',
      lineHeight: '56px',
      color: '#141718',
    },
    h4: {
      fontFamily: "'Roboto Bold'",
      fontSize: '34px',
      fontStyle: 'Bold',
      lineHeight: '46px',
      color: '#141718',
    },
    h5: {
      fontFamily: "'Bodoni Moda 6pt'",
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.18px',
      color: '#141718',
      fontWeight: 600,
    },
    h6: {
      fontFamily: "'Roboto Medium'",
      fontSize: '20px',
      fontStyle: 'Medium',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#141718',
    },
    subtitle1: {
      fontFamily: "'Roboto Regular'",
      fontSize: '18px',
      fontStyle: 'Regular',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#141718',
    },
    subtitle2: {
      fontFamily: "'Roboto Bold'",
      fontSize: '16px',
      fontStyle: 'Bold',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#141718',
    },
    body1: {
      fontFamily: "'Roboto Regular'",
      fontSize: '16px',
      fontStyle: 'Regular',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: '#141718',
    },
    body2: {
      fontFamily: "'Roboto Regular'",
      fontSize: '14px',
      fontStyle: 'Regular',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: '#141718',
    },
    button: {
      fontFamily: "'Roboto Medium'",
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
      color: '#FFFFFF',
    },
    overline: {
      fontFamily: "'Roboto Medium'",
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
      color: '#141718',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          bodoniModa6ptSemiBold,
          bodoniModa10ptSemiBold,
          bodoniModa10ptMedium,
          bodoniModa7pt,
          robotoBold,
          robotoRegular,
          robotoMedium,
        ],
      },
    },
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        borderRadius: 100,
        height: '40px',
      },
    },
    MuiChip: {
      root: {
        padding: '4px 12px',
        fontSize: '14px',
        height: '28px',
      },
    },
  },
};

const theme = createMuiTheme(themeOptions);
export default theme;

export const courseTheme = createMuiTheme(
  merge(cloneDeep(themeOptions), {
    palette: {
      primary: {
        main: '#009A80',
      },
      secondary: {
        main: '#CBE6DF',
      },
    },
  })
);

export const forumTheme = createMuiTheme(
  merge(cloneDeep(themeOptions), {
    palette: {
      primary: {
        main: '#8C2AC1',
      },
      secondary: {
        main: '#E3CAF0',
      },
    },
  })
);

export const groupTheme = createMuiTheme(
  merge(cloneDeep(themeOptions), {
    palette: {
      primary: {
        main: brandColors.Elsa,
      },
      secondary: {
        main: '#c2e9f1',
      },
    },
  })
);

const colorByInstructorId: { [key: string]: string } = {};

export const getInstructorColor = (instructorId: string) => {
  if (!colorByInstructorId[instructorId]) {
    const colors = Object.values(brandColors);
    const numberOfInstructors = Object.keys(colorByInstructorId).length;
    colorByInstructorId[instructorId] =
      colors[numberOfInstructors % colors.length];
  }
  return colorByInstructorId[instructorId];
};
