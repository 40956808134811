import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getForumTopicPreviews,
  createForumReply,
  getForumTopicFromSlug,
  deleteForumTopic as deleteForumTopicAPI,
  updateForumTopic as updateForumTopicAPI,
  updateForumReply as updateForumReplyAPI,
  deleteForumReply as deleteForumReplyAPI
} from '../api/forum-api';
import { ForumTopicPreview, ForumTopic, ForumReply, ForumTopicUpdate } from '../types';
import { AppThunk } from './index';
import { RootState } from './rootReducer';

export interface ForumState {
  forumTopicPreviews: ForumTopicPreview[];
  currentForumTopic: ForumTopic | null;
  isLoading: boolean;
  isAddingReply: boolean;
  error: string | null;
}

const initialState = {
  forumTopicPreviews: [],
  currentForumTopic: null,
  isLoading: true,
  isAddingReply: false,
  error: null,
} as ForumState;

function startLoading(state: ForumState) {
  state.isLoading = true;
}

function stopLoading(state: ForumState) {
  state.isLoading = false;
}

function loadingFailed(state: ForumState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const forumSlice = createSlice({
  name: 'forum',
  initialState: initialState,
  reducers: {
    getForumTopicPreviewsStart: startLoading,
    getForumTopicStart: startLoading,
    getForumTopicPreviewsStop: stopLoading,
    startAddingReply(state: ForumState) {
      state.isAddingReply = true;
    },
    stopAddingReply(state: ForumState) {
      state.isAddingReply = false;
    },
    getForumTopicPreviewsSuccess(
      state,
      action: PayloadAction<ForumTopicPreview[]>
    ) {
      state.forumTopicPreviews = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getForumTopicSuccess(state, action: PayloadAction<ForumTopic>) {
      state.currentForumTopic = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getForumTopicPreviewsFailure: loadingFailed,
    getForumTopicFailure: loadingFailed,
    addForumReply(state, action: PayloadAction<ForumReply>) {
      state.currentForumTopic?.replies.push(action.payload);
    },
    updateForumTopicFailure: loadingFailed,
    removeForumTopic(state, action: PayloadAction<{ id: string }>) {
      state.forumTopicPreviews = state.forumTopicPreviews.filter(topic => topic.id !== action.payload.id)
    },
    removeForumReply(state, action: PayloadAction<{ id: string }>) {
      if (state.currentForumTopic)
        state.currentForumTopic.replies = state.currentForumTopic?.replies.filter(reply => reply.id !== action.payload.id)
    }
  },
});

export const {
  getForumTopicPreviewsStart,
  getForumTopicPreviewsStop,
  getForumTopicPreviewsSuccess,
  getForumTopicPreviewsFailure,
  getForumTopicStart,
  getForumTopicSuccess,
  getForumTopicFailure,
  updateForumTopicFailure,
  removeForumTopic,
  startAddingReply,
  stopAddingReply,
  removeForumReply
} = forumSlice.actions;

export default forumSlice.reducer;

// Thunks

export const addForumReply = (formattedReply: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch(startAddingReply());
    const forumTopicId = (getState().forum.currentForumTopic as ForumTopic).id;
    const forumReply = await createForumReply(forumTopicId, formattedReply);
    dispatch(forumSlice.actions.addForumReply(forumReply));
    dispatch(stopAddingReply());
  } catch (err: any) {
    dispatch(updateForumTopicFailure(err.toString()));
    dispatch(stopAddingReply());
  }
};

export const fetchForumTopicPreviews = (categoryId: string = ""): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(getForumTopicPreviewsStart());
    const forumTopics = await getForumTopicPreviews(undefined, categoryId);

    const activeModules = getState().instance.instance?.activeModules;
    const instanceIsLoading = getState().instance.updateInstanceLoading;
    if (!instanceIsLoading && activeModules && activeModules?.includes('Forum')) {
      dispatch(getForumTopicPreviewsSuccess(forumTopics));
    } else {
      dispatch(getForumTopicPreviewsStop());
    }
  } catch (err: any) {
    dispatch(getForumTopicPreviewsFailure(err.toString()));
  }
};

export const fetchForumTopic = (slug: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getForumTopicStart());
    const currentForumTopic = await getForumTopicFromSlug(slug);
    dispatch(getForumTopicSuccess(currentForumTopic));
  } catch (err: any) {
    dispatch(getForumTopicFailure(err.toString()));
  }
};

export const updateCurrentForumTopic = (id: string, topic: ForumTopicUpdate): AppThunk => async (dispatch) => {
  try {
    dispatch(getForumTopicStart());
    const currentForumTopic = await updateForumTopicAPI(id, topic);
    dispatch(getForumTopicSuccess(currentForumTopic));
  } catch (err: any) {
    dispatch(getForumTopicFailure(err.toString()));
  }
};

export const deleteForumTopic = (id: string): AppThunk => async (dispatch) => {
  try {
    await deleteForumTopicAPI(id);
    dispatch(removeForumTopic({ id }));
  } catch (err: any) { console.error(err) }
}

export const deleteForumReply = (id: string): AppThunk => async (dispatch) => {
  try {
    await deleteForumReplyAPI(id);
    dispatch(removeForumReply({ id }));
  } catch (err: any) { console.error(err) }
}

export const updateForumReply = (id: string, formattedText: string): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(getForumTopicStart());
    await updateForumReplyAPI(id, formattedText);
    const currentForumTopicSlug = getState().forum.currentForumTopic?.slug;
    if (currentForumTopicSlug) {
      dispatch(fetchForumTopic(currentForumTopicSlug));
    }
  } catch (err: any) {
    dispatch(getForumTopicFailure(err.toString()));
  }
};

// Selectors

const selectForumState = (rootState: RootState) => rootState.forum;

export const forumTopicsIsLoadingSelector = createSelector(
  selectForumState,
  (forumState: ForumState) => forumState.isLoading
);

export const forumTopicsIsAddingReplySelector = createSelector(
  selectForumState,
  (forumState: ForumState) => forumState.isAddingReply
);

export const forumTopicsSelector = createSelector(
  selectForumState,
  (forumState: ForumState) => forumState.forumTopicPreviews
);

export const forumTopicSelector = createSelector(
  selectForumState,
  (forumState: ForumState) => forumState.currentForumTopic
);
