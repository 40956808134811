import axios from 'axios';

export const baseApiUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://dev.webapp.conductme.com/api/'
    : `${window.location.origin}/api/`;

const httpClient = axios.create({
  baseURL: baseApiUrl,
});

const localApiPorts: { [key: string]: string } = {
  COURSE: '5001',
  USER: '5002',
  INSTANCE: '5003',
  FORUM: '5004',
  NOTIFICATION: '5006',
  CHAT: '5007',
};

const getApiUrl = (api: string) =>
  process.env.REACT_APP_LOCAL_APIS?.includes(api)
    ? `http://localhost:${localApiPorts[api]}/api/`
    : baseApiUrl;

export const courseHttpClient = axios.create({
  baseURL: getApiUrl('COURSE'),
});

export const forumHttpClient = axios.create({
  baseURL: getApiUrl('FORUM'),
});

export const notificationsHttpClient = axios.create({
  baseURL: getApiUrl('NOTIFICATION'),
});

export const userHttpClient = axios.create({
  baseURL: getApiUrl('USER'),
});

export const chatHttpClient = axios.create({
  baseURL: getApiUrl('CHAT'),
});

export const instanceHttpClient = axios.create({
  baseURL: getApiUrl('INSTANCE'),
});

httpClient.interceptors.response.use((response) => response, handleHttpError);

courseHttpClient.interceptors.response.use(
  (response) => response,
  handleHttpError
);

forumHttpClient.interceptors.response.use(
  (response) => response,
  handleHttpError
);

notificationsHttpClient.interceptors.response.use(
  (response) => response,
  handleHttpError
);

userHttpClient.interceptors.response.use(
  (response) => response,
  handleHttpError
);

chatHttpClient.interceptors.response.use(
  (response) => response,
  handleHttpError
);

instanceHttpClient.interceptors.response.use(
  (response) => response,
  handleHttpError
);

function handleHttpError(error: any) {
  if (error.response?.status === 401 || error.response?.status === 403) {
    // User is not authenticated or not authorized
    // Refresh page to trigger authentication flow
    let url = new URL(window.location.href);
    const refreshNumber = url.searchParams.get('refresh');
    if (refreshNumber === null) {
      url = new URL(window.location.origin);
      url.searchParams.append('refresh', '1');
      window.location.href = url.toString();
    } else if (refreshNumber === '1') {
      url.searchParams.set('refresh', '2');
      window.location.href = url.toString();
    } else {
      //Something is wrong, logout
      if (logout) {
        logout();
      } else {
        localStorage.clear();
      }
    }
    return Promise.reject(error);
  }

  return Promise.reject(error);
}

export const addAccessToken = (accessToken: string) => {
  const addToken = (config: any) => {
    const token = accessToken;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  };

  httpClient.interceptors.request.use(addToken, (error) => {
    Promise.reject(error);
  });
  courseHttpClient.interceptors.request.use(addToken, (error) => {
    Promise.reject(error);
  });
  forumHttpClient.interceptors.request.use(addToken, (error) => {
    Promise.reject(error);
  });
  notificationsHttpClient.interceptors.request.use(addToken, (error) => {
    Promise.reject(error);
  });
  userHttpClient.interceptors.request.use(addToken, (error) => {
    Promise.reject(error);
  });
  chatHttpClient.interceptors.request.use(addToken, (error) => {
    Promise.reject(error);
  });
  instanceHttpClient.interceptors.request.use(addToken, (error) => {
    Promise.reject(error);
  });
};

let logout: Function | null = null;

export const setLogoutFunction = (logoutFunction: Function) => {
  logout = logoutFunction;
};
