import BodoniModa6ptSemiBold from './BodoniModa_6pt-SemiBold.ttf';
import BodoniModa10ptSemiBold from './BodoniModa_10pt-SemiBold.ttf';
import BodoniModa10ptMedium from './BodoniModa_10pt-Medium.ttf';
import BodoniModa7ptExtraBold from './BodoniModa_7pt-ExtraBold.ttf';
import RobotoBold from './Roboto-Bold.ttf';
import RobotoMedium from './Roboto-Medium.ttf';
import RobotoRegular from './Roboto-Regular.ttf';

export const robotoRegular = {
  fontFamily: 'Roboto Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${RobotoRegular}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as const;

export const robotoMedium = {
  fontFamily: 'Roboto Medium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${RobotoMedium}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as const;

export const robotoBold = {
  fontFamily: 'Roboto Bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${RobotoBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as const;

export const bodoniModa7pt = {
  fontFamily: 'Bodoni Moda 7pt',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${BodoniModa7ptExtraBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as const;

export const bodoniModa6ptSemiBold = {
  fontFamily: 'Bodoni Moda 6pt',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${BodoniModa6ptSemiBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as const;

export const bodoniModa10ptSemiBold = {
  fontFamily: 'Bodoni Moda 10pt SemiBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${BodoniModa10ptSemiBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as const;

export const bodoniModa10ptMedium = {
  fontFamily: 'Bodoni Moda 10pt Medium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${BodoniModa10ptMedium}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as const;
