import React from 'react';
import { WhitelabelContext } from '../../whitelabel-config/WhitelabelProvider';

type MessageProps = {
  id: string;
};

const Message = ({ id }: MessageProps) => {
  const { getMessage } = React.useContext(WhitelabelContext);
  const message = getMessage(id);
  if (message === 'Missing message') {
    return <span style={{ color: 'red' }}>{message}</span>;
  }
  return <>{message}</>;
};

export default Message;
