import { useState, useEffect } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Messages from './translation/Message';

type ToastProps = {
  message: string;
  variant: VariantType;
  buttonText: string;
  showSecondaryButton?: boolean;
  secondaryButtonAction?: () => void;
};

const Toast = ({
  message,
  variant,
  buttonText,
  showSecondaryButton = false,
  secondaryButtonAction,
}: ToastProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [hasShown, setHasShown] = useState<boolean>(false);

  useEffect(() => {
    const action = (key: any) => (
      <>
        {showSecondaryButton && (
          <Button
            onClick={() => {
              if (secondaryButtonAction) {
                secondaryButtonAction();
              }
              closeSnackbar(key);
            }}
            color="inherit"
            size="small"
          >
            <Messages id={buttonText} />
          </Button>
        )}
        <IconButton
          color="inherit"
          size="small"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon />
        </IconButton>
      </>
    );

    const showToast = () => {
      enqueueSnackbar(<Messages id={message} />, {
        variant: variant,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        preventDuplicate: true,
        persist: true,
        action,
      });
    };

    if (!hasShown) {
      showToast();
      setHasShown(true);
    }
  }, [
    buttonText,
    closeSnackbar,
    enqueueSnackbar,
    hasShown,
    message,
    secondaryButtonAction,
    showSecondaryButton,
    variant,
  ]);

  return <span />;
};

export default Toast;
