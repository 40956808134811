import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createCourseType } from '../../store/editCourseSlice';
import { WhitelabelContext } from '../../whitelabel-config/WhitelabelProvider';
import Message from '../../components/translation/Message';
import MaterialIcon from '../../components/icons/MaterialIcon';
import CourseIcon from '../../components/icons/CourseIcon';
import { getCourseTypeIconList as getCourseTypeIconListAPI } from '../../api/course-api';
import { IconsFromBackend } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'block',
      marginBottom: theme.spacing(6),
    },
    icons: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(6),
    },
    icon: {},
    iconChosen: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  })
);

export default function AddCourseTypeButton() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [courseTypeName, setCourseTypeName] = useState<string>('');
  const [chosenIcon, setChosenIcon] = useState<string>('Default');
  const [iconNames, setIconsNames] = useState<string[]>([]);
  const { getMessage } = useContext(WhitelabelContext);
  const classes = useStyles();

  useEffect(() => {
    const getIconNames = async () => {
      await getCourseTypeIconListAPI()
        .then((icons) => {
          // Backend needs 'Icon' at the end of an icon name while frontend needs without 'Icon'
          const names = (icons as IconsFromBackend).icons.map(
            (iconName) => iconName.split('Icon')[0]
          );
          setIconsNames(names);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (iconNames.length === 0) {
      getIconNames();
    }
  }, [iconNames]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCourseTypeName('');
    setChosenIcon('Default');
  };

  const handleCourseNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCourseTypeName((event.target as HTMLInputElement).value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(createCourseType(courseTypeName, chosenIcon));
    handleClose();
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <Message id="course.courseAdmin.addCourseType" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>
          <Message id="course.courseAdmin.createCourseType" />
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={`${getMessage('course.courseAdmin.courseType')}`}
              fullWidth
              required
              className={classes.input}
              value={courseTypeName}
              onChange={handleCourseNameChange}
            />
            <FormControl>
              <FormLabel>
                <Message id="course.courseAdmin.icon" />
              </FormLabel>
              <Box className={classes.icons}>
                <Button
                  className={
                    chosenIcon === 'Default' ? classes.iconChosen : classes.icon
                  }
                  onClick={() => setChosenIcon('Default')}
                >
                  <CourseIcon />
                </Button>
                {iconNames.map((iconName) => {
                  return (
                    <Button
                      className={
                        chosenIcon === iconName + 'Icon'
                          ? classes.iconChosen
                          : classes.icon
                      }
                      key={iconName}
                      onClick={() => setChosenIcon(iconName + 'Icon')}
                    >
                      <MaterialIcon fontSize="small" iconName={iconName} />
                    </Button>
                  );
                })}
              </Box>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>
              <Message id="handleUser.cancel" />
            </Button>
            <Button type="submit" color="primary">
              <Message id="course.courseAdmin.editCoursePage.add" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
