import {
    Box,
    Typography
} from "@material-ui/core"
import Message from "../../components/translation/Message"

const NoActors = () => {
    return (
        <Box pt={2}>
            <Typography>
                <Message id='handleActors.noActors' />
            </Typography>
        </Box>
    )
}

export default NoActors;