import { Box, Button, CircularProgress, Container } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SafeHtmlRenderer from '../../components/SafeHtmlRenderer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchTermsAndConditions,
  termsAndConditionsIsLoadingSelector,
  termsAndConditionsSelector,
} from '../../store/userSlice';

const AcceptableUsePolicyPage = () => {
  const termsOfCondition = useSelector(termsAndConditionsSelector);
  const termsOfConditionIsLoading = useSelector(
    termsAndConditionsIsLoadingSelector
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!termsOfConditionIsLoading && !termsOfCondition) {
      dispatch(fetchTermsAndConditions());
    }
  });

  if (!termsOfCondition) {
    return <CircularProgress size={64} />;
  }
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Button
          size="large"
          startIcon={<ArrowBackIcon />}
          onClick={history.goBack}
        >
          Back
        </Button>
      </Box>
      <SafeHtmlRenderer html={termsOfCondition.acceptableUsePolicy} />
    </Container>
  );
};

export default AcceptableUsePolicyPage;
