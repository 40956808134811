import { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Typography,
  Button,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Message from '../../../components/translation/Message';
import { useDispatch, useSelector } from 'react-redux';
import {
  forumCategoriesSelector,
  fetchForumCategories,
} from '../../../store/forumCategoriesSlice';
import EditableTitle from '../../../components/EditableTitle';
import {
  createForumCategory,
  updateForumCategory,
  deleteForumCategory,
} from '../../../api/forum-api';
import PageOrSectionHeader from '../../../components/typography/PageOrSectionHeader';
import RemoveCategoryDialog from './RemoveCategoryDialog';
import { ForumCategory } from '../../../types';
import AddCategoryDialog from './AddCategoryDialog';
import { activeSubModulesSelector } from '../../../store/instanceSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundHover: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      maxWidth: '280px',
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    textOverflow: {
      width: '216px',
    },
  })
);

type CategoriesProps = {
  userAreaId?: string;
  groupId?: string;
};
const Categories = ({ userAreaId, groupId }: CategoriesProps) => {
  const [categoryToDelete, setCategoryToDelete] =
    useState<ForumCategory | null>(null);
  const [isAddCategoryDialogOpen, setIsAddCategoryDialogOpen] =
    useState<boolean>(false);

  const categories = useSelector(forumCategoriesSelector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const activeSubModules = useSelector(activeSubModulesSelector);

  useEffect(() => {
    if (!activeSubModules?.Forum?.includes('PrivateForum')) return;
    dispatch(fetchForumCategories({ userAreaId, groupId }));
  }, [activeSubModules?.Forum, dispatch, groupId, userAreaId]);

  const createNewCategory = (name: string) => {
    if (!name) return;
    createForumCategory({ name, privateUserId: userAreaId, groupId }).then(
      () => {
        dispatch(fetchForumCategories({ userAreaId, groupId }));
      }
    );
  };

  const handleChangeCategoryName = (categoryId: string, name: string) => {
    updateForumCategory({ id: categoryId, name }).then(() => {
      dispatch(fetchForumCategories({ userAreaId, groupId }));
    });
  };

  const handleDeleteCategory = () => {
    if (!categoryToDelete) return;
    deleteForumCategory(categoryToDelete.id).then(() => {
      dispatch(fetchForumCategories({ userAreaId, groupId }));
    });
  };

  if (
    (userAreaId || groupId) &&
    !activeSubModules?.Forum?.includes('PrivateForum') &&
    !activeSubModules?.Forum?.includes('GroupForum')
  ) {
    return <Box />;
  }

  return (
    <Box mt={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        mb={2}
      >
        <PageOrSectionHeader>
          <Message id="forum.adminPage.categories" />
        </PageOrSectionHeader>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsAddCategoryDialogOpen(true)}
        >
          <Message id="forum.adminPage.addCategory" />
        </Button>
      </Box>

      {isAddCategoryDialogOpen && (
        <AddCategoryDialog
          isOpen={isAddCategoryDialogOpen}
          onClose={() => setIsAddCategoryDialogOpen(false)}
          onSave={createNewCategory}
        />
      )}

      {categories && categories.length <= 0 && (
        <Box my={2} mt={2}>
          <Typography color="error" gutterBottom>
            <Message id="forum.adminPage.errorMissingCategories" />
          </Typography>
        </Box>
      )}

      {categories && (
        <Box mb={6} mt={2}>
          {categories.map((category) => (
            <Box key={category.id} className={classes.backgroundHover} px={1}>
              <Box className={classes.textOverflow}>
                <EditableTitle
                  title={category.name}
                  variant="body1"
                  component="p"
                  onChange={(name) =>
                    handleChangeCategoryName(category.id, name)
                  }
                />
              </Box>
              <IconButton
                aria-label="delete-category"
                onClick={() => setCategoryToDelete(category)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
      {!!categoryToDelete && (
        <RemoveCategoryDialog
          isOpen={!!categoryToDelete}
          onClose={() => setCategoryToDelete(null)}
          onRemove={handleDeleteCategory}
          categoryName={categoryToDelete.name}
        />
      )}
    </Box>
  );
};

export default Categories;
