import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@material-ui/core/';
import Message from './translation/Message';

type InfoDialogProps = {
  open: boolean;
  onClose: () => void;
  action: () => void;
  title?: string;
  actionLabel?: string;
  children: React.ReactNode;
  noCancel?: boolean;
};

const InfoDialog = ({
  open,
  onClose,
  title,
  action,
  actionLabel,
  children,
  noCancel
}: InfoDialogProps) => {

  const handleClose = () => {
    onClose();
  };

  const handleAction = () => {
    action();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box pt={1} />
      {title &&
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      }
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Box p={2} display='flex'>
          {!noCancel &&
            <>
              <Button onClick={handleClose} variant='contained'>
                <Message id="handleUser.cancel" />
              </Button>
              <Box mr={2} />
            </>
          }
          <Button onClick={handleAction} color="primary" variant='contained'>
            {actionLabel || <Message id="siteSettings.settingsDialogueBtn" />}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
