import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  TextField,
  Typography,
  ClickAwayListener,
  Tooltip,
  TypographyVariant,
} from '@material-ui/core';

type ComponentType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

type EditableTitleProps = {
  title: string;
  variant?: TypographyVariant;
  component?: ComponentType;
  onChange?: (value: string) => void;
  onInput?: (value: string) => void;
};

interface StyleProps {
  variant?: TypographyVariant;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {},
    input: (props: StyleProps) => ({
      fontSize: theme.typography[props.variant || 'h5'].fontSize,
    }),
    title: {
      marginTop: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    tooltip: {
      fontSize: '16px',
    },
  })
);

const EditableTitle = ({
  title,
  variant,
  component,
  onChange,
  onInput,
}: EditableTitleProps) => {
  const classes = useStyles({ variant });
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>('');

  const handleOnInput = (s: string) => {
    setValue(s);
    if (onInput) {
      onInput(s);
    }
  };

  const leaveField = () => {
    if (onChange) {
      onChange(value);
    }

    setIsEditing(value.length === 0);
  };

  React.useEffect(() => {
    setValue(title);
  }, [title]);

  if (isEditing) {
    return (
      <ClickAwayListener onClickAway={leaveField}>
        <TextField
          value={value}
          onInput={(e) =>
            handleOnInput((e.target as HTMLTextAreaElement).value)
          }
          onKeyPress={(e) => e.key === 'Enter' && leaveField()}
          onBlur={leaveField}
          error={value.length === 0}
          helperText={
            !onInput && value.length === 0 ? "Title can't be empty" : ''
          }
          inputProps={{ className: classes.input }}
          className={classes.textField}
        />
      </ClickAwayListener>
    );
  } else {
    return (
      <Tooltip
        title="Click to edit"
        arrow
        classes={{ tooltip: classes.tooltip }}
      >
        <Typography
          variant={variant || 'h5'}
          component={component || 'h2'}
          className={classes.title}
          onClick={() => setIsEditing(true)}
        >
          {value}
        </Typography>
      </Tooltip>
    );
  }
};
export default EditableTitle;
