import {
  AppBar,
  Box,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  CheckCircle,
  Close,
  KeyboardArrowDown,
  KeyboardArrowRight,
  PriorityHigh,
  Search,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Message from '../../components/translation/Message';
import { fetchUserCourseResults } from '../../store/allResultsSlice';
import { resultStateSelector } from '../../store/selectors';
import { CourseResult } from '../../types';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import { renderMandatoryIcon } from '../HandleUserCourses/UserCoursesTable';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
});

type UserResultRowProps = {
  data: CourseResult;
  handleRedirect: () => void;
};

const UserResultRow = ({ data, handleRedirect }: UserResultRowProps) => {
  const [open, setOpen] = useState<boolean>();
  const classes = useRowStyles();
  const { sessionResults } = data;
  const redirectPath = `/masterclass/${data.courseSlug}`;
  const history = useHistory();

  const innerRows = sessionResults.map((res) => {
    return {
      sessionName: res.sessionName,
      points: `${res.totalPoints} / ${res.maxPoints}`,
      totalPoints: res.totalPoints,
      maxPoints: res.maxPoints,
    };
  });

  const getTimestamp = (dateStr: string) => {
    const [dateComp, timeCompz] = dateStr.split('T');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [timeComp, rest] = timeCompz.split('.');
    return `${dateComp} ${timeComp}`;
  };

  const renderCourseOverview = () => {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Typography variant="subtitle2">
                <Message id="course.sessionName" />
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2">
                <Message id="course.points" />
              </Typography>
            </TableCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {innerRows.map((row, i) => (
            <TableRow key={`key_${i}_${row.sessionName.substring(0, 3)}`}>
              <TableCell />
              <TableCell>{row.sessionName}</TableCell>
              <TableCell align="right">{row.points}</TableCell>
              <TableCell align="left">
                {row.totalPoints > 0 && row.totalPoints === row.maxPoints ? (
                  <CheckCircle fontSize="small" color="disabled" />
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="medium"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <IconButton
            size="small"
            onClick={() => {
              history.push(redirectPath);
              handleRedirect();
            }}
          >
            <Typography variant="subtitle1">{data.courseName}</Typography>
          </IconButton>
        </TableCell>
        <TableCell align="right">
          <Typography variant="subtitle1">
            {data.totalPoints} / {data.maxPoints}
          </Typography>
        </TableCell>
        <TableCell align="left" width="200px">
          <Typography variant="subtitle1">
            {data.datePassed &&
              (data.datesPassed === undefined ||
                data.datesPassed.length === 0) && (
                <Typography>{getTimestamp(data.datePassed)}</Typography>
              )}
            {data.datesPassed && data.datesPassed.length > 0 && (
              <Select
                key={`startedcourses_select_${data.courseId.substring(0, 4)}}`}
                value={0}
              >
                {data.datesPassed.map((d, j) => (
                  <MenuItem key={`s_c_dp_${j}`} value={j}>
                    {getTimestamp(d)}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {data.isMandatory ? renderMandatoryIcon(data) : <></>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: '#f9f9f9',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>{renderCourseOverview()}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

type UserResultsProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const UserResults = ({ isOpen, onClose }: UserResultsProps) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [filterMandatory, setFilterMandatory] = useState(false);
  const resultState = useSelector(resultStateSelector);
  const courseResults = resultState.userCoursesResults;
  const dispatch = useDispatch();

  const handleOnClose = () => {
    setSearchInput('');
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchUserCourseResults());
    }
  }, [dispatch, isOpen]);

  const listToRender = () => {
    const inputFiltered =
      courseResults?.filter((res) =>
        res.courseName.toLowerCase().includes(searchInput.toLowerCase())
      ) ?? [];

    if (filterMandatory) {
      return inputFiltered.filter((res) => res.isMandatory);
    }
    return inputFiltered;
  };

  const renderTable = () => {
    return (
      <Paper>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell width="5%" />
                <TableCell>
                  <Typography variant="subtitle2">
                    <Message id="course.courseName" />
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    <Message id="course.points" />
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle2">
                    <Message id="handleUserCourses.passed" />
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle2">
                    <Message id="handleUserCourses.mandatory" />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courseResults &&
                listToRender().map((row) => (
                  <UserResultRow
                    key={row.courseId}
                    data={row}
                    handleRedirect={handleOnClose}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        style: { backgroundColor: '#e4e4e4' },
      }}
    >
      <AppBar position="relative">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleOnClose}>
            <Close />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', flex: 1 }}>
            <Message id="nav.myCourses" />
          </Typography>
          <Tooltip
            title={
              <Typography style={{ color: 'white', fontSize: 14 }}>
                <Message id="profile.filterMandatory" />
              </Typography>
            }
          >
            <IconButton
              style={{ marginRight: 10 }}
              onClick={() =>
                filterMandatory
                  ? setFilterMandatory(false)
                  : setFilterMandatory(true)
              }
            >
              {filterMandatory && <PriorityHigh style={{ color: 'white' }} />}
              {!filterMandatory && (
                <PriorityHigh style={{ color: 'secondary' }} />
              )}
            </IconButton>
          </Tooltip>
          <TextField
            style={{ marginRight: 10 }}
            inputProps={{ style: { color: 'white' } }}
            variant="standard"
            type="search"
            placeholder={getMessage('course.searchByCourseName')}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <Search />
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box width="100%" display="flex" justifyContent="space-around">
          {resultState.isLoading && (
            <CircularProgress color="primary" size="5em" />
          )}
        </Box>
        {!resultState.isLoading && renderTable()}
      </DialogContent>
      <Box pb={1} />
    </Dialog>
  );
};
