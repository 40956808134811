import { getRandomBrandColor } from "../../theme";

export const getMainPageUrl = (link: string, userAreaOrGroupId: string | null = null) => {
    if (userAreaOrGroupId) {
        return link.split('/', 4).join('/');
    }
    return link.split('/', 2).join('/');
};

export const getAuthorColor = () => {
    const colorById: { [key: string]: string } = {};

    const getColorById = (id: string) => {
        if (!colorById[id]) {
            colorById[id] = getRandomBrandColor();
        }
        return colorById[id];
    };
    return getColorById;
};