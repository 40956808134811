import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const MenuIcon = (props:SvgIconProps)=> {
    return (
      <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" >
        <path d="M3 5.25V6.75H21V5.25H3ZM3 11.25V12.75H21V11.25H3ZM3 17.25V18.75H21V17.25H3Z"/>
      </SvgIcon>
    );   
}

export default MenuIcon;