import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@material-ui/core";
import Message from '../../components/translation/Message';
import { UserDetails } from "../../types";

type RelationshipDialogProps = {
    userDetails: UserDetails | null,
    isOpen: boolean,
    onClose: () => void
}
const RelationshipDialog = ({
    userDetails,
    isOpen,
    onClose
}: RelationshipDialogProps) => {
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            {userDetails && (
                <>
                    <DialogTitle id="confirmation-dialog-title">
                        {`${userDetails.name} `}
                        <Message id="handleActors.relations" />
                    </DialogTitle>
                    <DialogContent dividers>
                        {userDetails.actorRelationships.length === 0 && (
                            <Message id="handleActors.missingRelationsMessage" />
                        )}
                        {userDetails.actorRelationships.length > 0 &&
                            userDetails.actorRelationships.map((relations, index) => (
                                <Grid container xs={12} justify="space-evenly">
                                    <Grid xs={4}>
                                        <Typography key={index} component="p">
                                            {relations.relationship.name}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={1}>
                                        <Typography key={index} component="p" align="center">
                                            {' → '}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={5}>
                                        <Typography key={index} component="p">
                                            {relations.toUser}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color='primary'
                            onClick={onClose}
                        >
                            <Message id="siteSettings.settingsDialogueBtn" />
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}

export default RelationshipDialog;
