import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const PlayCourseIcon = (props:SvgIconProps)=> {
    return (
      <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" >
        <path d="M12 3C7.03725 3 3 7.03725 3 12C3 16.9628 7.03725 21 12 21C16.9628 21 21 16.9628 21 12C21 7.03725 16.9628 3 12 3ZM12 4.5C16.1512 4.5 19.5 7.84875 19.5 12C19.5 16.1512 16.1512 19.5 12 19.5C7.84875 19.5 4.5 16.1512 4.5 12C4.5 7.84875 7.84875 4.5 12 4.5ZM9 6.84375V17.1562L10.125 16.5L16.875 12.6562L18 12L16.875 11.3438L10.125 7.5L9 6.84375ZM10.5 9.42225L14.9767 12L10.5 14.5785V9.42225Z"/>
      </SvgIcon>
    );   
}

export default PlayCourseIcon;