import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import CustomChip from '../../components/CustomChip';
import Message from '../../components/translation/Message';
import { useSelector } from 'react-redux';
import { actorsSelector } from '../../store/actorsSlice';

type UserCoursesFilterProps = {
  isOpen: boolean;
  roleFilters: string[];
  handleRoleFilter: (value: string[]) => void;
  onClose: () => void;
};

const useStyles = makeStyles((_theme) => ({
  autocompleteInput: {
    '& input': {
      padding: '12px !important',
    },
  },
}));

export const UserCoursesFilter = (props: UserCoursesFilterProps) => {
  const { isOpen, roleFilters, handleRoleFilter, onClose } = props;
  const [newRoleFilters, setNewRoleFilters] = useState<string[]>(roleFilters);
  const classes = useStyles();
  const actors = useSelector(actorsSelector);

  const roleFilterChanged = (newValue: string[]) => {
    setNewRoleFilters(newValue);
  };

  const isEqual = () => {
    return JSON.stringify(newRoleFilters) === JSON.stringify(roleFilters);
  };

  const onSave = () => {
    handleRoleFilter(newRoleFilters);
  };

  const handleClose = () => {
    setNewRoleFilters(roleFilters);
    onClose();
  };

  const renderRoleFilter = () => {
    return (
      <Autocomplete
        id="roleFilter"
        multiple
        options={actors?.map((a) => a.name) || []}
        value={newRoleFilters}
        onChange={(_e, newVal) => roleFilterChanged(newVal)}
        renderInput={(params) => (
          <Box>
            <TextField
              {...params}
              classes={{ root: classes.autocompleteInput }}
              required
            />
          </Box>
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <CustomChip {...getTagProps({ index })} label={option} />
          ));
        }}
      />
    );
  };

  return (
    <Dialog open={isOpen} onClose={() => handleClose()} fullWidth maxWidth="md">
      <DialogTitle>Filter</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Typography>
            <Message id="handleUser.roles" />
          </Typography>
          {renderRoleFilter()}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box m={2}>
          <Button variant="text" onClick={onClose}>
            <Message id="handleUserCourses.mandatoryDialog.cancel" />
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={isEqual()}
            onClick={onSave}
            style={{ marginLeft: 5 }}
          >
            <Message id="handleUserCourses.mandatoryDialog.save" />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
