import { Box, CircularProgress } from "@material-ui/core";
import { useState, useEffect } from "react";
import { getVideoBlob } from "../../../api/course-api";

type VideoProps = {
    videoUrl: string,
    canDownload?: boolean,
    previewOnly?: boolean
}
const Video = ({
    videoUrl,
    canDownload = false,
    previewOnly = false
}: VideoProps) => {
    const [fetchedUrl, setFetchedUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchVideo = async () => {
            setIsLoading(true);
            const logoData = await getVideoBlob(videoUrl);
            const fetchedUrl = window.URL.createObjectURL(logoData);
            setIsLoading(false);
            setFetchedUrl(fetchedUrl);
        };

        fetchVideo();

        return () => window.URL.revokeObjectURL(fetchedUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoUrl]);

    if (isLoading) {
        return (
            <Box
                width='100%' height='100%'
                display='flex' justifyContent='center' alignItems='center'
            >
                <CircularProgress size={64} />
            </Box>
        );
    }

    if (fetchedUrl) {
        return (
            <video
                src={fetchedUrl}
                controls={!previewOnly}
                controlsList={
                    canDownload
                        ? 'noremoteplayback'
                        : 'nodownload'
                }
                disablePictureInPicture
                playsInline
                width='100%'
                height='100%'
            />
        );
    }

    return <Box />;
};

export default Video;