import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  actorsSelector,
  actorsUsersDetailsListSelector,
  fetchActorsUsersDetailsList,
} from '../../store/actorsSlice';
import {
  filteredActorUserDetailsSelector,
  setFilteredActorUserDetails,
} from '../../store/userAreaSlice';
import { getActorList } from '../../utils';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import ActorsDropdown from './ActorsDropdown';
import Pagination from '../Pagination';
import Message from '../translation/Message';
import SearchField from '../SearchField';

type ActorUserListWithSearchAndFilterProps = {
  listComponent: (props: any) => JSX.Element;
  listHeaderComponent?: (props: any) => JSX.Element;
  listItemsPerPage?: number;
  paginationPosition?: 'top' | 'bottom';
  data?: any[];
  onSetSearchAndFilter?: (
    searchString: string,
    actorToFilterBy: string | null
  ) => void;
  searchFieldOptions?: string[];
};
const ActorUserListWithSearchAndFilter = ({
  listComponent,
  listHeaderComponent,
  listItemsPerPage,
  paginationPosition = 'top',
  data,
  onSetSearchAndFilter,
  searchFieldOptions,
}: ActorUserListWithSearchAndFilterProps) => {
  const [searchString, setSearchString] = useState<string>('');
  const [actorFilter, setActorFilter] = useState<string>(
    getMessage('statistics.showAllActors')
  );

  const actorUsersList = useSelector(actorsUsersDetailsListSelector);
  const filteredActorUsers = useSelector(filteredActorUserDetailsSelector);
  const actors = useSelector(actorsSelector);

  const dispatch = useDispatch();

  // The default behaviour of this component is to show and filter actorUserDetails,
  // however it has been customized so that other data to be showed and filtered by actor can be sent in.
  // If other data than actorUserDetails is to be filtered, use the onSetSearchAndFilter prop.

  useEffect(() => {
    if (!onSetSearchAndFilter && !actorUsersList) {
      dispatch(fetchActorsUsersDetailsList());
    }
  }, [dispatch, actorUsersList, onSetSearchAndFilter]);

  useEffect(() => {
    if (!onSetSearchAndFilter && !actorUsersList) return;

    if (onSetSearchAndFilter) {
      const actorToFilterBy =
        actorFilter === getMessage('statistics.showAllActors')
          ? null
          : actorFilter;
      onSetSearchAndFilter(searchString, actorToFilterBy);
    } else if (actorUsersList) {
      const filteredByActor =
        actorFilter !== getMessage('statistics.showAllActors')
          ? actorUsersList.filter(
              (actorUser) =>
                actorUser.actors.find((actor) => actor.id === actorFilter) ||
                actorUser.actorRelationships.find(
                  (relationship) => relationship.relationship.id === actorFilter
                )
            )
          : actorUsersList;

      const filteredBySearchAndActor = searchString
        ? filteredByActor.filter(
            (item) =>
              item.name.toLowerCase().includes(searchString.toLowerCase()) ||
              item.email.toLowerCase().includes(searchString.toLowerCase())
          )
        : filteredByActor;
      dispatch(setFilteredActorUserDetails(filteredBySearchAndActor));
    }

    return () => {
      dispatch(setFilteredActorUserDetails(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, actorFilter, actorUsersList, dispatch]);

  const getSortedActorUserList = () => {
    if (!actorUsersList) return [];
    return actorUsersList
      .slice()
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  };

  const getSortedFilteredList = () => {
    if (!filteredActorUsers) return [];
    return filteredActorUsers
      .slice()
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  };

  const getActorGroupName = (id: string) => {
    if (!actors) return '';
    return actors.find((actor) => actor.id === id)?.name || '';
  };

  const getChosenFilter = (): string => {
    if (actorFilter === getMessage('statistics.showAllActors') && !searchString)
      return '';

    return actorFilter === getMessage('statistics.showAllActors')
      ? ' ' + searchString
      : getActorGroupName(actorFilter) +
          `${searchString ? ', ' + searchString : ''}`;
  };

  return (
    <Box>
      <Box display="flex">
        <Box width={280} mr={2}>
          <SearchField
            options={
              searchFieldOptions || actorUsersList?.map((au) => au.name) || []
            }
            value={searchString}
            onChangeValue={setSearchString}
            label={getMessage('statistics.searchUser')}
            placeholder={getMessage('statistics.searchUser')}
          />
        </Box>
        <Box width={280}>
          <ActorsDropdown
            value={actorFilter}
            onChange={(actorGroup: string) => setActorFilter(actorGroup)}
          />
        </Box>
      </Box>
      {!actorUsersList && (
        <Box pt={2}>
          <CircularProgress size={64} />
        </Box>
      )}
      {filteredActorUsers && (
        <Pagination
          data={
            data ||
            getSortedFilteredList().map((actorUser) => ({
              name: actorUser.name,
              id: actorUser.id,
              actors: getActorList(actorUser).map((a) => a.name),
              relationships: actorUser.actorRelationships,
            }))
          }
          RenderComponent={listComponent}
          RenderHeading={listHeaderComponent}
          pageLimit={10}
          dataLimit={listItemsPerPage || 20}
          chosenFilter={getChosenFilter()}
          paginationPosition={paginationPosition}
        />
      )}
      {actorUsersList && !filteredActorUsers && (
        <Pagination
          data={
            data ||
            getSortedActorUserList().map((actorUser) => ({
              name: actorUser.name,
              id: actorUser.id,
              actors: getActorList(actorUser).map((a) => a.name),
              relationships: actorUser.actorRelationships,
            }))
          }
          RenderComponent={listComponent}
          RenderHeading={listHeaderComponent}
          pageLimit={10}
          dataLimit={listItemsPerPage || 20}
          paginationPosition={paginationPosition}
        />
      )}
      {filteredActorUsers && filteredActorUsers.length === 0 && (
        <NoUsersMatchingActorAndSearch
          searchString={searchString}
          hasFilter={actorFilter !== getMessage('statistics.showAllActors')}
          filterName={getActorGroupName(actorFilter)}
        />
      )}
    </Box>
  );
};

export default ActorUserListWithSearchAndFilter;

type NoUsersMatchingActorAndSearchProps = {
  searchString: string;
  hasFilter: boolean;
  filterName: string;
};
const NoUsersMatchingActorAndSearch = ({
  searchString,
  hasFilter,
  filterName,
}: NoUsersMatchingActorAndSearchProps) => {
  return (
    <Box pt={2}>
      <Typography>
        <Message id="statistics.noUsersOfActorType" />
        {searchString && (
          <>
            {' '}
            <Message id="statistics.matching" />
            {' ' + searchString}
          </>
        )}
        {searchString && hasFilter && (
          <>
            {' '}
            <Message id="statistics.and" />
          </>
        )}
        {hasFilter && ' ' + filterName}.
      </Typography>
    </Box>
  );
};
