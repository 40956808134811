import { useDispatch } from 'react-redux';
import { correctFreeText } from '../../store/allResultsSlice';
import { ExtendedResult, QuestionAnswer } from '../../types';
import { QuestionComponent } from './QuestionComponent';

type ExtendedQuestionComponentProps = {
  result: ExtendedResult;
  qa: QuestionAnswer;
};
export const ExtendedQuestionComponent = ({
  result,
  qa,
}: ExtendedQuestionComponentProps) => {
  const dispatch = useDispatch();

  const handleFreeTextCorrection = (pass: boolean) => {
    const { sessionId, userId } = result;
    const { index } = qa;
    dispatch(correctFreeText(sessionId, userId, pass, index));
  };

  return (
    <>
      <QuestionComponent
        handleAnswer={() => {}}
        questionAnswer={qa}
        results={result.answerResults}
        freeTextCorrection={handleFreeTextCorrection}
        disabled={true}
      />
    </>
  );
};
