import { Divider, Menu, MenuItem, Typography } from '@material-ui/core';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { activeSubModulesSelector } from '../../store/instanceSlice';
import Message from '../translation/Message';
import { groupAndCustomerOnlySelector } from '../../store/userAreaSlice';

type AccountMenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onLogout: () => void;
  onShowProfile: () => {};
  onShowExams: () => void;
};
const AccountMenu = ({
  anchorEl,
  onClose,
  onLogout,
  onShowProfile,
  onShowExams,
}: AccountMenuProps) => {
  const activeSubModules = useSelector(activeSubModulesSelector);
  const groupAndCustomerOnly = useSelector(groupAndCustomerOnlySelector);
  const isExamOn = useCallback((): boolean => {
    return (
      !!activeSubModules &&
      activeSubModules.Course.includes('ExamCourse') &&
      !groupAndCustomerOnly
    );
  }, [activeSubModules, groupAndCustomerOnly]);

  const isDev =
    window.location.origin === 'https://dev.webapp.conductme.com' ||
    window.location.origin.includes('localhost');
  const handleAccountClick = () => {
    let url = isDev
      ? 'https://web-dev.conductonline.devies.se/signin/'
      : `https://web.conductonline.devies.se/signin/`;

    window.open(url, 'Account settings');
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="primary-search-account-menu"
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={!!anchorEl}
      onClose={onClose}
    >
      <MenuItem onClick={onShowProfile}>
        <Message id="nav.myProfile" />
      </MenuItem>
      {isExamOn() && (
        <MenuItem onClick={onShowExams}>
          <Message id="nav.myCourses" />
        </MenuItem>
      )}
      {false && (
        <MenuItem onClick={handleAccountClick}>
          <Message id="nav.accountSettings" />
        </MenuItem>
      )}
      <Divider />
      <MenuItem onClick={onLogout}>
        <Typography color="error">
          <Message id="nav.logout" />
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default AccountMenu;
