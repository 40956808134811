import { Theme, ThemeProvider } from '@material-ui/core';
import React from 'react';
import defaultTheme from '../theme';

interface IFeatureThemeContext {
  theme: Theme;
  setTheme: (theme: Theme | null) => void;
}

const initialConfig: IFeatureThemeContext = {
  theme: defaultTheme,
  setTheme: (_theme) => {},
};

export const FeatureThemeContext = React.createContext<IFeatureThemeContext>(
  initialConfig
);

type FeatureThemeProviderProps = {
  children: React.ReactNode;
};

const FeatureThemeProvider = ({ children }: FeatureThemeProviderProps) => {
  const [theme, setTheme] = React.useState<Theme>(defaultTheme);

  const onSetTheme = (inputTheme: Theme | null) => {
    if (inputTheme === null) {
      setTheme(defaultTheme);
    } else {
      setTheme(inputTheme);
    }
  };

  return (
    <FeatureThemeContext.Provider
      value={{
        theme,
        setTheme: onSetTheme,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </FeatureThemeContext.Provider>
  );
};

export default FeatureThemeProvider;
