import { Box, Typography } from '@material-ui/core';
import SafeHtmlRenderer from '../../components/SafeHtmlRenderer';
import { Course } from '../../types';
import Message from '../../components/translation/Message';
import IntroductionMetadata from './IntroductionMetadata';

type IntroductionProps = {
  course: Course;
};

const Introduction = ({ course }: IntroductionProps) => {
  return (
    <Box
      p={{ xs: 2, md: 4 }}
      display="flex"
      justifyContent="space-between"
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <Box flexGrow={1} maxWidth={800} mr={{ xs: 0, md: 8 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          <Message id="course.coursePage.introduction" />
        </Typography>
        <Box mb={8}>
          <SafeHtmlRenderer html={course.formattedIntroText} />
        </Box>
      </Box>
      <IntroductionMetadata course={course}></IntroductionMetadata>
    </Box>
  );
};

export default Introduction;
