import {
  Box,
  CircularProgress,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  TextField,
  Button,
  InputLabel,
  Switch,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import EditableTitle from '../../components/EditableTitle';
import HtmlEditor from '../../components/HtmlEditor';
import {
  addIntro,
  fetchIntro,
  deleteIntro,
  introSelector,
  introLoadingSelector,
  addNewIntroLoadingSelector,
  introBeenFetchedSelector,
  getIntroFailureSelector,
  instanceModulesSelector,
  activeSubModulesSelector,
} from '../../store/instanceSlice';
import { Intro } from '../../types';
import Message from '../../components/translation/Message';
import InfoDialog from '../../components/InfoDialog';
import { getMessage } from '../../whitelabel-config/WhitelabelProvider';
import { courseTypesSelector } from '../../store/editCourseSlice';
import * as paths from '../../routes/paths';
import { matchPath, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      '& > button': {
        marginLeft: '10px',
      },
    },
    addNewIntroButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    urlInput: {
      width: '300px',
    },
    formControl: {
      marginBottom: theme.spacing(1),
      minWidth: 120,
    },
    flexCenter: {
      display: 'flex',
    },
  })
);
const emptyIntro = {
  videoUrl: '',
  title: 'Intro title',
  description: '',
  email: '',
  show: false,
  buttonText: 'Click Here',
  buttonUrl: 'mailto:info@conductme.com',
};

interface Error {
  field: string;
  message: JSX.Element;
}

const HomeAdminPage = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const intro = useSelector(introSelector);
  const introLoading = useSelector(introLoadingSelector);
  const addNewIntroLoading = useSelector(addNewIntroLoadingSelector);
  const introBeenFetched = useSelector(introBeenFetchedSelector);
  const introError = useSelector(getIntroFailureSelector);
  const [error, setError] = useState<Error[]>([]);
  const [newIntro, setNewIntro] = useState<Intro>({ ...emptyIntro });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isCustomUrl, setIsCustomUrl] = useState<boolean>(false);
  const [customUrl, setCustomUrl] = useState<string>('');
  const classes = useStyles();
  const location = useLocation();
  const courseTypes = useSelector(courseTypesSelector);
  const activeModules = useSelector(instanceModulesSelector);
  const activeSubModules = useSelector(activeSubModulesSelector);

  const matchPage = (path: string, exact = false) => {
    return Boolean(matchPath(location.pathname, { path, exact }));
  };

  type NavItemProps = {
    text: string;
    selected: boolean;
    url: string;
    moduleActive?: boolean;
  };

  const checkIsCustom = () => {
    return !regularNavItems
      .map((item) => item.url)
      .includes(newIntro.buttonUrl);
  };

  const regularNavItems: NavItemProps[] = [
    {
      text: getMessage('nav.home'),
      selected: matchPage(paths.homeUrl, true),
      url: paths.homeUrl,
      moduleActive: true,
    },
    {
      text: getMessage('nav.forum'),
      selected: matchPage(paths.forumUrl),
      url: paths.forumUrl,
      moduleActive:
        activeModules?.includes('Forum') &&
        activeSubModules?.Forum?.includes('PublicForum'),
    },
  ];

  if (courseTypes && courseTypes.length > 0) {
    const courseTypesNavItems = courseTypes.map((type) => {
      return {
        text: type.name,
        selected: matchPage(paths.getCourseTypeUrl(type.name)),
        url: paths.getCourseTypeUrl(type.name),
        moduleActive:
          activeModules?.includes('Course') &&
          activeSubModules?.Course?.includes('PublicCourse') &&
          type.isActive,
      };
    });
    const mailTo = {
      text: 'Contact',
      selected: false,
      url: 'mailto:info@conductme.com',
      moduleActive: true,
    };
    regularNavItems.splice(1, 0, mailTo, ...courseTypesNavItems);
  }

  const getSideNavs = () => {
    return regularNavItems.filter((item) => item.moduleActive);
  };

  useEffect(() => {
    if (!intro && !introLoading && !introBeenFetched) {
      dispatch(fetchIntro());
    }
    if (intro) {
      setNewIntro({ ...intro });
    }
  }, [dispatch, intro, introLoading, introBeenFetched]);

  useEffect(() => {
    setIsCustomUrl(checkIsCustom());
    if (checkIsCustom()) {
      setCustomUrl(newIntro.buttonUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIntro]);

  const onChangeTitle = (name: string) => {
    setNewIntro({ ...newIntro, title: name });
  };

  const onChangeVideo = (url: string) => {
    setNewIntro({ ...newIntro, videoUrl: url });
  };

  const onIntroTextChange = (formattedText: string) => {
    setNewIntro({ ...newIntro, description: formattedText });
  };

  const onButtonNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewIntro({ ...newIntro, buttonText: event.target.value });
  };

  const onButtonUrlChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNewIntro({ ...newIntro, buttonUrl: event.target.value as string });
    setCustomUrl(event.target.value as string);
  };

  const onCustomUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomUrl(event.target.value);
  };

  const onCustomCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCustomUrl(event.target.checked);
  };

  useEffect(() => {
    const errors: Error[] = [];

    if (newIntro.videoUrl !== '' && !ReactPlayer.canPlay(newIntro.videoUrl)) {
      errors.push({
        field: 'videoUrl',
        message: <Message id="course.homeAdmin.error.videoUrlInvalid" />,
      });
    }
    setError(errors);
  }, [newIntro]);

  const handleShowIntro = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewIntro({
      ...newIntro,
      show: (event.target as HTMLInputElement).checked,
    });
  };

  const handleDelete = () => {
    dispatch(deleteIntro());
    setNewIntro({ ...emptyIntro });
  };

  const handleSave = () => {
    if (error.length > 0) {
      return;
    }

    if (!isIntroInputCorrect()) {
      return;
    }

    const onComplete = (success: boolean) => {
      if (success) {
        enqueueSnackbar(<Message id="course.homeAdmin.newIntroSucces" />, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(<Message id="course.homeAdmin.newIntroFailure" />, {
          variant: 'error',
        });
      }
    };
    const introToAdd = { ...newIntro };
    if (isCustomUrl) {
      introToAdd.buttonUrl = customUrl;
    }
    dispatch(addIntro(introToAdd, onComplete));
  };

  const disableDelete = () => {
    return !intro;
  };

  const isIntroInputCorrect = () => {
    const introToCompare = { ...newIntro };
    if (isCustomUrl) {
      introToCompare.buttonUrl = customUrl;
    }
    if (intro) {
      return !isEqual(intro, introToCompare);
    }
    return !isEqual(emptyIntro, introToCompare);
  };

  const isEqual = (obj1: Intro, obj2: Intro) => {
    const js1 = JSON.stringify(obj1);
    const js2 = JSON.stringify(obj2);
    return js1 === js2;
  };

  return (
    <Box p={4} width={introError ? '100%' : '800px'}>
      <Typography variant="h5" component="h2" gutterBottom>
        <Message id="course.homeAdmin.homeAdmin" />
      </Typography>
      <Box mt={4}>
        {introLoading && <CircularProgress size={64} />}
        {introError && (
          <Typography variant="h5" component="h5" color="error">
            {introError}
          </Typography>
        )}
        {!introLoading && !introError && (
          <Box>
            <Box mb={newIntro.videoUrl ? 0 : 4}>
              <EditableTitle
                title={newIntro.title}
                onInput={onChangeTitle}
                variant="h6"
                component="h5"
              />
              {error.find((index) => index.field === 'title') && (
                <>
                  {error
                    .filter((index) => index.field === 'title')
                    .map((index, id) => (
                      <Typography
                        key={id}
                        variant="body2"
                        component="p"
                        color="error"
                      >
                        {index.message}
                      </Typography>
                    ))}
                </>
              )}
            </Box>
            <Box mb={4} maxWidth={800}>
              <ReactPlayer
                url={newIntro.videoUrl}
                controls
                width="100%"
                height={newIntro.videoUrl ? '500px' : ''}
              />
              <Box maxWidth={400}>
                <TextField
                  label="Video url"
                  size="small"
                  value={newIntro.videoUrl}
                  onInput={(e) =>
                    onChangeVideo((e.target as HTMLTextAreaElement).value)
                  }
                  error={Boolean(
                    error.find((index) => index.field === 'videoUrl')
                  )}
                  className={classes.urlInput}
                />
              </Box>
              {error.find((index) => index.field === 'videoUrl') && (
                <>
                  {error
                    .filter((index) => index.field === 'videoUrl')
                    .map((index, id) => (
                      <Typography
                        key={id}
                        variant="body2"
                        component="p"
                        color="error"
                      >
                        {index.message}
                      </Typography>
                    ))}
                </>
              )}
            </Box>

            <Box mb={4}>
              <HtmlEditor
                html={intro ? intro.description : ''}
                onChange={onIntroTextChange}
              />
              {error.find((index) => index.field === 'description') && (
                <>
                  {error
                    .filter((index) => index.field === 'description')
                    .map((index, id) => (
                      <Typography
                        key={id}
                        variant="body2"
                        component="p"
                        color="error"
                      >
                        {index.message}
                      </Typography>
                    ))}
                </>
              )}
            </Box>

            <Box mb={4} display="flex" alignItems="center">
              <TextField
                label="Intro button name"
                value={newIntro.buttonText}
                id="outlined-helperText"
                onChange={onButtonNameChange}
              />
            </Box>
            <FormControl className={classes.formControl}>
              <InputLabel variant="standard">URL</InputLabel>
              {!isCustomUrl ? (
                <Select
                  id="simple-select-standard"
                  value={newIntro.buttonUrl}
                  onChange={onButtonUrlChange}
                  style={{ width: 150 }}
                  disabled={isCustomUrl}
                >
                  {getSideNavs().map((item) => (
                    <MenuItem value={item.url}>{item.text}</MenuItem>
                  ))}
                </Select>
              ) : (
                <Select value="Custom" disabled>
                  <MenuItem value="Custom">Custom</MenuItem>
                </Select>
              )}
            </FormControl>
            <TextField
              label=" "
              InputLabelProps={{ shrink: true }}
              value={isCustomUrl ? customUrl : newIntro.buttonUrl}
              disabled={!isCustomUrl}
              style={{ width: '40%', marginLeft: 10 }}
              onChange={onCustomUrlChange}
            />
            <Box className={classes.flexCenter}>
              <Checkbox
                color="primary"
                checked={isCustomUrl}
                onChange={onCustomCheckboxChange}
              />
              <Typography style={{ marginTop: 12 }}>Custom URL</Typography>
            </Box>
            <Box mb={4} display="flex" alignItems="center">
              <InputLabel id="intro-show">
                <Message id="course.homeAdmin.showIntroVideo" />
              </InputLabel>
              <Switch
                checked={newIntro.show}
                onChange={handleShowIntro}
                name="showIntro"
                color="primary"
              />
            </Box>
            <Box className={classes.addNewIntroButton}>
              <div className={classes.wrapper}>
                <Button
                  onClick={() => setIsDeleteModalOpen(true)}
                  disabled={disableDelete()}
                >
                  <Message id="course.homeAdmin.delete" />
                </Button>
                {addNewIntroLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>

              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={error.length > 0 || !isIntroInputCorrect()}
                >
                  <Message id="siteSettings.save" />
                </Button>
                {addNewIntroLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
            {isDeleteModalOpen && (
              <InfoDialog
                open={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                action={handleDelete}
                title={getMessage('course.homeAdmin.delete')}
              >
                <Typography>
                  <Message id="course.homeAdmin.deleteDialog" />
                </Typography>
              </InfoDialog>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HomeAdminPage;
