import {
    Box,
    createStyles,
    Divider,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Message from "../../components/translation/Message";
import { Actor } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            width: '100%',
            maxWidth: 600,
            padding: theme.spacing(1),
        },
        divider: {
            width: '100%',
            maxWidth: 600
        },
        backgroundEven: {
            backgroundColor: theme.palette.grey[100]
        },
        backgroundUneven: {
            backgroundColor: theme.palette.background.default
        },
        icons: {
            display: 'flex',
            lineHeight: '0px',
            justifyContent: 'end',
        },
        icon: {
            cursor: 'pointer',
            color: theme.palette.grey[700],
            '&:hover': {
                color: theme.palette.common.black
            }
        }
    })
);

type ActorListProps = {
    actors: Actor[],
    onEdit?: (actor: Actor) => void,
    onDelete?: (actor: Actor) => void
}
const ActorList = ({
    actors,
    onEdit,
    onDelete
}: ActorListProps) => {
    const classes = useStyles();

    return (
        <Box pt={2}>
            <Grid container className={classes.gridContainer}>
                <Grid item xs={10} md={10}>
                    <Typography variant='subtitle2'>
                        <Message id="handleActors.name" />
                    </Typography>
                </Grid>
                <Grid item xs={2} md={4}></Grid>
            </Grid>
            <Divider component='div' className={classes.divider} />
            {actors.map((actor, index) => (
                <Grid
                    key={actor.id}
                    container
                    className={`
                        ${classes.gridContainer}
                        ${index % 2 === 0
                            ? classes.backgroundEven
                            : classes.backgroundUneven
                        }
                    `}
                >
                    <Grid item xs={10} md={10}>
                        <Typography>{actor.name}</Typography>
                    </Grid>
                    <Grid item xs={2} md={2} className={classes.icons}>
                        {onEdit &&
                            <Box mr={onDelete ? 3 : 0}
                                className={classes.icon}
                                onClick={() => onEdit(actor)}
                            >
                                <EditIcon />
                            </Box>
                        }
                        {onDelete &&
                            <Box
                                className={classes.icon}
                                onClick={() => onDelete(actor)}
                            >
                                <DeleteIcon />
                            </Box>
                        }
                    </Grid>
                </Grid>
            ))}
        </Box >
    )
}

export default ActorList;