import React from 'react';
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Avatar,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Instructor } from '../../types';
import { getInstructorColor } from '../../theme';

type InstructorItemProps = {
  instructor: Instructor;
  onDelete?: (instructor: Instructor) => void;
  showName?: Boolean;
  showTitle?: Boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructorName: {
      fontWeight: 700,
      marginLeft: theme.spacing(2),
    },
    instructorAvatar: {
      width: '32px',
      height: '32px',
    },
  })
);

const InstructorItem = ({
  instructor,
  onDelete,
  showName,
  showTitle,
}: InstructorItemProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Avatar
        style={{ backgroundColor: getInstructorColor(instructor.id) }}
        className={classes.instructorAvatar}
      >
        {instructor.name[0]}
      </Avatar>
      <Box>
        {showTitle && instructor.title && (
          <Typography variant="overline" className={classes.instructorName}>
            {instructor.title}
          </Typography>
        )}
        {showName && (
          <Typography className={classes.instructorName}>
            {instructor.name}
          </Typography>
        )}
      </Box>
      {onDelete && (
        <IconButton aria-label="delete" onClick={() => onDelete(instructor)}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default InstructorItem;
