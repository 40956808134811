import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/translation/Message";
import PageOrSectionHeader from "../../components/typography/PageOrSectionHeader";
import { actorsSelector, actorsUsersDetailsListSelector } from "../../store/actorsSlice";
import { fetchGroupsCount, groupsCountSelector } from "../../store/groupSlice";
import { adminLimitSelector, groupLimitSelector, roomLimitSelector, userLimitSelector } from "../../store/instanceSlice";
import { fetchUsers, usersSelector } from "../../store/userSlice";
import { getMessage } from "../../whitelabel-config/WhitelabelProvider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        limitSection: {
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.shape.borderRadius
        }
    })
);

const LimitsInfo = () => {
    const userLimit = useSelector(userLimitSelector);
    const adminLimit = useSelector(adminLimitSelector);
    const groupLimit = useSelector(groupLimitSelector);
    const roomLimit = useSelector(roomLimitSelector);

    const users = useSelector(usersSelector);
    const actors = useSelector(actorsSelector);
    const groupsCount = useSelector(groupsCountSelector)
    const actorUsersList = useSelector(actorsUsersDetailsListSelector);
    const getAdminUsers = () => {
        return users?.filter(u => u.roles.includes('admin')) || [];
    }
    const getActorUsersWithPage = () => {
        return actorUsersList?.filter(
            au => au.actors.find(a => {
                const actor = actors?.find(actor => actor.id === a.id);
                return actor?.hasPrivateRoom;
            })
        ) || [];
    }

    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!users) {
            dispatch(fetchUsers());
        }
    }, [users, dispatch]);

    useEffect(() => {
        dispatch(fetchGroupsCount());
    }, [groupsCount, dispatch]);

    return (
        <Box>
            <PageOrSectionHeader>
                <Message id='siteSettings.limits' />
            </PageOrSectionHeader>
            <Box mt={1} mb={2}>
                <Typography>
                    <Message id='siteSettings.limitsSaleText' />
                </Typography>
            </Box>
            <Box p={2} mb={2} className={classes.limitSection}>
                <LimitItem
                    limitText={getMessage('handleUser.allowedUserLimit')}
                    limit={userLimit}
                    currentAmount={users?.length || 0}
                />
                <Box mb={1} />
                <LimitItem
                    limitText={getMessage('handleUser.allowedAdminLimit')}
                    limit={adminLimit}
                    currentAmount={getAdminUsers().length || 0}
                />
                <Box mb={1} />
                <LimitItem
                    limitText={getMessage('handleActors.allowedRoomLimit')}
                    limit={roomLimit}
                    currentAmount={getActorUsersWithPage().length || 0}
                />
                <Box mb={1} />
                <LimitItem
                    limitText={getMessage('handleActors.allowedGroupLimit')}
                    limit={groupLimit}
                    currentAmount={groupsCount || 0}
                />
            </Box>
        </Box>
    )
}

const LimitItem = (
    { limitText, limit, currentAmount }: { limitText: string, limit?: number, currentAmount: number }
) => {
    return (
        <Box display='flex' alignItems='center'>
            <Typography variant='subtitle2'>
                {limitText}
            </Typography>
            <Box mr={1} />
            {limit != null &&
                <Typography>
                    {currentAmount + ' '}
                    <Message id='handleUser.of' />
                    {' ' + limit}
                </Typography>}
            {limit === null &&
                <Typography>
                    {getMessage('siteSettings.unlimitedLimits')}
                </Typography>}

        </Box>
    )
}

export default LimitsInfo;