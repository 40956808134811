type Seconds = number;
type HtmlString = string;

export interface Provider {
  id: string;
  name: string;
  instructors: Instructor[];
}

export interface Instructor {
  id: string;
  name: string;
  title: string | null;
}

export interface SearchItem {
  name: string;
  courseSlug: string;
  sessionId: string;
  sessionName: string;
}

export interface Document {
  id: string;
  name: string;
  url: string;
  fileSize: number;
  contentType: string;
  isFileDownloadable: boolean;
  uploadedBy: string;
  uploadedAt: string;
  pdfUrl: string | null;
}

export interface SimplifiedDocument extends SearchItem {
  courseSlug: string;
  sessionId: string;
  sessionName: string;
  documentName: string;
}

export interface QuestionAnswer {
  question: string;
  imageUrl?: string;
  answerOptions: AnswerOption[];
  index: number;
  answerType: AnswerType;
}

export type AnswerType =
  | 'SingleChoice'
  | 'MultipleChoice'
  | 'FreeText'
  | 'Unknown';

export interface AnswerOption {
  text?: string;
  image?: string;
  isCorrect?: boolean;
}

export interface UserAnswer {
  questionAnswer: QuestionAnswer;
  userAnswer: AnswerOption[];
  sessionId: string;
}

export interface Result {
  question: string;
  answerOptions: AnswerOptionResult[];
  answerType: AnswerType;
  index: number;
  maxPoints: number;
}

export type Session = {
  id: string;
  name: string;
  length: Seconds;
  index: number;
  documents: Document[];
  formattedText: HtmlString;
  vimeoUrl: string;
  liveStreamUrl: string;
  formattedDescription?: HtmlString;
  type: 'text' | 'video';
  isFileDownloadable: boolean;
  questionAnswers: QuestionAnswer[];
  minimumPoints?: number;
  maximumPoints: number;
  usedInCourses?: string[];
  courseId?: string;
  mandatoryToAnswer?: boolean;
};

export type SessionResultDict = {
  [sessionId: string]: AnswerResult;
};

export type CourseResultDict = {
  [courseId: string]: CourseResult;
};

export interface AnswerOptionResult extends AnswerOption {
  selected: boolean;
}

export interface AnswerResult {
  sessionId: string;
  sessionName: string;
  maxPoints: number;
  totalPoints: number;
  answerResults: Result[];
}

export interface ExtendedResult extends AnswerResult {
  userId: string;
  username: string;
  pass: boolean | null;
  passes: string[];
  sessionIndex: number;
}

export interface CourseResult {
  courseId: string;
  courseName: string;
  courseSlug: string;
  totalPoints: number;
  maxPoints: number;
  sessionResults: ExtendedResult[];
  isMandatory: boolean;
  datePassed: string;
  datesPassed: string[];
}

export interface UserCourseResult {
  userId: string;
  username: string;
  roles: string[];
  courseResults: CourseResult[];
  notStarted: CourseResult[];
  mandatoryCourses: SimplifiedCourse[];
}

export interface Course {
  id: string;
  slug: string;
  name: string;
  previewImageUrl: string;
  provider: Provider;
  length: Seconds;
  formattedIntroText: HtmlString;
  tags: string[];
  instructors: Instructor[];
  sessions: Session[];
  featured: boolean;
  isActive: boolean;
  progress?: number;
  courseType: string;
  repeatable: boolean;
  sessionOrder?: SimplifiedSession[];
}

export interface SimplifiedSession {
  id: string;
  name: string;
}

export interface SimplifiedCourse extends SearchItem {
  id: string;
  name: string;
  slug: string;
  tags: string[];
  courseType?: CourseType;
}
export interface CoursePreview {
  id: string;
  slug: string;
  name: string;
  previewImageUrl: string;
  provider: Provider;
  length: Seconds;
  tags: string[];
  instructors: Instructor[];
  featured: boolean;
  isActive: boolean;
  progress?: number;
  numberOfSessions: number;
  courseType: string;
  seenAt?: string[];
}

export interface CourseDTO {
  name: string;
  providerId: string;
  formattedIntroText: HtmlString;
  previewImageUrl: string;
  tags: string[];
  instructorIds: string[];
  featured: boolean;
  isActive: boolean;
  courseType: string;
  repeatable: boolean;
  sessionIds?: string[];
}

export type CourseCreate = Omit<CourseDTO, 'previewImageUrl'>;

export interface CourseType {
  id: string;
  name: string;
  icon: string;
  isActive: boolean;
  color?: string;
  order: number;
}

export interface CourseTypes {
  courseType: CourseType;
  coursePreview: CoursePreview[];
  hasBeenFetched: boolean;
  isLoading: boolean;
}

const instanceRoles = [
  'User',
  'CourseAdmin',
  'Admin',
  'SuperAdmin',
  'ForumAdmin',
  'ForumModerator',
  'ExternalApiUser',
  'user',
  'courseadmin',
  'admin',
  'superadmin',
  'forumadmin',
  'forummoderator',
  'externalapiuser',
] as const;

export type InstanceRole = (typeof instanceRoles)[number];

export interface User {
  id: string;
  userInstanceId: string;
  name: string;
  instanceRoles: InstanceRole[];
  hasApprovedTermsAndConditions: boolean;
  userProfile: MyUserProfile | null;
}

export interface MyUserProfile {
  name: string;
  role: string;
  languages: string[];
  organization: string;
  country: string;
  profilePicture: string | File;
  externalId: string;
  honorific: string | null;
  isAnonymous: boolean;
}

export interface ChatUserBackend {
  nickname: string;
  profile_url: string;
  user_id: string;
}

export interface ChatUserFrontend {
  userId: string;
  profileUrl: string;
  nickname: string;
}

export interface ChatSessionToken {
  expires_at: number;
  token: string;
}

export interface InstanceUser {
  id: string;
  name: string;
  email: string;
  emailConfirmed: boolean;
  roles: InstanceRole[];
}

export interface InviteUser {
  name: string;
  email: string;
  roles: InstanceRole[];
}

export interface Actor {
  name: string;
  id: string;
  assignableToUser: boolean;
  assignableAsRelationship: boolean;
  assignableAsRelationshipOnlyToActorIds: string[];
  permissions: string | string[];
  hasPrivateRoom: boolean;
  canAssignActorIds: string[];
  mandatoryCourses: SimplifiedCourse[];
}

export interface ActorRelationship {
  fromUser: string;
  relationship: Actor;
  toUser: string;
  toUserId?: string;
}

export interface ActorsUsersDetails {
  name: string;
  email: string;
  id: string;
  actors: Actor[];
  actorRelationships: ActorRelationship[];
}

export interface TermsAndConditions {
  cookiePolicy: HtmlString;
  acceptableUsePolicy: HtmlString;
  termsOfService: HtmlString;
  privacyPolicy: HtmlString;
  id: string;
}

export interface Author {
  id: string;
  name: string;
}

export interface ForumCategory {
  id: string;
  name: string;
}

export interface ForumReply {
  id: string;
  author: Author;
  formattedText: string;
  createdAt: string;
  updatedAt: string;
  edited: boolean;
}

export interface ForumTopic {
  id: string;
  author: Author;
  subject: string;
  slug: string;
  category: ForumCategory;
  createdAt: string;
  formattedText: string;
  replies: ForumReply[];
  updatedAt: string;
  privateForumUserId: string | null;
  groupId: string | null;
  edited: boolean;
}

export type ForumTopicDTO = Pick<ForumTopic, 'subject' | 'formattedText'> & {
  categoryId?: string;
  privateForumUserId?: string;
  groupId?: string;
};

export type ForumTopicUpdate = Pick<ForumTopic, 'subject' | 'formattedText'> & {
  categoryId: string;
};

export interface ForumTopicPreview {
  id: string;
  author: Author;
  subject: string;
  slug: string;
  category: ForumCategory;
  createdAt: string;
  numberOfReplies: number;
  updatedAt: string;
  privateForumUserId: string | null;
  groupId: string | null;
  canBeDeleted: boolean;
  seenAt?: string[];
}

export interface Intro {
  videoUrl: string;
  title: string;
  description: string;
  email?: string | null;
  show: boolean;
  buttonText: string;
  buttonUrl: string;
}

export interface Hosts {
  host: string;
  dnsValid: boolean;
  certificateValid: boolean;
  id: string;
}

export interface ExternalLink {
  MainPageLink: string;
  SupportEmail: string;
}
export interface Instance {
  id: string;
  hosts?: Hosts[];
  name: string;
  activeModules: Module[];
  availableModules: Record<Module, SubModule[]>;
  activeSubModules: Record<Module, SubModule[]>;
  enabledModules: Record<Module, SubModule[]>;
  userLimit?: number;
  adminLimit?: number;
  groupLimit?: number;
  roomLimit?: number;
  externalLinks: ExternalLink;
  openRegistration: boolean;
  metaDescription: string;
  chatApplicationId: string;
  instanceLanguage: InstanceLanguage;
}

export type InstanceLanguage = 'English' | 'Svenska';

export type Module = 'Forum' | 'External' | 'Course' | 'Notification' | 'Chat';
export type SubModule =
  | 'PrivateForum'
  | 'PublicForum'
  | 'PrivateCourse'
  | 'PublicCourse'
  | 'PrivateVideos'
  | 'CourseNotifications'
  | 'ForumNotifications'
  | 'VideoNotifications'
  | 'PrivateChat'
  | 'PublicChat'
  | 'GroupForum'
  | 'GroupCourse'
  | 'GroupVideos'
  | 'GroupLiveStream'
  | 'PrivateLiveStream'
  | 'PublicLiveStream'
  | 'ExamCourse';

export type UpdateInstance = Omit<
  Instance,
  | 'availableModules'
  | 'enabledModules'
  | 'userLimit'
  | 'adminLimit'
  | 'roomLimit'
  | 'groupLimit'
  | 'id'
  | 'hosts'
  | 'chatApplicationId'
  | 'instanceLanguage'
>;

export const toUpdateInstance = (instance: Instance): UpdateInstance => {
  const { id, availableModules, hosts, chatApplicationId, ...updateInstance } =
    instance;
  return updateInstance;
};

export interface Notification {
  id: string;
  userId: string;
  referenceId: string;
  secondReferenceId: string;
  additionalData: string;
  notificationType: NotificationType;
  referenceTitle: string;
  referenceSlug: string;
  wasNotifiedByEmail: boolean;
}

export type NotificationType =
  | 'ForumTopicPublished'
  | 'ForumTopicReply'
  | 'PrivateForumTopicPublished'
  | 'GroupForumTopicPublished'
  | 'PrivateForumTopicReply'
  | 'GroupForumTopicReply'
  | 'CoursePublished'
  | 'MandatoryCourseAssigned'
  | 'CourseReset'
  | 'PrivateCoursePublished'
  | 'CourseTypePublished'
  | 'GroupCoursePublished'
  | 'VideoUploaded'
  | 'VideoPublished'
  | 'GroupVideoUploaded'
  | 'GroupVideoPublished';

export interface IconsFromBackend {
  icons: string[];
}

export interface Statistic {
  userInstanceId: string;
  instanceId: string;
  userAreaId?: string;
  userAreaName?: string;
  topicSlug?: string;
  topicId?: string;
  replyId?: string;
  courseId?: string;
  sessionId?: string;
  eventType: StatisticType;
  timeOfEvent: string;
}

export type StatisticType =
  | 'LOGGED_IN'
  | 'COURSE_PAGELOAD'
  | 'SESSION_PAGELOAD'
  | 'USERAREA_PAGELOAD_ACTORS'
  | 'USERAREA_PAGELOAD_PRIVATE_FORUM'
  | 'USERAREA_PAGELOAD_PRIVATE_COURSES'
  | 'FORUM_TOPIC_PAGELOAD_PRIVATE'
  | 'POST_IN_PRIVATE_FORUM'
  | 'EDIT_PRIVATE_FORUM_TOPIC'
  | 'EDIT_PRIVATE_FORUM_TOPIC_SUBJECT'
  | 'ADD_REPLY_TO_PRIVATE_FORUM_TOPIC'
  | 'EDIT_PRIVATE_FORUM_TOPIC_REPLY';

export interface LoginStatistic {
  day: number;
  amount: number;
}

export interface CustomerStatistic {
  id: string;
  customerId: string;
  customerName: string;
  eventsTotal: number;
  forumTopicsViewed: number;
  forumTopicsTotal: number;
  coursesViewed: number;
  coursesTotal: number;
  notViewed: number;
}

// Dates: YYYY-MM-DD
export interface StatisticParams {
  toDate?: string;
  fromDate?: string;
  events?: string[];
  userInstanceId?: string;
}

export interface PaginationData {
  name?: string;
  id?: string;
  actors?: string[];
  relationships?: ActorRelationship[];
}

export interface Customer {
  id: string;
  name: string;
}

export type UserDetails = ActorsUsersDetails & {
  id: string;
  numberOfRelationships: number;
};

export type AddOrRemove = 'Add' | 'Remove';

export interface Tab {
  id: string;
  name: string;
  privateUserId?: string;
  groupId?: string;
  tabCourses: string[];
}

export interface TabDTO {
  name: string;
  privateUserId?: string;
  groupId?: string;
  tabCourses: string[];
}

export type Permission =
  | 'WriteVideo'
  | 'ReadVideo'
  | 'PublishVideo'
  | 'WriteForum'
  | 'ReadForum'
  | 'ReadCourse'
  | 'AssignAndRemoveCourse'
  | 'WriteForumCategories'
  | 'CorrectExam'
  | 'GroupAndCustomer';

export interface VideoData {
  uploadedAt: string;
  publishedAt: string | null;
  uploadedBy: VideoAuthor;
  fileName: string;
  isPublished: boolean;
  filePath: string;
}

export interface VideoAuthor {
  userId: string;
  name: string;
}

export interface Group {
  id: string;
  name: string;
}

export interface GroupDTO {
  name?: string;
  members?: string[];
  admins?: string[];
}

export interface GroupMember {
  id: string;
  name: string;
  email: string;
  actors: any[];
  isGroupAdmin: boolean;
}

export interface UserAreaOrGroupId {
  userAreaId?: string;
  groupId?: string;
}

export function isCourseAdmin(user: User): boolean {
  return (
    user.instanceRoles.includes('CourseAdmin') ||
    user.instanceRoles.includes('courseadmin')
  );
}

export function isForumAdmin(user: User): boolean {
  return (
    user.instanceRoles.includes('ForumAdmin') ||
    user.instanceRoles.includes('forumadmin')
  );
}

export function isAdmin(user: User): boolean {
  return (
    user.instanceRoles.includes('Admin') || user.instanceRoles.includes('admin')
  );
}

export function isSuperAdmin(user: User | null): boolean {
  if (user) {
    return (
      user.instanceRoles.includes('SuperAdmin') ||
      user.instanceRoles.includes('superadmin')
    );
  }
  return false;
}
