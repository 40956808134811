import { SvgIcon, SvgIconProps } from "@material-ui/core";

const BackIcon = (props:SvgIconProps)=> {
    return (
      <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" >
        <path d="M9.96075 5.08575L3.58575 11.4608L3.0705 12L3.58575 12.5393L9.96075 18.9143L11.0392 17.8358L5.9535 12.75H21V11.25H5.95275L11.0392 6.16425L9.96075 5.08575Z" />
      </SvgIcon>
    );   
}

export default BackIcon;