import { useContext, useState } from 'react';
import Uploady, {
  UploadyContext,
  useItemProgressListener,
  useItemFinishListener,
} from '@rpldy/uploady';
import { Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  courseHttpClient as httpClient,
  baseApiUrl,
} from '../../api/httpClient';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Document, Session } from '../../types';
import Message from '../translation/Message';

type DocumentUploaderProps = {
  session: Session;
  onUploadFinish?: (document: Document) => void;
  isFileDownloadable: boolean;
  hasUpload?: boolean;
  uploaderName: string;
};
const DocumentUploader = ({
  session,
  onUploadFinish,
  isFileDownloadable,
  hasUpload = false,
  uploaderName,
}: DocumentUploaderProps) => {
  const { oidcUser } = useReactOidc();
  //Todo later: Add support for uploading multiple files
  return (
    <Uploady
      inputFieldName="document"
      destination={{
        url: `${
          httpClient.defaults.baseURL ?? baseApiUrl
        }Course/SessionDocument/${
          session.id
        }/document?isFileDownloadable=${isFileDownloadable}&uploaderName=${uploaderName}`,
        headers: { Authorization: 'Bearer ' + oidcUser.access_token },
      }}
      // Accept PDF and files ending in .doc/.docx
      accept="application/pdf,.doc,.docx,.pptx,.xlsx,.jpeg,.jpg,.png"
    >
      <InnerDocumentUploader hasUpload={hasUpload} />
      <UploadProgress onUploadFinish={onUploadFinish} />
    </Uploady>
  );
};

type InnerDocumentUploaderProps = {
  hasUpload?: boolean;
};
const InnerDocumentUploader = ({ hasUpload }: InnerDocumentUploaderProps) => {
  const uploady = useContext(UploadyContext);

  const onClick = () => {
    uploady.showFileUpload();
  };

  return (
    <Button
      color="primary"
      variant="contained"
      startIcon={<CloudUploadIcon />}
      onClick={onClick}
    >
      {hasUpload ? (
        <Message id="course.courseAdmin.editCoursePage.edit" />
      ) : (
        <Message id="course.courseAdmin.editCoursePage.upload" />
      )}
    </Button>
  );
};

type UploadProgressProps = {
  onUploadFinish?: (document: Document) => void;
};

const UploadProgress = ({ onUploadFinish }: UploadProgressProps) => {
  const [progress, setProgress] = useState(0);
  const progressData = useItemProgressListener();
  useItemFinishListener((item) => {
    if (onUploadFinish) {
      const document = item.uploadResponse.data as Document;
      //TODO Handle errors
      onUploadFinish(document);
    }
  });

  if (progressData && progressData.completed > progress) {
    setProgress(() => progressData.completed);
  }

  // return (
  //   progressData && (
  //     <CircularProgress variant="determinate" value={progress / 100} />
  //   )
  // );
  return null;
};

export default DocumentUploader;
