import {
    Box,
    Typography,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import Message from '../translation/Message';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatHeader: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '16px',
            userSelect: 'none'
        }
    })
);

const CustomChannelListHeader = () => {
    const classes = useStyles();
    return (
        <Box className={classes.chatHeader}>
            <Typography variant='h6'><Message id='chat.chatHeader' /></Typography>
        </Box>
    )
}

export default CustomChannelListHeader;
