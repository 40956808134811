import {
    Theme,
    Grid,
    Box,
    Typography,
    Divider,
    Checkbox,
    makeStyles,
    createStyles
} from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { CoursePreview } from '../../../types';
import Message from '../../../components/translation/Message';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listContainer: {
            width: '100%',
            maxWidth: 800,
            marginBottom: theme.spacing(4)
        },
        header: {
            marginBottom: theme.spacing(2)
        },
        columnRow: {
            justifyContent: 'space-between',
            alignSelf: 'center',
            flexWrap: 'nowrap',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        columnRowTop: {
            justifyContent: 'space-between',
            alignSelf: 'center',
            flexWrap: 'nowrap',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            backgroundColor: '#f5f5f5'
        },
        columnItem: {
            justifyContent: 'space-between',
            alignSelf: 'center',
            paddingRight: theme.spacing(2),
        },
        flex: {
            display: 'flex',
            alignItems: 'center'
        },
        visibilityOffIcon: {
            display: 'flex',
            color: '#4D555B',
            paddingRight: theme.spacing(1),
        }
    })
);

type CourseListProps = {
    header: string | JSX.Element,
    courseTypeId: string | null,
    isCourseTypeInactive?: boolean,
    coursePreviews: CoursePreview[],
    checkedIds: string[],
    onEdit: (coursePreviewId: string) => void,
    onAddAll: (courseTypeId: string | null) => void,
}

const CourseList = ({
    header,
    courseTypeId,
    isCourseTypeInactive,
    coursePreviews,
    checkedIds,
    onEdit,
    onAddAll,
}: CourseListProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.listContainer}>
            <Typography variant='h6' className={classes.header}>
                {header}
            </Typography>
            <Divider component='div' />
            <Grid container className={classes.columnRowTop}>
                <Typography
                    variant='subtitle2'
                    component='p'
                    className={classes.columnItem}
                >
                    <Message id='userArea.allIn' />
                    {' '}
                    {header}
                </Typography>
                <Box className={classes.flex}>
                    {isCourseTypeInactive &&
                        <Box className={classes.visibilityOffIcon}>
                            <VisibilityOffOutlinedIcon fontSize='small' />
                        </Box>
                    }
                    <Checkbox
                        checked={coursePreviews.every(preview => checkedIds.includes(preview.id))}
                        onChange={() => onAddAll(courseTypeId)}
                    />
                </Box>
            </Grid>
            <Divider component='div' />
            {coursePreviews.map((coursePreview: CoursePreview) => {
                if (!coursePreview.isActive) return <Box key={coursePreview.id} />;
                return (
                    <Box component='span' key={coursePreview.id}>
                        <Grid container className={classes.columnRow}>
                            <Typography
                                variant='body1'
                                component='p'
                                className={classes.columnItem}
                            >
                                {coursePreview.name}
                            </Typography>
                            <Box className={classes.flex}>
                                <Checkbox
                                    checked={checkedIds.includes(coursePreview.id)}
                                    onChange={() => onEdit(coursePreview.id)}
                                />
                            </Box>
                        </Grid>
                        <Divider component='div' />
                    </Box>
                )
            })}
        </Box>
    );
};

export default CourseList;