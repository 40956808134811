import { useEffect, useState } from 'react';
import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import Message from './translation/Message';
import { PaginationData } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    pagination: {
      marginBottom: '0.5em',
    },
    buttonPageNumber: {
      width: '48px',
    },
    activePageNumber: {
      color: theme.palette.primary.main,
    },
    showingCopy: {
      display: 'inline',
      marginLeft: '0.5em',
    },
    dataList: {
      width: '100%',
    },
  })
);

type PaginationProps = {
  data: any[];
  RenderComponent: (props: any) => JSX.Element;
  RenderHeading?: (props: any) => JSX.Element;
  pageLimit: number;
  dataLimit: number;
  chosenFilter?: string;
  paginationPosition?: 'top' | 'bottom';
};
const Pagination = ({
  data,
  RenderComponent,
  RenderHeading,
  pageLimit,
  dataLimit,
  chosenFilter,
  paginationPosition = 'top',
}: PaginationProps) => {
  const [pageCount, setPageCount] = useState(
    Math.ceil(data.length / dataLimit)
  );
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data.length / dataLimit));
    }
  }, [data, dataLimit]);

  const classes = useStyles();

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function changePage(eventTarget: any) {
    const pageNumber = Number(eventTarget.innerText);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };
  const paginatedData = getPaginatedData();

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    const pagesAmountToShow = pageCount > pageLimit ? pageLimit : pageCount;
    return new Array(pagesAmountToShow)
      .fill(0)
      .map((_, idx) => start + idx + 1);
  };

  const renderPagination = () => (
    <Box
      className={classes.pagination}
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <IconButton onClick={goToPreviousPage} disabled={currentPage === 1}>
        <ArrowLeft />
      </IconButton>
      {getPaginationGroup().map((pageNumber) => (
        <IconButton
          key={pageNumber}
          onClick={(event) => changePage(event.target)}
          className={classes.buttonPageNumber}
        >
          <Typography
            component="span"
            className={
              currentPage === pageNumber ? classes.activePageNumber : ''
            }
          >
            {pageNumber}
          </Typography>
        </IconButton>
      ))}
      <IconButton onClick={goToNextPage} disabled={currentPage === pageCount}>
        <ArrowRight />
      </IconButton>
      <Box className={classes.showingCopy}>
        <Typography variant="caption">
          <Message id="statistics.showing" />
          {' ' + paginatedData.length + ' '}
          <Message id="statistics.of" />
          {' ' + data.length}
          {chosenFilter && (
            <>
              {' '}
              <Message id="statistics.in" />
              {' ' + chosenFilter}
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box className={classes.container}>
      {paginationPosition === 'top' && renderPagination()}

      {RenderHeading && <RenderHeading />}

      <Box className={classes.dataList}>
        {paginatedData.map((d: PaginationData) => (
          <RenderComponent key={d.id} {...d} />
        ))}
      </Box>

      {paginationPosition === 'bottom' && renderPagination()}
    </Box>
  );
};

export default Pagination;
