import React from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Button,
  Card,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../../components/translation/Message';
import {
  providersSelector,
  fetchProviders,
} from '../../../store/providersSlice';
import EditableTitle from '../../../components/EditableTitle';
import {
  updateProvider,
  deleteProvider,
  deleteInstructor,
  updateInstructor,
  createInstructor,
} from '../../../api/course-api';
import DeleteButton from '../../../components/DeleteButton';
import { Instructor, Provider } from '../../../types';
import { useParams, useHistory } from 'react-router-dom';
import { getMessage } from '../../../whitelabel-config/WhitelabelProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    allInstructorsList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: theme.spacing(4),
    },
    instructorCard: {
      padding: theme.spacing(2),
    },
  })
);

const EditProvidersDashboardPage = () => {
  const dispatch = useDispatch();
  const providers = useSelector(providersSelector);
  let params: { id: string } = useParams();
  let history = useHistory();
  const classes = useStyles();
  const provider = providers?.find((p) => p.id === params.id);
  React.useEffect(() => {
    if (!providers) {
      dispatch(fetchProviders());
    }
  }, [providers, dispatch]);

  const onChangeProviderName = (providerId: string, name: string) => {
    const provider = providers?.find((p) => p.id === providerId);
    if (provider) {
      updateProvider({ ...provider, name }).then(() => {
        dispatch(fetchProviders());
      });
    }
  };

  const onDeleteProvider = (providerId: string) => {
    deleteProvider(providerId).then(() => {
      dispatch(fetchProviders());
      history.goBack();
    });
  };

  const createNewInstructor = (name: string, title: string | null) => {
    if (name && provider) {
      createInstructor(provider.id, name, title).then(() => {
        dispatch(fetchProviders());
      });
    }
  };

  if (!provider) {
    return <CircularProgress size={64} />;
  }

  return (
    <Box p={4}>
      <Box display="flex" alignItems="center" mb={4}>
        <EditableTitle
          title={provider.name}
          onChange={(name) => onChangeProviderName(provider.id, name)}
        />
        <DeleteButton
          itemCategory={getMessage('course.provider')}
          itemName={provider.name}
          onDelete={() => onDeleteProvider(provider.id)}
          dialogTitle={getMessage('course.courseAdmin.deleteProvider')}
        />
      </Box>
      <Box>
        <Typography variant="h5" component="h2" gutterBottom>
          <Message id="course.courseAdmin.editProviderPage.instructors" />
        </Typography>
        <Box className={classes.allInstructorsList}>
          {provider.instructors.map((instructor) => (
            <EditInstructorItem
              key={instructor.id}
              provider={provider}
              instructor={instructor}
            />
          ))}
          <AddInstructorCard onAdd={createNewInstructor} />
        </Box>
      </Box>
    </Box>
  );
};

export default EditProvidersDashboardPage;

type EditInstructorItemProps = {
  provider: Provider;
  instructor: Instructor;
};

const EditInstructorItem = ({
  provider,
  instructor,
}: EditInstructorItemProps) => {
  const [title, setTitle] = React.useState<string>('');
  const dispatch = useDispatch();
  const classes = useStyles();

  const onChangeInstructorName = (name: string) => {
    updateInstructor(provider.id, { ...instructor, name }).then(() => {
      dispatch(fetchProviders());
    });
  };

  const onChangeTitle = (title: string | null) => {
    if (title !== '' && title !== undefined) {
      updateInstructor(provider.id, { ...instructor, title }).then(() => {
        dispatch(fetchProviders());
      });
    }
  };

  const onDeleteInstructor = () => {
    deleteInstructor(provider.id, instructor.id).then(() => {
      dispatch(fetchProviders());
    });
  };
  return (
    <Card variant="outlined" className={classes.instructorCard}>
      <Box display="flex" alignItems="center" key={instructor.id}>
        <EditableTitle
          title={instructor.name}
          variant="h6"
          component="p"
          onChange={onChangeInstructorName}
        />
        <DeleteButton
          itemCategory={getMessage('course.instructor')}
          itemName={instructor.name}
          onDelete={onDeleteInstructor}
          dialogTitle={getMessage('course.courseAdmin.deleteInstructor')}
        />
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        {instructor.title ? (
          <>
            <EditableTitle
              title={instructor.title}
              variant="body1"
              component="p"
              onChange={onChangeTitle}
            />
            <IconButton aria-label="delete" onClick={() => onChangeTitle(null)}>
              <DeleteIcon />
            </IconButton>
          </>
        ) : (
          <>
            <TextField
              label="Title"
              size="small"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && onChangeTitle(title)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => onChangeTitle(title)}
            >
              <Message id="course.courseAdmin.editCoursePage.setVideoUrl" />
            </Button>
          </>
        )}
      </Box>
    </Card>
  );
};

type AddInstructorCardProps = {
  onAdd: (name: string, title: string | null) => void;
};

const AddInstructorCard = ({ onAdd }: AddInstructorCardProps) => {
  const classes = useStyles();
  const [name, setName] = React.useState<string>('');
  const [title, setTitle] = React.useState<string>('');

  const handleOnAdd = () => {
    if (name) {
      onAdd(name, title ? title : null);
      setName('');
      setTitle('');
    }
  };

  return (
    <Card variant="outlined" className={classes.instructorCard}>
      <Typography variant="h6" component="h3" gutterBottom>
        <Message id="course.courseAdmin.editProviderPage.addInstructor" />
      </Typography>
      <Box mb={2}>
        <TextField
          label="Name"
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Title"
          size="small"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" color="primary" onClick={handleOnAdd}>
          <Message id="course.courseAdmin.editCoursePage.add" />
        </Button>
      </Box>
    </Card>
  );
};
