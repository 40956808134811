import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import { useMemo } from "react";
import DataGridHeader from "../../components/DataGridHeader";
import { Actor, ActorsUsersDetails, UserDetails } from "../../types";
import { getMessage } from "../../whitelabel-config/WhitelabelProvider";

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        actorList: {
            width: 1095,
            maxWidth: '100%',
        },
        clickable: {
            width: '100%',
            cursor: 'pointer'
        },
        trucateCellText: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inherit',
        },
    })
);

type ActorsUserListProps = {
    actorUsersList: ActorsUsersDetails[],
    onOpenDialog: () => void,
    onSetUserDetails: (newUserDetails: UserDetails) => void
}
const ActorsUserList = ({
    actorUsersList,
    onOpenDialog,
    onSetUserDetails
}: ActorsUserListProps) => {
    const classes = useStyles();

    const userListRows = useMemo(() => {
        if (!actorUsersList) {
            return [];
        }
        return actorUsersList.map((actorUser) => ({
            ...actorUser,
            id: actorUser.id,
            actors: actorUser.actors.map((a: Actor) => a.name).join(', '),
            numberOfRelationships: actorUser.actorRelationships.length,
        }));
    }, [actorUsersList]);

    const userListColumns: GridColDef[] = [
        {
            field: 'name',
            renderHeader: () => <DataGridHeader text={getMessage("handleActors.userName")} />,
            flex: 1,
            renderCell: (params: GridCellParams) => (
                <div
                    onClick={() => {
                        onOpenDialog();
                        onSetUserDetails(params.row as UserDetails);
                    }}
                    className={classes.clickable}
                >
                    {params.value as string}
                </div>
            ),
        },
        {
            field: 'email',
            renderHeader: () => <DataGridHeader text={getMessage("handleUser.email")} />,
            flex: 1,
        },
        {
            field: 'actors',
            renderHeader: () => <DataGridHeader text={getMessage("handleActors.actors")} />,
            flex: 1,
            renderCell: (params: GridCellParams) => (
                <div style={{ minWidth: 120 }}>
                    <span className={classes.trucateCellText}>
                        {params.value as string}
                    </span>
                </div>
            )
        },
        {
            field: 'numberOfRelationships',
            renderHeader: () => <DataGridHeader text={getMessage("handleActors.numbersOfRelations")} />,
            width: 120,
            align: 'center',
            renderCell: (params: GridCellParams) => (
                <div
                    onClick={() => {
                        onOpenDialog();
                        onSetUserDetails(params.row as UserDetails);
                    }}
                    className={classes.clickable}
                >
                    {params.value as string}
                </div>
            ),
        },
    ];

    return (
        <DataGrid
            autoHeight
            rows={userListRows}
            columns={userListColumns}
            pageSize={10}
            className={classes.actorList}
        />
    )
}

export default ActorsUserList;