import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  userAreaSelector,
  fetchPrivateForumPreviews,
  privateForumPreviewsSelector,
  permissionsForUserAreaSelector,
  privateForumIsLoadingSelector,
  deletePrivateForumTopic,
} from '../../../store/userAreaSlice';
import {
  Box,
  Theme,
  makeStyles,
  createStyles,
  Button,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import Message from '../../../components/translation/Message';
import ForumList from '../../../components/forum/ForumListOverview';
import PrivateTopicPage from '../PrivateTopicPage';
import { fetchForumCategories, forumCategoriesSelector } from '../../../store/forumCategoriesSlice';
import PageOrSectionHeader from '../../../components/typography/PageOrSectionHeader';
import CreateNewTopic from '../../../components/forum/CreateNewTopic';
import { getPrivateCreateTopicUrl } from '../../../routes/paths';
import { currentGroupSelector } from '../../../store/groupSlice';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    selectPadding: {
      padding: '10px 14px',
    },
  })
);

const PrivateForumPage = () => {
  const [categoryToShow, setCategoryToShow] = useState<string>('');

  const userArea = useSelector(userAreaSelector);
  const currentGroup = useSelector(currentGroupSelector);
  const permissionsForUserArea = useSelector(permissionsForUserAreaSelector);
  const privateForumPreviews = useSelector(privateForumPreviewsSelector);
  const categories = useSelector(forumCategoriesSelector);
  const isLoading = useSelector(privateForumIsLoadingSelector);

  let params: { slug: string } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchForumCategories({ userAreaId: userArea?.id, groupId: currentGroup?.id }));
  }, [currentGroup, dispatch, userArea]);

  useEffect(() => {
    if (categories) {
      dispatch(fetchPrivateForumPreviews(
        {
          userAreaId: userArea?.id,
          groupId: currentGroup?.id
        },
        categoryToShow
      ));
    }
  }, [dispatch, userArea, categoryToShow, params.slug, categories, currentGroup]);

  const handleCategoryChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCategoryToShow(event.target.value as string);
  };

  return (
    <Box width='100%' maxWidth='1300px'>
      {(currentGroup || (userArea && permissionsForUserArea)) &&
        <Box>
          {!params.slug &&
            <Box>
              <PageOrSectionHeader>
                <Message id="forum.overviewPage.titleTopic" />
              </PageOrSectionHeader>
              <Box pt={4} pb={2} display='flex' justifyContent='space-between' flexWrap='wrap'>
                {categories && categories.length > 0 &&
                  <Select
                    labelId='private-topic-category'
                    value={categoryToShow}
                    onChange={handleCategoryChange}
                    defaultValue='Show all categories'
                    variant='outlined'
                    classes={{ outlined: classes.selectPadding }}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <Message id="forum.overviewPage.showCategories" />
                    </MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
                {categories &&
                  categories.length === 0 &&
                  (currentGroup || permissionsForUserArea?.includes('WriteForum')) &&
                  <Box pr={2} width={500}>
                    <Typography color='error'>
                      <Message id='forum.overviewPage.noCategories' />
                    </Typography>
                  </Box>
                }
                {!categories && <Box pt={2} />}
                {(currentGroup || permissionsForUserArea?.includes('WriteForum')) && (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!categories || categories.length === 0}
                    component={Link}
                    to={getPrivateCreateTopicUrl(currentGroup?.id || userArea?.id || '')}
                  >
                    <Message id="forum.overviewPage.createTopic" />
                  </Button>
                )}
              </Box>

              <ForumList
                topicPreviews={privateForumPreviews}
                isLoading={isLoading}
                onDelete={(id: string) => {
                  dispatch(deletePrivateForumTopic(id));
                }}
              />
            </Box>
          }
          {params.slug && params.slug !== 'create-topic' &&
            <PrivateTopicPage />
          }
          {params.slug && params.slug === 'create-topic' &&
            <CreateNewTopic
              userAreaId={userArea?.id}
              groupId={currentGroup?.id}
            />
          }
        </Box>
      }
    </Box>
  );
};

export default PrivateForumPage;
