import { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Theme,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { getUnreadMessageCount } from '../../api/chat-api';
import ChatIcon from '@material-ui/icons/SmsOutlined';
import Message from '../translation/Message';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/userSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatButton: {
      padding: '12px 24px 0px 21px',
      borderRadius: '4px',
      backgroundColor: 'white',
      color: '#141718',
      position: 'absolute',
      bottom: 0,
      right: 0,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      display: 'flex',
      border: `1px solid ${theme.palette.grey[300]}`,
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    chatIcon: {
      marginRight: '16px',
      color: theme.palette.grey[600],
    },
    unreadMessagesBubble: {
      width: '18px',
      height: '18px',
      borderRadius: '50%',
      backgroundColor: 'red',
      position: 'absolute',
      top: '5px',
      right: '4px',
    },
    unreadMessagesBubbleText: {
      color: 'white',
      position: 'absolute',
      top: '1px',
      right: '4px',
    },
    moreThanNineBubbleText: {
      color: 'white',
      position: 'absolute',
      top: '1px',
      right: '3px',
      letterSpacing: 0,
    },
  })
);

type ChatButtonProps = {
  onClick: () => void;
};
const ChatButton = ({ onClick }: ChatButtonProps) => {
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
  const user = useSelector(userSelector);
  const classes = useStyles();

  useEffect(() => {
    if (user && user.userProfile) {
      getUnreadMessageCount().then((count) => setUnreadMessageCount(count));
    }
  }, [user]);

  return (
    <Box onClick={onClick}>
      <Paper elevation={2} className={classes.chatButton}>
        <Box className={classes.chatIcon}>
          <ChatIcon />
        </Box>
        <Typography variant="body1">
          <Message id="chat.openChat" />
        </Typography>
        {unreadMessageCount > 0 && (
          <Box className={classes.unreadMessagesBubble}>
            {unreadMessageCount > 9 ? (
              <Typography
                variant="overline"
                className={classes.moreThanNineBubbleText}
              >
                9+
              </Typography>
            ) : (
              <Typography
                variant="button"
                className={classes.unreadMessagesBubbleText}
              >
                {unreadMessageCount}
              </Typography>
            )}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ChatButton;
