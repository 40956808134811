import {
    makeStyles,
    Theme,
    createStyles,
    Box,
    Button,
    Typography
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Message from "../../../components/translation/Message";
import {
    fetchCoursePreviews
} from "../../../store/coursesSlice";
import {
    courseTypesSelector,
    privateCoursePreviewsSelector
} from "../../../store/selectors";
import { updateTab } from "../../../store/userAreaSlice";
import { Tab, TabDTO } from "../../../types";
import CourseList from "./CourseList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginBottom: theme.spacing(4)
        },
        flexContainerEditing: {
            display: 'flex',
            justifyContent: 'space-between'
        },
    })
);

type EditMasterClassesProps = {
    onBack: () => void,
    currentTab: Tab
}
const EditMasterClasses = ({
    onBack,
    currentTab
}: EditMasterClassesProps) => {
    const [addedIds, setAddedIds] = useState<string[]>([]);

    const privateCoursePreviews = useSelector(privateCoursePreviewsSelector);
    const courseTypes = useSelector(courseTypesSelector);

    const classes = useStyles();
    const dispatch = useDispatch();
    const params: { id: string } = useParams();

    useEffect(() => {
        dispatch(fetchCoursePreviews());

        if (courseTypes) {
            courseTypes.forEach(courseType => {
                dispatch(fetchCoursePreviews(courseType.courseType.id));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, dispatch]);

    useEffect(() => {
        setAddedIds([]);
        if (privateCoursePreviews) {
            privateCoursePreviews.forEach(coursePreview => {
                setAddedIds(addedIds => [...addedIds, coursePreview.id]);
            })
        }
    }, [privateCoursePreviews]);

    const noChangesMade = () => {
        const previewIds = privateCoursePreviews?.map(preview => preview.id);
        const valuesAreSame = previewIds?.every(previewId => addedIds.includes(previewId));
        const lengthIsSame = previewIds?.length === addedIds.length;
        return (valuesAreSame && lengthIsSame);
    }

    const noCoursesYet = () => {
        return (!courseTypes || !courseTypes.find(ct => ct.coursePreview.length > 0));
    }

    const handleEditAddedIds = (coursePreviewId: string) => {
        if (addedIds.includes(coursePreviewId)) {
            const filteredIdList = [...addedIds].filter(id => id !== coursePreviewId);
            setAddedIds(filteredIdList);
        } else {
            setAddedIds([...addedIds, coursePreviewId]);
        }
    }

    const handleAddAllInCourseType = (courseTypeId: string | null) => {
        if (courseTypeId) {
            const courseType = courseTypes?.find(courseType => courseType.courseType.id === courseTypeId);
            const previewIds = courseType?.coursePreview.map(preview => preview.id);
            const everyIdIsInAddedIds = previewIds?.every(id => addedIds.includes(id));

            if (everyIdIsInAddedIds) {
                const filteredIdList = [...addedIds].filter(id => !previewIds?.includes(id));
                setAddedIds(filteredIdList);
            } else {
                const uniqueIds = new Set([...addedIds, ...previewIds as string[]]);
                let uniqueIdsArray: string[] = []
                uniqueIds.forEach(id => uniqueIdsArray.push(id));

                setAddedIds(uniqueIdsArray);
            }
        }
    }

    const handleSave = () => {
        const updateObj: TabDTO = {
            name: currentTab.name,
            privateUserId: currentTab.privateUserId,
            groupId: currentTab.groupId,
            tabCourses: addedIds
        }
        dispatch(updateTab(currentTab.id, updateObj));
        onBack();
    }

    const handleBack = () => {
        if (!noChangesMade()) {
            setAddedIds([]);
            if (privateCoursePreviews) {
                privateCoursePreviews.forEach(coursePreview => {
                    setAddedIds(addedIds => [...addedIds, coursePreview.id]);
                });
            }
        }
        onBack();
    }

    return (
        <Box>
            <Box className={classes.flexContainerEditing}>
                <Button
                    className={classes.button}
                    onClick={handleBack}
                >
                    <Message id='userArea.backFromEdit' />
                </Button>
                <Button
                    className={classes.button}
                    color='primary'
                    onClick={handleSave}
                    variant='contained'
                    disabled={noChangesMade()}
                >
                    <Message id='userArea.saveEditedMasterclasses' />
                </Button>
            </Box>
            {courseTypes && courseTypes.map(courseType => {
                if (courseType.coursePreview.length > 0) {
                    return (
                        <CourseList
                            key={courseType.courseType.id}
                            header={courseType.courseType.name}
                            courseTypeId={courseType.courseType.id}
                            isCourseTypeInactive={!courseType.courseType.isActive}
                            coursePreviews={courseType.coursePreview}
                            checkedIds={addedIds}
                            onEdit={handleEditAddedIds}
                            onAddAll={handleAddAllInCourseType}
                        />
                    )
                } else { return <Box key={courseType.courseType.id} />; }
            })}
            {noCoursesYet() &&
                <Box>
                    <Typography>
                        <Message id='userArea.noCoursesAddedYet' />
                    </Typography>
                </Box>
            }
        </Box>
    )
}

export default EditMasterClasses;