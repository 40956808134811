import { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  Theme,
  Box,
  Typography,
  Button,
  makeStyles,
  createStyles,
  CircularProgress,
  Tabs,
  Tab as MUITab,
  styled,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  permissionsForUserAreaSelector,
  tabsSelector,
} from '../../../store/userAreaSlice';
import {
  fetchCourseTypes,
  fetchPrivateCoursePreviewsByIds,
  fetchTabVideos,
  resetPrivateCoursePreviews,
} from '../../../store/coursesSlice';
import {
  setCurrentCourseType,
  currentCourseTypeSelector,
  fetchFilteredCourses,
  filteredCoursesSelector,
} from '../../../store/editCourseSlice';
import {
  privateCoursePreviewsSelector,
  courseTypesSelector,
  tabVideosSelector,
} from '../../../store/selectors';
import CourseCard from '../../../components/courses/CourseCard';
import Message from '../../../components/translation/Message';
import groupBy from 'lodash/groupBy';
import {
  CoursePreview,
  CourseType,
  isAdmin,
  Tab,
  VideoData,
} from '../../../types';
import EditMasterClasses from './EditMasterClasses';
import ViewVideoDialog from './ViewVideoDialog';
import { useSnackbar } from 'notistack';
import VideoCardWithMetaData from './VideoCardWithMetaData';
import { getMessage } from '../../../whitelabel-config/WhitelabelProvider';
import { activeSubModulesSelector } from '../../../store/instanceSlice';
import { privateMasterClassUrl, privateVideosUrl } from '../../../routes/paths';
import {
  addTabVideo,
  deleteTabVideo,
  publishTabVideo,
} from '../../../api/course-api';
import { fetchNotifications } from '../../../store/notificationsSlice';
import {
  currentGroupMembersSelector,
  currentGroupSelector,
} from '../../../store/groupSlice';
import { userSelector } from '../../../store/userSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categories: {
      marginBottom: theme.spacing(3),
    },
    categoryButton: {
      marginRight: theme.spacing(2),
    },
    allCoursesList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(340px, 0fr))',
      gridGap: '24px',
    },
  })
);

const Input = styled('input')({
  display: 'none',
});

type PrivateMasterclassesPageProps = {
  tab: Tab;
};
const PrivateMasterclassesPage = ({ tab }: PrivateMasterclassesPageProps) => {
  const [isEditingMasterclasses, setIsEditingMasterclasses] =
    useState<boolean>(false);
  const [showAllActivePreviews, setShowAllActivePreviews] =
    useState<boolean>(true);
  const [viewingVideo, setViewingVideo] = useState<VideoData | null>(null);
  const [tabValue, setTabValue] = useState<number>(0); // 0 for classes, 1 for videos

  const permissions = useSelector(permissionsForUserAreaSelector);
  const currentGroup = useSelector(currentGroupSelector);
  const groupMembers = useSelector(currentGroupMembersSelector);
  const user = useSelector(userSelector);
  const privateCoursePreviews = useSelector(privateCoursePreviewsSelector);
  const courseTypes = useSelector(courseTypesSelector);
  const currentCourseType = useSelector(currentCourseTypeSelector);
  const filteredPreviews = useSelector(filteredCoursesSelector);
  const tabs = useSelector(tabsSelector);
  const tabVideos = useSelector(tabVideosSelector);
  const activeSubModules = useSelector(activeSubModulesSelector);

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const params: { id: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const currentTab = tabs?.find((t) => t.id === tab.id);
    if (
      currentTab &&
      (params.id === currentTab.privateUserId ||
        params.id === currentTab.groupId) &&
      (permissions || currentGroup)
    ) {
      dispatch(
        fetchPrivateCoursePreviewsByIds(currentTab.tabCourses, currentGroup?.id)
      );
      dispatch(fetchTabVideos(currentTab.id));
    }

    return () => {
      dispatch(resetPrivateCoursePreviews());
    };
  }, [tab, tabs, params.id, dispatch, permissions, currentGroup]);

  useEffect(() => {
    if (
      activeSubModules &&
      activeSubModules.Notification &&
      activeSubModules.Notification.includes('VideoNotifications')
    ) {
      dispatch(fetchNotifications());
    }
  }, [activeSubModules, dispatch, tabVideos]);

  useEffect(() => {
    if (!courseTypes) {
      dispatch(fetchCourseTypes());
    }
  }, [courseTypes, dispatch]);

  useEffect(() => {
    if (window.location.pathname.includes('video')) {
      setTabValue(1);
      return;
    }

    if (
      (currentGroup && activeSubModules?.Course.includes('GroupCourse')) ||
      (!currentGroup && activeSubModules?.Course.includes('PrivateCourse'))
    ) {
      setTabValue(0);
    } else {
      setTabValue(1);
    }
  }, [activeSubModules?.Course, currentGroup]);

  const isCurrentUserGroupAdmin = () => {
    if (groupMembers && currentGroup && user) {
      return (
        isAdmin(user) ||
        !!groupMembers.find((m) => m.id === user.userInstanceId)?.isGroupAdmin
      );
    } else {
      return false;
    }
  };

  const isMasterclassesAndVideosOn = () => {
    if (currentGroup) {
      return (
        activeSubModules &&
        activeSubModules.Course.includes('GroupCourse') &&
        activeSubModules.Course.includes('GroupVideos')
      );
    } else {
      return (
        activeSubModules &&
        activeSubModules.Course.includes('PrivateCourse') &&
        activeSubModules.Course.includes('PrivateVideos')
      );
    }
  };

  const getActivePrivatePreviews = (): CoursePreview[] => {
    if (!privateCoursePreviews) {
      return [];
    }
    return privateCoursePreviews.filter((preview) => preview.isActive);
  };

  const getFilteredPreviews = (): CoursePreview[] => {
    const activePrivatePreviews = getActivePrivatePreviews();
    if (showAllActivePreviews && activePrivatePreviews.length > 0) {
      return activePrivatePreviews;
    } else if (filteredPreviews && activePrivatePreviews) {
      return filteredPreviews.filter(
        (filteredPreview) =>
          activePrivatePreviews.find(
            (privatePreview) => privatePreview.id === filteredPreview.id
          ) && filteredPreview.isActive
      );
    }

    return [];
  };

  const getActiveCourseTypes = () => {
    const groupedPreviews = groupBy(getActivePrivatePreviews(), 'courseType');
    let activeCourseTypes: CourseType[] = [];
    Object.keys(groupedPreviews).forEach((id) => {
      if (id === '00000000-0000-0000-0000-000000000000') {
        activeCourseTypes.push({
          id: id,
          name: '',
          icon: 'Default',
          isActive: true,
          order: 0,
        });
      }

      const courseType = courseTypes?.find((ct) => ct.courseType.id === id);
      if (courseType) {
        activeCourseTypes.push(courseType.courseType);
      }
    });

    return activeCourseTypes;
  };

  const getSortedVideos = () => {
    if (!tabVideos) return [];
    return tabVideos.slice().sort((a, b) => {
      const aDate = a.publishedAt
        ? new Date(a.publishedAt)
        : new Date(a.uploadedAt);
      const bDate = b.publishedAt
        ? new Date(b.publishedAt)
        : new Date(a.uploadedAt);
      return bDate.valueOf() - aDate.valueOf();
    });
  };

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleAddVideo = (event: any) => {
    const video = event.target.files[0];
    addTabVideo(video, tab.id, tab.privateUserId)
      .then(() => {
        enqueueSnackbar(<Message id="userArea.videos.uploadSuccess" />, {
          variant: 'success',
        });
        if (
          (permissions && permissions.includes('ReadVideo')) ||
          currentGroup
        ) {
          dispatch(fetchTabVideos(tab.id));
        }
      })
      .catch((error) => {
        enqueueSnackbar(<Message id="userArea.videos.uploadFailed" />, {
          variant: 'error',
        });
      });
  };

  const handleDeleteVideo = () => {
    if (!viewingVideo) return;
    const split1 = viewingVideo.filePath.split('/');
    const videoId = split1[split1.length - 1].split('?')[0];
    deleteTabVideo(videoId, tab.id)
      .then(() => {
        enqueueSnackbar(<Message id="userArea.videos.deleteSuccess" />, {
          variant: 'success',
        });
        if (
          (permissions && permissions.includes('ReadVideo')) ||
          currentGroup
        ) {
          dispatch(fetchTabVideos(tab.id));
        }
        setViewingVideo(null);
      })
      .catch((error) => {
        enqueueSnackbar(<Message id="userArea.videos.deleteFailed" />, {
          variant: 'error',
        });
      });
  };

  const handlePublish = () => {
    if (!viewingVideo) return;
    const split1 = viewingVideo.filePath.split('/');
    const videoId = split1[split1.length - 1].split('?')[0];
    publishTabVideo({
      videoId: videoId,
      tabId: tab.id,
      publish: true,
    })
      .then(() => {
        enqueueSnackbar(<Message id="userArea.videos.publishSuccess" />, {
          variant: 'success',
        });
        if (
          (permissions && permissions.includes('ReadVideo')) ||
          currentGroup
        ) {
          dispatch(fetchTabVideos(tab.id));
        }
        setViewingVideo(null);
      })
      .catch((error) => {
        enqueueSnackbar(<Message id="userArea.videos.publishFailed" />, {
          variant: 'error',
        });
      });
  };

  const activePrivatePreviews = getActivePrivatePreviews();
  const activeCourseTypes = getActiveCourseTypes();

  return (
    <Box>
      {isEditingMasterclasses && (
        <EditMasterClasses
          onBack={() => setIsEditingMasterclasses(false)}
          currentTab={tab}
        />
      )}
      {!isEditingMasterclasses && isMasterclassesAndVideosOn() && (
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
          >
            <MUITab
              label={getMessage('userArea.masterclassesLabel')}
              onClick={() => {
                history.push(
                  generatePath(privateMasterClassUrl, {
                    id: currentGroup ? tab.groupId : tab.privateUserId,
                    tabId: tab.id,
                  })
                );
              }}
            />
            <MUITab
              label={getMessage('userArea.videosLabel')}
              onClick={() => {
                history.push(
                  generatePath(privateVideosUrl, {
                    id: currentGroup ? tab.groupId : tab.privateUserId,
                    tabId: tab.id,
                  })
                );
              }}
            />
          </Tabs>
        </Box>
      )}
      {!isEditingMasterclasses && (
        <Box mt={3}>
          <Box display="flex" justifyContent="end" mb={4}>
            {tabValue === 0 &&
              ((currentGroup && isCurrentUserGroupAdmin()) ||
                (permissions &&
                  permissions.includes('AssignAndRemoveCourse'))) && (
                <Button
                  color="primary"
                  onClick={() => setIsEditingMasterclasses(true)}
                  variant="contained"
                >
                  {privateCoursePreviews &&
                  privateCoursePreviews.length === 0 ? (
                    <Message id="userArea.addPrivateMasterclasses" />
                  ) : (
                    <Message id="userArea.editPrivateMasterclasses" />
                  )}
                </Button>
              )}
            {tabValue === 1 &&
              (currentGroup || permissions?.includes('WriteVideo')) && (
                <label htmlFor="video-input">
                  <Input
                    accept="video/mp4, video/quicktime, video/x-msvideo"
                    id="video-input"
                    multiple={false}
                    type="file"
                    onChange={handleAddVideo}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    <Message id="userArea.videos.uploadVideo" />
                  </Button>
                </label>
              )}
          </Box>

          {/* TODO: Use TabPanels */}
          {tabValue === 0 && (
            <>
              {activePrivatePreviews.length > 0 &&
                activeCourseTypes.length > 1 && (
                  <Box className={classes.categories}>
                    <Button
                      variant="contained"
                      color={showAllActivePreviews ? 'primary' : 'default'}
                      className={classes.categoryButton}
                      onClick={() => setShowAllActivePreviews(true)}
                    >
                      <Message id="userArea.allFilter" />
                    </Button>
                    {activeCourseTypes.map((courseType) => {
                      return (
                        <Button
                          key={courseType.id}
                          variant="contained"
                          color={
                            !showAllActivePreviews &&
                            currentCourseType?.id === courseType.id
                              ? 'primary'
                              : 'default'
                          }
                          className={classes.categoryButton}
                          onClick={() => {
                            dispatch(fetchFilteredCourses(courseType.id));
                            dispatch(setCurrentCourseType(courseType));
                            setShowAllActivePreviews(false);
                          }}
                        >
                          {courseType.name ? (
                            courseType.name
                          ) : (
                            <Message id="userArea.masterclassesLabel" />
                          )}
                        </Button>
                      );
                    })}
                  </Box>
                )}
              <Box className={classes.allCoursesList}>
                {getFilteredPreviews()?.map((preview) => (
                  <CourseCard coursePreview={preview} key={preview.id} />
                ))}
              </Box>
              {privateCoursePreviews && activePrivatePreviews.length === 0 && (
                <Box>
                  <Typography variant="body1">
                    <Message id="userArea.noMasterclassesAssigned" />
                  </Typography>
                </Box>
              )}
              {!privateCoursePreviews && (
                <Box>
                  <CircularProgress size={64} />
                </Box>
              )}
            </>
          )}

          {tabValue === 1 && (
            <Box
              display="flex"
              flexWrap="wrap"
              width="100%"
              justifyContent="center"
            >
              {getSortedVideos().map((video) => (
                <VideoCardWithMetaData
                  key={video.filePath}
                  video={video}
                  canPublish={
                    (permissions && permissions.includes('PublishVideo')) ||
                    !!(currentGroup && isCurrentUserGroupAdmin())
                  }
                  onClick={() => setViewingVideo(video)}
                />
              ))}
              {(!tabVideos || tabVideos.length === 0) && (
                <Box mt={4}>
                  <Typography>
                    <Message id="userArea.videos.noVideosAdded" />
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}

      {viewingVideo && (
        <ViewVideoDialog
          video={viewingVideo}
          isOpen={!!viewingVideo}
          onClose={() => setViewingVideo(null)}
          onDelete={handleDeleteVideo}
          canDelete={
            (permissions && permissions.includes('PublishVideo')) ||
            !!(currentGroup && isCurrentUserGroupAdmin())
          }
          canDownload={
            (permissions && permissions.includes('PublishVideo')) ||
            !!(currentGroup && isCurrentUserGroupAdmin())
          }
          canPublish={
            (permissions && permissions.includes('PublishVideo')) ||
            !!(currentGroup && isCurrentUserGroupAdmin())
          }
          onPublish={handlePublish}
        />
      )}
    </Box>
  );
};

export default PrivateMasterclassesPage;
