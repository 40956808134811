import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CourseCard from '../../components/courses/CourseCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeaturedCourses } from '../../store/coursesSlice';
import { courseTypesSelector } from '../../store/editCourseSlice';
import {
  coursesIsLoadingSelector,
  featuredCoursesSelector,
} from '../../store/selectors';
import {
  fetchIntro,
  introSelector,
  introLoadingSelector,
  introBeenFetchedSelector,
  instanceModulesSelector,
  activeSubModulesSelector,
} from '../../store/instanceSlice';
import Message from '../../components/translation/Message';
import PlayCourseIcon from '../../components/icons/PlayCourseIcon';
import SafeHtmlRender from '../../components/SafeHtmlRenderer';
import ReactPlayer from 'react-player';
import PageOrSectionHeader from '../../components/typography/PageOrSectionHeader';
import { useHistory } from 'react-router-dom';
import { groupAndCustomerOnlySelector } from '../../store/userAreaSlice';
import { WhitelabelContext } from '../../whitelabel-config/WhitelabelProvider';
import { getStaticContentImage } from '../../api/instance-api';
import { CoursePreview } from '../../types';
import noImage from '../../assets/no-image.jpg';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    featuredCourseList: {
      display: 'flex',
      overflow: 'scroll',
      flexWrap: 'wrap',
      '& > *': {
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },
    },
    featuredCourses: {
      display: 'flex',
      flexDirection: 'column',
    },
    featuredCourseGrid: {
      paddingTop: theme.spacing(1),
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(340px, 0fr))',
      gridGap: '25px',
    },
    featuredContainer: {
      display: 'flex',
      maxWidth: '1070px',
      width: '100%',
    },
    featured: {
      marginRight: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        width: '100%',
      },
    },
    featuredIntro: {
      '& > *': {
        '& :not(p:first-of-type)': {
          display: 'none',
        },
        '& p:first-of-type': {
          '& span': {
            display: 'block',
            fontSize: '14px !important',
          },
        },
      },
    },
    instructorGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',
    },
    featuredImg: {
      width: '600px',
      maxWidth: '100%',
      height: '400px',
      borderRadius: '0',
      objectFit: 'cover',
      [theme.breakpoints.down('md')]: {
        height: '300px',
        marginTop: theme.spacing(2),
      },
    },
    startButton: {
      marginTop: theme.spacing(4),
      width: '30ch',
      alignSelf: 'center',
    },
    showAllMasterClass: {
      textAlign: 'center',
      marginTop: theme.spacing(8),
      textDecoration: 'none',
      '& > span': {
        fontSize: '14px',
      },
    },
    videoContainer: {
      width: 700,
      height: 380,
      minWidth: 700,
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: 'unset',
      },
      [theme.breakpoints.down('xs')]: {
        height: '60vw',
      },
    },
    marginText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
  })
);

const Homepage = () => {
  const dispatch = useDispatch();
  const courseTypes = useSelector(courseTypesSelector);
  const isLoading = useSelector(coursesIsLoadingSelector);

  const intro = useSelector(introSelector);
  const introLoading = useSelector(introLoadingSelector);
  const introBeenFetched = useSelector(introBeenFetchedSelector);
  const history = useHistory();
  const featuredCourses = useSelector(featuredCoursesSelector);

  const activeModules = useSelector(instanceModulesSelector);
  const activeSubModules = useSelector(activeSubModulesSelector);
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const localStorage = window.localStorage;
  const groupAndCustomerOnly = useSelector(groupAndCustomerOnlySelector);
  const [isFetchedPreviews, setIsFetchedPreviews] = useState(false);

  const { getImage } = useContext(WhitelabelContext);
  const defaultImg = getImage('defaultMapImage');
  const [defaultImgUrl, setDefaultImgUrl] = useState('');

  useEffect(() => {

    if(!defaultImgUrl) {
      const fetchImage = async (
        imageUrl: string,
        setImage: React.Dispatch<React.SetStateAction<string>>
      ) => {
        const logoData = await getStaticContentImage(imageUrl);
        const imageSrcUrl = window.URL.createObjectURL(logoData);
        setImage(imageSrcUrl);
      };
      const fetchOrSetImage = (
        imageIdOrUrl: string,
        setImage: React.Dispatch<React.SetStateAction<string>>
      ) => {
        if (imageIdOrUrl && imageIdOrUrl.length > 0) {
          fetchImage(imageIdOrUrl, setImage);
        }
      };

      fetchOrSetImage(defaultImg, setDefaultImgUrl);
    }

  }, [defaultImg]);


  if (localStorage.getItem('backPath')) {
    localStorage.removeItem('backPath');
  }

  useEffect(() => {
    if (!intro && !introLoading && !introBeenFetched) {
      dispatch(fetchIntro());
    }
  }, [dispatch, intro, introLoading, introBeenFetched]);

  useEffect(() => {
    if (courseTypes && !isFetchedPreviews) {
      dispatch(fetchFeaturedCourses());
      setIsFetchedPreviews(true);
    }
  }, [courseTypes]);

  const getActiveFeaturedCourses = () => {
    return featuredCourses.filter(
      (c) =>
        c.isActive &&
        courseTypes?.find((ct) => ct.id === c.courseType && ct.isActive)
    );
  };

  const activeFeaturedCourses = getActiveFeaturedCourses();

  const videoBox = (
    <Box className={classes.videoContainer} mb={4}>
      <ReactPlayer
        key={intro?.videoUrl}
        url={intro?.videoUrl}
        controls
        width="100%"
        height="100%"
      />
    </Box>
  );

  const introButtonClick = () => {
    if (!intro) {
      return;
    }
    if (intro.buttonUrl.startsWith('/')) {
      history.push(intro.buttonUrl);
    } else {
      if (intro.buttonUrl.startsWith('www')) {
        window.open(`http://${intro.buttonUrl}`, '_blank');
      } else {
        window.open(intro.buttonUrl, '_blank');
      }
    }
  };

  const introButton = () => {
    if (!intro) {
      return;
    }

    if (intro.buttonUrl && intro.buttonUrl.includes('mailto')) {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.startButton}
          startIcon={<PlayCourseIcon />}
          href={intro.buttonUrl}
        >
          {intro.buttonText}
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.startButton}
        startIcon={<PlayCourseIcon />}
        onClick={introButtonClick}
      >
        {intro.buttonText}
      </Button>
    );
  };

  const loadingPage = () => {
    return (
      <Box p={{ xs: 1, md: 4 }}>
        <CircularProgress />
      </Box>
    );
  };

  const limitedPage = () => {
    return (
      <Box p={{ xs: 1, md: 4 }}>
        <Typography color="primary" variant="overline" component="p">
          intro
        </Typography>
        <Message id="course.home.limited" />
      </Box>
    );
  };

  const showCourseCard = (coursePreview : CoursePreview) => {
    if(coursePreview.previewImageUrl !== ''){
      return (
        <CourseCard
          coursePreview={coursePreview}
          key={coursePreview.id}
        />
      )
    } else {
      return (
        <CourseCard
          coursePreview={coursePreview}
          key={coursePreview.id}
          defaultImage={defaultImgUrl ?? noImage}
        />
      )
    }
  }

  const fullHomePage = () => {
    return (
      <Box p={{ xs: 1, md: 4 }}>
        {isLoading && introLoading && <CircularProgress size={64} />}
        {intro && intro.show && (
          <Box>
            <Typography color="primary" variant="overline" component="p">
              <Message id="course.home.intro.intro" />
            </Typography>

            <Box pb={8} className={classes.featuredContainer}>
              <Box className={classes.featured}>
                <PageOrSectionHeader>{intro.title}</PageOrSectionHeader>
                {!isDesktop && videoBox}
                <Typography
                  variant="body2"
                  component="div"
                  className={classes.marginText}
                >
                  <SafeHtmlRender html={intro.description} />
                </Typography>
                {introButton()}
              </Box>
              {isDesktop && videoBox}
            </Box>
          </Box>
        )}

        {activeModules?.includes('Course') &&
          activeSubModules?.Course.includes('PublicCourse') && (
            <Box>
              {activeFeaturedCourses.length > 0 && (
                <Box className={classes.featuredCourses}>
                  <PageOrSectionHeader>
                    <Message id="course.home.featured" />
                  </PageOrSectionHeader>
                  <Box className={classes.featuredCourseGrid}>
                    {activeFeaturedCourses.slice(0, 10).map((coursePreview) => (
                      showCourseCard(coursePreview)
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          )}
      </Box>
    );
  };

  return (
    <>
      {groupAndCustomerOnly === null && loadingPage()}
      {groupAndCustomerOnly !== null && groupAndCustomerOnly
        ? limitedPage()
        : fullHomePage()}
    </>
  );
};

export default Homepage;
