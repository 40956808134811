import { useEffect, useState } from 'react';
import {
    Box,
    Theme,
    makeStyles,
    createStyles,
    Typography,
} from '@material-ui/core/';
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridRowParams,
} from '@material-ui/data-grid';
import {
    Statistic,
    CustomerStatistic,
    ForumTopicPreview,
    CoursePreview,
    Customer
} from '../../../types';
import { getForumTopicPreviews } from '../../../api/forum-api';
import { getPrivateCoursePreviews } from '../../../api/course-api';
import DetailsDialog from './DetailsDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            cursor: 'pointer'
        },
        completed: {
            backgroundColor: theme.palette.success.light,
            width: '100%',
            padding: theme.spacing(1)
        },
        unfinished: {
            backgroundColor: theme.palette.warning.light,
            width: '100%',
            padding: theme.spacing(1)
        }
    })
);

type CustomerStatsGridProps = {
    customers: Customer[],
    assistantName: string,
    assistantStats: Statistic[] | null,
    timeFrame: string
}
const CustomerStatsGrid = ({
    customers,
    assistantName,
    assistantStats,
    timeFrame
}: CustomerStatsGridProps) => {
    const [modalCustomer, setModalCustomer] = useState<Customer | null>(null);
    const [forumTopics, setForumTopics] = useState<ForumTopicPreview[]>([]);
    const [courses, setCourses] = useState<{ customerId: string, courses: CoursePreview[] }[]>([]);

    const classes = useStyles();

    useEffect(() => {
        if (!assistantStats) return;

        let promises: Promise<ForumTopicPreview[]>[] = [];
        customers.forEach(customer => {
            promises.push(getForumTopicPreviews({ userAreaId: customer.id }));
        });
        Promise.all(promises).then(results => setForumTopics(results.flat(1)));
    }, [
        customers,
        assistantStats
    ]);

    useEffect(() => {
        if (!assistantStats) return;

        let results: { customerId: string, courses: Promise<CoursePreview[]> }[] = [];
        customers.forEach(customer => {
            results.push({
                customerId: customer.id,
                courses: getPrivateCoursePreviews(customer.id)
            });
        });

        results.forEach(result => {
            Promise.resolve(result.courses).then(c => {
                if (!courses.find(c => c.customerId === result.customerId)) {
                    const cs = courses;
                    cs.push({
                        customerId: result.customerId,
                        courses: c
                    });
                    setCourses(cs);
                }
            });
        });
    }, [assistantStats]);

    const handleModalOpen = (params: GridRowParams) => {
        const customerRow = params.row as CustomerStatistic;
        setModalCustomer({
            id: customerRow.customerId,
            name: customerRow.customerName
        });
    }

    const columns: GridColDef[] = [
        {
            field: 'customerName',
            headerName: 'Name',
            width: 200,
            renderCell: (params: GridCellParams) => {
                const name = (params.row as CustomerStatistic).customerName;
                return (
                    <Typography variant='subtitle2'>{name}</Typography>
                )
            }
        },
        {
            field: 'eventsTotal',
            headerName: 'Total events',
            width: 140
        },
        {
            field: 'forumTopicsViewed',
            headerName: 'Forum topics viewed',
            width: 140,
            renderCell: (params: GridCellParams) => {
                const forumTopicsViewed: number = (params.row as CustomerStatistic).forumTopicsViewed;
                const forumTopicsTotal: number = (params.row as CustomerStatistic).forumTopicsTotal;
                return (
                    <Box className={`${forumTopicsViewed === forumTopicsTotal
                        ? classes.completed
                        : classes.unfinished}`
                    }>
                        {forumTopicsViewed}
                        /
                        {forumTopicsTotal}
                    </Box>
                )
            }
        },
        {
            field: 'coursesViewed',
            headerName: 'Courses viewed',
            width: 140,
            renderCell: (params: GridCellParams) => {
                const coursesViewed: number = (params.row as CustomerStatistic).coursesViewed;
                const coursesTotal: number = (params.row as CustomerStatistic).coursesTotal;
                return (
                    <Box className={`${coursesViewed === coursesTotal
                        ? classes.completed
                        : classes.unfinished}`
                    }>
                        {(params.row as CustomerStatistic).coursesViewed}
                        /
                        {(params.row as CustomerStatistic).coursesTotal}
                    </Box>
                )
            }
        },
        {
            field: 'notViewed',
            headerName: 'Not viewed',
            width: 140,
            renderCell: (params: GridCellParams) => {
                const totalItemsToView: number =
                    (params.row as CustomerStatistic).forumTopicsTotal
                    + (params.row as CustomerStatistic).coursesTotal;
                const notViewed: number = (params.row as CustomerStatistic).notViewed;

                return (
                    <Box className={`${notViewed === 0
                        ? classes.completed
                        : classes.unfinished}`
                    }>
                        {notViewed}
                        /
                        {totalItemsToView}
                    </Box>
                )
            }
        }
    ];

    const getCustomerRows = () => {
        const users = customers.map(customer => {
            const forumTopicsInCustomerRoom = forumTopics.filter(ft => ft.privateForumUserId === customer.id);
            const forumTopicStats = assistantStats?.filter(
                stat => stat.eventType === 'FORUM_TOPIC_PAGELOAD_PRIVATE'
            );
            const viewedForumTopics = forumTopicsInCustomerRoom.filter(
                topic => forumTopicStats?.find(stat => stat.topicId === topic.id)
                    || forumTopicStats?.find(stat => stat.topicSlug === topic.slug)
            );
            const notSeenForumTopics = forumTopicsInCustomerRoom.length - viewedForumTopics.length;

            const coursesInCustomerRoom = courses?.filter(
                c => c.customerId === customer.id
            ).map(c => c.courses).flat(1);
            const courseStats = assistantStats?.filter(
                stat => stat.eventType === 'COURSE_PAGELOAD'
            );
            const viewedCourses = coursesInCustomerRoom.filter(
                course => courseStats?.find(stat => stat.courseId === course.id)
            );
            const notSeenCourses = coursesInCustomerRoom.length - viewedCourses.length;

            return ({
                id: customer.id,
                customerId: customer.id,
                customerName: customer.name,
                eventsTotal: assistantStats?.filter(stat =>
                    stat.userAreaId && stat.userAreaId === customer.id
                ).length || 0,
                forumTopicsViewed: viewedForumTopics.length,
                forumTopicsTotal: forumTopicsInCustomerRoom?.length || 0,
                coursesViewed: viewedCourses.length,
                coursesTotal: coursesInCustomerRoom?.length || 0,
                notViewed: notSeenForumTopics + notSeenCourses
            })
        });
        return users;
    }

    return (
        <Box>
            {customers &&
                <DataGrid
                    autoHeight
                    rows={getCustomerRows()}
                    onRowClick={handleModalOpen}
                    columns={columns}
                    getRowClassName={() => classes.row}
                    pageSize={6}
                />
            }
            {modalCustomer &&
                <DetailsDialog
                    modalCustomer={modalCustomer}
                    assistantName={assistantName}
                    timeFrame={timeFrame}
                    statistics={assistantStats}
                    forumTopics={forumTopics}
                    courses={courses.find(c => c.customerId === modalCustomer.id)?.courses || []}
                    onClose={() => setModalCustomer(null)}
                />
            }
        </Box>
    )
}

export default CustomerStatsGrid;
