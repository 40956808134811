import { forumHttpClient as httpClient } from './httpClient';
import {
  ForumCategory,
  ForumReply,
  ForumTopic,
  ForumTopicDTO,
  ForumTopicUpdate,
  ForumTopicPreview,
  UserAreaOrGroupId,
} from '../types';


// ForumTopic
export const getForumTopicPreviews = async (
  userOrGroupId?: UserAreaOrGroupId,
  categoryId?: string,
): Promise<ForumTopicPreview[]> => {
  let path = '';
  let hasAddedToPath = false;

  if (categoryId) {
    path = path.concat(`${hasAddedToPath ? '&' : '?'}filterCategoryId=${categoryId}`);
    hasAddedToPath = true;
  }

  if (userOrGroupId?.userAreaId) {
    path = path.concat(`${hasAddedToPath ? '&' : '?'}privateForumUserId=${userOrGroupId.userAreaId}`);
    hasAddedToPath = true;
  }

  if (userOrGroupId?.groupId) {
    path = path.concat(`${hasAddedToPath ? '&' : '?'}groupId=${userOrGroupId.groupId}`);
  }

  const response = await httpClient.get(`forum${path}`);
  return response.data;
};

export const getForumTopic = async (id: string): Promise<ForumTopic> => {
  const response = await httpClient.get(`forum/${id}`);
  return response.data;
};

export const getForumTopicFromSlug = async (
  slug: string
): Promise<ForumTopic> => {
  const response = await httpClient.get(`forum/slug/${slug}`);
  return response.data;
};

export const createForumTopic = async (
  topic: ForumTopicDTO
): Promise<ForumTopic> => {
  if (topic.privateForumUserId) {
    const response = await httpClient.post(
      `forum?privateForumUserId=${topic.privateForumUserId}`, topic
    );
    return response.data;

  } else if (topic.groupId) {
    const response = await httpClient.post(
      `forum?groupId=${topic.groupId}`, topic
    );
    return response.data;

  } else {
    const response = await httpClient.post(`forum`, topic);
    return response.data;
  }
};

export const deleteForumTopic = async (id: string) => {
  const response = await httpClient.delete(`forum/${id}`);
  return response.data;
};

export const updateForumTopic = async (id: string, topic: ForumTopicUpdate): Promise<ForumTopic> => {
  const response = await httpClient.put(`forum/${id}`, topic);
  return response.data;
};


// ForumReply
export const createForumReply = async (
  forumTopicId: string,
  formattedText: string
): Promise<ForumReply> => {
  const response = await httpClient.post(`forum/ForumReply/${forumTopicId}`, {
    formattedText,
  });
  return response.data;
};

export const deleteForumReply = async (id: string) => {
  const response = await httpClient.delete(`forum/forumreply/${id}`);
  return response.data;
};

export const updateForumReply = async (id: string, formattedText: string): Promise<ForumReply> => {
  const response = await httpClient.put(`forum/ForumReply/${id}`, { formattedText });
  return response.data;
};


// Image
export const addImage = async (
  image: File,
  userOrGroupId: UserAreaOrGroupId
): Promise<string> => {
  const formData = new FormData();
  formData.append('image', image);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  let path = '';
  if (userOrGroupId.userAreaId) {
    path = `?privateForumUserId=${userOrGroupId.userAreaId}`;
  }

  if (userOrGroupId.groupId) {
    path = `?groupId=${userOrGroupId.groupId}`;
  }

  const response = await httpClient.post(
    `forum/uploadimage${path}`,
    formData,
    config
  );
  return response.data;
}


// ForumCategory
export const getCategories = async ({
  userAreaId,
  groupId
}: UserAreaOrGroupId): Promise<ForumCategory[]> => {
  let path = '';
  if (userAreaId) {
    path = `?privateUserId=${userAreaId}`;
  }

  if (groupId) {
    path = `?groupId=${groupId}`;
  }

  const response = await httpClient.get(`forum/ForumCategory${path}`);
  return response.data;
};

export const createForumCategory = async (
  { name, privateUserId, groupId }:
    { name: string, privateUserId?: string, groupId?: string }
): Promise<ForumCategory> => {
  let path = '';
  if (privateUserId) {
    path = `?privateUserId=${privateUserId}`;
  }

  if (groupId) {
    path = `?groupId=${groupId}`;
  }

  const response = await httpClient.post(`forum/ForumCategory${path}`, { name });
  return response.data;
};

export const updateForumCategory = async (
  forumCategory: ForumCategory
): Promise<ForumCategory> => {
  const response = await httpClient.put(
    `forum/ForumCategory/${forumCategory.id}`,
    { name: forumCategory.name }
  );
  return response.data;
};

export const deleteForumCategory = async (forumCategoryId: string) => {
  const response = await httpClient.delete(
    `forum/ForumCategory/${forumCategoryId}`
  );
  return response.data;
};
