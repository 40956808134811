import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    Box,
    DialogActions,
    Button,
    Grid,
    createStyles,
    makeStyles,
    Theme
} from "@material-ui/core";
import Message from "../../../components/translation/Message";
import {
    Statistic,
    ForumTopicPreview,
    CoursePreview,
    Customer
} from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        columns: {
            justifyContent: 'space-between',
            // '& > *': {
            //     alignSelf: 'baseline',
            // },
            padding: theme.spacing(1)
        },
        backgroundUneven: {
            backgroundColor: theme.palette.background.default
        },
        backgroundEven: {
            backgroundColor: theme.palette.grey[100]
        },
        successColor: {
            color: theme.palette.success.main
        },
        warningColor: {
            color: theme.palette.warning.dark
        },
        overflowEllipsis: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    })
);

type DetailsDialogProps = {
    modalCustomer?: Customer,
    assistantName: string,
    timeFrame: string,
    statistics: Statistic[] | null,
    forumTopics: ForumTopicPreview[],
    courses: CoursePreview[],
    onClose: () => void
}
const DetailsDialog = ({
    modalCustomer,
    assistantName,
    timeFrame,
    statistics,
    forumTopics,
    courses,
    onClose
}: DetailsDialogProps) => {
    const classes = useStyles();

    const getMissedCourses = () => {
        const courseStats = statistics?.filter(
            stat => stat.eventType === 'COURSE_PAGELOAD'
        );
        const missedCourses = courses.filter(
            course => !courseStats?.find(stat => stat.courseId === course.id)
        );
        return missedCourses;
    }

    const getSeenCourses = () => {
        const courseStats = statistics?.filter(
            stat => stat.eventType === 'COURSE_PAGELOAD'
        ) || [];
        const seenCourses = courses.filter(
            course => courseStats?.find(stat => stat.courseId === course.id)
        ).map(course => {
            const seenAt: string[] = courseStats.filter(
                stat => stat.courseId === course.id
            ).map(c => c.timeOfEvent);
            return ({
                ...course,
                seenAt
            })
        });
        return seenCourses;
    }

    const getMissedForumTopics = () => {
        const forumTopicsInCustomerRoom = forumTopics.filter(ft => ft.privateForumUserId === modalCustomer?.id);
        const forumTopicStats = statistics?.filter(
            stat => stat.eventType === 'FORUM_TOPIC_PAGELOAD_PRIVATE'
        );
        const missedForumTopics = forumTopicsInCustomerRoom.filter(
            topic => (
                !forumTopicStats?.find(stat => stat.topicId === topic.id)
                && !forumTopicStats?.find(stat => stat.topicSlug === topic.slug))
        );
        return missedForumTopics;
    }

    const getSeenForumTopics = () => {
        const customerTopics = forumTopics.filter(ft => ft.privateForumUserId === modalCustomer?.id);
        const topicStats = statistics?.filter(stat =>
            stat.eventType === 'FORUM_TOPIC_PAGELOAD_PRIVATE'
        ) || [];

        const seenTopics: ForumTopicPreview[] = [];
        customerTopics.filter(t =>
            topicStats.find(s => s.topicId === t.id)
            || topicStats.find(s => s.topicSlug === t.slug)
        ).forEach(topic => {
            const seenAt: string[] = topicStats.filter(stat =>
                stat.topicId === topic.id
                || stat.topicSlug === topic.slug
            ).map(stat => stat.timeOfEvent);

            if (seenAt.length > 0) {
                seenTopics.push({
                    ...topic,
                    seenAt
                });
            }
        });

        return seenTopics;
    }

    return (
        <Dialog
            open={!!modalCustomer}
            onClose={onClose}
            maxWidth='xl'
            fullWidth
        >
            <DialogTitle>
                <Message id='statistics.activity' />
                <Typography variant='caption' display='block'>
                    {timeFrame}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {(!statistics || !modalCustomer) &&
                    <Message id='statistics.noActivity' />
                }
                {statistics && modalCustomer &&
                    <Box>
                        <BasicInfo
                            customerName={modalCustomer.name}
                            assistantName={assistantName}
                        />

                        <MissedCourses
                            classes={classes}
                            courses={getMissedCourses()}
                        />

                        <MissedForumTopics
                            classes={classes}
                            forumTopics={getMissedForumTopics()}
                        />

                        <SeenCourses
                            classes={classes}
                            courses={getSeenCourses()}
                            assistantName={assistantName}
                        />

                        <SeenForumTopics
                            classes={classes}
                            forumTopics={getSeenForumTopics()}
                            assistantName={assistantName}
                        />
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    <Message id='statistics.done' />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

type BasicInfoProps = {
    customerName: string,
    assistantName: string,
}
const BasicInfo = ({
    customerName,
    assistantName,
}: BasicInfoProps) => {
    return (
        <Box>
            <Box display='flex'>
                <Box mr={1}><Typography variant='subtitle2'>Assistant:</Typography></Box>
                <Typography>{assistantName}</Typography>
            </Box>
            <Box display='flex'>
                <Box mr={1}><Typography variant='subtitle2'>Client:</Typography></Box>
                <Typography>{customerName}</Typography>
            </Box>
        </Box>
    )
}

type MissedOrSeenCoursesProps = {
    classes: any,
    courses: CoursePreview[],
    assistantName?: string
}
const MissedCourses = ({
    classes,
    courses
}: MissedOrSeenCoursesProps) => {
    const missedCourses = courses;
    return (
        <Box mt={4}>
            <Typography variant='subtitle1'>
                <span className={classes.warningColor}>
                    <Message id='statistics.missed' />
                </span>
                {' '}
                <Message id='statistics.courses' />
            </Typography>
            {missedCourses.length === 0 &&
                <Box>
                    <Typography>
                        <Message id='statistics.noMissedCourses' />
                    </Typography>
                </Box>
            }
            {missedCourses.length > 0 &&
                <Box>
                    <Grid container className={classes.columns}>
                        <Grid item xs={3} md={3}>
                            <Typography variant='subtitle2'>
                                <Message id='statistics.course' />
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Typography variant='subtitle2'>
                                <Message id='statistics.posted' />
                            </Typography>
                        </Grid>
                    </Grid>
                    {missedCourses.map((missedCourse, index) => (
                        <Grid
                            key={missedCourse.id}
                            container
                            className={`${classes.columns} ${index % 2 === 0
                                ? classes.backgroundEven
                                : classes.backgroundUneven}`
                            }
                        >
                            <Grid item xs={3} md={3}>
                                <Typography className={classes.overflowEllipsis}>
                                    {missedCourse.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <Typography variant='caption'>Coming soon</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            }
        </Box>
    )
}

const SeenCourses = ({
    classes,
    courses,
    assistantName
}: MissedOrSeenCoursesProps) => {
    const seenCourses = courses;
    return (
        <Box mt={4}>
            <Typography variant='subtitle1'>
                <span className={classes.successColor}>
                    <Message id='statistics.visited' />
                </span>
                {' '}
                <Message id='statistics.courses' />
            </Typography>
            {seenCourses.length === 0 &&
                <Box>
                    <Typography>
                        <Message id='statistics.noSeenCourses' />
                    </Typography>
                </Box>
            }
            {seenCourses.length > 0 &&
                <Box>
                    <Grid container className={classes.columns}>
                        <Grid item xs={3} md={3}>
                            <Typography variant='subtitle2'>
                                <Message id='statistics.course' />
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Typography variant='subtitle2'>
                                <Message id='statistics.posted' />
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Typography variant='subtitle2'>
                                <Message id='statistics.visitedBy' />
                                {' ' + assistantName}
                            </Typography>
                        </Grid>
                    </Grid>
                    {seenCourses.map((seenCourse, index) => (
                        <Grid
                            key={seenCourse.id}
                            container
                            className={`${classes.columns} ${index % 2 === 0
                                ? classes.backgroundEven
                                : classes.backgroundUneven}`
                            }
                        >
                            <Grid item xs={3} md={3}>
                                <Typography className={classes.overflowEllipsis}>
                                    {seenCourse.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <Typography variant='caption'>Coming soon</Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                {seenCourse.seenAt && seenCourse.seenAt.map((seen: string, index: number) => (
                                    <Typography key={seen + index}>
                                        {new Date(seen).toLocaleString().slice(0, 17)}
                                    </Typography>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            }
        </Box>
    )
}

type MissedOrSeenForumTopicsProps = {
    classes: any,
    forumTopics: ForumTopicPreview[],
    assistantName?: string
}
const MissedForumTopics = ({
    classes,
    forumTopics
}: MissedOrSeenForumTopicsProps) => {
    const missedTopics = forumTopics;
    return (
        <Box mt={4}>
            <Typography variant='subtitle1'>
                <span className={classes.warningColor}>
                    <Message id='statistics.missed' /></span>
                {' '}
                <Message id='statistics.topics' />
            </Typography>
            {missedTopics.length === 0 &&
                <Box>
                    <Typography>
                        <Message id='statistics.noMissedTopics' />
                    </Typography>
                </Box>
            }
            {missedTopics.length > 0 &&
                <Box>
                    <Grid container className={classes.columns}>
                        <Grid item xs={3} md={3}>
                            <Typography variant='subtitle2'>
                                <Message id='statistics.topic' />
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Typography variant='subtitle2'>
                                <Message id='statistics.posted' />
                            </Typography>
                        </Grid>
                    </Grid>
                    {missedTopics.map((missedTopic: ForumTopicPreview, index: number) => (
                        <Grid
                            key={missedTopic.id}
                            container
                            className={`${classes.columns} ${index % 2 === 0
                                ? classes.backgroundEven
                                : classes.backgroundUneven}`
                            }
                        >
                            <Grid item xs={3} md={3}>
                                <Typography className={classes.overflowEllipsis}>
                                    {missedTopic.subject}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <Typography>
                                    {new Date(missedTopic.createdAt).toLocaleString().slice(0, 17)}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            }
        </Box >
    )
}

const SeenForumTopics = ({
    classes,
    forumTopics,
    assistantName
}: MissedOrSeenForumTopicsProps) => {
    const seenTopics = forumTopics;
    return (
        <Box mt={4}>
            <Typography variant='subtitle1'>
                <span className={classes.successColor}>
                    <Message id='statistics.visited' />
                </span>
                {' '}
                <Message id='statistics.topics' />
            </Typography>
            {seenTopics.length === 0 &&
                <Box>
                    <Typography>
                        <Message id='statistics.noSeenTopics' />
                    </Typography>
                </Box>
            }
            {seenTopics.length > 0 &&
                <Box>
                    <Grid container className={classes.columns}>
                        <Grid item xs={3} md={3}>
                            <Typography variant='subtitle2'>
                                <Message id='statistics.topic' />
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Typography variant='subtitle2'>
                                <Message id='statistics.posted' />
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Typography variant='subtitle2'>
                                <Message id='statistics.visitedBy' />
                                {' ' + assistantName}
                            </Typography>
                        </Grid>
                    </Grid>
                    {seenTopics.map((seenTopic: ForumTopicPreview, index: number) => (
                        <Grid
                            key={seenTopic.id}
                            container
                            className={`${classes.columns} ${index % 2 === 0
                                ? classes.backgroundEven
                                : classes.backgroundUneven}`
                            }
                        >
                            <Grid item xs={3} md={3}>
                                <Typography className={classes.overflowEllipsis}>
                                    {seenTopic.subject}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <Typography>
                                    {new Date(seenTopic.createdAt).toLocaleString().slice(0, 17)}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                {seenTopic.seenAt && seenTopic.seenAt.map((seen: string, index: number) => (
                                    <Typography key={seen + index}>
                                        {new Date(seen).toLocaleString().slice(0, 17)}
                                    </Typography>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            }
        </Box >
    )
}

export default DetailsDialog;