import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Course,
  CourseDTO,
  Session,
  Document,
  CourseType,
  CoursePreview,
} from '../types';
import { AppThunk } from './index';
import {
  getCourseFromSlug,
  updateCourse as updateCourseAPI,
  updateSession as updateSessionAPI,
  deleteCourse as deleteCourseAPI,
  uploadPreviewImage as uploadPreviewImageAPI,
  updateSessionDocument as updateSessionDocumentAPI,
  deleteSessionDocument as deleteSessionDocumentAPI,
  createCourseType as createCourseTypeAPI,
  getCourseTypes as getCourseTypesAPI,
  getCoursePreviews as getCoursePreviewsAPI,
  updateCourseType as updateCourseTypeAPI,
  updateCourseTypeOrder as updateCourseTypeOrderAPI,
  deleteCourseType as deleteCourseTypeAPI,
} from '../api/course-api';

import { RootState } from './rootReducer';

export interface EditCourseState {
  course: Course | null;
  isLoading: boolean;
  error: string | null;
  isSaving: boolean;
  courseTypes: CourseType[] | null;
  currentCourseType: CourseType | null;
  filteredCourses: CoursePreview[] | null;
  loadingCourses: boolean;
}

const initialState = {
  course: null,
  isLoading: false,
  error: null,
  isSaving: false,
  courseTypes: null,
  currentCourseType: null,
  filteredCourses: null,
  loadingCourses: false,
} as EditCourseState;

function startLoading(state: EditCourseState) {
  state.isLoading = true;
}

function loadingFailed(state: EditCourseState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const editCourseSlice = createSlice({
  name: 'editCourse',
  initialState: initialState,
  reducers: {
    getCourseStart: startLoading,
    getCourseSuccess(state, action: PayloadAction<Course>) {
      state.course = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getCourseFailure: loadingFailed,
    updateCourseStart(state, action: PayloadAction<Course>) {
      state.course = action.payload;
      state.isSaving = true;
    },
    updateCourseSuccess(state, action: PayloadAction<Course>) {
      state.course = action.payload;
      state.error = null;
      state.isSaving = false;
    },
    updateCourseFailure(state, action: PayloadAction<string>) {
      state.isSaving = false;
      state.error = action.payload;
    },
    removeCourseSuccess(state) {
      state.course = null;
    },
    addSessionDocument(
      state,
      action: PayloadAction<{
        session: Session;
        document: Document;
        isFileDownloadable: Boolean;
      }>
    ) {
      const { session, document } = action.payload;
      state.course?.sessions
        .find((s) => s.id === session.id)
        ?.documents.push(document);
    },
    updateSessionDoc(
      state,
      action: PayloadAction<{ session: Session; document: Document }>
    ) {
      const { session, document } = action.payload;
      const sessionToUpdate = state.course?.sessions.find(
        (s) => s.id === session.id
      );
      if (sessionToUpdate) {
        sessionToUpdate.documents = sessionToUpdate?.documents.map((d) =>
          d.id === document.id ? document : d
        );
      }
    },
    deleteSessionDocument(
      state,
      action: PayloadAction<{ session: Session; document: Document }>
    ) {
      const { session, document } = action.payload;
      const sessionToUpdate = state.course?.sessions.find(
        (s) => s.id === session.id
      );
      if (sessionToUpdate) {
        sessionToUpdate.documents = sessionToUpdate?.documents.filter(
          (d) => d.id !== document.id
        );
      }
    },
    addCourseType(state, action: PayloadAction<CourseType>) {
      if (!state.courseTypes) {
        state.courseTypes = [action.payload];
        return;
      }
      state.courseTypes.push(action.payload);
    },

    getCourseTypes(state, action: PayloadAction<CourseType[]>) {
      state.courseTypes = action.payload;
    },

    getFilteredCourses(state, action: PayloadAction<CoursePreview[]>) {
      state.filteredCourses = action.payload;
    },

    setLoadingCourses(state, action: PayloadAction<boolean>) {
      state.loadingCourses = action.payload;
    },
    updateCourseType(state, action: PayloadAction<CourseType>) {
      if (!state.courseTypes) {
        return;
      }

      state.courseTypes = state.courseTypes.map((type) => {
        if (type.id === action.payload.id) {
          type = action.payload;
        }
        return type;
      });
    },
    removeCourseType(state, action: PayloadAction<CourseType>) {
      if (!state.courseTypes) {
        return;
      }
      state.courseTypes = state.courseTypes.filter(
        (type) => type.id !== action.payload.id
      );
    },
    setCurrentCourseType(state, action: PayloadAction<CourseType | null>) {
      state.currentCourseType = action.payload;
    },
  },
});

export const {
  getCourseStart,
  getCourseSuccess,
  getCourseFailure,
  updateCourseStart,
  updateCourseSuccess,
  updateCourseFailure,
  removeCourseSuccess,
  addSessionDocument,
  updateSessionDoc,
  addCourseType,
  getCourseTypes,
  getFilteredCourses,
  setLoadingCourses,
  updateCourseType,
  removeCourseType,
  setCurrentCourseType,
} = editCourseSlice.actions;

export default editCourseSlice.reducer;

// Thunks

export const fetchCourseFromSlug =
  (slug: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCourseStart());
      const course = await getCourseFromSlug(slug);
      dispatch(getCourseSuccess(course));
    } catch (err: any) {
      dispatch(getCourseFailure(err.toString()));
    }
  };

export const updateCourse =
  (course: Course): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateCourseStart(course));
      const courseDTO: CourseDTO = {
        name: course.name,
        providerId: course.provider.id,
        formattedIntroText: course.formattedIntroText,
        previewImageUrl: course.previewImageUrl,
        tags: course.tags,
        instructorIds: course.instructors.map((i) => i.id),
        featured: course.featured,
        isActive: course.isActive,
        courseType: course.courseType,
        repeatable: course.repeatable,
        sessionIds: course.sessionOrder?.map((i) => i.id),
      };
      const updatedCourse = await updateCourseAPI(course.id, courseDTO);
      dispatch(updateCourseSuccess(updatedCourse));
    } catch (err: any) {
      dispatch(updateCourseFailure(err.toString()));
    }
  };

export const updateSession =
  (session: Session): AppThunk =>
  async (dispatch, getState) => {
    try {
      const course = { ...(getState().editCourse.course as Course) };
      course.sessions = course.sessions.map((s) =>
        s.id === session.id ? session : s
      );
      dispatch(updateCourseStart(course));
      const updatedSession = await updateSessionAPI(session);
      course.sessions = course.sessions.map((s) =>
        s.id === updatedSession.id ? updatedSession : s
      );
      dispatch(updateCourseSuccess(course));
    } catch (err: any) {
      dispatch(updateCourseFailure(err.toString()));
    }
  };

export const uploadPreviewImage =
  (imageFile: File): AppThunk =>
  async (dispatch, getState) => {
    try {
      const course = getState().editCourse.course as Course;
      const updatedCourse = await uploadPreviewImageAPI(course.id, imageFile);
      dispatch(updateCourseSuccess(updatedCourse));
    } catch (err: any) {
      dispatch(updateCourseFailure(err.toString()));
    }
  };

export const updateSessionDocument =
  (session: Session, document: Document): AppThunk =>
  async (dispatch) => {
    try {
      const updatedDocument = await updateSessionDocumentAPI(
        session.id,
        document
      );
      dispatch(
        updateSessionDoc({
          session,
          document: updatedDocument,
        })
      );
    } catch (err: any) {
      dispatch(updateCourseFailure(err.toString()));
    }
  };

export const deleteSessionDocument =
  (session: Session, document: Document): AppThunk =>
  async (dispatch) => {
    try {
      await deleteSessionDocumentAPI(session.id, document.id);
      dispatch(
        editCourseSlice.actions.deleteSessionDocument({ session, document })
      );
    } catch (err: any) {
      dispatch(updateCourseFailure(err.toString()));
    }
  };

export const createCourseType =
  (name: string, icon: string): AppThunk =>
  async (dispatch) => {
    try {
      const courseType = await createCourseTypeAPI(name, icon);
      dispatch(addCourseType(courseType));
      dispatch(setCurrentCourseType(courseType));
      dispatch(getFilteredCourses([]));
    } catch (err: any) {
      console.error(err);
    }
  };

export const fetchCourseTypes = (): AppThunk => async (dispatch) => {
  try {
    const courseType = await getCourseTypesAPI();
    dispatch(getCourseTypes(courseType));
  } catch (err: any) {
    console.error(err);
  }
};

export const fetchFilteredCourses =
  (courseTypeId: string = ''): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoadingCourses(true));
      const courses = await getCoursePreviewsAPI(courseTypeId);
      dispatch(getFilteredCourses(courses));
      dispatch(setLoadingCourses(false));
    } catch (err: any) {
      dispatch(setLoadingCourses(false));
      console.error(err);
    }
  };

export const changeCourseType =
  (courseType: CourseType): AppThunk =>
  async (dispatch) => {
    try {
      const updatedCourseType = await updateCourseTypeAPI(courseType);
      dispatch(updateCourseType(updatedCourseType));
    } catch (err: any) {
      console.error(err);
    }
  };

export const changeCourseTypeOrder =
  (courseTypes: CourseType[]): AppThunk =>
  async (dispatch) => {
    try {
      await updateCourseTypeOrderAPI(courseTypes);
      const courseType = await getCourseTypesAPI();
      dispatch(getCourseTypes(courseType));
    } catch (err: any) {
      console.error(err);
    }
  };

export const deleteCourseType =
  (courseType: CourseType): AppThunk =>
  async (dispatch) => {
    try {
      const removedCourseType = await deleteCourseTypeAPI(courseType.id);
      dispatch(removeCourseType(removedCourseType));
      dispatch(setCurrentCourseType(null));
      dispatch(fetchFilteredCourses());
    } catch (err: any) {
      console.error(err);
    }
  };

export const deleteCourse = (): AppThunk => async (dispatch, getState) => {
  if (getState()?.editCourse?.course) {
    try {
      await deleteCourseAPI(getState()?.editCourse?.course?.id || '');
      dispatch(removeCourseSuccess());

      dispatch(
        fetchFilteredCourses(getState()?.editCourse?.currentCourseType?.id)
      );
    } catch (err: any) {
      console.error(err);
      dispatch(setLoadingCourses(false));
    }
  }
};

// Selectors
const selectEditCourseState = (rootState: RootState) => rootState.editCourse;

export const courseIsLoadingSelector = createSelector(
  selectEditCourseState,
  (editCourseState: EditCourseState) => editCourseState.isLoading
);

export const courseSelector = createSelector(
  selectEditCourseState,
  (editCourseState: EditCourseState) => editCourseState.course
);

export const courseIsSavingSelector = createSelector(
  selectEditCourseState,
  (editCourseState: EditCourseState) => editCourseState.isSaving
);

export const courseTypesSelector = createSelector(
  selectEditCourseState,
  (editCourseState: EditCourseState) => editCourseState.courseTypes
);

export const filteredCoursesSelector = createSelector(
  selectEditCourseState,
  (editCourseState: EditCourseState) => editCourseState.filteredCourses
);

export const loadingCoursesSelector = createSelector(
  selectEditCourseState,
  (editCourseState: EditCourseState) => editCourseState.loadingCourses
);

export const currentCourseTypeSelector = createSelector(
  selectEditCourseState,
  (editCourseState: EditCourseState) => editCourseState.currentCourseType
);
