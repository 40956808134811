import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  makeStyles,
  Dialog,
  DialogContent,
  Avatar,
  Chip,
  DialogActions,
  IconButton,
  TextField,
  InputLabel,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import ISO6391 from 'iso-639-1';
import Countries from 'countries-list';
import { useDispatch, useSelector } from 'react-redux';
import { MyUserProfile, InstanceUser } from '../types';
import {
  getProfileData,
  resendInviteLoadingSelector,
  profileDataHonorificsSelector,
  profileDataRolesSelector,
  resendInviteUser,
} from '../store/userSlice';
import { getProfilePicture } from '../api/user-api';
import Messages from '../components/translation/Message';
import CustomChip from './CustomChip';
import Message from '../components/translation/Message';
import { Done } from '@material-ui/icons';

const useStyles = makeStyles((_theme) => ({
  profilePic: {
    width: '162px',
    height: '162px',
  },
  editProfilePic: {
    backgroundColor: 'rgba(20, 23, 24, 0.45) !important',
    borderRadius: '50%',
    width: '162px',
    height: '162px',
    lineHeight: '20px',
    position: 'absolute',
    top: '20px',
  },
  editProfilePicText: {
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '162px',
  },
  infoIcon: {
    marginRight: '10px',
  },
  infoProfileBox: {
    display: 'flex',
    alignItems: 'center',
  },
  languagesBox: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '300px',
  },
  infoProfileLabel: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ABB0B5',
  },
  languagesChip: {
    margin: '0 5px 5px 0',
    '& > * ': {
      fontSize: '12px;',
    },
  },
  messageButton: {
    marginTop: '10px',
    padding: '5px 25px',
    height: 'fit-content',
  },
  editButton: {
    padding: '0 12px',
  },
  input: {
    display: 'none',
  },

  showEditPicture: {
    display: 'block',
  },
  hideEditPicture: {
    display: 'none',
  },
  honorificTextBox: {
    backgroundColor: '#D7DADD',
    width: '90px',
    marginRight: '5px',
    alignSelf: 'center',
  },
  honorificText: {
    fontSize: '34px',
    fontWeight: 'bold',
    padding: '2px 1px !important',
    '& > div > input': {
      fontSize: '34px',
      fontWeight: 'bold',
      padding: '2px 1px !important',
    },
  },
  checkBoxCursor: { cursor: 'default' },
  checkboxAnonymous: {
    fontSize: '13px',
  },
  autocompleteInput: {
    '& input': {
      padding: '12px !important',
    },
  },
  inviteButton: {
    fontSize: 12,
    height: 'auto',
    width: 'auto',
    float: 'left',
    marginLeft: 15,
  },
}));

const getCountries = () => {
  const newArray = [{ code: '', name: '', emoji: '' }];
  for (const [key, value] of Object.entries(Countries.countries)) {
    const country = {
      name: value.name,
      emoji: value.emoji,
      code: key,
    };
    newArray.push(country);
  }
  return newArray;
};

const emptyProfileData = {
  name: '',
  honorific: '',
  role: '',
  languages: [],
  organization: '',
  country: '',
  profilePicture: '',
  externalId: '',
  isAnonymous: false,
  inviteSent: false,
};

type UserData = Omit<InstanceUser, 'emailConfirmed' | 'roles' | 'email'>;

type UserProfileProps = {
  changeable: boolean;
  open: boolean;
  onClose: () => void;
  updateProfile?: (userProfile: MyUserProfile) => void;
  userProfileData: MyUserProfile | null;
  user: UserData;
  isLoading?: boolean;
  isLoggedInUsersProfile?: boolean;
  emailConfirmed?: boolean;
};

const UserProfile = (props: UserProfileProps) => {
  const {
    open,
    onClose,
    updateProfile,
    userProfileData,
    user,
    isLoading,
    isLoggedInUsersProfile,
    changeable,
    emailConfirmed,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const roles = useSelector(profileDataRolesSelector);
  const honorifics = useSelector(profileDataHonorificsSelector);
  const resendInviteLoading = useSelector(resendInviteLoadingSelector);

  const filter = createFilterOptions<string>();
  const countries = getCountries();

  const [edit, setEdit] = useState<boolean>(false);
  const [editPicture, setEditPicture] = useState<boolean>(
    !userProfileData
  );
  const [profilePic, setProfilePic] = useState<string>('');
  const [userProfile, setUserProfile] = useState<MyUserProfile>({
    ...emptyProfileData,
  });

  const [inviteSent, setInviteSent] = useState<boolean>(false);

  useEffect(() => {
    setInviteSent(false);
  }, [props]);

  useEffect(() => {
    if (edit && honorifics.length <= 0 && roles.length <= 0) {
      dispatch(getProfileData());
    }
  }, [dispatch, edit, honorifics.length, roles.length]);

  useEffect(() => {
    const getPic = async (id: string) => {
      try {
        const picture = await getProfilePicture(id);
        const url = window.URL.createObjectURL(picture);
        setProfilePic(url);
      } catch (err: any) {
        if (err.response?.status === 404) {
          setProfilePic('');
        }
      }
    };

    if (userProfileData && userProfileData.profilePicture) {
      getPic(user.id);
    }
  }, [dispatch, user.id, userProfileData]);

  useEffect(() => {
    if (userProfileData) {
      setUserProfile({ ...userProfileData });
      setEdit(false);
    } else {
      setUserProfile({ ...emptyProfileData, name: user.name });
      if (changeable) {
        setEdit(true);
      }
    }
  }, [userProfileData, user.name, changeable]);

  const handleClose = () => {
    onClose();
    if (!isLoggedInUsersProfile) {
      setProfilePic('');
      setUserProfile({ ...emptyProfileData, name: user.name });
    }
  };

  const handleSave = () => {
    if (updateProfile && edit) {
      updateProfile(userProfile);
    }
    handleClose();
  };

  const hasErrors = () => {
    if (
      !userProfile.country ||
      userProfile.languages.length <= 0 ||
      !userProfile.role
    ) {
      return true;
    }
    return false;
  };

  const handleProfilePicInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const imageInput = event.target as HTMLInputElement;
    if (!imageInput || !imageInput.files) {
      return;
    }

    setProfilePic(URL.createObjectURL(imageInput.files[0]));
    setUserProfile({ ...userProfile, profilePicture: imageInput.files[0] });
  };

  const filterCountryOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: { name: string; emoji: string; code: string }) =>
      option.name,
  });

  const editProfile = (
    <Box>
      <Box display="flex">
        <Box>
          <Avatar
            onMouseEnter={() => {
              setEditPicture(true);
            }}
            src={profilePic}
            className={classes.profilePic}
          ></Avatar>
          <Box
            className={
              editPicture ? classes.showEditPicture : classes.hideEditPicture
            }
            onMouseLeave={() => {
              setEditPicture(false);
            }}
          >
            <input
              accept={'image/*'}
              className={classes.input}
              id={`contained-button-profilepic`}
              type="file"
              onInput={handleProfilePicInput}
            />
            <label htmlFor={`contained-button-profilepic`}>
              <Button component="span" className={classes.editProfilePic}>
                <Typography
                  variant="subtitle2"
                  component="p"
                  className={classes.editProfilePicText}
                >
                  <Messages id="profile.profilePicture" />
                </Typography>
              </Button>
            </label>
          </Box>
        </Box>
        <Box pt={4} pl={5}>
          <Box display="flex">
            <Autocomplete
              id="honorific"
              freeSolo
              options={honorifics}
              classes={{ input: classes.honorificText }}
              renderInput={(params) => <TextField {...params} />}
              className={classes.honorificTextBox}
              value={userProfile.honorific ? userProfile.honorific : ''}
              onChange={(_e, value) => {
                value
                  ? setUserProfile({ ...userProfile, honorific: value })
                  : setUserProfile({ ...userProfile, honorific: '' });
              }}
              onInput={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setUserProfile({ ...userProfile, honorific: value });
              }}
            />
            <TextField
              required
              id="name"
              value={userProfile.name}
              onInput={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setUserProfile({ ...userProfile, name: value });
              }}
              classes={{
                root: classes.honorificText,
              }}
            />
          </Box>

          <Box pt={4} maxWidth={'425px'}>
            <Autocomplete
              id="roles"
              freeSolo
              disableClearable
              options={roles}
              value={userProfile.role || ''}
              onChange={(_e, newValue) => {
                setUserProfile({ ...userProfile, role: newValue || '' });
              }}
              onInput={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setUserProfile({ ...userProfile, role: value });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push(params.inputValue);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <Box>
                  <InputLabel
                    htmlFor="my-roles"
                    required
                    className={classes.infoProfileLabel}
                    error={!userProfile.role}
                  >
                    <Messages id="profile.role" />
                  </InputLabel>
                  <TextField
                    error={!userProfile.role}
                    {...params}
                    required
                    classes={{ root: classes.autocompleteInput }}
                  />
                </Box>
              )}
            />
            <Box pt={4}>
              <InputLabel htmlFor="org" className={classes.infoProfileLabel}>
                <Messages id="profile.organization" />
              </InputLabel>
              <TextField
                id="org"
                fullWidth
                value={userProfile.organization}
                classes={{ root: classes.autocompleteInput }}
                onInput={(e) => {
                  const value = (e.target as HTMLTextAreaElement).value;
                  setUserProfile({ ...userProfile, organization: value });
                }}
              />
            </Box>
            <Box pt={4}>
              <Autocomplete
                id="country"
                disableClearable
                options={countries}
                getOptionLabel={(option) =>
                  `${option.emoji}${' '}${option.name}`
                }
                value={countries.find(
                  (country) => country.code === userProfile.country
                )}
                filterOptions={(options, value) => {
                  const filtered = options.filter((opt) => opt.code);
                  return filterCountryOptions(filtered, value);
                }}
                onChange={(_e, newValue) => {
                  setUserProfile({
                    ...userProfile,
                    country: newValue.code || '',
                  });
                }}
                renderInput={(params) => (
                  <Box>
                    <InputLabel
                      htmlFor="my-country"
                      required
                      className={classes.infoProfileLabel}
                      error={!userProfile.country}
                    >
                      <Messages id="profile.country" />
                    </InputLabel>
                    <TextField
                      error={!userProfile.country}
                      {...params}
                      required
                      classes={{ root: classes.autocompleteInput }}
                    />
                  </Box>
                )}
              />
            </Box>

            <Box pt={4}>
              <Autocomplete
                id="language"
                multiple
                options={ISO6391.getAllNames()}
                value={userProfile.languages.map((language) =>
                  ISO6391.getName(language)
                )}
                onChange={(_e, newValue) => {
                  setUserProfile({
                    ...userProfile,
                    languages: newValue.map((language) =>
                      ISO6391.getCode(language)
                    ),
                  });
                }}
                renderInput={(params) => (
                  <Box>
                    <InputLabel
                      htmlFor="my-language"
                      required
                      className={classes.infoProfileLabel}
                      error={!userProfile.country}
                    >
                      <Messages id="profile.editLanguages" />
                    </InputLabel>
                    <TextField
                      error={userProfile.languages.length <= 0}
                      {...params}
                      classes={{ root: classes.autocompleteInput }}
                      required
                    />
                  </Box>
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <CustomChip {...getTagProps({ index })} label={option} />
                  ));
                }}
              />
            </Box>
            {/* <Box pt={2}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={userProfile.isAnonymous}
                      size="small"
                      onChange={() =>
                        setUserProfile({
                          ...userProfile,
                          isAnonymous: !userProfile.isAnonymous,
                        })
                      }
                    />
                  }
                  label={<Messages id="profile.anonymous" />}
                  classes={{ label: classes.checkboxAnonymous }}
                />
              </FormGroup>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const profile = (
    <>
      {userProfileData && (
        <Box>
          <Box display="flex">
            <Avatar src={profilePic} className={classes.profilePic}></Avatar>
            <Box pt={2} pl={5}>
              <Box display="flex">
                <Typography variant="h4" component="h2">
                  {userProfileData.honorific &&
                    `${userProfileData.honorific}. `}
                  {userProfileData.name}
                </Typography>
                {changeable && (
                  <IconButton
                    onClick={() => setEdit(true)}
                    className={classes.editButton}
                  >
                    <SettingsIcon />
                  </IconButton>
                )}
              </Box>
              <Typography variant="body2" component="p" gutterBottom>
                {userProfileData.role}
              </Typography>
              <Box display="flex">
                <Box className={classes.infoProfileBox} pr={7}>
                  <LocationOnOutlinedIcon
                    fontSize="small"
                    className={classes.infoIcon}
                  />
                  <Typography variant="body2" component="p">
                    {
                      countries.find(
                        (country) => country.code === userProfile.country
                      )?.name
                    }
                  </Typography>
                </Box>
                {userProfileData.organization && (
                  <Box className={classes.infoProfileBox}>
                    <BusinessCenterOutlinedIcon
                      fontSize="small"
                      className={classes.infoIcon}
                    />
                    <Typography variant="body2" component="p">
                      {userProfileData.organization}
                    </Typography>
                  </Box>
                )}
              </Box>
              {/* <Button
                color="primary"
                variant="contained"
                size="small"
                className={classes.messageButton}
              >
                Message
              </Button> */}
            </Box>
          </Box>
          <Box pt={3}>
            <Typography
              variant="subtitle2"
              component="p"
              id="mutiple-languages-label"
              className={classes.infoProfileLabel}
              gutterBottom
            >
              <Messages id="profile.languages" />
            </Typography>
            <Box className={classes.languagesBox}>
              {userProfileData.languages.map((code) => (
                <Chip
                  key={code}
                  label={ISO6391.getName(code)}
                  size="small"
                  color="secondary"
                  className={classes.languagesChip}
                />
              ))}
            </Box>
          </Box>
          <Box pt={2}>
            <Typography
              variant="subtitle2"
              component="span"
              id="mutiple-languages-label"
              className={classes.infoProfileLabel}
              gutterBottom
            >
              {/* <Checkbox
                checked={myUserProfile.isAnonymous}
                size="small"
                className={classes.checkBoxCursor}
              />
              <Messages id="profile.anonymous" /> */}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );

  if (isLoading) {
    return (
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="md">
        {isLoading && (
          <DialogContent>
            <Box my={5}>
              <LinearProgress />
            </Box>
          </DialogContent>
        )}
      </Dialog>
    );
  }

  const resendInvite = async () => {
    await dispatch(resendInviteUser(user.id));
    setInviteSent(true);
  };

  const canInvite = () => {
    const eConfirm =
      typeof emailConfirmed === 'string'
        ? emailConfirmed === 'Yes'
        : !!emailConfirmed;
    return !isLoggedInUsersProfile && !eConfirm;
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="md">
        <>
          <DialogContent>
            {!changeable && !userProfileData && (
              <Box mt={4} textAlign="center">
                <Typography
                  variant="h6"
                  component="p"
                  id="mutiple-languages-label"
                  gutterBottom
                >
                  This user is missing a profile
                </Typography>
              </Box>
            )}
            {edit && changeable && editProfile}
            {!edit && profile}
          </DialogContent>

          <DialogActions style={{ display: 'inline' }}>
            {canInvite() && !inviteSent && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.inviteButton}
                onClick={resendInvite}
                disabled={resendInviteLoading}
              >
                <Message id="profile.sendInvite" />
              </Button>
            )}
            {resendInviteLoading && (
              <CircularProgress size={12} color="primary" />
            )}
            {canInvite() && inviteSent && (
              <Button
                color="primary"
                variant="text"
                disableElevation
                className={classes.inviteButton}
                endIcon={<Done fontSize="small" />}
              >
                Sent
              </Button>
            )}
            {!changeable && !userProfileData && (
              <Button
                color="primary"
                onClick={() => onClose()}
                style={{ float: 'right' }}
              >
                <Messages id="siteSettings.settingsDialogueBtn" />
              </Button>
            )}
            {(userProfileData || changeable) && (
              <Button
                color="primary"
                onClick={() => handleSave()}
                disabled={edit ? hasErrors() : false}
                style={{ float: 'right' }}
              >
                {edit && <Messages id="siteSettings.save" />}
                {!edit && <Messages id="siteSettings.settingsDialogueBtn" />}
              </Button>
            )}
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
};

export default UserProfile;
